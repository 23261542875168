import { Drawer, Badge, Collapse, Divider, Button, Spin } from "antd";
import sampleimage from "../../assets/placeholder.svg";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import opennow from "../../assets/opennow.svg";
import address from "../../assets/address.svg";
import editicon from "../../assets/editicon.svg";
import rates from "../../assets/rates.svg";
import allTransactions from "../../assets/allTransactions.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { GET_SIGNED_URL } from "./addLocation/graphql";
import { useState, useEffect } from "react";

const { Panel } = Collapse;

function LocationDrawer(props) {
  const navigate = useNavigate();
  const [arrow, setArrow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getSignedUrl] = useMutation(GET_SIGNED_URL);
  const [fileList, setFileList] = useState([]);
  const timeNow = moment().format("YYYY-MM-DD");
  const startTime = moment(props.currentItem.details.open_time_tz).format(
    "HH:MM"
  );
  const endTime = moment(props.currentItem.details.close_time_tz).format(
    "HH:MM"
  );
  let startTimeF = moment(timeNow + " " + startTime);
  let endTimef = moment(timeNow + " " + endTime);

  useEffect(() => {
    if (props) {
      getCases(props.currentItem);
    }
    // eslint-disable-next-line
  }, [props]);

  const getCases = async (props) => {
    let fileListArray = [];
    if (props.details?.cover_image && props.details?.cover_image.length > 0) {
      props.details?.cover_image
        // eslint-disable-next-line
        .forEach(async (file, index) => {
          setLoading(true);
          let responseUrl = await getResponseUrl(file);
          fileListArray.push({
            uid: moment().valueOf(),
            name: file,
            status: "done",
            url: responseUrl,
          });
        });

      // setFileName(props.details?.details?.cover_image);
    }
    // if (
    //   props.details?.cover_fileList &&
    //   props.details?.cover_fileList.length > 0
    // ) {
    //   props.details?.cover_image
    //     // eslint-disable-next-line
    //     .forEach(async (file, index) => {
    //       if (
    //         props.details?.cover_fileList[index].status === "done" &&
    //         props.details?.response_list.length > 0
    //       ) {
    //         let url = Object.values(props.details?.response_list[index]);
    //         let fileName = url[0].split("/");
    //         let responseUrl = await getResponseUrl(
    //           fileName[fileName.length - 1]
    //         );
    //         fileListArray.push({
    //           uid: props.details?.cover_fileList[index].uid,
    //           name: file,
    //           status: props.details?.cover_fileList[index].status,
    //           url: responseUrl,
    //         });
    //       }
    //     });
    // }
    setFileList(fileListArray);
    setLoading(false);
  };

  const getResponseUrl = (file) => {
    return new Promise((resolve) => {
      getSignedUrl({
        variables: {
          fileName: file,
        },
      }).then((res) => {
        resolve(res?.data?.get_signed_url?.url);
      });
    });
  };

  const onClose = () => {
    props.setOpenDrawer(false);
    props.setCurrentItem([]);
  };

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <Spin spinning={loading}>
      <Drawer
        title={props.currentItem ? props.currentItem.locationName : "title"}
        width={400}
        placement="right"
        closable={true}
        onClose={onClose}
        open={props.openDrawer}
        rootStyle={{}}
      >
        <div className="slider">
          <Slider afterChange={onChange} {...settings}>
            {fileList && fileList.length > 0 ? (
              fileList.map((item, index) => {
                return (
                  <div>
                    <img
                      src={fileList[index].url}
                      style={{ height: 203 }}
                      alt="Kabadiwalla Connect"
                      className="mx-auto "
                    />
                  </div>
                );
              })
            ) : (
              <div>
                <img
                  src={sampleimage}
                  style={{ width: 375, height: 203 }}
                  alt="Kabadiwalla Connect"
                  className="mx-auto "
                />
              </div>
            )}
          </Slider>
        </div>
        <div className="collectionDrawer mt-8">
          <p className="h-7">
            <span className="text-base font-semibold float-left">
              About Collection Point
            </span>
            <span className="float-right">
              <Badge
                count={
                  props.currentItem.userRating !== "-"
                    ? props.currentItem.userRating
                    : "Unrated"
                }
                style={{
                  background: "#D1FAE5",
                  color: "#065F46",
                  // width: "35px",
                }}
              ></Badge>
            </span>
          </p>
          {props.currentItem.details.open_time_tz &&
            props.currentItem.details.close_time_tz &&
            moment(props.currentItem.details.open_time_tz).format("h:mm A") !==
              "Invalid date" &&
            moment(props.currentItem.details.close_time_tz).format("h:mm A") !==
              "Invalid date" && (
              <p>
                <img src={opennow} alt="opennow" />

                <span className="text-sm font-semibold align-middle ml-4">
                  {moment().isBetween(startTimeF, endTimef) ? (
                    "Open now"
                  ) : (
                    <span className="text-red">Closed</span>
                  )}
                </span>
                <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                <span className="align-middle font-medium text-sm">
                  {moment(props.currentItem.details.open_time_tz).format(
                    "h:mm A"
                  )}{" "}
                  -{" "}
                  {moment(props.currentItem.details.close_time_tz).format(
                    "h:mm A"
                  )}
                </span>
              </p>
            )}
          {props.currentItem.details.address && (
            <p>
              <span className="float-left">
                <img src={address} alt="address" />
              </span>
              <span className="text-sm font-medium align-middle ml-8 block">
                {props.currentItem.details.address}
              </span>
            </p>
          )}
          {props.currentItem.collection_materials &&
            props.currentItem.collection_materials.length > 0 && (
              <Collapse bordered={false} onChange={() => setArrow(!arrow)}>
                <img src={rates} alt="rates" className="float-left mr-4" />
                <Panel
                  style={{ marginLeft: "21px" }}
                  showArrow={false}
                  header={
                    <span className="text-sm font-medium">
                      Approximate Rates{" "}
                      {!arrow ? (
                        <DownOutlined className="ml-8" />
                      ) : (
                        <UpOutlined className="ml-8" />
                      )}
                    </span>
                  }
                >
                  {props.currentItem.collection_materials.map((item) => {
                    return (
                      <p className="mb-0 mt-1">
                        <span>{item.material.name} </span>- Rs.{" "}
                        <span>{item.price}</span>/kg
                      </p>
                    );
                  })}
                </Panel>
              </Collapse>
            )}
          <Button
            type="link"
            className="mt-4 p-0"
            onClick={() =>
              navigate(`/locations/editLocations/about/${props.currentItem.id}`)
            }
          >
            <img src={editicon} alt="editIcon" />
            <span className="text-sm font-semibold align-middle text-green ml-3">
              Manage Location Details
            </span>
          </Button>
          <Divider />
          <p className="h-7">
            <span className="text-base font-semibold ">Summary Stats</span>
          </p>
          <p>
            <span className="text-sm font-semibold align-middle">
              Number of purchases
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>
            <span className="align-middle font-medium text-sm">
              {props.currentItem.transaction}
            </span>
          </p>
          <p>
            <span className="text-sm font-semibold align-middle">
              Total Volume Purchased
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>
            <span className="align-middle font-medium text-sm">
              {props.currentItem.volumePurchased} kg
            </span>
          </p>
          <p>
            <span className="text-sm font-semibold align-middle">
              Total Revenue Generated
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>
            <span className="align-middle font-medium text-sm">{`INR ${props.currentItem.revenue}`}</span>
          </p>
          <Button
            type="link"
            className="mt-3 p-0"
            onClick={() =>
              navigate(
                `/locations/editLocations/transactions/${props.currentItem.id}`
              )
            }
          >
            <img src={allTransactions} alt="allTransactions" />
            <span className="text-sm font-semibold align-middle text-green ml-3">
              View all transactions
            </span>
          </Button>
        </div>
      </Drawer>
    </Spin>
  );
}

export default LocationDrawer;
