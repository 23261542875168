import { Route, Routes } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import PrivateRoute from "./privateRoute";
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import VendorDashboard from "../pages/vendorDashboard";
import Location from "../pages/location";
import AddLocation from "../pages/location/addLocation/about";
import Address from "../pages/location/addLocation/address";
import MaterialsHandled from "../pages/location/addLocation/materialsHandled";
import AdditionalInfo from "../pages/location/addLocation/additionalInfo";
import Transactions from "../pages/location/addLocation/transactions";
import SuggestedLocations from "../pages/location/suggestedLocations";
import Users from "../pages/users";
import SalesDetails from "../pages/users/viewSalesDetails/sales";
import CouponDetails from "../pages/users/viewSalesDetails/coupon";
import Rewards from "../pages/rewards";
import VendorDetails from "../pages/rewards/addRewards/vendor";
import CampaignDetails from "../pages/rewards/addRewards/campaign";
import Financials from "../pages/rewards/addRewards/financials";
import Outstandings from "../pages/rewards/addRewards/outstanding";
import PastPayments from "../pages/rewards/addRewards/pastPayments";
import Sales from "../pages/sales";
import Apartments from "../pages/apartments";
import ApartmentDetails from "../pages/apartments/addApartment/apartment";
import ResidentList from "../pages/apartments/addApartment/residentList";
import GlobalMaterial from "../pages/globalMaterials";
import VendorLogin from "../pages/login/vendorLogin";
import VendorError from "../components/vendorError";
import VendorLoginError from "../components/vendorLoginError";
import Verification from "../pages/emailVerification/verification";

export default function AppRoutes() {
  return (
    <main>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/vendor/login" element={<VendorLogin />} />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute
              heading={"Dashboard"}
              component={Dashboard}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations"
          element={
            <PrivateRoute
              heading={"Locations"}
              component={Location}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/addLocations/about"
          element={
            <PrivateRoute
              heading={""}
              component={AddLocation}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/addLocations/about/:id"
          element={
            <PrivateRoute
              heading={""}
              component={AddLocation}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/addLocations/address"
          element={
            <PrivateRoute heading={""} component={Address} roles={["admin"]} />
          }
        />
        <Route
          path="/locations/addLocations/address/:id"
          element={
            <PrivateRoute heading={""} component={Address} roles={["admin"]} />
          }
        />
        <Route
          path="/locations/addLocations/materials-handled"
          element={
            <PrivateRoute
              heading={""}
              component={MaterialsHandled}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/addLocations/materials-handled/:id"
          element={
            <PrivateRoute
              heading={""}
              component={MaterialsHandled}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/addLocations/addtional-info"
          element={
            <PrivateRoute
              heading={""}
              component={AdditionalInfo}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/addLocations/addtional-info/:id"
          element={
            <PrivateRoute
              heading={""}
              component={AdditionalInfo}
              roles={["admin"]}
            />
          }
        />
        {/* <Route
          path="/locations/addLocations/transactions"
          element={
            <PrivateRoute
              heading={""}
              component={Transactions}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/addLocations/transactions/:id"
          element={
            <PrivateRoute
              heading={""}
              component={Transactions}
              roles={["admin"]}
            />
          }
        /> */}
        <Route
          path="/locations/editLocations/about"
          element={
            <PrivateRoute
              heading={""}
              component={AddLocation}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/editLocations/about/:id"
          element={
            <PrivateRoute
              heading={""}
              component={AddLocation}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/editLocations/address"
          element={
            <PrivateRoute heading={""} component={Address} roles={["admin"]} />
          }
        />
        <Route
          path="/locations/editLocations/address/:id"
          element={
            <PrivateRoute heading={""} component={Address} roles={["admin"]} />
          }
        />
        <Route
          path="/locations/editLocations/materials-handled"
          element={
            <PrivateRoute
              heading={""}
              component={MaterialsHandled}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/editLocations/materials-handled/:id"
          element={
            <PrivateRoute
              heading={""}
              component={MaterialsHandled}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/editLocations/addtional-info"
          element={
            <PrivateRoute
              heading={""}
              component={AdditionalInfo}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/editLocations/addtional-info/:id"
          element={
            <PrivateRoute
              heading={""}
              component={AdditionalInfo}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/editLocations/transactions"
          element={
            <PrivateRoute
              heading={""}
              component={Transactions}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/locations/editLocations/transactions/:id"
          element={
            <PrivateRoute
              heading={""}
              component={Transactions}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/suggestedLocations"
          element={
            <PrivateRoute
              heading={"Suggested Locations"}
              component={SuggestedLocations}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute
              heading={"Users"}
              component={Users}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/users/viewUser/sales"
          element={
            <PrivateRoute
              heading={""}
              component={SalesDetails}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/users/viewUser/sales/:id"
          element={
            <PrivateRoute
              heading={""}
              component={SalesDetails}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/users/viewUser/coupons"
          element={
            <PrivateRoute
              heading={""}
              component={CouponDetails}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/users/viewUser/coupons/:id"
          element={
            <PrivateRoute
              heading={""}
              component={CouponDetails}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/sales"
          element={
            <PrivateRoute
              heading={"Sales"}
              component={Sales}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/rewards"
          element={
            <PrivateRoute
              heading={"Rewards"}
              component={Rewards}
              roles={["admin"]}
            />
          }
        />
        {/* <Route
          path="/rewards/drawer/:id"
          element={
            <PrivateRoute
              heading={"Rewards"}
              component={Rewards}
              roles={["admin"]}
            />
          }
        /> */}
        <Route
          path="/rewards/addRewards/vendorDetails"
          element={<PrivateRoute component={VendorDetails} roles={["admin"]} />}
        />
        <Route
          path="/rewards/addRewards/vendorDetails/:id"
          element={<PrivateRoute component={VendorDetails} roles={["admin"]} />}
        />

        <Route
          path="/rewards/addRewards/campaignDetails"
          element={
            <PrivateRoute component={CampaignDetails} roles={["admin"]} />
          }
        />
        <Route
          path="/rewards/addRewards/campaignDetails/:id"
          element={
            <PrivateRoute component={CampaignDetails} roles={["admin"]} />
          }
        />
        <Route
          path="/rewards/addRewards/campaignDetails/:id/:rewardId"
          element={
            <PrivateRoute component={CampaignDetails} roles={["admin"]} />
          }
        />
        <Route
          path="/rewards/addRewards/financials"
          element={<PrivateRoute component={Financials} roles={["admin"]} />}
        />
        <Route
          path="/rewards/addRewards/financials/:id"
          element={<PrivateRoute component={Financials} roles={["admin"]} />}
        />
        <Route
          path="/rewards/addRewards/outstanding"
          element={<PrivateRoute component={Outstandings} roles={["admin"]} />}
        />
        <Route
          path="/rewards/addRewards/outstanding/:id"
          element={<PrivateRoute component={Outstandings} roles={["admin"]} />}
        />
        <Route
          path="/rewards/addRewards/pastPayments"
          element={<PrivateRoute component={PastPayments} roles={["admin"]} />}
        />
        <Route
          path="/rewards/addRewards/pastPayments/:id"
          element={<PrivateRoute component={PastPayments} roles={["admin"]} />}
        />
        <Route
          path="/rewards/editRewards/vendorDetails/:id"
          element={<PrivateRoute component={VendorDetails} roles={["admin"]} />}
        />
        <Route
          path="/rewards/editRewards/campaignDetails/:id"
          element={
            <PrivateRoute component={CampaignDetails} roles={["admin"]} />
          }
        />
        <Route
          path="/rewards/editRewards/campaignDetails/:id/:rewardId"
          element={
            <PrivateRoute
              heading={"Rewards"}
              component={CampaignDetails}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/rewards/editRewards/financials/:id"
          element={<PrivateRoute component={Financials} roles={["admin"]} />}
        />
        <Route
          path="/rewards/editRewards/outstanding/:id"
          element={<PrivateRoute component={Outstandings} roles={["admin"]} />}
        />
        <Route
          path="/rewards/editRewards/pastPayments/:id"
          element={<PrivateRoute component={PastPayments} roles={["admin"]} />}
        />

        <Route
          path="/apartments"
          element={
            <PrivateRoute
              heading={"Apartments"}
              component={Apartments}
              roles={["admin"]}
            />
          }
        />

        <Route
          path="/apartments/addApartment/details"
          element={
            <PrivateRoute
              heading={""}
              component={ApartmentDetails}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/apartments/addApartment/residentList"
          element={
            <PrivateRoute
              heading={""}
              component={ResidentList}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/apartments/editApartment/details/:id"
          element={
            <PrivateRoute
              heading={""}
              component={ApartmentDetails}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/apartments/editApartment/residentList/:id"
          element={
            <PrivateRoute
              heading={""}
              component={ResidentList}
              roles={["admin"]}
            />
          }
        />
        <Route
          path="/globalMaterials"
          element={
            <PrivateRoute
              heading={"Global Materials"}
              component={GlobalMaterial}
              roles={["admin"]}
            />
          }
        />

        <Route
          path="/vendor/dashboard"
          element={
            <PrivateRoute
              heading={"Dashboard"}
              component={VendorDashboard}
              roles={["vendor"]}
            />
          }
        />
        <Route
          path="/vendor/dashboard/:code"
          element={
            <PrivateRoute
              heading={"Dashboard"}
              component={VendorDashboard}
              roles={["vendor"]}
            />
          }
        />
        <Route path="*" element={<ErrorBoundary />} />
        <Route path="/vendorError" element={<VendorError />} />
        <Route path="/VendorLoginError" element={<VendorLoginError />} />
        <Route path="/verify_email/:id" element={<Verification />} />
      </Routes>
    </main>
  );
}
