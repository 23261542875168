import { useQuery, useMutation } from "@apollo/client";
import { Button, Spin, Card, Radio, Modal, message } from "antd";
import editicon from "../../assets/editicon.svg";
import { useEffect, useState } from "react";
import { GET_REWARDS } from "./graphql";
import { UPDATE_REWARDS_STATUS, GET_SIGNED_URL } from "./addRewards/graphql";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function AvailableCoupons(props) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [elementId, setElementId] = useState();
  const [selectedValue, setSelectedValue] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [updateStatus] = useMutation(UPDATE_REWARDS_STATUS);
  const [getSignedUrl] = useMutation(GET_SIGNED_URL);

  const {
    data: rewardDetails,
    loading: rewardLoading,
    refetch,
  } = useQuery(GET_REWARDS, {
    variables: {
      id: props.currentItem.id,
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (rewardDetails) {
      let newArray = [];
      let statusList = [];
      rewardDetails.rewards.map((item) => {
        var start = moment().startOf("day");
        var end = moment(item.details.expiryDate).endOf("day");

        newArray.push({
          id: item.id,
          bgcolor: item.details.cardColor,
          unlockPoints: item.details.pointsRequired,
          description: item.name,
          expiryDate: end.diff(start, "days"),
          logo: props.avatar,
          vendorStatus: item.vendor.status,
          status:
            end.diff(start, "days") <= 0
              ? "expired"
              : item.total_used_number >= parseInt(item.details.totalCount)
              ? "disabled"
              : item.status,
          couponUsed: item.total_used_number,
          totalCount: item.details.totalCount,
          statusData: item.status,
        });
        statusList.push(item.status);
      });
      newArray.sort((a, b) =>
        a.id == props.currentItem.camid
          ? -1
          : b.id == props.currentItem.camid
          ? 1
          : 0
      );
      setData(newArray);
    }
    // eslint-disable-next-line
  }, [rewardDetails]);

  const showModal = (value, id) => {
    if (value === "disabled") {
      setValue(value);
      setElementId(id);
      setIsModalOpen(true);
    } else {
      updateStatus({
        variables: {
          id: parseInt(id),
          status: value,
        },
      }).then(() => {
        message.open({
          type: "success",
          content: "Campaign status updated successfully",
        });
        setTimeout(() => window.location.reload(), 1000);
      });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    updateStatus({
      variables: {
        id: parseInt(elementId),
        status: value,
      },
    }).then(() => {
      message.open({
        type: "success",
        content: "Campaign status updated successfully",
      });
      setTimeout(() => window.location.reload(), 1000);
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Spin spinning={rewardLoading || loading}>
      <div>
        {data.map((element, index) => {
          return (
            <div className="flex flex-col mb-4">
              <Card
                style={{
                  width: 350,
                  backgroundColor: `${element.bgcolor}`,
                }}
                className="cursor-pointer"
              >
                <div className="flex flex-row">
                  <div className="w-1/2 flex flex-col justify-between">
                    <p className="text-white opacity-60 text-xs font-bold mb-0">
                      {`Unlock for ${element.unlockPoints} points`}
                    </p>
                    <p className="text-white text-base font-bold break-words">
                      {`${element.description}`}
                    </p>
                    {element.expiryDate <= 0 ? (
                      <p className="text-white opacity-70 text-sm font-bold">
                        Expired
                      </p>
                    ) : (
                      <p className="text-white opacity-70 text-sm font-bold">
                        {element.couponUsed >= parseInt(element.totalCount)
                          ? "Expired"
                          : `Expire in ${element.expiryDate} days`}
                      </p>
                    )}
                  </div>

                  <div className="w-1/2 flex flex-col justify-between">
                    <p className="text-right">
                      <img
                        alt="logo"
                        src={element.logo}
                        style={{
                          width: "90px",
                          height: "44px",
                          borderRadius: "5px",
                        }}
                      ></img>
                    </p>

                    <p className="text-right mt-9">
                      <span className="text-white opacity-70 text-sm font-bold underline">
                        How to claim this?
                      </span>
                    </p>
                  </div>
                </div>
              </Card>
              <div className="flex flex-row justify-between">
                <Radio.Group
                  value={element.status}
                  className="mt-2 campaignstatus"
                  disabled={
                    element.expiryDate <= 0 ||
                    element.statusData === "expired" ||
                    element.couponUsed >= parseInt(element.totalCount)
                      ? true
                      : element.vendorStatus === "disabled"
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    showModal(e.target.value, element.id);
                  }}
                >
                  <Radio value="active">Active</Radio>
                  <Radio className="disabled" value="disabled">
                    Disabled
                  </Radio>
                  <Radio className="disabled" value="expired" disabled>
                    Expired
                  </Radio>
                </Radio.Group>

                <div className="mt-2">
                  {(element.expiryDate <= 0 ||
                    element.statusData === "expired" ||
                    element.couponUsed >= parseInt(element.totalCount)) && (
                    <span className="text-red">Expired: </span>
                  )}
                  {`${element.couponUsed}/${element.totalCount}`}
                </div>
              </div>
            </div>
          );
        })}
        <Button
          type="link"
          className="mt-3 p-0 w-full text-left"
          onClick={() =>
            navigate(
              `/rewards/editRewards/campaignDetails/${props.currentItem.id}`
            )
          }
        >
          <img src={editicon} alt="editIcon" />
          <span className="text-sm font-semibold align-middle text-green ml-3">
            Manage coupon details
          </span>
        </Button>
        <Modal
          title="Confirmation"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>
            Disabling the campaign will remove it from the App's active list of
            campaigns. Those who purchased this coupon previously will still be
            able to use the coupon. Proceed to disable campaign?
          </p>
        </Modal>
      </div>
    </Spin>
  );
}

export default AvailableCoupons;
