import { useState, useMemo, useEffect } from "react";
import { Button, Badge, Spin } from "antd";
import Select from "../../components/Select";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import CountCard from "../../components/CountCard";
import Table from "../../components/Table";
import { useNavigate, useLocation } from "react-router-dom";
import LocationDrawer from "./locationDrawer";
import { GET_COLLECTION_CENTRE, GET_LOCATION_LIST } from "./graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { capitalizeFirst, numFormatter } from "../../utils";
import { getColumnSearchProps } from "../../utils";

function Location(props) {
  const navigate = useNavigate();
  const [getLocation, { data: locationDetails, loading: collectionLoading }] =
    useLazyQuery(GET_COLLECTION_CENTRE, { fetchPolicy: "no-cache" });

  const {
    data: locationList,
    loading: locationLoading,
    refetch,
  } = useQuery(GET_LOCATION_LIST);

  const location = useLocation();
  const search = useLocation().search;
  const [data, setData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchTerm, setSearchTerm] = useState({});
  const [searchLocName, setSearchLocName] = useState();
  const [category, setCategory] = useState();
  const [locality, setLocality] = useState([]);
  const [selectLocation, setselectLocation] = useState();
  const [userRating, setUserRating] = useState();
  const [collectionStatus, setCollectionStatus] = useState("Active");
  const [rejectionrRating, setRejectionrRating] = useState();
  const [noOfLocations, setNoOfLocations] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [noOfTransactions, setNoOfTransactions] = useState(0);
  const [averageVolume, setAverageVolume] = useState(0);
  const [currentItem, setCurrentItem] = useState([]);
  const [suggestedCount, setSuggestedCount] = useState();
  const [tableData, setTableData] = useState([]);
  const [bounds, setBounds] = useState();

  useEffect(() => {
    if (locationList) {
      let newList = [];
      locationList.collection_centre.map((item) => {
        if (
          item.locality !== null &&
          item.status === collectionStatus.toLowerCase()
        )
          newList.push(item.locality);
      });

      let list = newList.filter(
        (item, index) => newList.indexOf(item) === index
      );
      list.sort();
      setLocality(list);
    }
  }, [locationList, collectionStatus]);

  useEffect(() => {
    refetch();
    getLocation({
      variables: {
        where: { _and: { locality: {}, status: { _eq: "active" } } },
      },
    });
  }, []);

  useEffect(() => {
    if (Object.keys(searchTerm).length > 0) {
      getLocation({ variables: { where: searchTerm } });
    }
  }, [searchTerm]);

  useEffect(() => {
    setSearchTerm(
      new URLSearchParams(search).get("searchText") &&
        new URLSearchParams(search).get("category") === "Name"
        ? {
            locality: selectLocation
              ? { _in: selectLocation.length > 0 ? selectLocation : locality }
              : {},
            status: collectionStatus
              ? { _eq: collectionStatus.toLowerCase() }
              : { status: { _eq: "active" } },
            name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "Locality"
        ? {
            locality:
              selectLocation && searchLocName
                ? { _ilike: `%${searchLocName}%`, _in: selectLocation }
                : selectLocation
                ? { _in: selectLocation.length > 0 ? selectLocation : locality }
                : searchLocName
                ? { _ilike: `%${searchLocName}%` }
                : {},
            status: collectionStatus
              ? { _eq: collectionStatus.toLowerCase() }
              : { status: { _eq: "active" } },
            name: {},
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "locations ID"
        ? {
            locality: selectLocation
              ? { _in: selectLocation.length > 0 ? selectLocation : locality }
              : {},
            status: collectionStatus
              ? { _eq: collectionStatus.toLowerCase() }
              : { status: { _eq: "active" } },
            // id: !isNaN(searchLocName) ? { _eq: parseInt(searchLocName) } : {},
            name: {},
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "null"
        ? {
            _and: [
              {
                status: collectionStatus
                  ? { _eq: collectionStatus.toLowerCase() }
                  : { status: { _eq: "active" } },
              },
              {
                _or: [
                  {
                    locality:
                      selectLocation && searchLocName
                        ? { _ilike: `%${searchLocName}%`, _in: selectLocation }
                        : selectLocation
                        ? {
                            _in:
                              selectLocation.length > 0
                                ? selectLocation
                                : locality,
                          }
                        : searchLocName
                        ? { _ilike: `%${searchLocName}%` }
                        : {},
                  },
                  // {
                  //   id: !isNaN(searchLocName)
                  //     ? { _eq: parseInt(searchLocName) }
                  //     : {},
                  // },
                  {
                    name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
                  },
                ],
              },
            ],
          }
        : {
            locality: selectLocation
              ? { _in: selectLocation.length > 0 ? selectLocation : locality }
              : {},
            status: collectionStatus
              ? { _eq: collectionStatus.toLowerCase() }
              : { status: { _eq: "active" } },
          }
    );
  }, [selectLocation, collectionStatus, searchLocName, category]);

  useEffect(() => {
    setSearchLocName(new URLSearchParams(search).get("searchText"));
    setCategory(new URLSearchParams(search).get("category"));
  }, [location]);

  useEffect(() => {
    if (locationDetails) {
      let newList = [];
      let locationList = [];
      locationDetails.collection_centre.map((item) => {
        let sum = 0;

        if (item.sales.length > 0) {
          item.sales.map((ele) => {
            if (ele.status === "Approved") {
              return (sum += ele?.sales?.aggregate?.sum?.actual_quantity);
            }
          });
        }
        console.log(item?.approved?.aggregate?.count);
        newList.push({
          id: item.id,
          locationID: item.id,
          locationName: capitalizeFirst(item.name),
          locality: item.locality,
          details: item.details,
          volumePurchased: sum > 0 ? sum.toFixed(2) : 0,
          transaction: item?.approved?.aggregate?.count,
          avgVol:
            sum > 0 ? (sum / item?.approved?.aggregate?.count).toFixed(2) : "",
          rejectionRate:
            item?.rejection?.aggregate?.count > 0
              ? (
                  (item?.rejection?.aggregate?.count * 100) /
                  item?.totalSale?.aggregate?.count
                ).toFixed(2)
              : 0,
          revenue:
            item?.totalSale?.aggregate?.sum?.total_price > 0
              ? item?.totalSale?.aggregate?.sum?.total_price
              : 0,
          // userRating: item.rating,
          lat: item.lat ? item.lat : null,
          long: item.long ? item.long : null,
          collection_materials: item.collection_materials,
          userRating:
            item.collection_center_ratings_aggregate?.aggregate?.sum?.rate &&
            item.collection_center_ratings_aggregate?.aggregate?.count > 0
              ? (
                  item.collection_center_ratings_aggregate?.aggregate?.sum
                    ?.rate /
                  item.collection_center_ratings_aggregate?.aggregate?.count
                ).toFixed(1)
              : "-",
        });
        locationList.push(item.locality);
      });
      if (userRating && rejectionrRating) {
        let ratingValue = rejectionrRating.split("-");
        let userratingValue = userRating.split("-");

        let DisplayData = [];
        if (userRating !== "unrated") {
          DisplayData = newList?.filter(
            (ele) =>
              parseFloat(ele.rejectionRate) >= parseFloat(ratingValue[0]) &&
              parseFloat(ele.rejectionRate) <= parseFloat(ratingValue[1]) &&
              parseFloat(ele.userRating) >= parseFloat(userratingValue[0]) &&
              parseFloat(ele.userRating) <= parseFloat(userratingValue[1])
          );
        } else {
          DisplayData = newList?.filter(
            (ele) =>
              parseFloat(ele.rejectionRate) >= parseFloat(ratingValue[0]) &&
              parseFloat(ele.rejectionRate) <= parseFloat(ratingValue[1]) &&
              ele.userRating === "-"
          );
        }

        setData(DisplayData);
      } else if (rejectionrRating) {
        let ratingValue = rejectionrRating.split("-");
        const DisplayData = newList?.filter(
          (ele) =>
            parseFloat(ele.rejectionRate) >= parseFloat(ratingValue[0]) &&
            parseFloat(ele.rejectionRate) <= parseFloat(ratingValue[1])
        );

        setData(DisplayData);
      } else if (userRating) {
        let userratingValue = userRating.split("-");
        let DisplayData = [];
        if (userRating !== "Unrated") {
          DisplayData = newList?.filter(
            (ele) =>
              parseFloat(ele.userRating) >= parseFloat(userratingValue[0]) &&
              parseFloat(ele.userRating) <= parseFloat(userratingValue[1])
          );
        } else {
          DisplayData = newList?.filter((ele) => ele.userRating === "-");
        }
        setData(DisplayData);
      } else {
        setData(newList);
      }
      setSuggestedCount(locationDetails.suggestedLocations.length);
    }
  }, [locationDetails, rejectionrRating, userRating]);

  useEffect(() => {
    let totVol = 0;
    let totTransactions = 0;
    let avgVol;
    let bounds = new props.google.maps.LatLngBounds();
    // if (data.length > 0) {
    setNoOfLocations(data.length);
    data.map((item) => {
      totVol += parseFloat(item.volumePurchased);
      totTransactions += item.transaction;
      avgVol = totVol / totTransactions;
      if (item.lat && item.long)
        bounds.extend({
          lat: parseFloat(item.lat),
          lng: parseFloat(item.long),
        });
    });
    data.sort((a, b) => {
      return b.transaction - a.transaction;
    });
    setTableData(data);
    setBounds(bounds);
    setNoOfTransactions(totTransactions);
    setTotalVolume(totVol);
    setAverageVolume(isNaN(avgVol) ? 0 : avgVol);
    // }
  }, [data]);

  const handleselectedValues = (value, type) => {
    if (type === "Locality") setselectLocation(value);
    if (type === "Rating") setUserRating(value);
    if (type === "Rejection Rate") {
      setRejectionrRating(value);
    }
    if (type === "Status" && value) {
      setCollectionStatus(value);
    } else {
      setCollectionStatus("Active");
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "LOCATION ID",
        dataIndex: "locationID",
        key: "locationID",
        className: "w-40",
        ...getColumnSearchProps("locationID"),
        render: (text) => <>{`LOC${`${text}`.padStart(6, "0")}`}</>,
      },
      {
        title: "LOCATION NAME",
        dataIndex: "locationName",
        key: "locationName",
        className: "w-40",
      },
      {
        title: "LOCALITY",
        dataIndex: "locality",
        key: "locality",
        className: "w-40",
      },
      {
        title: "VOL. PURCHASED",
        dataIndex: "volumePurchased",
        key: "volumePurchased",
        className: "w-56",
        sorter: (a, b) => a.volumePurchased - b.volumePurchased,
        render: (text) =>
          text ? (
            <>
              <span className="float-right">{numFormatter(text)} kg </span>
            </>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "TRANSACTIONS",
        dataIndex: "transaction",
        key: "transaction",
        className: "w-40",
        sorter: (a, b) => a.transaction - b.transaction,
        render: (text) =>
          text ? (
            <>
              <span className="float-right">{numFormatter(text)} </span>
            </>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "AVG. VOL / TRANSACTIONS",
        dataIndex: "avgVol",
        key: "avgVol",
        className: "w-60",
        sorter: (a, b) => a.avgVol - b.avgVol,
        render: (text) =>
          text ? (
            <span className="float-right">{numFormatter(text)}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "REJECTION RATE",
        dataIndex: "rejectionRate",
        key: "rejectionRate",
        className: "w-44",
        sorter: (a, b) => a.rejectionRate - b.rejectionRate,
        render: (text) =>
          text ? (
            <span className="float-right">{text} %</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "USER RATING",
        dataIndex: "userRating",
        key: "userRating",
        className: "w-44",
        sorter: (a, b) => a.userRating - b.userRating,
        render: (text) =>
          text ? (
            <span className="float-right">{text}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "Revenue",
        dataIndex: "revenue",
        key: "revenue",
        className: "w-40",
        sorter: (a, b) => a.revenue - b.revenue,
        render: (text) =>
          text ? (
            <span className="float-right">Rs. {numFormatter(text)}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "MANAGE",
        dataIndex: "",
        key: "",
        fixed: "right",
        width: 100,
        render: (text, record) => (
          <Button
            type="link"
            className="text-green hover:text-green"
            onClick={() => {
              setOpenDrawer(true);
              setCurrentItem(record);
            }}
            id="details"
          >
            Details
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <Spin spinning={locationLoading || collectionLoading}>
      <div className="flex flex-col gap-5 p-8 w-full justify-between ">
        <div className="flex flex-row gap-5 justify-evenly w-full ">
          <div style={{ width: "13%" }}>
            <Select
              placeholder="City"
              type="City"
              data={["Chennai"]}
              onChange={handleselectedValues}
              value="Chennai"
              // width="127px"
              allowClear={true}
            />
          </div>
          <div style={{ width: "20%" }}>
            <Select
              placeholder="Locality"
              type="Locality"
              mode="multiple"
              data={locality || []}
              onChange={handleselectedValues}
              value=""
              width=""
              allowClear={true}
            />
          </div>
          <div style={{ width: "13%" }}>
            <Select
              placeholder="Rating"
              type="Rating"
              data={[
                "1.0 - 2.0",
                "2.0 - 3.0",
                "3.0 - 4.0",
                "4.0 - 5.0",
                "Unrated",
              ]}
              onChange={handleselectedValues}
              value=""
              // width="127px"
              allowClear={true}
            />
          </div>
          <div style={{ width: "15%" }}>
            <Select
              placeholder="Rejection Rate"
              type="Rejection Rate"
              data={["0 - 20", "21 - 40", "41 - 60", "61 - 80", "81 - 100"]}
              onChange={handleselectedValues}
              symbol="%"
              value=""
              // width="127px"
              allowClear={true}
            />
          </div>
          <div style={{ width: "13%" }}>
            <Select
              placeholder="Status"
              type="Status"
              data={["Active", "Inactive", "Disabled"]}
              onChange={handleselectedValues}
              value={collectionStatus}
              // width="127px"
              allowClear={true}
            />
          </div>
          <div style={{ width: "18%" }}>
            <Button
              type="link"
              size="large"
              onClick={() => navigate("/suggestedLocations")}
              // style={{ width: "127px" }}
            >
              <span className="text-black text-semibold text-sm mr-2">
                New Suggestions
              </span>

              <Badge
                count={suggestedCount === 0 ? null : suggestedCount}
                style={{
                  background: "#27AE60",
                  color: "#FFFFFF",
                }}
              ></Badge>
            </Button>
          </div>
          <div style={{ width: "13%" }}>
            <Button
              type="primary"
              id="addlocations"
              size="large"
              onClick={() => navigate("/locations/addLocations/about")}
              // style={{ width: "127px" }}
            >
              Add Location
            </Button>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-4 gap-5">
            <CountCard
              count={noOfLocations}
              title="Number of locations"
              width="280px"
              className="CountCard"
            />
            <CountCard
              count={totalVolume}
              title="Total volume purchased (kg)"
              width="280px"
              className="CountCard"
            />
            <CountCard
              count={noOfTransactions}
              title="Total number of transactions"
              width="280px"
              className="CountCard"
            />
            <CountCard
              count={averageVolume}
              title="Average volume / transaction (kg)"
              width="280px"
              className="CountCard"
            />
          </div>
        </div>
        <div className="w-full min-h-33">
          <Map
            google={props.google}
            style={{ height: "32vh", maxWidth: "100%" }}
            containerStyle={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
            bounds={bounds}
          >
            {data &&
              data.length > 0 &&
              data.map((location, locationIndex) => {
                return (
                  <Marker
                    key={locationIndex}
                    name={location.locationName}
                    title={location.locationName}
                    id={location.id}
                    data={location}
                    position={
                      location.lat && location.long
                        ? {
                            lat: parseFloat(location.lat),
                            lng: parseFloat(location.long),
                          }
                        : {}
                    }
                    onClick={(props, marker, e) => {
                      setOpenDrawer(true);
                      setCurrentItem(props.data);
                    }}
                  />
                );
              })}
            {/* <Marker onClick={onMarkerClick} name={"Current location"} /> */}
          </Map>
        </div>
        <div className="w-full">
          <Table
            dataSource={!!tableData.length ? tableData : []}
            columns={columns}
            key="locationListing"
            pagination={true}
            scroll={{
              x: 1300,
            }}
          />
        </div>
        {openDrawer && (
          <LocationDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
          />
        )}
      </div>
    </Spin>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_API_KEY,
})(Location);
