import { Form, Input, Button, Spin, Radio, InputNumber, message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useCallback, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  INSERT_APARTMENT,
  GET_APARRTMENT_LIST,
  UPDATE_APARTMENT,
  REMOVE_THE_USER_FROM_APARTMENT,
} from "../graphql";
import TitleHeadingContext from "../../../context/TitleHeadingContext";

function Apartment() {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [insertApartment] = useMutation(INSERT_APARTMENT);
  const [updateApartment] = useMutation(UPDATE_APARTMENT);
  const [removeUser] = useMutation(REMOVE_THE_USER_FROM_APARTMENT);
  const titleHeading = useContext(TitleHeadingContext);
  let mobile = /^[0-9]{10}$/;

  const {
    data: apartmentDetails,
    loading: apartmentLoading,
    refetch,
  } = useQuery(GET_APARRTMENT_LIST, {
    variables: {
      where: { id: { _eq: parseInt(location.pathname.split("/")[4]) } },
    },
  });

  useEffect(() => {
    refetch();
    titleHeading.setTitle("");
  }, []);

  useEffect(() => {
    if (apartmentDetails) {
      titleHeading.setTitle(apartmentDetails.apartments[0].name);

      form.setFieldsValue({
        apartmentName: apartmentDetails.apartments[0].name,
        locality: apartmentDetails.apartments[0].locality,
        householdsAvailable: apartmentDetails.apartments[0].household,
        mobileNumber: apartmentDetails.apartments[0].poc_phone,
        apartmentStatus: apartmentDetails.apartments[0].status,
        locationLink: apartmentDetails.apartments[0].details.locationLink,
        pocName: apartmentDetails.apartments[0].poc_name,
        address: apartmentDetails.apartments[0].address,
      });
    }
  }, [apartmentDetails]);

  const onFinish = (values) => {
    let url = values.locationLink;
    let regex = new RegExp("@(.*),(.*),");
    let lat_long_match = url.match(regex);
    let payload = {
      name: values.apartmentName,
      locality: values.locality,
      status: values.apartmentStatus ? values.apartmentStatus : "disabled",
      household: values.householdsAvailable,
      poc_name: values.pocName,
      poc_phone: values.mobileNumber,
      address: values.address,
      lat: lat_long_match[1],
      long: lat_long_match[2],
      city_id: 1,
      details: {
        locationLink: url,
      },
    };
    if (!location.pathname.split("/")[4]) {
      insertApartment({
        variables: {
          objects: payload,
        },
      }).then(() => {
        navigate("/apartments", {
          replace: true,
        });
      });
    } else {
      updateApartment({
        variables: {
          object: payload,
          id: parseInt(location.pathname.split("/")[4]),
        },
      }).then((res) => {
        res?.data?.update_apartments?.returning[0]?.users?.map((user) => {
          removeUser({
            variables: {
              id: parseInt(user.id),
            },
          });
        });
        setTimeout(() => {
          message.open({
            type: "success",
            content: "Apartment details updated successfully",
          });
        }, 1000);
      });
    }
  };

  return (
    <Spin spinning={apartmentLoading}>
      <div className="bg-bgGray min-h-screen w-full flex justify-center items-center py-4">
        <div className="w-5/12 p-4 bg-white rounded-md shadow-3xl">
          <p className="text-lg mb-0">Apartment details</p>
          <p className="text-sm text-gray500 font-normal">
            This information is for internal use only so please review carefully
            before you save
          </p>
          <Form
            name="apartment"
            layout="vertical"
            onFinish={onFinish}
            form={form}
            requiredMark={false}
            // onValuesChange={() => setFormChange(true)}
          >
            <Form.Item
              label={<span className="text-gray700">Apartment name</span>}
              name="apartmentName"
              rules={[
                {
                  required: true,
                  message: "Please enter a apartment name",
                },
                () => ({
                  validator(_, value) {
                    // let alphanumeric = /^([ A-Za-z0-9]){1,}$/;
                    if (value && value.match(/^ *$/) !== null) {
                      return Promise.reject("Please enter a apartment name");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Apartment Address</span>}
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please enter a apartment address",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label={
                <span className="text-gray700">
                  Apartment Location (Google maps link)
                </span>
              }
              name="locationLink"
              rules={[
                () => ({
                  validator(_, value) {
                    const urlPattern = new RegExp("@(.*),(.*),");
                    // /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

                    if (value && !urlPattern.test(value)) {
                      return Promise.reject("Please enter a valid url");
                    } else if (!value) {
                      return Promise.reject(
                        "Please enter a apartment location link"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Apartment Locality</span>}
              name="locality"
              rules={[
                {
                  required: true,
                  message: "Please enter a apartment locality",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-gray700">Total number of households</span>
              }
              name="householdsAvailable"
              rules={[
                () => ({
                  validator(_, value) {
                    let alphanumeric = /^[0-9]*$/;
                    if (value && !alphanumeric.test(value)) {
                      return Promise.reject("Decimal values are not allowed");
                    } else if (!value) {
                      return Promise.reject(
                        "Please enter a Total number of households available"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber controls={false} className="w-full" />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-gray700">Point of contact (POC)</span>
              }
              name="pocName"
              rules={[
                {
                  required: true,
                  message: "Please enter a POC name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">POC Mobile Number</span>}
              name="mobileNumber"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value !== "" && !mobile.test(value)) {
                      return Promise.reject(
                        "Please enter a valid phone number"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Apartment Status</span>}
              style={{ height: 0 }}
            ></Form.Item>
            <Form.Item
              label={
                <span className="text-gray500">
                  Disabling removes apartment from dropdown selection in app
                </span>
              }
              name="apartmentStatus"
            >
              <Radio.Group defaultValue="disabled">
                <Radio value="active">Active</Radio>
                <Radio value="disabled">Disabled</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="float-right">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
}
export default Apartment;
