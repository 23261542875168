import { gql } from "@apollo/client";

export const INSERT_APARTMENT = gql`
  mutation INSERT_APARTMENT($objects: [apartments_insert_input!]!) {
    insert_apartments(objects: $objects) {
      affected_rows
    }
  }
`;

export const GET_APARRTMENT_LIST = gql`
  query GET_APARRTMENT_LIST($where: apartments_bool_exp) {
    apartments(where: $where, order_by: { id: desc }) {
      address
      household
      city_id
      city {
        name
      }
      id
      details
      poc_name
      poc_phone
      status
      created_at
      locality
      name
    }
  }
`;

export const GET_APARTMENT_LOCATION_LIST = gql`
  query GET_APARTMENT_LOCATION_LIST {
    apartments {
      locality
    }
  }
`;

export const GET_SIGNED_URL = gql`
  mutation GET_SIGNED_URL($fileName: String) {
    get_signed_url(file_name: $fileName) {
      message
      url
    }
  }
`;

export const GET_RESIDENT_LIST = gql`
  query GET_RESIDENT_LIST(
    $apartmentWhere: apartments_bool_exp
    $userWhere: users_bool_exp
  ) {
    apartments(where: $apartmentWhere) {
      name
      users(where: $userWhere, order_by: { id: asc }) {
        id
        name
        phone
        email
        details
        created_at
        sales_aggregate(where: { status: { _eq: "Approved" } }) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const REMOVE_THE_USER_FROM_APARTMENT = gql`
  mutation REMOVE_THE_USER_FROM_APARTMENT($id: bigint) {
    update_users(where: { id: { _eq: $id } }, _set: { apartment_id: null }) {
      affected_rows
    }
  }
`;

export const UPDATE_APARTMENT = gql`
  mutation UPDATE_APARTMENT($id: bigint, $object: apartments_set_input) {
    update_apartments(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
      returning {
        users {
          id
        }
      }
    }
  }
`;

export const INSERT_NOTIFICATION = gql`
  mutation INSERT_NOTIFICATION($object: [notifications_insert_input!]!) {
    insert_notifications(objects: $object) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const INSERT_NOTIFICATION_USER = gql`
  mutation INSERT_NOTIFICATION_USER(
    $object: [notification_user_insert_input!]!
  ) {
    insert_notification_user(objects: $object) {
      affected_rows
    }
  }
`;

export const SEND_PUSH_NOTIFICATIONS = gql`
  mutation SEND_PUSH_NOTIFICATIONS(
    $message: jsonb
    $title: String
    $user_ids: [Int]!
  ) {
    send_notifications(message: $message, title: $title, user_ids: $user_ids) {
      message
    }
  }
`;
