import { Button, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import Table from "../../../components/Table";
import { useLocation } from "react-router-dom";
import Tag from "../../../components/Tag";
import VendorPaymentDrawer from "./vendorPaymentDrawer";
import { numFormatter, getColumnSearchProps } from "../../../utils";
import { GET_USER_REWARD } from "./graphql";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";

function Outstandings() {
  const location = useLocation();
  const search = useLocation().search;
  const [tableData, setTableData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchTerm, setSearchTerm] = useState({});
  const [searchLocName, setSearchLocName] = useState();
  const [category, setCategory] = useState();
  const [outstandingAmount, setOutstandingAmount] = useState(0);

  const [
    getUserRewards,
    { data: userRewardDetails, loading: userrewardLoading },
  ] = useLazyQuery(GET_USER_REWARD, { fetchPolicy: "no-cache" });

  useEffect(() => {
    getUserRewards({
      variables: {
        where: {
          reward: {
            vendor_id: { _eq: parseInt(location.pathname.split("/")[4]) },
          },
        },
      },
    });
  }, []);

  useEffect(() => {
    setSearchLocName(new URLSearchParams(search).get("searchText"));
    setCategory(new URLSearchParams(search).get("category"));
  }, [location]);

  useEffect(() => {
    getUserRewards({
      variables: {
        where: searchTerm,
      },
    });
  }, [searchTerm]);

  useEffect(() => {
    setSearchTerm(
      new URLSearchParams(search).get("searchText") &&
        new URLSearchParams(search).get("category") === "Name"
        ? {
            _and: {
              reward: {
                vendor_id: { _eq: parseInt(location.pathname.split("/")[4]) },
                name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
              },
            },
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "Locality"
        ? {
            _and: {
              reward: {
                vendor_id: { _eq: parseInt(location.pathname.split("/")[4]) },
                name: {},
                location: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
              },
            },
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "null"
        ? {
            _and: {
              reward: {
                _and: [
                  {
                    vendor_id: {
                      _eq: parseInt(location.pathname.split("/")[4]),
                    },
                  },
                  {
                    _or: [
                      {
                        location: searchLocName
                          ? { _ilike: `%${searchLocName}%` }
                          : {},
                      },

                      {
                        name: searchLocName
                          ? { _ilike: `%${searchLocName}%` }
                          : {},
                      },
                    ],
                  },
                ],
              },
            },
          }
        : {
            reward: {
              vendor_id: { _eq: parseInt(location.pathname.split("/")[4]) },
            },
          }
    );
  }, [searchLocName, category]);

  useEffect(() => {
    if (userRewardDetails) {
      let newArray = [];
      let sum = 0;
      userRewardDetails.user_reward.map((item) => {
        if (item.status === "Redeemed" && !item.transaction_id) {
          newArray.push({
            campaignID: item.reward_id,
            key: item.id,
            campaignDetails: item.reward.name,
            locality: item.reward.location,
            couponStatus: item.status,
            redeemedOn: moment(item.redeemed_at).format("DD MMM YYYY"),
            amount: item.reward.details.vendorCost,
          });

          sum += parseInt(item.reward.details.vendorCost);
        }
      });

      setTableData(newArray);
      setOutstandingAmount(sum);
    }
  }, [userRewardDetails]);

  const columns = useMemo(
    () => [
      {
        title: "CAMPAIGN ID",
        dataIndex: "campaignID",
        key: "campaignID",
        className: "w-40",
        ...getColumnSearchProps("campaignID"),
        render: (text) => <>{`CAM${`${text}`.padStart(6, "0")}`}</>,
      },
      {
        title: "CAMPAIGN details",
        dataIndex: "campaignDetails",
        key: "campaignName",
        className: "w-40",
      },
      {
        title: "LOCALITY",
        dataIndex: "locality",
        key: "locality",
        className: "w-40",
      },
      {
        title: "COUPON STATUS",
        dataIndex: "couponStatus",
        key: "couponStatus",
        className: "w-40",
        render: (text) => (
          <>
            {text === "Paid" && (
              <Tag color="#D1FAE5" textColor="#065F46">
                Paid
              </Tag>
            )}
            {text === "Redeemed" && (
              <Tag color="#FEF3C7" textColor="#92400E">
                Redeemed
              </Tag>
            )}
          </>
        ),
      },
      {
        title: "REDEEMED ON",
        dataIndex: "redeemedOn",
        key: "redeemedOn",
        className: "w-40",
        sorter: (a, b) => a.redeemedOn - b.redeemedOn,
      },
      {
        title: "VENDOR COST",
        dataIndex: "amount",
        key: "amount",
        className: "w-40",
        sorter: (a, b) => a.amount - b.amount,
        render: (text) =>
          text ? (
            <>
              <span className="float-right">Rs. {numFormatter(text)} </span>
            </>
          ) : (
            <span className="float-right">-</span>
          ),
      },
    ],
    []
  );

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);

    if (newSelectedRowKeys.length > 0) {
      let DisplayData = tableData?.filter((ele) => {
        return newSelectedRowKeys.includes(ele.key);
      });
      let sum = 0;
      DisplayData.map((item) => {
        sum += parseInt(item.amount);
      });
      setOutstandingAmount(sum);
    } else {
      let sum = 0;
      tableData.map((item) => {
        sum += parseInt(item.amount);
      });
      setOutstandingAmount(sum);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleClose = () => {
    setOpenDrawer(false);
    window.location.reload();
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <Spin spinning={userrewardLoading}>
      <div className="flex flex-col gap-5 p-8 w-full justify-between ">
        <div>
          {selectedRowKeys.length > 0 ? (
            <span>{selectedRowKeys.length} item(s) selected</span>
          ) : (
            <span>Select individual items to be paid or select all</span>
          )}
          <div className="float-right">
            <span className="mr-4">
              Total Oustanding amount: INR {numFormatter(outstandingAmount)}
            </span>
            <Button
              type="primary"
              id="payNow"
              size="medium"
              style={{ backgroundColor: `${!hasSelected ? "" : "#ff4d4f"}` }}
              disabled={!hasSelected}
              onClick={() => {
                setOpenDrawer(true);
              }}
            >
              Pay now
            </Button>
          </div>
        </div>
        <div className="w-full">
          <Table
            rowSelection={rowSelection}
            dataSource={!!tableData.length ? tableData : []}
            columns={columns}
            key="usersListing"
            pagination={true}
          />
        </div>
        {openDrawer && (
          <VendorPaymentDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleClose={handleClose}
            outstandingAmount={outstandingAmount}
            selectedRowKeys={selectedRowKeys}
          />
        )}
      </div>
    </Spin>
  );
}
export default Outstandings;
