import { Button, Card } from "antd";
import React from "react";
import nointernet from "../assets/nointernet.svg";
import Logo from "../assets/logo.svg";

export default function NetworkStatus() {
  return (
    <>
      <div className="sticky top-0  pt-4 px-3">
        <img
          src={Logo}
          alt="Logo"
          style={{ width: "200px" }}
          className="px-5 pt-4 pb-2.5"
        />
      </div>
      <div className="flex justify-center items-center h-screen  ">
        <Card>
          <p className="text-center">
            <img src={nointernet} />
          </p>

          <p className="text-xl font-semibold text-gray-900 text-center">
            No internet connection
          </p>
          <p className="text-base font-normal text-gray-900 text-center">
            Please check your internet connection and try again
          </p>

          <Button
            type="primary"
            size="large"
            className="w-full mt-4"
            onClick={() => {
              window.location.reload();
            }}
          >
            Retry
          </Button>
        </Card>
      </div>
    </>
  );
}
