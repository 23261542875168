import { Spin, Steps } from "antd";
import { useEffect, useState } from "react";
import { GET_USER_SALE_DETAIL } from "./graphql";
import { CircleIcon } from "../../assets/svg";
import { FinishIcon } from "../../assets/svg";
import { RejectIcon } from "../../assets/svg";
import { useQuery } from "@apollo/client";
import moment from "moment";

function UpdateHistory(props) {
  const [dataHistory, setDataHistory] = useState([]);
  const {
    data: userSaleDetails,
    loading: userSaleLoading,
    refetch,
  } = useQuery(GET_USER_SALE_DETAIL, {
    variables: {
      id: props.transactionID,
    },
  });

  useEffect(() => {
    refetch();
  }, [props]);

  useEffect(() => {
    if (userSaleDetails) {
      let newList = [];
      userSaleDetails.sales[0].sales_logs &&
        userSaleDetails.sales[0].sales_logs.logs &&
        userSaleDetails.sales[0].sales_logs.logs.map((item) => {
          newList.push({
            title: item.title.toUpperCase(),
            subTitle: (
              <p className="text-sm text-gray mb-3">
                {moment(item.timestamp).format("DD MMM YYYY")}{" "}
                <span className="bg-gray w-1.5 h-1.5  align-middle inline-block rounded-md"></span>{" "}
                {moment(item.timestamp).format("hh:mma")}{" "}
                {item.points && (
                  <>
                    <span className="bg-gray w-1.5 h-1.5  align-middle inline-block rounded-md"></span>{" "}
                    <span className="text-sm text-gray mb-3">
                      {item.points} points
                    </span>
                  </>
                )}
              </p>
            ),
            description:
              item.title === "Sale rejected" || item.title === "Sale Update info" ? (
                <p className="text-sm text-gray mb-3">{item.comment}</p>
              ) : (
                ""
              ),
            icon:
              item.title === "In review" ||
                item.title === "Material details updated" ? (
                <CircleIcon />
              ) : item.title === "Sale Approved" ? (
                <FinishIcon />
              ) : (
                <RejectIcon />
              ),
          });
        });
      setDataHistory(newList);
    }
  }, [userSaleDetails]);
  return (
    <Spin spinning={userSaleLoading}>
      {dataHistory.length > 0 ? (
        <Steps
          current={dataHistory.length}
          direction="vertical"
          items={dataHistory}
        />
      ) : (
        <div className="flex flex-col justify-center items-center py-4">
          <p className="text-lg-l5-semibold text-gray-500 mt-4">
            No history found
          </p>
        </div>
      )}
    </Spin>
  );
}

export default UpdateHistory;
