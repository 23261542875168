import { useEffect, useState, useContext } from "react";
import { Form, Input, Button, Spin, InputNumber, message, Radio } from "antd";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GET_COLLECTION_MATERIALS,
  UPDATE_COLLECTION_CENTRE,
  SEND_PUSH_NOTIFICATIONS,
} from "./graphql";
import { useMutation, useQuery } from "@apollo/client";
import TitleHeadingContext from "../../../context/TitleHeadingContext";
import moment from "moment";

function Address(props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [onChangeStatus, setonChangeStatus] = useState();
  const [Bounds, setBounds] = useState(undefined);
  const [status, setStatus] = useState();
  const [updateCollectionCentre] = useMutation(UPDATE_COLLECTION_CENTRE);
  const [sendPushNotifications] = useMutation(SEND_PUSH_NOTIFICATIONS);
  const titleHeading = useContext(TitleHeadingContext);

  const {
    data: getCollectionMaterials,
    loading: getCollectionLoading,
    refetch,
  } = useQuery(
    GET_COLLECTION_MATERIALS,

    {
      variables: {
        id: parseInt(location.pathname.split("/")[4]),
      },
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getCollectionMaterials) {
      titleHeading.setTitle(getCollectionMaterials?.collection_centre[0]?.name);
      form.setFieldsValue({
        address: getCollectionMaterials?.collection_centre[0]?.details.address,
        lat: getCollectionMaterials?.collection_centre[0]?.lat,
        long: getCollectionMaterials?.collection_centre[0]?.long,
        locality: getCollectionMaterials?.collection_centre[0]?.locality,
        locationStatus:
          getCollectionMaterials?.collection_centre[0]?.status === "new"
            ? "disabled"
            : getCollectionMaterials?.collection_centre[0]?.status,
        points:
          getCollectionMaterials?.collection_centre[0]?.points === 0
            ? null
            : getCollectionMaterials?.collection_centre[0]?.points,
      });
      setLat(getCollectionMaterials?.collection_centre[0]?.lat);
      setLong(getCollectionMaterials?.collection_centre[0]?.long);
      setStatus(
        getCollectionMaterials?.collection_centre[0]?.status === "new"
          ? "disabled"
          : getCollectionMaterials?.collection_centre[0]?.status
      );
    }
    // eslint-disable-next-line
  }, [getCollectionMaterials]);

  useEffect(() => {
    if (lat && long) {
      let bounds = new props.google.maps.LatLngBounds();
      bounds.extend({ lat: parseFloat(lat), lng: parseFloat(long) });
      setBounds(bounds);
    }
    // eslint-disable-next-line
  }, [lat, long]);

  const onFinish = () => {
    let userid = [];
    form.validateFields().then((values) => {
      let payload = {
        lat: values.lat,
        long: values.long,
        locality: values.locality,
        points: values.points ? values.points : 0,
        status: values.locationStatus ? values.locationStatus : "active",
        details: {
          ...getCollectionMaterials?.collection_centre[0]?.details,
          address: values.address,
        },
      };
      userid.push(getCollectionMaterials?.collection_centre[0]?.user?.id);
      updateCollectionCentre({
        variables: {
          id: parseInt(location.pathname.split("/")[4]),
          object: payload,
        },
      }).then((res) => {
        if (location.pathname.split("/")[2] === "addLocations") {
          navigate(
            `/locations/addLocations/materials-handled/${parseInt(
              location.pathname.split("/")[4]
            )}`,
            { replace: true }
          );
        } else if (
          values.locationStatus === "active" &&
          status !== values.locationStatus
        ) {
          let pushObj = {
            id: moment().valueOf(),
            user_id: getCollectionMaterials?.collection_centre[0]?.user?.id,
            title: "Collection Point Approved",
            body:
              getCollectionMaterials?.collection_centre[0]?.user?.role_id ===
                1 &&
              getCollectionMaterials?.collection_centre[0]?.points === 0 &&
              values.locationStatus === "active"
                ? `Great news! Your suggested collection point has been approved. You have been rewarded ${values.points} points!`
                : `Great news! Your suggested collection point has been approved. You can now make a sale at ${getCollectionMaterials?.collection_centre[0]?.name}, located at ${getCollectionMaterials?.collection_centre[0]?.locality}.`,
            type: "collection",
          };
          sendPushNotifications({
            variables: {
              message: pushObj,
              title: "Collection Point Approved",
              user_ids: userid,
            },
          }).then(() => {
            message.open({
              type: "success",
              content: "Address details updated successfully",
            });
            refetch();
          });
        } else {
          message.open({
            type: "success",
            content: "Address details updated successfully",
          });
          refetch();
        }
      });
    });
  };
  return (
    <Spin spinning={getCollectionLoading}>
      <div className="bg-bgGray min-h-screen w-full flex justify-center items-center py-4">
        <div className="w-5/12 p-4 bg-white rounded-md shadow-3xl">
          <p className="text-lg mb-0">Address</p>
          <p className="text-sm text-gray500 font-normal">
            Only Address field will be displayed publicly so please review
            carefully before you save
          </p>
          <Form
            name="address"
            layout="vertical"
            onFinish={onFinish}
            form={form}
            requiredMark={false}
            // onChange={() => setFormChaged(true)}
          >
            <Form.Item
              label={<span className="text-gray700">Address</span>}
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please enter a address",
                },
                () => ({
                  validator(_, value) {
                    if (value && value.match(/^ *$/) !== null) {
                      return Promise.reject("Please enter a address");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.TextArea
                placeholder="Add address"
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Locality</span>}
              name="locality"
              rules={[
                {
                  required: true,
                  message: "Please enter a locality",
                },
                () => ({
                  validator(_, value) {
                    let alphanumeric = /^([ A-Za-z0-9]){1,}$/;
                    if (value && value.match(/^ *$/) !== null) {
                      return Promise.reject("Please enter a locality");
                    } else if (value && !alphanumeric.test(value)) {
                      return Promise.reject("Please enter a proper locality");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input controls={false} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Lat</span>}
              name="lat"
              rules={[
                {
                  required: true,
                  message: "Please enter a latitude",
                },
                () => ({
                  validator(_, value) {
                    if (value !== "" && value >= -90 && value <= 90) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Please enter a valid Latitude");
                    }
                  },
                }),
              ]}
            >
              <InputNumber
                onChange={(e) => setLat(e)}
                controls={false}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Long</span>}
              name="long"
              rules={[
                {
                  required: true,
                  message: "Please enter a longitude",
                },
                () => ({
                  validator(_, value) {
                    if (value !== "" && value >= -180 && value <= 180) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Please enter a valid Longitude");
                    }
                  },
                }),
              ]}
            >
              <InputNumber
                onChange={(e) => setLong(e)}
                controls={false}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Location Status</span>}
              style={{ height: 0 }}
            ></Form.Item>
            <Form.Item
              label={
                <span className="text-gray500">
                  Inactive: Not accepting sales. Disabled: Not visible on maps
                </span>
              }
              name="locationStatus"
            >
              <Radio.Group
                defaultValue="active"
                onChange={({ target: { value } }) => {
                  if (
                    getCollectionMaterials?.collection_centre[0]?.points ===
                      0 &&
                    value !== "active"
                  ) {
                    form.setFieldsValue({ points: null });
                  }
                  setonChangeStatus(value);
                }}
              >
                <Radio value="active">Active</Radio>
                <Radio value="inactive">Inactive</Radio>
                <Radio value="disabled">Disabled</Radio>
              </Radio.Group>
            </Form.Item>
            {getCollectionMaterials?.collection_centre[0]?.user?.role_id ===
              1 && (
              <Form.Item
                label={<span className="text-gray700">Points</span>}
                name="points"
                rules={
                  onChangeStatus === "active" && [
                    // {
                    //   required: true,
                    //   message: "Please enter points",
                    // },
                    () => ({
                      validator(_, value) {
                        let alphanumeric = /^[0-9]*$/;
                        if (value && !alphanumeric.test(value)) {
                          return Promise.reject("Decimal values are not allowed");
                        } else if (!value) {
                          return Promise.reject("Please enter a points required");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]
                }
              >
                <InputNumber
                  controls={false}
                  className="w-full"
                  disabled={
                    getCollectionMaterials?.collection_centre[0]?.points ===
                      0 && onChangeStatus === "active"
                      ? false
                      : true
                  }
                />
              </Form.Item>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit" className="float-right">
                {location.pathname.split("/")[2] === "addLocations"
                  ? "Next"
                  : "Save"}
              </Button>
            </Form.Item>
          </Form>
          <div className="w-full min-h-33">
            <Map
              google={props.google}
              style={{ height: "32vh", maxWidth: "100%" }}
              zoom={10}
              containerStyle={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
              bounds={Bounds}
            >
              {lat && long && (
                <Marker
                  name={"Current location"}
                  position={{ lat: lat, lng: long }}
                />
              )}
            </Map>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_API_KEY,
})(Address);
