import { Button, Spin, Input } from "antd";
import { useEffect, useMemo, useState } from "react";
import Table from "../../components/Table";
import { useLocation, useNavigate } from "react-router-dom";
import Tag from "../../components/Tag";
import {
  numFormatter,
  downloadCSVFile,
  getColumnSearchProps,
} from "../../utils";
import { GET_USER_REWARD, CHECK_THE_CODE } from "./graphql";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import SearchComponent from "../../components/Search";
import VendorRedeemDrawer from "./vendorRedeemDrawer";
import downloadExcel from "../../assets/downloadExcel.svg";
import jwtDecode from "jwt-decode";

const { Search } = Input;

function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const [tableData, setTableData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [amount, setAmount] = useState();
  const [couponCount, setCouponCount] = useState();
  const [error, setError] = useState(false);
  const [searchLocName, setSearchLocName] = useState();
  const [codeList, setCodeList] = useState([]);
  const [allCoupons, setAllCoupons] = useState([]);
  const [category, setCategory] = useState();
  const [searchCoupon, setSearchCoupon] = useState({});
  const [code, setCode] = useState();
  const [searchVisible, setSearchVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const tabNames = [
    "locations",
    "users",
    "sales",
    "rewards",
    "apartments",
    "feedback",
    "suggestedLocations",
  ];

  let rewardNames = ["campaignDetails", "financials", "outstanding"];
  const [getVendorDetails, { data: couponDetails, loading }] = useLazyQuery(
    GET_USER_REWARD,
    { fetchPolicy: "no-cache" }
  );

  const [checkCode, { data: codeDetails, loading: codeLoading }] = useLazyQuery(
    CHECK_THE_CODE,
    { fetchPolicy: "no-cache" }
  );

  useEffect(() => {
    navigate(`/vendor/dashboard`);
    getVendorDetails({
      variables: {
        where: {
          _and: {
            vendor_user_id: {
              _eq: parseInt(
                jwtDecode(localStorage.getItem("token"))[
                  "https://hasura.io/jwt/claims"
                ]["x-hasura-user-id"]
              ),
            },
          },
        },
      },
    });
  }, []);

  useEffect(() => {
    setSearchLocName(new URLSearchParams(search).get("searchText"));
    setCategory(new URLSearchParams(search).get("category"));
  }, [location]);

  useEffect(() => {
    getVendorDetails({
      variables: {
        where: searchCoupon,
      },
    });
  }, [searchCoupon]);

  useEffect(() => {
    const user_id = parseInt(
      jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
        "x-hasura-user-id"
      ]
    );
    setSearchCoupon(
      new URLSearchParams(search).get("searchText") &&
        new URLSearchParams(search).get("category") === "Name"
        ? {
            name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},

            vendor_user_id: { _eq: user_id },
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "Locality"
        ? {
            location: searchLocName ? { _ilike: `%${searchLocName}%` } : {},

            vendor_user_id: { _eq: user_id },
          }
        : new URLSearchParams(search).get("searchText") !== null &&
          new URLSearchParams(search).get("category") === "null"
        ? {
            _and: [
              { vendor_user_id: { _eq: user_id } },

              {
                _or: [
                  {
                    location: searchLocName
                      ? { _ilike: `%${searchLocName}%` }
                      : {},
                  },
                  {
                    name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
                  },
                ],
              },
            ],
          }
        : {
            vendor_user_id: { _eq: user_id },
          }
    );
  }, [searchLocName, category]);

  useEffect(() => {
    if (codeDetails) {
      let couponList = [];
      let newCodeArray = [];
      codeDetails.rewards.map((ele) => {
        if (ele.user_rewards.length > 0) {
          ele.user_rewards.map((item) => {
            newCodeArray.push(item.code);
            if (
              item.status.toLowerCase() === "redeemed" ||
              item.status === "Approved" ||
              item.status === "Submitted"
            ) {
              couponList.push(item.code);
            }
          });
        }
      });
      setAllCoupons(newCodeArray);
      setCodeList(couponList);
    }
  }, [codeDetails]);

  useEffect(() => {
    if (couponDetails) {
      let newList = [];
      let couponList = [];
      let newCodeArray = [];
      let sum = 0;
      let couponRedeemThisWeek = 0;
      couponDetails.rewards.map((ele) => {
        if (ele.user_rewards.length > 0) {
          ele.user_rewards.map((item) => {
            newCodeArray.push(item.code);
            if (
              item.status.toLowerCase() === "redeemed" ||
              item.status === "Approved" ||
              item.status === "Submitted"
            ) {
              console.log(item.status);
              sum += parseInt(item.reward.details.vendorCost);
              couponList.push(item.code);
              newList.push({
                id: item.id,
                campaignID: ele.id,
                code: item.code,
                campaignDetails: item.reward.name,
                locality: item.reward.location,
                couponStatus:
                  item.status === "Submitted" ? "Pending" : item.status,
                updated_at: item.updated_at,
                redeemedOn: item.redeemed_at
                  ? moment(item.redeemed_at).format("DD MMM YYYY")
                  : "-",
                amount: parseInt(item.reward.details.vendorCost),
                payment:
                  item.status === "Paid"
                    ? "-"
                    : item.transaction_id !== null
                    ? "cleared"
                    : "outstanding",
              });

              couponRedeemThisWeek += 1;
            }
          });
        }
      });
      newList.sort((a, b) => {
        return moment(b.updated_at).unix() - moment(a.updated_at).unix();
      });

      setAmount(sum);
      setCouponCount(couponRedeemThisWeek);
      setTableData(newList);
    }
  }, [couponDetails]);

  useEffect(() => {
    if (codeList && allCoupons && code) {
      if (codeList.includes(code)) {
        setError("Code already redeemed");
      } else if (!allCoupons.includes(code)) {
        setError("Invalid coupon code");
      } else {
        setError();
        navigate(`/vendor/dashboard/${code}`);
        setOpenDrawer(true);
      }
    }
  }, [codeList, allCoupons]);

  const columns = useMemo(
    () => [
      {
        title: "CAMPAIGN ID",
        dataIndex: "campaignID",
        key: "campaignID",
        className: "w-40",
        ...getColumnSearchProps("campaignID"),
        render: (text) => <>{`CAM${`${text}`.padStart(6, "0")}`}</>,
      },
      {
        title: "CAMPAIGN details",
        dataIndex: "campaignDetails",
        key: "campaignName",
        className: "w-40",
      },
      { title: "CODE", dataIndex: "code", key: "code", className: "w-40" },
      {
        title: "LOCALITY",
        dataIndex: "locality",
        key: "locality",
        className: "w-40",
      },
      {
        title: "COUPON STATUS",
        dataIndex: "couponStatus",
        key: "couponStatus",
        className: "w-40",
        render: (text) => (
          <>
            {text === "Pending" ? (
              <Tag color="#FEF3C7" textColor="#92400E">
                Pending
              </Tag>
            ) : (
              <Tag color="#D1FAE5" textColor="#065F46">
                Redeemed
              </Tag>
            )}
          </>
        ),
      },
      {
        title: "REDEEMED ON",
        dataIndex: "redeemedOn",
        key: "redeemedOn",
        className: "w-40",
        sorter: (a, b) =>
          moment(a.redeemedOn).unix() - moment(b.redeemedOn).unix(),
      },
      {
        title: "PAYMENT",
        dataIndex: "payment",
        key: "payment",
        className: "w-40",
        sorter: (a, b) => a.payment - b.payment,
        render: (text) => (
          <>
            {text === "cleared" && (
              <Tag color="#D1FAE5" textColor="#065F46">
                Cleared
              </Tag>
            )}
            {text === "outstanding" && (
              <Tag color="#FEF3C7" textColor="#92400E">
                Outstanding
              </Tag>
            )}
            {text === "-" && <span>-</span>}
          </>
        ),
      },
      {
        title: "VENDOR COST",
        dataIndex: "amount",
        key: "amount",
        className: "w-40",
        sorter: (a, b) => a.amount - b.amount,
        render: (text) =>
          text ? (
            <>
              <span className="float-right">Rs. {numFormatter(text)} </span>
            </>
          ) : (
            <span className="float-right">-</span>
          ),
      },
    ],
    []
  );

  const handleClose = () => {
    setOpenDrawer(false);
    navigate(`/vendor/dashboard`, { replace: true });
  };
  console.log(searchVisible, "searchVisible");
  return (
    <Spin spinning={loading || codeLoading}>
      {(location.pathname.split("/")[1] === "vendor" ||
        (tabNames.includes(location.pathname.split("/")[1]) &&
          !location.pathname.split("/")[2]) ||
        ((location.pathname.split("/")[2] === "editRewards" ||
          location.pathname.split("/")[2] === "addRewards" ||
          location.pathname.split("/")[3] === "residentList") &&
          location.pathname.split("/")[3] !== "vendorDetails")) && (
        <div className="block px-4 pt-4 md:hidden">
          <SearchComponent
            searchVisible={searchVisible}
            placeholder={
              location.pathname.split("/")[1] === "rewards" &&
              !location.pathname.split("/")[2]
                ? "Search for vendor name, campaign name, locality"
                : rewardNames.includes(location.pathname.split("/")[3])
                ? "Search for camapaign name, locality, ID"
                : location.pathname.split("/")[3] === "pastPayments"
                ? "Search for Trasaction ID"
                : location.pathname.split("/")[1] === "sales"
                ? "Search for name, location, user locality"
                : location.pathname.split("/")[3] === "residentList"
                ? "Search for ID, name, mobile number"
                : location.pathname.split("/")[1] === "apartments"
                ? "Search for apartment name, locality, address, POC name"
                : location.pathname.split("/")[1] === "users"
                ? "Search for name, locality, apartment"
                : "Search for name, locality"
            }
            role="admin"
            tag1={
              location.pathname.split("/")[1] === "rewards" &&
              !location.pathname.split("/")[2]
                ? "Vendor name"
                : location.pathname.split("/")[1] === "apartments"
                ? "Apartment name"
                : "Name"
            }
            tag2={
              location.pathname.split("/")[1] !== "sales"
                ? "Locality"
                : "Location"
            }
            tag3={
              location.pathname.split("/")[3] === "residentList"
                ? "User ID"
                : location.pathname.split("/")[1] === "rewards" &&
                  !location.pathname.split("/")[2]
                ? "Campaign name"
                : location.pathname.split("/")[1] === "apartments"
                ? "Address"
                : location.pathname.split("/")[1] === "sales"
                ? "User Locality"
                : location.pathname.split("/")[1] === "users"
                ? "Apartment"
                : null
            }
            tag4={
              location.pathname.split("/")[1] === "apartments"
                ? "POC name"
                : null
            }
            setSearchVisible={setSearchVisible}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </div>
      )}
      <div className="flex flex-col gap-5 p-4 md:p-8 w-full justify-between ">
        <div className="flex flex-col md:flex-row gap-5  w-full ">
          <div className="w-full md:w-30">
            <p className="text-green">Enter coupon code</p>
            <Search
              key="couponSearch"
              placeholder="Enter the coupon code"
              allowClear
              autoFocus={false}
              className="mb-3"
              onSearch={(e) => {
                if (e.length === 0) {
                  setError("Please enter a coupon code");
                } else {
                  setCode(e);
                  checkCode({
                    variables: {
                      where: {
                        _and: {
                          vendor_user_id: {
                            _eq: parseInt(
                              jwtDecode(localStorage.getItem("token"))[
                                "https://hasura.io/jwt/claims"
                              ]["x-hasura-user-id"]
                            ),
                          },
                        },
                      },
                    },
                  });
                }
              }}
              enterButton={<Button>Submit</Button>}
              size="large"
            />
            {error && <span className="text-red">{error}</span>}
          </div>
          <div className="flex flex-row  justify-between md:flex-none md:w-70">
            <div className="w-32 md:w-30">
              <p>Coupon redeemed</p>
              <Input
                key="couponCost"
                size="large"
                value={couponCount}
                readonly
              />
            </div>
            <div className="w-30 md:w-30">
              <p>Total Earnings</p>
              <Input key="amount" size="large" value={amount} readonly />
            </div>
            <div className="w-30 md:w-30">
              <p className="text-center">Download Excel</p>
              <p className="text-center ">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    downloadCSVFile(
                      !!tableData.length ? tableData : [],
                      columns,
                      `Campaign data - ${moment().format("DD MM YYYY")}`
                    );
                  }}
                >
                  <img src={downloadExcel} alt="downloadExcel" />
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full">
          <Table
            dataSource={!!tableData.length ? tableData : []}
            columns={columns}
            key="usersListing"
            pagination={true}
            scroll={{
              x: 1300,
            }}
          />
        </div>
        {openDrawer && (
          <VendorRedeemDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleClose={handleClose}
          />
        )}
      </div>
    </Spin>
  );
}

export default Dashboard;
