import { Drawer, Input, Button, Spin, Form } from "antd";
import { useState } from "react";
import closeIcon from "../assets/closeIcon.svg";

function BroadCastDrawer(props) {
  const [form] = Form.useForm();
  const [textValue, setTextValue] = useState();

  const onClose = () => {
    props.setOpenDrawer(false);
  };

  const onFinish = (values) => {
    props.broadCastonFinish(values);
  };

  return (
    <Spin spinning={false}>
      <Drawer
        width={400}
        placement="right"
        closable={false}
        onClose={onClose}
        // maskClosable={false}
        open={props.openDrawer}
        rootStyle={{}}
      >
        <Spin spinning={false}>
          <div className="mb-4">
            <div>
              <div className="w-3/4 float-left">
                <p className="text-sm font-semibold mb-0">Compose message</p>
                <p className="text-gray">users matching your profile</p>
              </div>
              <span onClick={onClose} className="cursor-pointer float-right">
                <img src={closeIcon} alt="closeIcon" />
              </span>
            </div>
            <div className="clear-both"></div>

            <Form
              name="broadcastUser"
              onFinish={onFinish}
              layout="vertical"
              className="mt-6"
              form={form}
              requiredMark={false}
            >
              <Form.Item
                labelCol={{ span: 24 }}
                label={
                  <>
                    <span>Compose new message</span>
                    <Button
                      type="link"
                      className="float-right text-green pr-0"
                      onClick={() => {
                        if (form.getFieldValue("message")) {
                          form.setFieldsValue({
                            message: "",
                          });
                          setTextValue();
                        }
                      }}
                      disabled={textValue ? false : true}
                    >
                      Clear
                    </Button>
                  </>
                }
                name="message"
              >
                <Input.TextArea
                  onChange={(e) => {
                    setTextValue(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="Submit"
                  size="large"
                  className="w-full"
                  disabled={textValue ? false : true}
                >
                  Send
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </Drawer>
    </Spin>
  );
}

export default BroadCastDrawer;
