import {
  Drawer,
  Spin,
  Button,
  Form,
  Input,
  DatePicker,
  message,
  InputNumber,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  GET_VENDOR_DETAILS,
  INSERT_REWARD,
  GET_REWARDS_ONE,
  UPDATE_REWARD,
} from "./graphql";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";

function CampaignDrawer(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [insertReward] = useMutation(INSERT_REWARD);
  const [updateReward] = useMutation(UPDATE_REWARD);
  const [vendorId, setVendorId] = useState();
  const [cardColor, setCardColor] = useState();
  const {
    data: vendorDetails,
    loading: vendorLoading,
    refetch,
  } = useQuery(GET_VENDOR_DETAILS, {
    variables: {
      id: parseInt(location.pathname.split("/")[4]),
    },
  });

  const {
    data: rewardDetails,
    loading: rewardLoading,
    refetch: rewardrefetch,
  } = useQuery(GET_REWARDS_ONE, {
    variables: {
      id: location.pathname.split("/")[5],
    },
  });

  useEffect(() => {
    refetch();
    rewardrefetch();
  }, []);

  useEffect(() => {
    if (rewardDetails) {
      let newArray = [];

      rewardDetails.rewards.map((item) => {
        form.setFieldsValue({
          cardColor: item.details.cardColor,
          claimText: item.details.claimText,
          pointsRequired: item.details.pointsRequired,
          description: item.details.description,
          expiryDate: moment(item.details.expiryDate),
          couponUsed: item.total_used_number,
          couponValue: item.details.couponValue,
          couponsCost: item.details.couponsCost,
          vendorCost: item.details.vendorCost,
          couponsAvailable: item.details.totalCount,
          locationLink: item.details.locationLink,
          location: item.location,
        });
        setCardColor(item.details.cardColor);
      });
    }
  }, [rewardDetails]);

  useEffect(() => {
    if (vendorDetails) {
      form.setFieldsValue({
        vendorName: vendorDetails.vendors[0].name,
      });
      setVendorId(vendorDetails.vendors[0].user_id);
    }
  }, [vendorDetails]);
  const onFinish = (values) => {
    let start = moment();
    let end = moment(rewardDetails?.rewards[0]?.details?.expiryDate);
    let camStatus =
      end.diff(start, "days") <= 0
        ? "expired"
        : rewardDetails.rewards[0].total_used_number >=
          parseInt(rewardDetails.rewards[0].details.totalCount)
        ? "disabled"
        : rewardDetails.rewards[0].status;

    let payload = {
      vendor_id: location.pathname.split("/")[4],
      total_used_number:
        rewardDetails && rewardDetails.rewards
          ? rewardDetails.rewards[0].total_used_number
          : 0,
      vendor_user_id: vendorId,
      name: values.description,
      status: camStatus === "active" ? "active" : "disabled",
      location: values.location,

      details: {
        cardColor: values.cardColor,
        claimText: values.claimText,
        couponValue: values.couponValue,
        couponsCost: values.couponsCost,
        description: values.description,
        expiryDate: values.expiryDate,
        locationLink: values.locationLink,
        pointsRequired: values.pointsRequired,
        vendorCost: values.vendorCost,
        totalCount: values.couponsAvailable,
      },
    };
    if (!location.pathname.split("/")[5]) {
      insertReward({
        variables: {
          object: payload,
        },
      }).then(() => {
        message.open({
          type: "success",
          content: "New Campaign added successfully",
        });
        props.handleClose();
      });
    } else {
      updateReward({
        variables: {
          object: payload,
          id: location.pathname.split("/")[5],
        },
      }).then(() => {
        message.open({
          type: "success",
          content: "Campaign details updated successfully",
        });
        props.handleClose();
      });
    }
  };

  const onClose = () => {
    props.handleClose("close");
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  return (
    <Spin spinning={vendorLoading || rewardLoading}>
      <Drawer
        width={495}
        placement="right"
        closable={true}
        onClose={onClose}
        maskClosable={false}
        open={props.openDrawer}
        headerStyle={{ borderBottom: 0 }}
        bodyStyle={{ padding: "0 24px" }}
      >
        <div className="bg-white rounded-md">
          <p className="text-lg mb-0">Campaign details</p>
          <p className="text-sm text-gray500 font-normal">
            This information is will displayed publicly so please review
            carefully before you save
          </p>
          <Form
            name="campaign"
            layout="vertical"
            onFinish={onFinish}
            form={form}
            requiredMark={false}
          >
            <Form.Item
              label={<span className="text-gray700">Vendor Name</span>}
              name="vendorName"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Campaign locality</span>}
              name="location"
              rules={[
                {
                  required: true,
                  message: "Please enter a  locality",
                },
                () => ({
                  validator(_, value) {
                    //   let alphanumeric = /^([ A-Za-z0-9]){1,}$/;
                    if (value && value.match(/^ *$/) !== null) {
                      return Promise.reject("Please enter a locality");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<span className="textsm">Campaign description</span>}
              name="description"
              rules={[
                { message: "Enter the description", required: true },
                () => ({
                  validator(_, value) {
                    //   let alphanumeric = /^([ A-Za-z0-9]){1,}$/;
                    if (value && value.match(/^ *$/) !== null) {
                      return Promise.reject("Please enter a description");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              className="mb-0"
            >
              <TextArea
                autoSize={{ minRows: 1, maxRows: 3 }}
                showCount
                maxLength={60}
              />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-gray700">
                  Points required (will be deducted from App user)
                </span>
              }
              name="pointsRequired"
              rules={[
                // {
                //   required: true,
                //   message: "Please enter a points required",
                // },
                () => ({
                  validator(_, value) {
                    let alphanumeric = /^[0-9]*$/;
                    if (value && !alphanumeric.test(value)) {
                      return Promise.reject("Decimal values are not allowed");
                    } else if (!value) {
                      return Promise.reject("Please enter a points required");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber controls={false} className="w-full" />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-gray700">
                  Total number of coupons available
                </span>
              }
              name="couponsAvailable"
              rules={[
                // {
                //   required: true,
                //   message:
                //     "Please enter a vendor Total number of coupons available",
                // },
                () => ({
                  validator(_, value) {
                    let alphanumeric = /^[0-9]*$/;
                    if (value && !alphanumeric.test(value)) {
                      return Promise.reject("Decimal values are not allowed");
                    } else if (!value) {
                      return Promise.reject(
                        "Please enter a vendor Total number of coupons available"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber controls={false} className="w-full" />
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span className="text-gray700">
                    Coupon Value (Product cost)
                  </span>
                </>
              }
              rules={[
                // {
                //   required: true,
                //   message: "Please enter a coupon value",
                // },
                () => ({
                  validator(_, value) {
                    let alphanumeric = /^[0-9]*$/;
                    if (value && !alphanumeric.test(value)) {
                      return Promise.reject("Decimal values are not allowed");
                    } else if (!value) {
                      return Promise.reject("Please enter a coupon value");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              name="couponValue"
            >
              <InputNumber controls={false} className="w-full" />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-gray700">
                  Coupon Cost (Purchase cost for App user)
                </span>
              }
              name="couponsCost"
              rules={[
                // {
                //   required: true,
                //   message: "Please enter a coupon cost",
                // },
                () => ({
                  validator(_, value) {
                    let alphanumeric = /^[0-9]*$/;
                    if (value && !alphanumeric.test(value)) {
                      return Promise.reject("Decimal values are not allowed");
                    } else if (!value) {
                      return Promise.reject("Please enter a coupon cost");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber controls={false} className="w-full" />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-gray700">
                  Vendor Cost (Money KC has to pay vendor)
                </span>
              }
              name="vendorCost"
              rules={[
                // {
                //   required: true,
                //   message: "Please enter a vendor cost",
                // },
                () => ({
                  validator(_, value) {
                    let alphanumeric = /^[0-9]*$/;
                    if (value && !alphanumeric.test(value)) {
                      return Promise.reject("Decimal values are not allowed");
                    } else if (!value) {
                      return Promise.reject("Please enter a vendor cost");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber controls={false} className="w-full" />
            </Form.Item>

            <Form.Item
              label={<span className="text-gray700">Expiry date</span>}
              name="expiryDate"
              rules={[
                {
                  required: true,
                  message: "Please enter a expiry date",
                },
              ]}
            >
              <DatePicker
                className="w-full"
                format="DD MMM YYYY"
                disabledDate={disabledDate}
                showToday={false}
              />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Location link</span>}
              name="locationLink"
              rules={[
                () => ({
                  validator(_, value) {
                    const urlPattern = new RegExp("@(.*),(.*),");
                    // const urlPattern =
                    //   /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

                    if (value && !urlPattern.test(value)) {
                      return Promise.reject("Please enter a valid url");
                    } else if (!value) {
                      return Promise.reject("Please enter a location link");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              label={
                <>
                  <span className="text-gray700">Card background color</span>
                  {cardColor && (
                    <div
                      className="float-right  pr-0"
                      style={{
                        width: "70px",
                        height: "22px",
                        backgroundColor: `${cardColor}`,
                      }}
                    ></div>
                  )}
                </>
              }
              name="cardColor"
              rules={[
                () => ({
                  validator(_, value) {
                    let alphanumeric = /^#([0-9a-f]{3}){1,2}$/i;
                    let colorarray = ["#000", "#000000", "fff", "#ffffff"];
                    if (colorarray.includes(value)) {
                      return Promise.reject(
                        "Card background color should not be balck or white"
                      );
                    } else if (value && !alphanumeric.test(value)) {
                      return Promise.reject(
                        "Card background should be hex values only"
                      );
                    } else if (!value) {
                      return Promise.reject(
                        "Please enter a card background color"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                onChange={(e) => {
                  setCardColor(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              label={<span className="textsm">How to claim this</span>}
              name="claimText"
              rules={[
                { message: "Enter the claim text", required: true },
                () => ({
                  validator(_, value) {
                    //   let alphanumeric = /^([ A-Za-z0-9]){1,}$/;
                    if (value && value.match(/^ *$/) !== null) {
                      return Promise.reject("Please enter a claim text");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <TextArea
                autoSize={{ minRows: 4, maxRows: 7 }}
                placeholder="Add details and steps that the user can follow"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="float-right">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    </Spin>
  );
}
export default CampaignDrawer;
