import Select from "../../../components/Select";
import { Button, Spin, Card, Radio, Modal } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import CampaignDrawer from "./campaignDrawer";
import {
  GET_REWARDS,
  GET_REWARDS_LOCATIONS,
  UPDATE_REWARDS_STATUS,
  GET_SIGNED_URL,
} from "./graphql";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import TitleHeadingContext from "../../../context/TitleHeadingContext";
import moment from "moment";

function CampaignDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [localityList, setLocalityList] = useState([]);
  const [selectLocation, setselectLocation] = useState();
  const [offerStatus, setOfferStatus] = useState("All");
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState();
  const [elementId, setElementId] = useState();
  const [selectedValue, setSelectedValue] = useState([]);
  const [searchLocName, setSearchLocName] = useState();
  const [category, setCategory] = useState();
  const [updateStatus] = useMutation(UPDATE_REWARDS_STATUS);
  const titleHeading = useContext(TitleHeadingContext);
  const [getSignedUrl] = useMutation(GET_SIGNED_URL);

  const [getRewards, { data: rewardDetails, loading: rewardLoading, refetch }] =
    useLazyQuery(GET_REWARDS, { fetchPolicy: "no-cache" });

  const {
    data: rewardListDetails,
    loading: rewardListLoading,
    refetch: localityListRefetch,
  } = useQuery(GET_REWARDS_LOCATIONS, {
    variables: {
      id: parseInt(location.pathname.split("/")[4]),
    },
  });

  useEffect(() => {
    localityListRefetch();
    setSearchTerm({
      _and: {
        location: {},
        status: {},
        vendor_id: { _eq: parseInt(location.pathname.split("/")[4]) },
      },
    });
  }, []);

  useEffect(() => {
    setSearchLocName(new URLSearchParams(search).get("searchText"));
    setCategory(new URLSearchParams(search).get("category"));
  }, [location]);

  useEffect(() => {
    if (Object.keys(searchTerm).length > 0) {
      getRewards({ variables: { where: searchTerm } });
    }
  }, [searchTerm]);

  useEffect(() => {
    setSearchTerm(
      new URLSearchParams(search).get("searchText") &&
        new URLSearchParams(search).get("category") === "Name"
        ? {
            location: selectLocation
              ? {
                  _in:
                    selectLocation.length > 0 ? selectLocation : localityList,
                }
              : {},
            status:
              offerStatus && offerStatus !== "All"
                ? { _eq: offerStatus.toLowerCase() }
                : {},
            vendor_id: { _eq: parseInt(location.pathname.split("/")[4]) },
            name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "Locality"
        ? {
            location:
              selectLocation && searchLocName
                ? { _ilike: `%${searchLocName}%`, _in: selectLocation }
                : selectLocation
                ? {
                    _in:
                      selectLocation.length > 0 ? selectLocation : localityList,
                  }
                : searchLocName
                ? { _ilike: `%${searchLocName}%` }
                : {},
            vendor_id: { _eq: parseInt(location.pathname.split("/")[4]) },
            status:
              offerStatus && offerStatus !== "All"
                ? { _eq: offerStatus.toLowerCase() }
                : {},
            name: {},
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "null"
        ? {
            _and: [
              {
                vendor_id: { _eq: parseInt(location.pathname.split("/")[4]) },
                status:
                  offerStatus && offerStatus !== "All"
                    ? { _eq: offerStatus.toLowerCase() }
                    : {},
              },
              {
                _or: [
                  {
                    location:
                      selectLocation && searchLocName
                        ? { _ilike: `%${searchLocName}%`, _in: selectLocation }
                        : selectLocation
                        ? {
                            _in:
                              selectLocation.length > 0
                                ? selectLocation
                                : localityList,
                          }
                        : searchLocName
                        ? { _ilike: `%${searchLocName}%` }
                        : {},
                  },

                  {
                    name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
                  },
                ],
              },
            ],
          }
        : {
            location: selectLocation
              ? {
                  _in:
                    selectLocation.length > 0 ? selectLocation : localityList,
                }
              : {},
            status:
              offerStatus && offerStatus !== "All"
                ? { _eq: offerStatus.toLowerCase() }
                : {},
            vendor_id: { _eq: parseInt(location.pathname.split("/")[4]) },
          }
    );
  }, [selectLocation, offerStatus, searchLocName, category]);

  useEffect(() => {
    if (rewardDetails) {
      getCases();
    }
    // eslint-disable-next-line
  }, [rewardDetails]);

  const getResponseUrl = (file) => {
    return new Promise((resolve) => {
      getSignedUrl({
        variables: {
          fileName: file,
        },
      }).then((res) => {
        resolve(res?.data?.get_signed_url?.url);
      });
    });
  };

  const getCases = async () => {
    let newArray = [];
    let statusList = [];
    let responseUrl;
    if (rewardDetails.rewards.length > 0) {
      responseUrl = await getResponseUrl(
        rewardDetails.rewards[0].vendor.details.logo
      );
    }

    rewardDetails.rewards.map((item) => {
      let startDate = moment().startOf("day");
      let endDate = moment(item.details.expiryDate).endOf("day");

      let camStatus =
        endDate.diff(startDate, "days") <= 0
          ? "expired"
          : item.total_used_number >= parseInt(item.details.totalCount)
          ? "disabled"
          : item.status;

      newArray.push({
        id: item.id,
        bgcolor: item.details.cardColor,
        unlockPoints: item.details.pointsRequired,
        description: item.name,
        expiryDate: endDate.diff(startDate, "days"),
        logo: responseUrl,
        status: camStatus,
        couponUsed: item.total_used_number,
        vendorStatus: item.vendor.status,
        totalCount: item.details.totalCount,
        statusData: item.status,
      });
      statusList.push(camStatus);
    });

    setSelectedValue(statusList);
    setData(newArray);
  };

  useEffect(() => {
    if (rewardListDetails && rewardListDetails.rewards.length > 0) {
      let newList = [];
      titleHeading.setTitle(rewardListDetails.rewards[0].vendor.name);
      rewardListDetails.rewards.map((ele) => {
        if (offerStatus === "All") {
          newList.push(ele.location);
        } else if (ele.status === offerStatus.toLowerCase()) {
          newList.push(ele.location);
        }
      });

      let list = newList.filter(
        (item, index) => newList.indexOf(item) === index
      );
      list.sort();
      setLocalityList(newList);
    }
  }, [rewardListDetails, offerStatus]);

  const handleselectedValues = (value, type) => {
    if (type === "Locality") {
      setselectLocation(value);
    } else if (type === "offerStatus" && value) {
      setOfferStatus(value);
    } else {
      setOfferStatus("All");
    }
  };

  const showModal = (value, id) => {
    if (value === "disabled") {
      setValue(value);
      setElementId(id);
      setIsModalOpen(true);
    } else {
      updateStatus({
        variables: {
          id: parseInt(id),
          status: value,
        },
      }).then(() => {
        window.location.reload();
      });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    updateStatus({
      variables: {
        id: parseInt(elementId),
        status: value,
      },
    }).then(() => {
      window.location.reload();
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClose = (action) => {
    navigate(
      `/rewards/addRewards/campaignDetails/${location.pathname.split("/")[4]}`
    );
    setOpenDrawer(false);
    if (!action) window.location.reload();
    // refetch();
    // localityListRefetch();
  };
  return (
    <Spin spinning={rewardLoading || rewardListLoading}>
      <div className="flex flex-col gap-5 p-8 w-full bg-[#F3F4F6] min-h-screen">
        <div className="flex flex-row gap-2  w-full ">
          <div style={{ width: "80%" }} className="flex flex-row gap-3 ">
            <div style={{ width: "20%" }}>
              <Select
                placeholder="Locality"
                type="Locality"
                mode="multiple"
                data={localityList}
                onChange={handleselectedValues}
                value=""
                width=""
                allowClear={true}
              />
            </div>
            <div style={{ width: "18%" }}>
              <Select
                placeholder="Offer Status"
                type="offerStatus"
                data={["All", "Active", "Disabled", "Expired"]}
                onChange={handleselectedValues}
                // symbol=""
                value={offerStatus}
                // width="127px"
                allowClear={true}
              />
            </div>
          </div>
          <div style={{ width: "20%" }}>
            <Button
              type="primary"
              id="addlocations"
              size="large"
              className="float-right"
              onClick={() => setOpenDrawer(true)}
            >
              New Campaign
            </Button>
          </div>
        </div>
        {data.length > 0 ? (
          <div className="flex flex-row gap-10 w-full flex-wrap">
            {data.map((element, index) => {
              return (
                <div className="flex flex-col">
                  <Card
                    style={{
                      width: 400,
                      height: 170,
                      backgroundColor: `${element.bgcolor}`,
                    }}
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenDrawer(true);
                      navigate(
                        `/rewards/addRewards/campaignDetails/${parseInt(
                          location.pathname.split("/")[4]
                        )}/${element.id}`
                      );
                    }}
                  >
                    <div className="flex flex-row ">
                      <div className="w-3/5 flex flex-col justify-between">
                        <p className="text-white opacity-60 text-xs font-bold mb-0">
                          {`Unlock for ${element.unlockPoints} points`}
                        </p>
                        <p className="text-white text-base font-bold break-words">
                          {`${element.description}`}
                        </p>
                        {element.expiryDate <= 0 ? (
                          <p className="text-white opacity-70 text-sm font-bold">
                            Expired
                          </p>
                        ) : (
                          <p className="text-white opacity-70 text-sm font-bold">
                            {element.couponUsed >= parseInt(element.totalCount)
                              ? "Expired"
                              : `Expire in ${element.expiryDate} days`}
                          </p>
                        )}
                      </div>
                      <div className="w-2/5 flex flex-col justify-between">
                        <p className="text-right">
                          <img
                            alt="logo"
                            src={element.logo}
                            style={{
                              width: "90px",
                              height: "44px",
                              borderRadius: "5px",
                            }}
                          ></img>
                        </p>

                        <p className="text-right mt-9">
                          <span className="text-white opacity-70 text-sm font-bold underline">
                            How to claim this?
                          </span>
                        </p>
                      </div>
                    </div>
                  </Card>
                  <div className="flex flex-row justify-between">
                    <Radio.Group
                      value={selectedValue[index]}
                      className="mt-2 campaignstatus"
                      disabled={
                        element.expiryDate <= 0 ||
                        element.statusData === "expired" ||
                        element.couponUsed >= parseInt(element.totalCount)
                          ? true
                          : element.vendorStatus === "disabled"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        showModal(e.target.value, element.id);
                      }}
                    >
                      <Radio value="active">Active</Radio>
                      <Radio className="disabled" value="disabled">
                        Disabled
                      </Radio>
                      <Radio className="disabled" value="expired" disabled>
                        Expired
                      </Radio>
                    </Radio.Group>
                    <div className="mt-2">
                      {(element.expiryDate <= 0 ||
                        element.statusData === "expired" ||
                        element.couponUsed >= parseInt(element.totalCount)) && (
                        <span className="text-red">Expired: </span>
                      )}
                      {`${element.couponUsed}/${element.totalCount}`}
                    </div>
                  </div>
                  <Modal
                    title="Confirmation"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <p>
                      Disabling the campaign will remove it from the App's
                      active list of campaigns. Those who purchased this coupon
                      previously will still be able to use the coupon. Proceed
                      to disable campaign?
                    </p>
                  </Modal>
                </div>
              );
            })}
          </div>
        ) : (
          <p className="text-base font-semibold text-center">
            No campaign added yet
          </p>
        )}

        {openDrawer && (
          <CampaignDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleClose={handleClose}
          />
        )}
      </div>
    </Spin>
  );
}

export default CampaignDetails;
