import { Button, Card } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import error404 from "../assets/error404.svg";
import Logo from "../assets/logo.svg";

export default function VendorError() {
  useEffect(() => {
    localStorage.clear();
  });
  return (
    <>
      <div className="sticky top-0  pt-4 px-3">
        <img
          src={Logo}
          alt="Logo"
          style={{ width: "200px" }}
          className="px-5 pt-4 pb-2.5"
        />
      </div>
      <div className="flex justify-center items-center h-screen  ">
        <Card>
          <p className="text-center">
            <img src={error404} />
          </p>

          {/* <p className="text-xl font-semibold text-gray-900 text-center">
            Vendor disabled
          </p> */}
          <p className="text-base font-normal text-gray-900 text-center">
            Your account has been disabled. Please contact the admin at{" "}
            <a
              href="mailto:support@kabadiwallaconnect.org"
              className="text-green"
            >
              support@kabadiwallaconnect.org
            </a>
          </p>
        </Card>
      </div>
    </>
  );
}
