import { gql } from "@apollo/client";

export const GET_COLLECTION_CENTRE = gql`
  query GET_COLLECTION_CENTRE($where: collection_centre_bool_exp) {
    collection_centre(where: $where, order_by: { id: desc }) {
      city
      locality
      long
      lat
      details
      id
      name
      status
      rating
      totalSale: sales_aggregate {
        aggregate {
          count
          sum {
            total_price
          }
        }
      }
      approved: sales_aggregate(where: { status: { _eq: "Approved" } }) {
        aggregate {
          count
          sum {
            total_price
          }
        }
      }
      rejection: sales_aggregate(where: { status: { _eq: "Rejected" } }) {
        aggregate {
          count
          sum {
            total_price
          }
        }
      }
      collection_materials {
        id
        price
        material {
          name
        }
      }
      collection_center_ratings_aggregate {
        aggregate {
          count
          sum {
            rate
          }
        }
      }
      sales {
        status
        sales: sales_materials_aggregate {
          aggregate {
            sum {
              actual_quantity
            }
            count
          }
        }
        receipt
        user {
          name
        }
      }
    }
    suggestedLocations: collection_centre(
      where: { user: { role_id: { _eq: 1 } }, status: { _eq: "new" } }
    ) {
      city
      locality
      long
      lat
      details
      id
      name
      status
      rating
      totalSale: sales_aggregate {
        aggregate {
          count
          sum {
            total_price
          }
        }
      }
      rejection: sales_aggregate(where: { status: { _eq: "Rejected" } }) {
        aggregate {
          count
          sum {
            total_price
          }
        }
      }
      approved: sales_aggregate(where: { status: { _eq: "Approved" } }) {
        aggregate {
          count
          sum {
            total_price
          }
        }
      }
      collection_materials {
        id
        price
        material {
          name
        }
      }
      collection_center_ratings_aggregate {
        aggregate {
          sum {
            rate
          }
        }
      }
      sales {
        sales: sales_materials_aggregate {
          aggregate {
            sum {
              actual_quantity
            }
            count
          }
        }
        receipt
        user {
          name
        }
      }
    }
  }
`;

export const GET_LOCATION_LIST = gql`
  query GET_LOCATION_LIST {
    collection_centre {
      locality
      status
    }
  }
`;

export const GET_SUGGESTED_LOCATIONS = gql`
  query GET_SUGGESTED_LOCATIONS($where: collection_centre_bool_exp) {
    collection_centre(where: $where) {
      name
      locality
      id
      lat
      long
      created_at
      details
      user {
        name
      }
      status
    }
  }
`;

export const GET_SUGGESTED_LOCATION_LIST = gql`
  query GET_SUGGESTED_LOCATION_LIST {
    collection_centre(where: { user: { role_id: { _eq: 1 } } }) {
      locality
    }
  }
`;
