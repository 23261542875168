import { gql } from "@apollo/client";

export const GET_CAMPAIGN_DETAILS = gql`
  query GET_CAMPAIGN_DETAILS(
    $where: vendors_bool_exp
    $rewardsWhere: rewards_bool_exp
  ) {
    vendors(where: $where, order_by: { id: desc }) {
      name
      id
      user_id
      status
      details
      email
      phone
      created_at
      rewards(where: $rewardsWhere) {
        id
        details
        location
        name
        status
        total_used_number
      }
    }
  }
`;

export const GET_VENDOR_ONE = gql`
  query GET_VENDOR_ONE($id: bigint, $transId: Int) {
    vendors(where: { id: { _eq: $id } }) {
      name
      status
      created_at
      id
      email
      phone
      user_id
      details
      rewards {
        status
        id
        details
        total_used_number
      }
    }
    transactions(
      where: { vendor_id: { _eq: $transId } }
      order_by: { id: desc }
    ) {
      created_at
    }
    user_reward(where: { reward: { vendor_id: { _eq: $transId } } }) {
      status
      transaction_id
      reward {
        details
      }
    }
  }
`;

export const GET_REWARDS = gql`
  query GET_REWARDS($id: Int) {
    rewards(where: { vendor_id: { _eq: $id } }) {
      details
      location
      id
      total_used_number
      status
      vendor_id
      name
      vendor {
        details
        status
      }
    }
  }
`;
