import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Badge, Dropdown, Popover, Button } from "antd";
import jwtDecode from "jwt-decode";
import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import packageJson from "../../../package.json";
import DropdownIcon from "../../assets/dropdown.svg";
import Notification from "../../assets/notifications.svg";
import LogoMobile from "../../assets/logoMobile.svg";
import { avatarMap } from "../../utils";
import Search from "../../components/Search";
import { UserOutlined } from "@ant-design/icons";
import fireBaseApp from "../../firebase";
import { getAuth } from "firebase/auth";
import TitleHeadingContext from "../../context/TitleHeadingContext";

export default function Nav({ heading }) {
  const navigate = useNavigate();
  const location = useLocation();
  const titleHeading = useContext(TitleHeadingContext);
  // eslint-disable-next-line
  const [avatar, setAvatar] = useState(null);
  // eslint-disable-next-line
  const [list, setList] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  // eslint-disable-next-line
  const [username, setUsername] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "user-name"
    ]
  );
  const [role, setRole] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "x-hasura-default-role"
    ]
  );

  const handleMenuClick = (item) => {
    if (item.key === "logout") {
      const auth = getAuth(fireBaseApp);
      auth
        .signOut()
        .then(function () {
          // Sign-out successful.
          localStorage.clear();
          if (role === "admin") {
            navigate("/");
          } else {
            navigate("/vendor/login");
          }
        })
        .catch(function (error) {
          // An error happened.
        });
    }
  };

  const items = [
    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
    {
      label: `${packageJson.version}`,
      key: "version",
      disabled: true,
    },
  ];

  const content = (
    <div>
      <p>
        <span className="text-black text-sm font-semibold">Notifications</span>
        <Button type="link" className="float-right p-0 text-green">
          Mark all as read
        </Button>
      </p>
      {list &&
        list.length !== 0 &&
        list.map((item, index) => {
          return (
            <div className="mb-2" key={index}>
              <div>
                <span className="text-gray-900 font-normal text-sm w-full">
                  {item.text}
                </span>
              </div>

              <div className="float-right bg-bgYellow text-sm rounded-xl text-center text-gray-500 ">
                {item.createdTime}
              </div>

              <div className="clear-both"></div>
            </div>
          );
        })}
      {list && list.length === 0 && (
        <div className="mb-12">
          <div className="float-left w-72">
            <span className="text-gray-900 font-normal text-sm w-full">
              No new notifications
            </span>
          </div>
        </div>
      )}
    </div>
  );

  const tabNames = [
    "locations",
    "users",
    "sales",
    "rewards",
    "apartments",
    "feedback",
    "suggestedLocations",
  ];

  let rewardNames = ["campaignDetails", "financials", "outstanding"];
  return (
    <>
      <div className="w-full px-8 py-3 flex justify-between items-center shadow-3xl sticky top-0 z-10 bg-white">
        <img
          src={LogoMobile}
          alt="closeIcon"
          className="w-5 h-5 block md:hidden"
        />
        <div className="text-md-l5-semibold md:text-lg-l5-semibold ">
          {(location.pathname.split("/")[2] === "editRewards" ||
            location.pathname.split("/")[2] === "addRewards" ||
            location.pathname.split("/")[3] === "residentList") &&
          location.pathname.split("/")[3] !== "vendorDetails"
            ? titleHeading.title
            : heading}
        </div>
        {(location.pathname.split("/")[1] === "vendor" ||
          (tabNames.includes(location.pathname.split("/")[1]) &&
            !location.pathname.split("/")[2]) ||
          ((location.pathname.split("/")[2] === "editRewards" ||
            location.pathname.split("/")[2] === "addRewards" ||
            location.pathname.split("/")[3] === "residentList") &&
            location.pathname.split("/")[3] !== "vendorDetails")) && (
          <div className="hidden md:block">
            {searchVisible && (
              <div
                className=" fixed inset-0 bg-black bg-opacity-5"
                onClick={() => {
                  setSearchVisible(false);
                }}
              ></div>
            )}
            <Search
              searchVisible={searchVisible}
              placeholder={
                location.pathname.split("/")[1] === "rewards" &&
                !location.pathname.split("/")[2]
                  ? "Search for vendor name, campaign name, locality"
                  : rewardNames.includes(location.pathname.split("/")[3])
                  ? "Search for camapaign name, locality, ID"
                  : location.pathname.split("/")[3] === "pastPayments"
                  ? "Search for Trasaction ID"
                  : location.pathname.split("/")[1] === "sales"
                  ? "Search for name, location, user locality"
                  : location.pathname.split("/")[3] === "residentList"
                  ? "Search for ID, name, mobile number"
                  : location.pathname.split("/")[1] === "apartments"
                  ? "Search for apartment name, locality, address, POC name"
                  : location.pathname.split("/")[1] === "users"
                  ? "Search for name, locality, apartment"
                  : "Search for name, locality"
              }
              role="admin"
              tag1={
                location.pathname.split("/")[1] === "rewards" &&
                !location.pathname.split("/")[2]
                  ? "Vendor name"
                  : location.pathname.split("/")[1] === "apartments"
                  ? "Apartment name"
                  : "Name"
              }
              tag2={
                location.pathname.split("/")[1] !== "sales"
                  ? "Locality"
                  : "Location"
              }
              tag3={
                location.pathname.split("/")[3] === "residentList"
                  ? "User ID"
                  : location.pathname.split("/")[1] === "rewards" &&
                    !location.pathname.split("/")[2]
                  ? "Campaign name"
                  : location.pathname.split("/")[1] === "apartments"
                  ? "Address"
                  : location.pathname.split("/")[1] === "sales"
                  ? "User Locality"
                  : location.pathname.split("/")[1] === "users"
                  ? "Apartment"
                  : null
              }
              tag4={
                location.pathname.split("/")[1] === "apartments"
                  ? "POC name"
                  : null
              }
              setSearchVisible={setSearchVisible}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          </div>
        )}

        {(location.pathname.split("/")[2] === "viewUser" ||
          location.pathname.split("/")[2] === "editLocations" ||
          location.pathname.split("/")[3] === "vendorDetails" ||
          location.pathname.split("/")[3] === "details") && (
          <>
            <p className="mb-0 text-lg-l5-semibold ">{titleHeading.title}</p>
          </>
        )}

        <div className="flex items-center justify-center">
          {location.pathname !== "/vendor/dashboard" && (
            <Badge size="small" offset={[-5, 7]}>
              <Popover
                placement="bottomRight"
                content={content}
                trigger="click"
                overlayStyle={{
                  width: "420px",
                }}
                overlayInnerStyle={{
                  background: "#F7F7F7",
                }}
              >
                <img
                  src={Notification}
                  alt="Notification"
                  className=" cursor-pointer"
                />
              </Popover>
            </Badge>
          )}
          <Dropdown
            menu={{
              items,
              onClick: handleMenuClick,
            }}
          >
            <div className="flex items-center ml-5 cursor-pointer" size="large">
              <div className="bg-orange bg-opacity-10 rounded-full">
                {avatar ? (
                  <Avatar src={avatar}></Avatar>
                ) : (
                  <Avatar
                    className=" bg-indigo"
                    style={{
                      fontSize: 14,
                    }}
                    size={32}
                  >
                    {username ? avatarMap(username, 3) : <UserOutlined />}
                  </Avatar>
                )}
              </div>

              <img src={DropdownIcon} className="ml-3" alt="Dropdown icon" />
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  );
}
