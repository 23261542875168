import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";
import Nav from "./layout/Nav";
import Sidebar from "./layout/Sidebar";
import jwtDecode from "jwt-decode";
import ErrorBoundary from "../components/ErrorBoundary";
import { getToken } from "../utils";

const PrivateRoute = (props) => {
  const { component: Component, heading, ...rest } = props;

  const hasToken = getToken();

  const role = useMemo(() => {
    if (hasToken)
      return jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
  }, [hasToken]);

  return (
    <>
      {hasToken ? (
        <>
          {role === rest.roles[0] ? (
            <>
              <div className="flex w-full">
                <div className="hidden md:block md:shadow-3xl md:flex-shrink-0 md:min-h-screen">
                  <Sidebar></Sidebar>
                </div>
                <div className="w-full md:w-84">
                  <Nav heading={heading}></Nav>
                  <Component />
                </div>
              </div>
            </>
          ) : (
            <ErrorBoundary />
          )}
        </>
      ) : (
        <Navigate to="/" replace />
      )}
    </>
  );
};

export default PrivateRoute;
