import Select from "../../../components/Select";
import { Button, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../../components/Table";
import Tag from "../../../components/Tag";
import { numFormatter, getColumnSearchProps } from "../../../utils";
import { useState, useMemo, useEffect } from "react";
import FinancialDrawer from "./financialDrawer";
import { GET_USER_REWARD, GET_VENDOR_REWARD_DETAILS } from "./graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";

function Financials() {
  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const [tableData, setTableData] = useState([]);
  const [currentItem, setCurrentItem] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [localityList, setLocalityList] = useState([]);
  const [outstandingAmount, setOutstandingAmount] = useState(0);
  const [selectCampaign, setSelectCampaign] = useState();
  const [selectLocation, setSelectLocation] = useState();
  const [selectPaymentStatus, setSelectPaymentStatus] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const [searchTerm, setSearchTerm] = useState({});
  const [searchLocName, setSearchLocName] = useState();
  const [category, setCategory] = useState();

  const {
    data: vendorReward,
    loading: rewardLoading,
    refetch: rewardrefetch,
  } = useQuery(GET_VENDOR_REWARD_DETAILS, {
    variables: { id: parseInt(location.pathname.split("/")[4]) },
  });

  const [
    getUserRewards,
    { data: userRewardDetails, loading: userrewardLoading },
  ] = useLazyQuery(GET_USER_REWARD, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (vendorReward) {
      let newCampaign = [];
      let newLocation = [];
      vendorReward.rewards.map((reward) => {
        newCampaign.push(reward.name);
        newLocation.push(reward.location);
      });
      setCampaignList(newCampaign);
      setLocalityList(newLocation);
    }
  }, [vendorReward]);

  useEffect(() => {
    rewardrefetch();
    getUserRewards({
      variables: {
        where: {
          reward: {
            vendor_id: { _eq: parseInt(location.pathname.split("/")[4]) },
          },
        },
      },
    });
  }, []);

  useEffect(() => {
    getUserRewards({
      variables: {
        where: searchTerm,
      },
    });
  }, [searchTerm]);

  useEffect(() => {
    setSearchLocName(new URLSearchParams(search).get("searchText"));
    setCategory(new URLSearchParams(search).get("category"));
  }, [location]);

  useEffect(() => {
    setSearchTerm(
      new URLSearchParams(search).get("searchText") &&
        new URLSearchParams(search).get("category") === "Name"
        ? {
            _and: {
              reward: {
                vendor_id: { _eq: parseInt(location.pathname.split("/")[4]) },
                name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
                location: selectLocation
                  ? {
                      _in:
                        selectLocation.length > 0
                          ? selectLocation
                          : localityList,
                    }
                  : {},
              },
              status: selectStatus ? { _eq: selectStatus.toLowerCase() } : {},
            },
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "Locality"
        ? {
            _and: {
              reward: {
                vendor_id: { _eq: parseInt(location.pathname.split("/")[4]) },
                name: {},
                location:
                  selectLocation && searchLocName
                    ? { _ilike: `%${searchLocName}%`, _in: selectLocation }
                    : selectLocation
                    ? {
                        _in:
                          selectLocation.length > 0
                            ? selectLocation
                            : localityList,
                      }
                    : searchLocName
                    ? { _ilike: `%${searchLocName}%` }
                    : {},
              },
              status: selectStatus ? { _eq: selectStatus.toLowerCase() } : {},
            },
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "null"
        ? {
            _and: {
              reward: {
                _and: [
                  {
                    vendor_id: {
                      _eq: parseInt(location.pathname.split("/")[4]),
                    },
                  },
                  {
                    _or: [
                      {
                        location:
                          selectLocation && searchLocName
                            ? {
                                _ilike: `%${searchLocName}%`,
                                _in: selectLocation,
                              }
                            : selectLocation
                            ? {
                                _in:
                                  selectLocation.length > 0
                                    ? selectLocation
                                    : localityList,
                              }
                            : searchLocName
                            ? { _ilike: `%${searchLocName}%` }
                            : {},
                      },

                      {
                        name: searchLocName
                          ? { _ilike: `%${searchLocName}%` }
                          : {},
                      },
                    ],
                  },
                ],
              },
              status: selectStatus ? { _eq: selectStatus.toLowerCase() } : {},
            },
          }
        : {
            _and: {
              reward: {
                vendor_id: { _eq: parseInt(location.pathname.split("/")[4]) },
                name: selectCampaign
                  ? {
                      _in:
                        selectCampaign.length > 0
                          ? selectCampaign
                          : campaignList,
                    }
                  : {},
                location: selectLocation
                  ? {
                      _in:
                        selectLocation.length > 0
                          ? selectLocation
                          : localityList,
                    }
                  : {},
              },
              status: selectStatus ? { _eq: selectStatus.toLowerCase() } : {},
            },
          }
    );
  }, [selectCampaign, selectLocation, selectStatus, searchLocName, category]);

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleselectedValues = (value, type) => {
    if (type === "campaigns") {
      setSelectCampaign(value);
    }
    if (type === "locality") {
      setSelectLocation(value);
    }
    if (type === "couponStatus" && value !== "All") {
      setSelectStatus(value);
    } else if (type === "couponStatus") {
      setSelectStatus();
    }
    if (type === "paymentStatus" && value !== "All") {
      setSelectPaymentStatus(value);
    } else if (type === "paymentStatus") {
      setSelectStatus();
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "CAMPAIGN ID",
        dataIndex: "campaignID",
        key: "campaignID",
        className: "w-40",
        ...getColumnSearchProps("campaignID"),
        render: (text) => <>{`CAM${`${text}`.padStart(6, "0")}`}</>,
      },
      {
        title: "CAMPAIGN details",
        dataIndex: "campaignDetails",
        key: "campaignDetails",
        className: "w-40",
      },
      {
        title: "LOCALITY",
        dataIndex: "locality",
        key: "locality",
        className: "w-40",
      },
      {
        title: "COUPON STATUS",
        dataIndex: "couponStatus",
        key: "couponStatus",
        className: "w-36",
        render: (text) => (
          <>
            {text === "Paid" && (
              <Tag color="#D1FAE5" textColor="#065F46">
                Paid
              </Tag>
            )}
            {text === "Redeemed" && (
              <Tag color="#FEF3C7" textColor="#92400E">
                Redeemed
              </Tag>
            )}
          </>
        ),
      },
      {
        title: "VENDOR COST",
        dataIndex: "amount",
        key: "amount",
        className: "w-40",
        sorter: (a, b) => a.amount - b.amount,
        render: (text) =>
          text ? (
            <>
              <span className="float-right">Rs. {numFormatter(text)} </span>
            </>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "PAYMENT",
        dataIndex: "payment",
        key: "payment",
        className: "w-40",
        sorter: (a, b) => a.payment - b.payment,
        render: (text) => (
          <>
            {text === "cleared" && (
              <Tag color="#D1FAE5" textColor="#065F46">
                Cleared
              </Tag>
            )}
            {text === "outstanding" && (
              <Tag color="#FEF3C7" textColor="#92400E">
                Outstanding
              </Tag>
            )}
            {text === "-" && <span>-</span>}
          </>
        ),
      },
      {
        title: "DATE",
        dataIndex: "date",
        key: "date",
        className: "w-40",
      },
      {
        title: "MANAGE",
        dataIndex: "",
        key: "",
        fixed: "right",
        className: "w-32",
        render: (text, record) => (
          <Button
            type="link"
            className="text-green hover:text-green"
            onClick={() => {
              setOpenDrawer(true);
              setCurrentItem(record);
            }}
            id="details"
          >
            Details
          </Button>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (userRewardDetails) {
      let newArray = [];
      let sum = 0;
      userRewardDetails.user_reward.map((item) => {
        if (item.status === "Paid" || item.status === "Redeemed") {
          newArray.push({
            campaignID: item.reward_id,
            username: item.user.name,
            logo: item.reward.vendor.details.logo,
            vendorName: item.reward.vendor.name,
            vendorStatus: item.reward.vendor.status,
            created_at: item.reward.vendor.created_at,
            campaignDetails: item.reward.name,
            locality: item.reward.location,
            couponStatus: item.status,
            couponCode: item.code,
            dueDate: moment(item.reward.details.expiryDate).format(
              "DD MMM YYYY"
            ),
            paidTimestamp: item.payment_at,
            paymentTransactionID: item.razorpay_transaction_id,
            redeemTimestamp: item.redeemed_at,
            redeemTransactionID: `RDM0000${item.id}`,
            amount: item.reward.details.vendorCost,
            vendorTransactionID:
              item.transaction_id !== null
                ? item.transaction.payment_details.transactionID
                : null,
            vendorPaymentDate:
              item.transaction_id !== null ? item.transaction.created_at : null,
            payment:
              item.status === "Paid"
                ? "-"
                : item.transaction_id !== null
                ? "cleared"
                : "outstanding",
            date:
              item.status === "Paid"
                ? "-"
                : item.transaction_id !== null
                ? moment(item.transaction.created_at).format("DD MMM YYYY")
                : "-",
          });
          if (!item.transaction_id && item.status === "Redeemed") {
            sum += parseInt(item.reward.details.vendorCost);
          }
        }
      });
      if (selectPaymentStatus) {
        let DisplayData = newArray?.filter((ele) => {
          return ele.payment === selectPaymentStatus.toLowerCase();
        });
        setTableData(DisplayData);
      } else {
        setTableData(newArray);
      }

      setOutstandingAmount(sum);
    }
  }, [userRewardDetails, selectPaymentStatus]);

  return (
    <Spin spinning={userrewardLoading || rewardLoading}>
      <div className="flex flex-col gap-5 p-8 w-full justify-between ">
        <div className="flex flex-row gap-5  w-full ">
          <div style={{ width: "12%" }}>
            <Select
              placeholder="City"
              type="City"
              data={["Chennai"]}
              onChange={handleselectedValues}
              value="Chennai"
              allowClear={true}
            />
          </div>
          <div style={{ width: "18%" }}>
            <Select
              placeholder="Campaigns"
              type="campaigns"
              data={campaignList || []}
              mode="multiple"
              onChange={handleselectedValues}
              allowClear={true}
            />
          </div>
          <div style={{ width: "18%" }}>
            <Select
              placeholder="Locality"
              type="locality"
              mode="multiple"
              data={localityList || []}
              onChange={handleselectedValues}
              allowClear={true}
            />
          </div>

          <div style={{ width: "13%" }}>
            <Select
              placeholder="Coupon Status"
              type="couponStatus"
              data={["All", "Paid", "Redeemed"]}
              onChange={handleselectedValues}
              value=""
              allowClear={true}
            />
          </div>
          <div style={{ width: "13%" }}>
            <Select
              placeholder="Payment Status"
              type="paymentStatus"
              data={["All", "Cleared", "Outstanding"]}
              onChange={handleselectedValues}
              value=""
              allowClear={true}
            />
          </div>
          <div style={{ width: "20%" }}>
            <Button
              type="primary"
              id="addlocations"
              size="large"
              style={{ backgroundColor: "#ff4d4f" }}
              className="float-right"
              onClick={() =>
                navigate(
                  `/rewards/${location.pathname.split("/")[2]}/outstanding/${
                    location.pathname.split("/")[4]
                  }`
                )
              }
            >
              Outstanding: INR {numFormatter(outstandingAmount)}
            </Button>
          </div>
        </div>

        <div className="w-full">
          <Table
            dataSource={!!tableData.length ? tableData : []}
            columns={columns}
            key="usersListing"
            pagination={true}
            scroll={{
              x: 1300,
            }}
          />
        </div>
        {openDrawer && (
          <FinancialDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleClose={handleClose}
            currentItem={currentItem}
          />
        )}
      </div>
    </Spin>
  );
}

export default Financials;
