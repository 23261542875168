import { Drawer, Button, Spin, Avatar, Tabs } from "antd";
import UserOutlined from "../../assets/UserOutlined.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SIGNED_URL } from "./addRewards/graphql";
import { GET_VENDOR_ONE } from "./graphql";
import { useState, useEffect } from "react";
import { capitalizeFirst } from "../../utils";
import Stats from "./Stats";
import AvailableCoupons from "./availableCoupons";

const { TabPane } = Tabs;

function CampaignDrawer(props) {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [getSignedUrl] = useMutation(GET_SIGNED_URL);
  const { data: vendorDetails, loading: loadingStatus } = useQuery(
    GET_VENDOR_ONE,
    {
      variables: {
        id: props.currentItem.id,
        transId: props.currentItem.id,
      },
    }
  );

  const [avatar, setAvatar] = useState();

  useEffect(() => {
    if (props) {
      getCases(props.currentItem);
    }
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    let newArray = [];

    if (vendorDetails) {
      let sumOfcouponsSold = 0;
      let sumOfCouponCost = 0;
      let sumOfProfit = 0;
      let sumOfVendorCost = 0;
      let sumOfOutstanding = 0;
      let active = vendorDetails?.vendors[0]?.rewards?.filter((item) => {
        return item.status === "active";
      });
      vendorDetails?.vendors[0]?.rewards?.map((reward) => {
        sumOfcouponsSold += reward.total_used_number;
        sumOfCouponCost += parseInt(
          reward.details.couponsCost * reward.total_used_number
        );
      });
      vendorDetails?.vendors[0]?.rewards?.map((reward) => {
        if (reward.total_used_number > 0) {
          sumOfProfit +=
            parseInt(reward.details.couponsCost) -
            parseInt(reward.details.vendorCost) * reward.total_used_number;
          sumOfVendorCost += parseInt(reward.details.vendorCost);
        }
      });
      vendorDetails?.user_reward?.map((item) => {
        if (item.status.toLowerCase() === "redeemed" && !item.transaction_id) {
          sumOfOutstanding += parseInt(item.reward.details.vendorCost);
        }
      });
      newArray.push({
        activeCampaign: active.length,
        couponsSold: sumOfcouponsSold,
        lastPaymentOn:
          vendorDetails?.transactions.length > 0
            ? moment(vendorDetails?.transactions[0]?.created_at).format(
                "DD MMM YYYY"
              )
            : "",
        outStandingAmount: sumOfOutstanding,
        revenueFromSales: sumOfCouponCost,
        profitForKC: sumOfProfit,
        totalAmount: sumOfVendorCost,
        paymentCycle: vendorDetails?.vendors[0].details.paymentCycle,
      });
      setData(newArray);
    }
  }, [vendorDetails]);

  const getCases = async (props) => {
    setLoading(true);
    if (props?.image) {
      let responseUrl = await getResponseUrl(props?.image);
      setAvatar(responseUrl);
    }
    setLoading(false);
  };

  const getResponseUrl = (file) => {
    return new Promise((resolve) => {
      getSignedUrl({
        variables: {
          fileName: file,
        },
      }).then((res) => {
        resolve(res?.data?.get_signed_url?.url);
      });
    });
  };

  const onClose = () => {
    props.setOpenDrawer(false);
    props.setCurrentItem([]);
  };

  return (
    <Spin spinning={loading}>
      <Drawer
        width={400}
        placement="right"
        closable={true}
        onClose={onClose}
        bodyStyle={{ paddingTop: 0 }}
        headerStyle={{ border: 0 }}
        open={props.openDrawer}
        rootStyle={{}}
      >
        <Spin spinning={loadingStatus}>
          <div className="mb-4">
            <div className="flex flex-col justify-between items-center">
              <p>
                {avatar ? (
                  <Avatar
                    src={avatar}
                    shape="square"
                    style={{
                      width: "120px",
                      height: "56px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                ) : (
                  <Avatar
                    className=" bg-indigo"
                    style={{
                      width: "120px",
                      height: "56px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    shape="square"
                  >
                    <img src={UserOutlined} alt="useroutlined" />
                  </Avatar>
                )}
              </p>

              <p className="text-base text-center mb-0 font-semibold">
                {props.currentItem.vendorName}
              </p>
              <p className="text-sm text-green text-center font-semibold">
                {capitalizeFirst(props.currentItem.vendorStatus)} since:{" "}
                {moment(props.currentItem.created_at).format("DD MMM YYYY")}
              </p>
            </div>
          </div>
          <div>
            <p className="h-7">
              <span className="text-base font-semibold float-left opacity-50">
                Vendor details
              </span>
              <Button
                type="link"
                className="float-right pr-0 text-green text-base"
                onClick={() =>
                  navigate(
                    `/rewards/editRewards/vendorDetails/${props.currentItem.id}`
                  )
                }
              >
                Edit
              </Button>
            </p>
            <p>
              <span className="text-sm font-semibold align-middle">Email</span>
              <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

              <span className="align-middle font-normal text-sm">
                {props.currentItem.email}
              </span>
            </p>
            <p>
              <span className="text-sm font-semibold align-middle">Mobile</span>
              <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

              <span className="align-middle font-normal text-sm">
                {props.currentItem.phone}
              </span>
            </p>
          </div>
          {props.currentItem.campaignID !== "-" ? (
            <Tabs>
              <TabPane tab="Stats & Financials" key="1">
                <Stats currentItem={props.currentItem} data={data} />
              </TabPane>
              <TabPane tab="Available coupons" key="2">
                <AvailableCoupons
                  currentItem={props.currentItem}
                  avatar={avatar}
                />
              </TabPane>
            </Tabs>
          ) : (
            <div className="mt-14 text-center">
              <p className="text-base">No Campaigns added for this vendor</p>
            </div>
          )}
        </Spin>
      </Drawer>
    </Spin>
  );
}

export default CampaignDrawer;
