import { gql } from "@apollo/client";

export const GET_USER_REWARD = gql`
  query GET_USER_REWARD($where: user_reward_bool_exp, $id: bigint) {
    user_reward(where: $where) {
      details
      status
      reward {
        location
        name
        details
      }
    }
    users(where: { id: { _eq: $id } }) {
      name
    }
  }
`;

export const GET_USER_STATUS = gql`
  query GET_USER_STATUS($phone_number: String) {
    users(where: { phone: { _ilike: $phone_number } }) {
      name
      status
      role_id
    }
  }
`;
