import { useState, useMemo, useEffect } from "react";
import { Button, Spin } from "antd";
import Select from "../../components/Select";
import Tag from "../../components/Tag";
import Table from "../../components/Table";
import { useNavigate, useLocation } from "react-router-dom";
import { GET_APARRTMENT_LIST, GET_APARTMENT_LOCATION_LIST } from "./graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { getColumnSearchProps } from "../../utils";

function Apartments() {
  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const [searchTerm, setSearchTerm] = useState({});
  const [searchLocName, setSearchLocName] = useState();
  const [category, setCategory] = useState();
  const [locality, setLocality] = useState([]);
  const [selectLocation, setselectLocation] = useState();
  const [collectionStatus, setCollectionStatus] = useState();
  const [tableDataArray, setTableDataArray] = useState([]);

  const {
    data: locationList,
    loading: locationLoading,
    refetch,
  } = useQuery(GET_APARTMENT_LOCATION_LIST);

  const [getApartmentList, { data: apartmentList, loading: apartmentLoading }] =
    useLazyQuery(GET_APARRTMENT_LIST, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (locationList) {
      let newList = [];
      locationList.apartments.map((item) => {
        if (item.locality !== null) newList.push(item.locality);
      });

      let list = newList.filter(
        (item, index) => newList.indexOf(item) === index
      );
      list.sort();
      setLocality(list);
    }
  }, [locationList]);

  useEffect(() => {
    refetch();
    getApartmentList({
      variables: {
        where: {},
      },
    });
  }, []);

  useEffect(() => {
    setSearchLocName(new URLSearchParams(search).get("searchText"));
    setCategory(new URLSearchParams(search).get("category"));
  }, [location]);

  useEffect(() => {
    if (apartmentList) {
      let newList = [];
      apartmentList.apartments.map((item) => {
        newList.push({
          id: item.id,
          apartmentId: item.id,
          apartmentName: item.name,
          locality: item.locality,
          address: item.address,
          status: item.status,
          houseHolds: item.household,
          pocName: item.poc_name,
          pocNumber: item.poc_phone,
        });
      });
      setTableDataArray(newList);
    }
  }, [apartmentList]);

  useEffect(() => {
    if (searchTerm) {
      getApartmentList({
        variables: {
          where: searchTerm,
        },
      });
    }
  }, [searchTerm]);

  useEffect(() => {
    setSearchTerm(
      new URLSearchParams(search).get("searchText") &&
        new URLSearchParams(search).get("category") === "Apartment name"
        ? {
            locality: selectLocation
              ? { _in: selectLocation.length > 0 ? selectLocation : locality }
              : {},
            status: collectionStatus
              ? { _eq: collectionStatus.toLowerCase() }
              : {},
            name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
            address: {},
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "Locality"
        ? {
            locality:
              selectLocation && searchLocName
                ? { _ilike: `%${searchLocName}%`, _in: selectLocation }
                : selectLocation
                ? { _in: selectLocation.length > 0 ? selectLocation : locality }
                : searchLocName
                ? { _ilike: `%${searchLocName}%` }
                : {},
            status: collectionStatus
              ? { _eq: collectionStatus.toLowerCase() }
              : {},
            name: {},
            address: {},
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "null"
        ? {
            _and: [
              {
                status: collectionStatus
                  ? { _eq: collectionStatus.toLowerCase() }
                  : {},
              },
              {
                _or: [
                  {
                    locality:
                      selectLocation && searchLocName
                        ? { _ilike: `%${searchLocName}%`, _in: selectLocation }
                        : selectLocation
                        ? {
                            _in:
                              selectLocation.length > 0
                                ? selectLocation
                                : locality,
                          }
                        : searchLocName
                        ? { _ilike: `%${searchLocName}%` }
                        : {},
                  },

                  {
                    name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
                  },
                  {
                    address: searchLocName
                      ? { _ilike: `%${searchLocName}%` }
                      : {},
                  },
                  {
                    poc_name: searchLocName
                      ? { _ilike: `%${searchLocName}%` }
                      : {},
                  },
                ],
              },
            ],
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "Address"
        ? {
            locality: selectLocation
              ? { _in: selectLocation.length > 0 ? selectLocation : locality }
              : {},
            status: collectionStatus
              ? { _eq: collectionStatus.toLowerCase() }
              : {},
            address: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
            name: {},
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "POC name"
        ? {
            locality: selectLocation
              ? { _in: selectLocation.length > 0 ? selectLocation : locality }
              : {},
            status: collectionStatus
              ? { _eq: collectionStatus.toLowerCase() }
              : {},
            address: {},
            name: {},
            poc_name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
          }
        : {
            locality: selectLocation
              ? { _in: selectLocation.length > 0 ? selectLocation : locality }
              : {},
            status: collectionStatus
              ? { _eq: collectionStatus.toLowerCase() }
              : {},
          }
    );
  }, [selectLocation, collectionStatus, searchLocName, category]);

  const handleselectedValues = (value, type) => {
    if (type === "Locality") setselectLocation(value);

    if (type === "Status") {
      setCollectionStatus(value);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "APARTMENT ID",
        dataIndex: "apartmentId",
        key: "apartmentId",
        className: "w-40",
        ...getColumnSearchProps("apartmentId"),
        render: (text) => <>{`APAR${`${text}`.padStart(6, "0")}`}</>,
      },

      {
        title: "APARTMENT",
        dataIndex: "apartmentName",
        key: "apartmentName",
        className: "w-40",
      },
      {
        title: "LOCALITY",
        dataIndex: "locality",
        key: "locality",
        className: "w-40",
      },
      {
        title: "ADDRESS",
        dataIndex: "address",
        key: "address",
        ellipsis: true,
        className: "w-40",
      },
      {
        title: "STATUS",
        dataIndex: "status",
        key: "status",
        className: "w-40",
        render: (text) => (
          <>
            {text === "active" && (
              <Tag color="#D1FAE5" textColor="#065F46">
                Active
              </Tag>
            )}
            {text === "disabled" && (
              <Tag color="#F3F4F6" textColor="#1F2937">
                Disabled
              </Tag>
            )}
          </>
        ),
      },
      {
        title: "HOUSEHOLDS",
        dataIndex: "houseHolds",
        key: "houseHolds",
        className: "w-40",
      },
      {
        title: "POC NAME",
        dataIndex: "pocName",
        key: "pocName",
        className: "w-40",
      },
      {
        title: "POC NUMBER",
        dataIndex: "pocNumber",
        key: "pocNumber",
        className: "w-40",
      },
      {
        title: "MANAGE",
        dataIndex: "",
        key: "",
        fixed: "right",
        width: 100,
        render: (text, record) => (
          <Button
            type="link"
            className="text-green hover:text-green"
            onClick={() => {
              navigate(`/apartments/editApartment/details/${record.id}`);
            }}
            id="details"
          >
            Details
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <Spin spinning={locationLoading || apartmentLoading}>
      <div className="flex flex-col gap-5 p-8 w-full justify-between ">
        <div className="flex flex-row gap-5  w-full ">
          <div style={{ width: "13%" }}>
            <Select
              placeholder="City"
              type="City"
              data={["Chennai"]}
              onChange={handleselectedValues}
              value="Chennai"
              // width="127px"
              allowClear={true}
            />
          </div>
          <div style={{ width: "20%" }}>
            <Select
              placeholder="Locality"
              type="Locality"
              mode="multiple"
              data={locality || []}
              onChange={handleselectedValues}
              value=""
              width=""
              allowClear={true}
            />
          </div>
          <div style={{ width: "13%" }}>
            <Select
              placeholder="Status"
              type="Status"
              data={["Active", "Disabled"]}
              onChange={handleselectedValues}
              value={collectionStatus}
              // width="127px"
              allowClear={true}
            />
          </div>
          <div style={{ width: "33%" }}></div>
          <div style={{ width: "13%" }}>
            <Button
              type="primary"
              id="addApartment"
              size="large"
              className="float-right"
              onClick={() => navigate("/apartments/addApartment/details")}
              style={{ width: "140px" }}
            >
              Add Apartment
            </Button>
          </div>
        </div>

        <div className="w-full">
          <Table
            dataSource={!!tableDataArray.length ? tableDataArray : []}
            columns={columns}
            key="apartmentListing"
            pagination={true}
            scroll={{
              x: 1300,
            }}
          />
        </div>
      </div>
    </Spin>
  );
}

export default Apartments;
