import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { numFormatter, calc } from "../utils";

export default function SaleLineChart(props) {
  const highestValue = props.data.length !== 0 ? props.data[0].category : 5000;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div>
          <p className="text-bold">{label}</p>
          <p>
            {payload.map((item) => {
              return (
                <p style={{ color: `${item.stroke}` }}>
                  {item.dataKey === "noOfTransaction"
                    ? "No of Transaction"
                    : item.dataKey === "avgPrice"
                    ? "Average price"
                    : item.dataKey}
                  {": "}
                  <span className="text-charcoal">
                    {!Number.isInteger(item.payload[item.dataKey])
                      ? item.payload[item.dataKey].toFixed(2)
                      : item.payload[item.dataKey]}
                  </span>
                </p>
              );
            })}
          </p>
        </div>
      );
    }

    return null;
  };

  const CustomizedAxisTick = (props) => {
    const { x, y, payload, width, maxChars, lineHeight, fontSize, fill } =
      props;
    const rx = new RegExp(`.{1,${maxChars}}`, "g");
    const chunks = payload.value
      .replace(/ - /g, "-")
      .split("~")
      .map((s) => s.match(rx))
      .flat();
    const tspans = chunks.map((s, i) => {
      return (
        <>
          {i < 3 && (
            <tspan x={0} y={lineHeight} dy={i * lineHeight}>
              {s}
            </tspan>
          )}
          {i === 3 && (
            <tspan x={0} y={lineHeight} dy={i * lineHeight}>
              {`${s.slice(0, 5)}...`}
            </tspan>
          )}
        </>
      );
    });
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          width={width}
          height="auto"
          textAnchor="middle"
          fontSize={fontSize}
          fill={fill}
        >
          {tspans}
        </text>
      </g>
    );
  };
  CustomizedAxisTick.defaultProps = {
    width: 50,
    maxChars: 10,
    fontSize: 12,
    lineHeight: 14,
    fill: "#9c9ca6",
  };

  return props.data.length !== 0 ? (
    <div id="linechart">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width="100%"
          height={300}
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid stroke="#E7E8F2" vertical={false} /> */}

          <XAxis
            interval={0}
            tick={<CustomizedAxisTick />}
            dataKey="name"
            // axisLine={false}
            minTickGap={2}
            stroke="#9c9ca6"
          />
          <YAxis
            domain={[0, highestValue]}
            tick={{ fill: "#9c9ca6" }}
            stroke="#9c9ca6"
            tickFormatter={numFormatter}
            tickCount={5}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            wrapperStyle={{
              outline: "none",
              border: "1px solid #828282",
              padding: "12px",
              borderRadius: "5px",
            }}
            content={<CustomTooltip />}
          />
          <Line
            dataKey="noOfTransaction"
            stroke="#27AE60"
            activeDot={{ r: 8 }}
          />
          <Line dataKey="Volume" stroke="#2F80ED" activeDot={{ r: 8 }} />
          <Line dataKey="avgPrice" stroke="#9B51E0" activeDot={{ r: 8 }} />
          <Line dataKey="Revenue" stroke="#EB9B3F" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <div className="flex flex-col justify-center items-center py-4">
      {/* <img alt="topcat" src={SaleIcon} /> */}
      <p className="text-lg-l5-semibold text-gray-500 mt-4">
        There is no sale added yet
      </p>
    </div>
  );
}
