import { Button, Select as ReactSelect } from "antd";
import React, { useEffect } from "react";

const { Option, OptGroup } = ReactSelect;

export default function CreateSelect({
  onChange,
  handleNewValues,
  handleListValues,
  defaultValue,
  data,
  value,
  type,
  index,
  width,
  ...rest
}) {
  const [selectValue, setSelectValue] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setSelectValue(value || []);
  }, [value]);

  return (
    <ReactSelect
      defaultValue={defaultValue}
      onChange={(value) => {
        setSelectValue(value);
        if (onChange) {
          onChange(value, index);
        }
      }}
      onSearch={(val) => {
        setSearchValue(val);
      }}
      // onFocus={handleListValues}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      onSelect={() => setSearchValue("")}
      size={"large"}
      style={{
        width: `${width ? width : "100%"}`,
      }}
      open={open}
      value={selectValue}
      showSearch
      showArrow
      optionFilterProp="children"
      className=" border-green cursor-pointer"
      popupClassName=" customSelect border-green border border-solid bg-white shadow-1 mt-4"
      onInputKeyDown={() => {
        if ((!data.includes(searchValue) && searchValue) || data.length === 0) {
          setSelectValue(searchValue);
          handleNewValues("key", searchValue, index);
        }
      }}
      dropdownRender={(menu) => (
        <>
          {menu}
          {((!data.includes(searchValue) && searchValue) ||
            data.length === 0) && (
            <Button
              type="text"
              className="w-full text-left"
              onClick={(e) => {
                setSelectValue(searchValue);
                handleNewValues(e, searchValue, index);
                setOpen(false);
              }}
            >{`Create ${searchValue}`}</Button>
          )}
        </>
      )}
      notFoundContent={
        <div className="text-sm-l5-normal text-[#ACB1BC]">
          Select material or type to create new
        </div>
      }
      {...rest}
    >
      <OptGroup
        label={
          <div className="text-sm-l5-normal text-[#ACB1BC] ">
            Select material or type to create new
          </div>
        }
      >
        {data.map((item, index) => {
          return (
            <Option value={item} key={`${item}-type-index`} className="py-2">
              {item}
            </Option>
          );
        })}
      </OptGroup>
    </ReactSelect>
  );
}
