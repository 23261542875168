import { Card } from "antd";
import { numFormatter } from "../utils";
import { Link } from "react-router-dom";

function CountCard(props) {
  return (
    <div className={props.className}>
      <Card style={{ width: `${props.width}` }} bordered={false}>
        <div>
          <div className="w-full">
            {props.type === "dashboard" && (
              <p
                className={`text-sm text-gray w-full ${
                  props.type === "dashboard" ? "" : "font-semibold"
                } ${props.tab === "rewards" ? "w-28" : ""}`}
              >
                {props.title}
              </p>
            )}
            <div>
              {props.count < 10000 ? (
                <span
                  className={`text-4xl mb-0 ${
                    props.link ? "text-green" : "font-extralight"
                  }  `}
                >
                  {props.symbol}
                  {props.count % 1 === 0
                    ? props.count
                    : parseFloat(props.count).toFixed(2)}
                  {/* {props.count.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]} */}
                  {props.metric}
                </span>
              ) : (
                <span
                  className={`text-4xl ${
                    props.link ? "text-green" : "font-extralight"
                  } mb-0`}
                >
                  {props.symbol} {numFormatter(props.count)}
                  {props.metric}
                </span>
              )}
              {((props.percentageValue && props.percentageValue !== "") ||
                props.percentageValue === 0) && (
                <span className="ml-2 text-xs">
                  {props.percentageValue.toFixed(2)} %
                </span>
              )}
            </div>
            {props.type !== "dashboard" && (
              <p
                className={`text-sm font-semibold text-gray ${
                  props.tab === "users"
                    ? "w-36"
                    : props.tab === "rewards"
                    ? "w-28"
                    : "w-32"
                }`}
              >
                {props.title}
              </p>
            )}

            {props.link && (
              <div className="mt-6">
                <Link
                  to={props.link}
                  className={`text-sm font-normal  w-2/12  text-green hover:text-green ${
                    props.count === 0 ? "pointer-events-none " : ""
                  }`}
                >
                  View details
                </Link>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}
export default CountCard;
