import { gql } from "@apollo/client";

export const GET_MATERIAL_LIST = gql`
  query GET_MATERIAL_LIST {
    materials(order_by: { id: asc }) {
      id
      is_global
      name
      sales_materials_aggregate(where: { status: { _eq: "Approved" } }) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const CHANGE_MATERIAL_TO_GLOBAL = gql`
  mutation CHANGE_MATERIAL_TO_GLOBAL($id: bigint) {
    update_materials(where: { id: { _eq: $id } }, _set: { is_global: true }) {
      affected_rows
    }
  }
`;

export const CHANGE_MATERIAL_TO_OTHERS = gql`
  mutation CHANGE_MATERIAL_TO_OTHERS($id: bigint) {
    update_materials(where: { id: { _eq: $id } }, _set: { is_global: false }) {
      affected_rows
    }
  }
`;

// export const UPDATE_MATERIALS = gql`
//   mutation UPDATE_MATERIALS($id: bigint, $name: String) {
//     update_materials(where: { id: { _eq: $id } }, _set: { name: $name }) {
//       affected_rows
//     }
//   }
// `;

export const INSERT_MATERIAL = gql`
  mutation INSERT_MATERIAL($object: [materials_insert_input!]!) {
    insert_materials(
      objects: $object
      on_conflict: { constraint: materials_pkey, update_columns: name }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
