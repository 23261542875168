import { useEffect, useState } from "react";
import { Form, Button, Input, Space, Spin, Dropdown, Modal } from "antd";
import deleteIcon from "../../assets/deleteIcon.svg";
import moreIcon from "../../assets/moreIcon.svg";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_MATERIAL_LIST,
  CHANGE_MATERIAL_TO_GLOBAL,
  CHANGE_MATERIAL_TO_OTHERS,
  INSERT_MATERIAL,
} from "./graphql";
import { dupInArray, arrayEquals, capitalizeFirst } from "../../utils";

function GlobalMaterial() {
  const [form] = Form.useForm();
  const [otherForm] = Form.useForm();
  const [materialList, setMaterialList] = useState([]);
  const [open, setOpen] = useState(false);
  const [indexVal, setIndexVal] = useState();
  const [error, setError] = useState();
  const [otherError, setOtherError] = useState();
  const [global, setGlobal] = useState([]);
  const [otherMaterialvalue, setOtherMaterialValue] = useState([]);
  const [changeToGlobal] = useMutation(CHANGE_MATERIAL_TO_GLOBAL);
  const [changeToOthers] = useMutation(CHANGE_MATERIAL_TO_OTHERS);
  const [insertMaterial, { loading }] = useMutation(INSERT_MATERIAL);

  const {
    data: materialsData,
    loading: materialLoading,
    refetch: materialRefetch,
  } = useQuery(GET_MATERIAL_LIST);

  useEffect(() => {
    let list = [];
    let globalinitValue = [];
    let otherinitValue = [];
    let globalList = [];
    let otherList = [];
    if (materialsData) {
      materialsData.materials.map((item) => {
        list.push(item.name);
        if (item.is_global) {
          globalList.push(item.name.toLowerCase());
          globalinitValue.push({
            key: item.id,
            materialName: item.name,
            instances: item.sales_materials_aggregate.aggregate.count,
          });
        } else if (!item.is_global) {
          otherList.push(item.name.toLowerCase());
          otherinitValue.push({
            key: item.id,
            othermaterialName: item.name,
            otherinstances: item.sales_materials_aggregate.aggregate.count,
          });
        }
      });
      setGlobal(globalList);
      setOtherMaterialValue(otherList);
      form.setFieldsValue({ globalMaterials: globalinitValue });
      otherForm.setFieldsValue({ otherMaterials: otherinitValue });
    }
    setMaterialList(list);
    // eslint-disable-next-line
  }, [materialsData]);

  const onFinish = (values) => {
    setError();
    const lower = materialList.map((element) => {
      return element.toLowerCase();
    });
    let newList = [];
    let materialArray = [];
    let status = false;
    // eslint-disable-next-line

    values.globalMaterials.map((item, index) => {
      if (item.materialName) {
        newList.push(item.materialName.toLowerCase());
      }
    });
    values.globalMaterials.map((item, index) => {
      if (item.materialName) {
        if (item.materialName.length === 0) {
          status = true;
          setOtherError(`Material name cannnot be empty`);
        } else if (
          !item.key &&
          lower.includes(item.materialName.toLowerCase())
        ) {
          status = true;
          setError(`
          Unable to save. It seems that Material "${item.materialName}" you have entered already exists in the Global or Other materials section.`);
        } else if (item.key && dupInArray(newList).length > 0) {
          status = true;
          setError(
            ` Unable to save. It seems that Material "${dupInArray(
              newList
            ).join(
              ","
            )}" you have entered already exists in the Global or Other materials section.`
          );
        }
      }
    });
    newList = newList.filter(function (element) {
      return element !== undefined;
    });
    if (!status && !arrayEquals(newList, global)) {
      // eslint-disable-next-line
      values.globalMaterials.map((item, index) => {
        if (!global.includes(item.materialName)) {
          if (item.key) {
            materialArray.push({
              id: item.key,
              name: capitalizeFirst(item.materialName),
              is_global: true,
            });
          } else {
            materialArray.push({
              name: capitalizeFirst(item.materialName),
              is_global: true,
            });
          }
        }
      });

      insertMaterial({
        variables: {
          object: materialArray,
        },
      }).then(() => materialRefetch());
    }
  };

  const onFinishOthers = (values) => {
    setOtherError();
    let materialArray = [];
    let newList = [];
    let status = false;
    values.otherMaterials.map((item, index) => {
      if (item.othermaterialName) {
        newList.push(item.othermaterialName.toLowerCase());
      }
    });
    let appendArray = global.concat(newList);
    // eslint-disable-next-line
    values.otherMaterials.map((item, index) => {
      if (item.othermaterialName.length === 0) {
        status = true;
        setOtherError(`Material name cannnot be empty`);
      } else if (item.othermaterialName) {
        if (dupInArray(appendArray).length > 0) {
          status = true;
          setOtherError(
            ` Unable to save. It seems that Material "${dupInArray(
              appendArray
            ).join(
              ","
            )}" you have entered already exists in the Global or Other materials section.`
          );
        }
      }
    });
    newList = newList.filter(function (element) {
      return element !== undefined;
    });
    if (!status && !arrayEquals(newList, otherMaterialvalue)) {
      // eslint-disable-next-line
      values.otherMaterials.map((item, index) => {
        if (!otherMaterialvalue.includes(item.materialName)) {
          if (item.key) {
            materialArray.push({
              id: item.key,
              name: capitalizeFirst(item.materialName),
              is_global: false,
            });
          } else {
            materialArray.push({
              name: capitalizeFirst(item.materialName),
              is_global: false,
            });
          }
        }
      });

      insertMaterial({
        variables: {
          object: materialArray,
        },
      }).then(() => materialRefetch());
    }
  };
  const handleMenuClick = (item) => {
    changeToGlobal({
      variables: {
        id: parseInt(item.key),
      },
    }).then(() => {
      materialRefetch();
    });
  };

  return (
    <Spin spinning={materialLoading || loading}>
      <div className="bg-bgGray w-full min-h-screen py-4 px-4 flex flex-row">
        <div className="w-45  p-4 bg-white rounded-md shadow-3xl mx-auto mt-4 mb-auto">
          <p className="text-lg mb-0">Global Materials</p>
          <p className="text-sm text-gray500 font-normal">
            This information is for internal use only so please review carefully
            before you save
          </p>
          <p className="flex flex-row flex-start mb-2">
            <span className="text-sm font-medium" style={{ width: "300px" }}>
              Material name
            </span>
            <span className="text-sm font-medium">Instances</span>
          </p>
          <Form
            name="globalMaterialsForm"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            preserve={true}
          >
            <Form.List initialValue={["materialList"]} name="globalMaterials">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Space
                      {...field}
                      key={field.key}
                      align="baseline"
                      name={[field.name, "materialList"]}
                      size={25}
                    >
                      <Form.Item name={[field.name, "materialName"]}>
                        <Input style={{ width: "270px" }} size="large" />
                      </Form.Item>
                      <Form.Item name={[field.name, "instances"]}>
                        <Input
                          type="number"
                          size="large"
                          style={{ width: "98px" }}
                          disabled
                        />
                      </Form.Item>
                      <span
                        className={`${
                          fields.length > 1
                            ? "cursor-pointer"
                            : "cursor-not-allowed"
                        }`}
                        onClick={() => {
                          if (
                            form.getFieldValue("globalMaterials")[index] &&
                            fields.length > 1
                          ) {
                            setOpen(true);
                            setIndexVal(
                              form.getFieldValue("globalMaterials")[index].key
                            );
                          } else if (fields.length > 1) {
                            remove(field.name);
                          }
                        }}
                      >
                        <img src={deleteIcon} alt="deleteIcon" />
                      </span>
                    </Space>
                  ))}

                  <Form.Item className="h-10 mb-0 block">
                    <Button
                      type="link"
                      className="text-base text-green p-0"
                      onClick={() => add()}
                    >
                      Add New
                    </Button>
                  </Form.Item>
                  {error && (
                    <Form.Item className="mb-0">
                      <p className="text-red mb-0">{error}</p>
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>

            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="float-right mr-4"
                  // disabled={loading}
                  disabled={!form.isFieldsTouched()}
                >
                  Save
                </Button>
              )}
            </Form.Item>
          </Form>
          <Modal
            title="Confirmation"
            open={open}
            onOk={() => {
              changeToOthers({
                variables: {
                  id: parseInt(indexVal),
                },
              }).then(() => {
                materialRefetch();
                setOpen(false);
              });
            }}
            onCancel={() => {
              setOpen(false);
            }}
            okText="Ok"
            cancelText="Cancel"
          >
            <p>Are you sure want to move the material from global list?</p>
          </Modal>
        </div>
        <div className="w-45  p-4 bg-white rounded-md shadow-3xl mx-auto mt-4 mb-auto">
          <p className="text-lg mb-0">Other Materials</p>
          <p className="text-sm text-gray500 font-normal">
            These materials are addeed for individual kabadiwallas. Based on
            instances you can make them global by tapping on the more icon.
          </p>
          {otherMaterialvalue.length > 0 ? (
            <>
              <p className="flex flex-row flex-start mb-2">
                <span
                  className="text-sm font-medium"
                  style={{ width: "300px" }}
                >
                  Material name
                </span>
                <span className="text-sm font-medium">Instances</span>
              </p>

              <Form
                name="otherMaterials"
                form={otherForm}
                layout="vertical"
                onFinish={onFinishOthers}
                preserve={true}
              >
                <Form.List
                  initialValue={["otherMaterialList"]}
                  name="otherMaterials"
                >
                  {(fields, { add }) => (
                    <>
                      {fields.map((field, index) => (
                        <Space
                          {...field}
                          key={field.key}
                          align="baseline"
                          name={[field.name, "otherMaterialList"]}
                          size={25}
                        >
                          <Form.Item name={[field.name, "othermaterialName"]}>
                            <Input style={{ width: "270px" }} size="large" />
                          </Form.Item>
                          <Form.Item name={[field.name, "otherinstances"]}>
                            <Input
                              type="number"
                              size="large"
                              style={{ width: "98px" }}
                              disabled
                            />
                          </Form.Item>
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  label: "Move to global materials",
                                  key: otherForm.getFieldValue([
                                    "otherMaterials",
                                    field.key,
                                  ]).key,
                                },
                              ],
                              onClick: handleMenuClick,
                            }}
                          >
                            <img src={moreIcon} alt="moreIcon" />
                          </Dropdown>
                        </Space>
                      ))}
                      {otherError && (
                        <Form.Item className="mb-0">
                          <p className="text-red mb-0">{otherError}</p>
                        </Form.Item>
                      )}
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="float-right mr-4"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form>
            </>
          ) : (
            <p className="text-center">No Other materials are added</p>
          )}
        </div>
      </div>
    </Spin>
  );
}

export default GlobalMaterial;
