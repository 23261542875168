import { Button, Card, Form, Input, notification, Spin } from "antd";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
// import { useMutation } from "@apollo/client";
// import { ADMIN_LOGIN } from "./graphql";
import fireBaseApp from "../../firebase";
import {
  getAuth,
  signInWithEmailAndPassword,
  // onAuthStateChanged,
} from "firebase/auth";

function LoginPage(props) {
  const navigate = useNavigate();
  // const location = useLocation();
  const [enableLoader, setEnableLoader] = useState(false);
  // let mobile = /^[0-9]{10}$/;
  // let onlynumbers = /^[0-9]*$/;
  // let email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  // const [adminLogin] = useMutation(ADMIN_LOGIN);
  let token;
  useEffect(() => {
    if (
      localStorage.token &&
      jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
        "x-hasura-allowed-roles"
      ][0] === "admin"
    ) {
      navigate("/dashboard");
    }

    // eslint-disable-next-line
  }, []);

  const onFinish = (values) => {
    setEnableLoader(true);

    const auth = getAuth(fireBaseApp);
    signInWithEmailAndPassword(auth, values.username, values.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        setEnableLoader(false);
        localStorage.setItem("token", user.accessToken);
        navigate("/dashboard");
      })
      .catch((error) => {
        setEnableLoader(false);
        notification.error({
          message: "Invalid email or password",
        });
      });
  };

  return (
    <Spin spinning={enableLoader}>
      <div className="flex justify-center items-center h-screen">
        <Card
          style={{
            width: `${props.title === "signup" ? "448px" : "460px"} `,
          }}
          className="cardShadow"
          bordered={false}
        >
          <div className={` text-center `}>
            <img src={logo} alt="Kabadiwalla Connect" className="mx-auto " />
            <>
              <p className="text3xl mt-8 mb-0">Login in to your acccount</p>
            </>
          </div>
          <div className={`p-6 shadow-2 rounded-lg`}>
            <Form
              name="login"
              layout="vertical"
              requiredMark={false}
              onFinish={onFinish}
            >
              <Form.Item
                label={<span className="textsm">Email</span>}
                name="username"
                rules={[
                  { message: "Enter your email", required: true },
                  { type: "email", message: "Enter a valid email!" },
                ]}
              >
                <Input
                  size="large"
                  type="text"
                  placeholder="Enter your email"
                  style={{ borderRadius: "6px" }}
                />
              </Form.Item>
              <Form.Item
                label={<span className="textsm">Password</span>}
                name="password"
                rules={[{ message: "Enter your password", required: true }]}
              >
                <Input
                  size="large"
                  type="password"
                  placeholder="Enter your password"
                  style={{ borderRadius: "6px" }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="uppercase"
                  block
                  size="large"
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </div>
    </Spin>
  );
}

export default LoginPage;
