// import { useEffect } from "react";
import { Form, Button, Checkbox, Input, message, Spin } from "antd";
import deleteIcon from "../../../assets/deleteIcon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { GET_COLLECTION_MATERIALS, UPDATE_COLLECTION_CENTRE } from "./graphql";
import { useMutation, useQuery } from "@apollo/client";
import TitleHeadingContext from "../../../context/TitleHeadingContext";

function AdditionalInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const [options, setOptions] = useState([
    { label: "Clean Setup", value: "Clean Setup" },
    { label: "Clean Signage", value: "Clean Signage" },
    { label: "Parking available", value: "Parking available" },
  ]);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState();
  const [updateCollectionCentre] = useMutation(UPDATE_COLLECTION_CENTRE);
  const titleHeading = useContext(TitleHeadingContext);

  const {
    data: getCollectionMaterials,
    loading: getCollectionLoading,
    refetch,
  } = useQuery(
    GET_COLLECTION_MATERIALS,

    {
      variables: {
        id: parseInt(location.pathname.split("/")[4]),
      },
    }
  );

  useEffect(() => {
    if (
      getCollectionMaterials &&
      getCollectionMaterials?.collection_centre[0]?.details.additional_info
    ) {
      titleHeading.setTitle(getCollectionMaterials?.collection_centre[0]?.name);
      let newList = [...options];
      // eslint-disable-next-line
      getCollectionMaterials?.collection_centre[0]?.details.additional_info_list.map(
        // eslint-disable-next-line
        (info) => {
          if (info !== null) newList.push({ label: info, value: info });
        }
      );

      var newArray = newList.filter(
        (ele, ind) =>
          ind === newList.findIndex((elem) => elem.label === ele.label)
      );
      setOptions(newArray);
      form.setFieldsValue({
        checkbox:
          getCollectionMaterials?.collection_centre[0]?.details.additional_info,
      });
      form.setFieldsValue({
        info: undefined,
      });
    } else {
      setOptions([
        { label: "Clean Setup", value: "Clean Setup" },
        { label: "Clean Signage", value: "Clean Signage" },
        { label: "Parking available", value: "Parking available" },
      ]);
      // form.setFieldsValue({
      //   checkbox: ["Clean Setup", "Clean Signage", "Parking available"],
      // });
    }
    // eslint-disable-next-line
  }, [getCollectionMaterials]);

  const onFinish = (values) => {
    let infoList = [];
    let newList = [];
    infoList.push(values.checkbox);
    if (values.info) {
      // eslint-disable-next-line
      values.info.map((item) => {
        if (item) {
          infoList.push(item.addList);
          newList.push(item.addList);
        }
      });
    }
    let array = [newList.flat(2)];

    let listArray = getCollectionMaterials?.collection_centre[0]?.details
      .additional_info
      ? [
          ...getCollectionMaterials?.collection_centre[0]?.details
            .additional_info,
          array.flat(2),
        ]
      : [];
    let convertToLowercase = getCollectionMaterials?.collection_centre[0]
      ?.details.additional_info_list
      ? getCollectionMaterials?.collection_centre[0]?.details.additional_info_list.map(
          (item) => {
            return item !== null && item.toLowerCase();
          }
        )
      : [];
    const foundExistingElement = array
      .flat()
      .some((r) => convertToLowercase.includes(r.toLowerCase()));
    if (foundExistingElement) {
      setErrorMessage("Duplicates are not allowed");
    } else {
      setErrorMessage("");
      let payload = {
        details: {
          ...getCollectionMaterials?.collection_centre[0]?.details,
          additional_info: infoList.flat(),
          additional_info_list: getCollectionMaterials?.collection_centre[0]
            ?.details.additional_info
            ? listArray.flat()
            : array.flat(),
        },
      };
      updateCollectionCentre({
        variables: {
          id: parseInt(location.pathname.split("/")[4]),
          object: payload,
        },
      }).then((res) => {
        refetch();
        if (location.pathname.split("/")[2] === "addLocations") {
          message.open({
            type: "success",
            content: "Collection centre details saved successfully",
          });
          navigate("/locations");
        } else {
          message.open({
            type: "success",
            content: "Additional Info updated successfully",
          });
        }
      });
    }
  };

  return (
    <Spin spinning={getCollectionLoading}>
      <div className="bg-bgGray w-full min-h-screen py-4">
        <div className="w-5/12 p-4 bg-white rounded-md shadow-3xl m-auto">
          <p className="text-lg mb-0">Additional Info</p>
          <p className="text-sm text-gray500 font-normal">
            These information will be displayed publicly so please review
            carefully before you save
          </p>
          <Form
            name="additonalInfo"
            layout="vertical"
            onFinish={onFinish}
            form={form}
          >
            <Form.Item className="mb-0" name="checkbox">
              <Checkbox.Group options={options} />
            </Form.Item>
            <Form.List
              name="info"
              rules={[
                () => ({
                  validator(_, value) {
                    let newArray = [];
                    if (value) {
                      // eslint-disable-next-line
                      value.map((item) => {
                        if (item) newArray.push(item.addList);
                      });
                    }
                    if (
                      newArray &&
                      newArray.filter(
                        (item, index) => newArray.indexOf(item) === index
                      ).length !== newArray.length
                    ) {
                      return Promise.reject(
                        new Error("Duplicate values are not allowed.")
                      );
                    } else if (value === undefined) {
                      return Promise.resolve();
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field) => (
                    <Form.Item
                      {...field}
                      key={field.key}
                      align="baseline"
                      size={25}
                    >
                      <Form.Item noStyle name={[field.name, "addList"]}>
                        <Input
                          style={{ width: "80%" }}
                          className="mr-5"
                          onChange={() => setErrorMessage()}
                        />
                      </Form.Item>
                      <span
                        className=" cursor-pointer"
                        onClick={() => {
                          remove(field.name);
                          setErrorMessage();
                        }}
                      >
                        <img src={deleteIcon} alt="deleteIcon" />
                      </span>
                    </Form.Item>
                  ))}
                  <Form.ErrorList className="text-red" errors={errors} />
                  {errorMessage && (
                    <Form.Item className="mb-0">
                      <p className="text-red mb-0">{errorMessage}</p>
                    </Form.Item>
                  )}
                  <Form.Item className="h-10 mb-0 block">
                    <Button
                      type="link"
                      className="text-base text-green p-0"
                      onClick={() => add()}
                    >
                      Add New
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="float-right"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </div>
      </div>
    </Spin>
  );
}

export default AdditionalInfo;
