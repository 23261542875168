import {
  Drawer,
  Spin,
  Form,
  Input,
  InputNumber,
  Button,
  Upload,
  Modal,
  notification,
  message,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback, useContext } from "react";
import AddImage from "../../../assets/AddImage.svg";
import {
  GET_SIGNED_URL,
  INSERT_TRANSACTION,
  UPDATE_USER_REWARD,
} from "./graphql";
import { useMutation } from "@apollo/client";
import { getS3UploadUrl, uploadFiles } from "../../../utils";
import TitleHeadingContext from "../../../context/TitleHeadingContext";
import jwtDecode from "jwt-decode";
import moment from "moment";

function VendorPayment(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState();

  // const [formChange, setFormChange] = useState(false);
  const [fileURL, setFileURL] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [logoError, setLogoError] = useState(false);

  const [fileName, setFileName] = useState([]);
  const [getSignedUrl] = useMutation(GET_SIGNED_URL);
  const [insertTransaction] = useMutation(INSERT_TRANSACTION);
  const [updateUserReward] = useMutation(UPDATE_USER_REWARD);

  const titleHeading = useContext(TitleHeadingContext);

  useEffect(() => {
    if (props) {
      if (props.currentItem) {
        getCases(props.currentItem);
        form.setFieldsValue({
          amount: props.currentItem.amount,
          transactionID: props.currentItem.transactionID,
          comments: props.currentItem.comments
            ? props.currentItem.comments
            : "",
        });
      } else {
        form.setFieldsValue({ amount: props.outstandingAmount });
      }
    }
    // eslint-disable-next-line
  }, [props]);

  const getCases = async (props) => {
    setLoading(true);
    if (props?.transactionImage) {
      let fileListArray = [];
      let responseUrl = await getResponseUrl(props?.transactionImage);
      fileListArray.push({
        uid: moment().valueOf(),
        name: props?.transactionImage,
        status: "done",
        url: responseUrl,
      });
      setFileList(fileListArray);
    }
    setLoading(false);
  };

  const getResponseUrl = (file) => {
    return new Promise((resolve) => {
      getSignedUrl({
        variables: {
          fileName: file,
        },
      }).then((res) => {
        resolve(res?.data?.get_signed_url?.url);
      });
    });
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const uploadFile = async (data) => {
    setLoading(true);
    if (fileList.length < 5) {
      let signedURL = await getS3UploadUrl(
        data.file.name,
        data.file.type,
        "payment_image"
      );
      if (signedURL[1].statuscode === "Success") {
        let URLs = await uploadFiles([signedURL[0]], [data.file], signedURL[2]);

        if (URLs) {
          setLoading(false);
        }
        let fileArray = [...fileURL];
        let fileNameArray = [...fileName];
        fileNameArray.push(signedURL[2]);
        fileArray.push({ [data.file.uid]: URLs[0] });
        setFileName(fileNameArray);
        setFileURL(fileArray);
      } else {
        notification.error({
          message: "An error occurred",
        });
      }
    } else {
      message.error(
        "You can upload only 5 images. To continue uploading, please remove any image and try again."
      );
    }
  };

  const handleChange = useCallback((info) => {
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    if (info.file.status === "done") {
      let newFileList = [...info.fileList];
      setFileList(newFileList);
    }
  }, []);

  const handleRemove = (file) => {
    let oldArray = [...fileList];
    let oldFileArray = [...fileURL];
    let newFileArray = [];
    let newArray = oldArray.filter((item) => {
      return item.uid !== file.uid;
    });
    if (newArray.length === 0) {
      newFileArray = [];
    } else {
      newFileArray = oldFileArray.filter((item) => {
        return Object.keys(item)[0] !== file.uid;
      });
    }
    setFileURL(newFileArray);
    setFileList(newArray);
  };

  const onClose = () => {
    props.handleClose("close");
  };

  const onFinish = (values) => {
    if (fileName.length <= 0) {
      setLogoError(true);
    } else {
      setLogoError(false);
      let payload = {
        vendor_user_id: parseInt(
          jwtDecode(localStorage.getItem("token"))[
            "https://hasura.io/jwt/claims"
          ]["x-hasura-user-id"]
        ),
        vendor_id: parseInt(location.pathname.split("/")[4]),
        amount: parseFloat(values.amount.toFixed(2)),
        payment_details: {
          transactionID: values.transactionID,
          comments: values.comments,
          transactionImage: fileName[0],
        },
        status: "cleared",
      };
      insertTransaction({
        variables: {
          object: payload,
        },
      }).then((res) => {
        props.selectedRowKeys.map((item) => {
          updateUserReward({
            variables: {
              id: item,
              transactionID: res?.data?.insert_transactions?.returning[0]?.id,
            },
          }).then(() => props.handleClose());
        });
      });
    }
  };

  return (
    <Spin spinning={false}>
      <Drawer
        width={495}
        title="Pay now"
        placement="right"
        closable={true}
        onClose={onClose}
        maskClosable={false}
        open={props.openDrawer}
        footerStyle={{ border: 0 }}
        footer={
          !props.currentItem && (
            <p className="text-center opacity-50">
              You can view payment history in the Past Payments section
            </p>
          )
        }
      >
        <Form
          name="payment"
          layout="vertical"
          onFinish={onFinish}
          form={form}
          requiredMark={false}
        >
          <Form.Item
            label={<span className="text-gray700">Amount</span>}
            name="amount"
            rules={[
              { message: "Enter the amount", required: true },
              () => ({
                validator(_, value) {
                  let regex = /^\d+\.\d{0,2}$/;
                  if (value && !regex.test(value.toFixed(2))) {
                    return Promise.reject("Amount should contain two decimals");
                  } else if (value && parseFloat(value) === 0.0) {
                    return Promise.reject("Amount value cannot be 0");
                  } else if (value < 0) {
                    return Promise.reject(
                      "Amount value cannot be in negative values"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              disabled={props.currentItem ? true : false}
              controls={false}
              className="w-full"
              step="0.05"
              min={1}
            />
          </Form.Item>

          <Form.Item
            label={
              <span className="text-gray700">Transaction proof image</span>
            }
            name="proofphoto"
            className="mb-0"
          >
            <>
              <Upload
                name="coverPhoto"
                disabled={props.currentItem ? true : false}
                listType="picture-card"
                fileList={fileList}
                method="PUT"
                accept={".png,.jpg,.jpeg"}
                onPreview={handlePreview}
                customRequest={uploadFile}
                onChange={(info) => {
                  handleChange(info);
                }}
                onRemove={handleRemove}
                maxCount={1}
                beforeUpload={async (file) => {
                  const isLt2M = file.size / 1024 / 1024 < 10;
                  if (!isLt2M) {
                    message.error("Image must smaller than 10MB!");
                    return Upload.LIST_IGNORE;
                  }

                  return isLt2M;
                }}
              >
                <div>
                  <p className="mb-1">
                    <img src={AddImage} alt="addimage" />
                  </p>
                  <p className="mb-1">
                    <span className="text-green">Upload a file</span> or drag
                    and drop
                  </p>
                  <p className="text-gray500">PNG,JPG/JPEG up to 10MB</p>
                </div>
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </>
          </Form.Item>
          <Form.Item className="mb-0">
            {logoError && (
              <p className="text-red mb-0">Upload transaction image</p>
            )}
          </Form.Item>
          <Form.Item
            label={<span className="text-gray700">Transaction ID</span>}
            name="transactionID"
            rules={[
              () => ({
                validator(_, value) {
                  let alphanumeric = /^([ A-Za-z0-9]){1,}$/;
                  if (!value) {
                    return Promise.reject("Please enter a transaction ID");
                  } else if (value && value.match(/^ *$/) !== null) {
                    return Promise.reject("Please enter a transaction ID");
                  } else if (value && !alphanumeric.test(value)) {
                    return Promise.reject(
                      "Please enter a proper transaction ID"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="w-full"
              disabled={props.currentItem ? true : false}
            />
          </Form.Item>
          <Form.Item
            label={<span className="textsm">Comments (Optional)</span>}
            name="comments"
          >
            <TextArea
              rows={4}
              placeholder="Add comments here if required"
              disabled={props.currentItem ? true : false}
            />
          </Form.Item>
          {!props.currentItem && (
            <Form.Item>
              <Button type="primary" htmlType="submit" className="float-right">
                Complete payment
              </Button>
            </Form.Item>
          )}
        </Form>
      </Drawer>
    </Spin>
  );
}
export default VendorPayment;
