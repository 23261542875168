/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo, useEffect } from "react";
import { Button, message, Spin } from "antd";
import Select from "../../components/Select";
import CountCard from "../../components/CountCard";
import Table from "../../components/Table";
import { useLocation } from "react-router-dom";
import DatePicker from "../../components/DatePicker";
import UserDetailsDrawer from "./UserDetailsDrawer";
import {
  GET_USERS_LOCALITY_LIST,
  GET_USER_DETAILS,
  GET_APARTMENTS,
  INSERT_NOTIFICATION,
  INSERT_NOTIFICATION_USER,
  SEND_PUSH_NOTIFICATIONS,
} from "./graphql";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { capitalizeFirst, numFormatter } from "../../utils";
import BroadCastDrawer from "../../components/BroadcastDrawer";
import moment from "moment";

function Users(props) {
  const [getUserDetails, { data: userDetails, loading: userDetailsLoading }] =
    useLazyQuery(GET_USER_DETAILS, { fetchPolicy: "no-cache" });

  const [
    getApartments,
    { data: apartmentDetails, loading: apartmentDetailsLoading },
  ] = useLazyQuery(GET_APARTMENTS, { fetchPolicy: "no-cache" });

  const { data: locationList, loading: locationLoading } = useQuery(
    GET_USERS_LOCALITY_LIST
  );
  const [sendPushNotifications] = useMutation(SEND_PUSH_NOTIFICATIONS);
  const [insertNotificationUser] = useMutation(INSERT_NOTIFICATION_USER);
  const [insertNotification] = useMutation(INSERT_NOTIFICATION);
  const location = useLocation();
  const search = useLocation().search;
  const [data, setData] = useState([]);
  const [openBroadCastDrawer, setOpenBroadCastDrawer] = useState(false);
  const [apartmentList, setApartmentList] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchTerm, setSearchTerm] = useState({});
  const [apartmentSearchTerm, setApartmentSearchTerm] = useState({});
  const [searchLocName, setSearchLocName] = useState();
  const [category, setCategory] = useState();
  const [locality, setLocality] = useState([]);
  const [selectLocation, setselectLocation] = useState();
  const [apartmentValue, setApartmentValue] = useState([]);
  const [userStatus, setUserStatus] = useState();
  const [daterange, setDaterange] = useState();
  const [noOfUsers, setNoOfUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [inActiveUsers, setInActiveUsers] = useState(0);
  const [noOfCoupons, setNoOfCoupons] = useState(0);
  const [totSavings, setTotSavings] = useState(0);
  const [totCouponVal, setTotCouponVal] = useState(0);
  const [currentItem, setCurrentItem] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [userID, setUserID] = useState([]);

  useEffect(() => {
    if (locationList) {
      let newList = [];
      locationList.users.map((item) => {
        if (item.locality) newList.push(item.locality);
      });

      let list = newList.filter(
        (item, index) => newList.indexOf(item) === index
      );
      list.sort();
      setLocality(list);
    }
  }, [locationList]);

  useEffect(() => {
    getUserDetails({
      variables: {
        where: {
          _and: {
            locality: {},
            status: {},
            role_id: { _eq: 1 },
          },
        },
        startTime: moment().startOf("year"),
        endTime: moment().endOf("year"),
      },
    });
    getApartments({
      variables: {
        where: {},
      },
    });
  }, []);

  useEffect(() => {
    if (Object.keys(apartmentSearchTerm).length > 0)
      getApartments({ variables: { where: apartmentSearchTerm } });
  }, [apartmentSearchTerm]);

  useEffect(() => {
    if (searchTerm && Object.keys(searchTerm).length !== 0) {
      getUserDetails({
        variables: {
          where: searchTerm,
          startTime: moment().startOf("year"),
          endTime: moment().endOf("year"),
        },
      });
    }
  }, [searchTerm]);

  useEffect(() => {
    setSearchTerm(
      new URLSearchParams(search).get("searchText") &&
        new URLSearchParams(search).get("category") === "Name"
        ? {
            locality:
              selectLocation && selectLocation.length > 0
                ? { _in: selectLocation }
                : {},
            status: userStatus
              ? userStatus === "Inactive"
                ? { _eq: "Active" }
                : { _eq: userStatus }
              : {},
            role_id: { _eq: 1 },
            name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "Locality"
        ? {
            locality:
              selectLocation && searchLocName && selectLocation.length > 0
                ? { _ilike: `%${searchLocName}%`, _in: selectLocation }
                : selectLocation && selectLocation.length > 0
                ? { _in: selectLocation }
                : searchLocName
                ? { _ilike: `%${searchLocName}%` }
                : {},
            status: userStatus
              ? userStatus === "Inactive"
                ? { _eq: "Active" }
                : { _eq: userStatus }
              : {},
            role_id: { _eq: 1 },
            name: {},
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "Apartment"
        ? {
            locality:
              selectLocation && selectLocation.length > 0
                ? { _in: selectLocation }
                : {},
            status: userStatus
              ? userStatus === "Inactive"
                ? { _eq: "Active" }
                : { _eq: userStatus }
              : {},
            role_id: { _eq: 1 },
            apartment: { name: { _ilike: `%${searchLocName}%` } },
            name: {},
          }
        : new URLSearchParams(search).get("searchText") !== null &&
          new URLSearchParams(search).get("category") === "null"
        ? {
            _and: [
              { role_id: { _eq: 1 } },
              {
                status: userStatus
                  ? userStatus === "Inactive"
                    ? { _eq: "Active" }
                    : { _eq: userStatus }
                  : {},
              },
              {
                _or: [
                  {
                    locality:
                      selectLocation &&
                      searchLocName &&
                      selectLocation.length > 0
                        ? { _ilike: `%${searchLocName}%`, _in: selectLocation }
                        : selectLocation && selectLocation.length > 0
                        ? { _in: selectLocation }
                        : searchLocName
                        ? { _ilike: `%${searchLocName}%` }
                        : {},
                  },
                  {
                    name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
                  },
                  { apartment: { name: { _ilike: `%${searchLocName}%` } } },
                ],
              },
            ],
          }
        : {
            locality:
              selectLocation && selectLocation.length > 0
                ? { _in: selectLocation }
                : {},
            status: userStatus
              ? userStatus === "Inactive"
                ? { _eq: "Active" }
                : { _eq: userStatus }
              : {},
            role_id: { _eq: 1 },
          }
    );
  }, [selectLocation, userStatus, searchLocName, category]);

  useEffect(() => {
    setSearchLocName(new URLSearchParams(search).get("searchText"));
    setCategory(new URLSearchParams(search).get("category"));
  }, [location]);

  useEffect(() => {
    setApartmentSearchTerm({
      locality:
        selectLocation && selectLocation.length > 0
          ? { _in: selectLocation }
          : {},
    });
  }, [selectLocation]);

  useEffect(() => {
    if (apartmentDetails) {
      let newList = ["Individual"];
      apartmentDetails?.apartments?.map((item) => {
        newList.push(item.name);
      });
      setApartmentList(newList);
    }
  }, [apartmentDetails]);

  useEffect(() => {
    if (userDetails) {
      let newList = [];
      let locationList = [];
      let userIDList = [];
      userDetails.users.map((item) => {
        let salesVolume = 0;
        let salesPrice = 0;
        let savings = 0;
        let dateCheck = true;
        let couponVal = 0;
        let rewardPoints = 0;
        let redeemedPoints = 0;
        if (item.sales.length > 0) {
          item.sales.map((ele) => {
            if (ele.status === "Approved") {
              salesVolume +=
                ele.sales_materials_aggregate.aggregate.sum.actual_quantity;
              salesPrice += ele.total_price;
              rewardPoints += parseInt(ele.points);
            }
          });
          dateCheck = moment(item.sales[0].created_at).isBetween(
            moment().subtract(4, "week"),
            moment()
          );
        }
        if (item.user_rewards.length > 0) {
          item.user_rewards.map((ele) => {
            // if (ele.details && ele.reward) {
            couponVal += parseInt(ele.reward.details.couponValue);

            ele.redeemed_points
              ? (redeemedPoints += parseInt(ele.redeemed_points))
              : (redeemedPoints += 0);
            savings +=
              (parseInt(ele.reward.details.couponValue) -
                parseInt(ele.reward.details.couponsCost)) *
              ele.reward.total_used_number;
            // }
          });
        }
        userIDList.push(item.id);
        newList.push({
          id: item.id,
          username: item.name ? capitalizeFirst(item.name) : "",
          locality: item.locality ? item.locality : "-",
          details: item.details,
          email: item.email,
          status:
            item.status === "Deactivated"
              ? item.status
              : dateCheck === false
              ? "Inactive"
              : "Active",
          phone: item.phone,
          apartment: item.apartment ? item.apartment.name : "Individual",
          noOfTransaction: item.sales_aggregate.aggregate.count,
          volume: salesVolume,
          value: salesPrice,
          avgValue:
            salesVolume === 0
              ? 0
              : (salesVolume / item.sales_aggregate.aggregate.count).toFixed(2),
          noOfTicketsRaised: "-",
          noOfTransactionsMonth: Math.ceil(
            item.transactionsCount.aggregate.count / 12
          ),
          noOfCouponsPurchased: item.user_rewards_aggregate.aggregate.count,
          valueOfCouponsPurchased: couponVal,
          totalSavings: savings,
          noOfrewardPoints: rewardPoints,
          noOfredeemedPoints: redeemedPoints,
          lastSaleOn:
            item.sales.length > 0
              ? moment(item.sales[0].created_at).format("DD MMM YYYY")
              : "-",
          created_at: item.created_at,
          updated_at: item.updated_at,
        });
        locationList.push(item.locality);
      });
      setUserID(userIDList);

      if (
        daterange &&
        daterange[0] &&
        daterange[1] &&
        apartmentValue.length > 0 &&
        userStatus
      ) {
        let DisplayData = newList?.filter((ele) => {
          return (
            moment(ele.lastSaleOn).isBetween(
              daterange[0],
              daterange[1],
              "days",
              "[]"
            ) &&
            ele.status === userStatus &&
            apartmentValue.includes(ele.apartment)
          );
        });
        setData(DisplayData);
      } else if (userStatus && apartmentValue.length > 0) {
        let DisplayData = newList?.filter((ele) => {
          return (
            ele.status === userStatus && apartmentValue.includes(ele.apartment)
          );
        });
        setData(DisplayData);
      } else if (
        daterange &&
        daterange[0] &&
        daterange[1] &&
        apartmentValue.length > 0
      ) {
        let DisplayData = newList?.filter((ele) => {
          return (
            moment(ele.lastSaleOn).isBetween(
              daterange[0],
              daterange[1],
              "days",
              "[]"
            ) && apartmentValue.includes(ele.apartment)
          );
        });
        setData(DisplayData);
      } else if (daterange && daterange[0] && daterange[1] && userStatus) {
        let DisplayData = newList?.filter((ele) => {
          return (
            moment(ele.lastSaleOn).isBetween(
              daterange[0],
              daterange[1],
              "days",
              "[]"
            ) && ele.status === userStatus
          );
        });
        setData(DisplayData);
      } else if (userStatus) {
        let DisplayData = newList?.filter((ele) => {
          return ele.status === userStatus;
        });
        setData(DisplayData);
      } else if (daterange && daterange[0] && daterange[1]) {
        let DisplayData = newList?.filter((ele) => {
          return moment(ele.lastSaleOn).isBetween(
            daterange[0],
            daterange[1],
            "days",
            "[]"
          );
        });
        setData(DisplayData);
      } else if (apartmentValue.length > 0) {
        let DisplayData = newList?.filter((ele) => {
          return apartmentValue.includes(ele.apartment);
        });
        setData(DisplayData);
      } else {
        setData(newList);
      }
    }
  }, [userDetails, apartmentValue, daterange]);

  useEffect(() => {
    let active = 0;
    let totCoupon = 0;
    let inactive = 0;
    let totCouponVal = 0;
    let savingsVal = 0;
    // if (data.length > 0) {
    data.map((item) => {
      if (item.status === "Active") {
        active += 1;
      } else if (item.status === "Inactive") {
        inactive += 1;
      }
      totCoupon += item.noOfCouponsPurchased;

      totCouponVal +=
        item.noOfCouponsPurchased > 0 ? item.valueOfCouponsPurchased : 0;
      savingsVal += item.noOfCouponsPurchased > 0 ? item.totalSavings : 0;
    });
    data.sort((a, b) => {
      return b.transaction - a.transaction;
    });
    setTableData(data);
    setNoOfCoupons(totCoupon);
    setTotCouponVal(totCouponVal);
    setTotSavings(savingsVal);
    setActiveUsers(active);
    setInActiveUsers(inactive);
    // }
    setNoOfUsers(data.length);
  }, [data]);

  const handleselectedValues = (value, type) => {
    if (type === "Locality") {
      setselectLocation(value);
    }
    if (type === "Apartment") {
      setApartmentValue(value);
      setselectLocation(selectLocation);
    }
    if (type === "Status") {
      setUserStatus(value);
    }
  };

  const handleDate = (values) => {
    setDaterange(values);
  };

  const columns = useMemo(
    () => [
      {
        title: "USER ID",
        dataIndex: "id",
        key: "id",
        className: "w-40",
        render: (text) =>
          text !== "-" ? (
            <span>{`USID${`${text}`.padStart(6, "0")}`}</span>
          ) : (
            <span>-</span>
          ),
      },
      {
        title: "NAME",
        dataIndex: "username",
        key: "username",
        className: "w-40",
      },
      {
        title: "LOCALITY",
        dataIndex: "locality",
        key: "locality",
        className: "w-40",
      },
      {
        title: "APARTMENT",
        dataIndex: "apartment",
        key: "apartment",
        className: "w-40",
      },

      {
        title: "TRANSACTIONS",
        dataIndex: "noOfTransaction",
        key: "noOfTransaction",
        className: "w-40",
        sorter: (a, b) => a.noOfTransaction - b.noOfTransaction,
        render: (text) =>
          text ? (
            <span className="float-right">{numFormatter(text)}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "VOLUME(KG)",
        dataIndex: "volume",
        key: "volume",
        className: "w-40",
        sorter: (a, b) => a.volume - b.volume,
        render: (text) =>
          text ? (
            <span className="float-right">{numFormatter(text)} kg</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "VALUE(RS.)",
        dataIndex: "value",
        key: "value",
        className: "w-40",
        sorter: (a, b) => a.value - b.value,
        render: (text) =>
          text ? (
            <span className="float-right">{numFormatter(text)}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "AVG VOLUME / TRANSACTION (KG)",
        dataIndex: "avgValue",
        key: "avgValue",
        className: "w-52",
        sorter: (a, b) => a.avgValue - b.avgValue,
        render: (text) =>
          text ? (
            <span className="float-right">{numFormatter(text)}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "TRANSACTIONS / MONTH",
        dataIndex: "noOfTransactionsMonth",
        key: "noOfTransactionsMonth",
        className: "w-40",
        sorter: (a, b) => a.noOfTransactionsMonth - b.noOfTransactionsMonth,
        render: (text) =>
          text ? (
            <span className="float-right">{numFormatter(text)}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      // {
      //   title: "TICKETS",
      //   dataIndex: "noOfTicketsRaised",
      //   key: "noOfTicketsRaised",
      //   className: "w-40",
      // },
      {
        title: "COUPONS PURCHASED",
        dataIndex: "noOfCouponsPurchased",
        key: "noOfCouponsPurchased",
        className: "w-40",
        sorter: (a, b) => a.noOfCouponsPurchased - b.noOfCouponsPurchased,
        render: (text) =>
          text ? (
            <span className="float-right">{numFormatter(text)}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "COUPONS VALUE(RS.)",
        dataIndex: "valueOfCouponsPurchased",
        key: "valueOfCouponsPurchased",
        className: "w-40",
        sorter: (a, b) => a.valueOfCouponsPurchased - b.valueOfCouponsPurchased,
        render: (text) =>
          text ? (
            <span className="float-right">{numFormatter(text)}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "SAVINGS(RS.)",
        dataIndex: "totalSavings",
        key: "totalSavings",
        className: "w-40",
        sorter: (a, b) => a.totalSavings - b.totalSavings,
        render: (text) =>
          text ? (
            <span className="float-right">{numFormatter(text)}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "LAST SALE ON",
        dataIndex: "lastSaleOn",
        key: "lastSaleOn",
        className: "w-40",
        sorter: (a, b) =>
          moment(a.lastSaleOn).unix() - moment(b.lastSaleOn).unix(),
        render: (text) =>
          text ? (
            <span className="float-right">{text}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "MANAGE",
        dataIndex: "",
        key: "",
        fixed: "right",
        className: "w-32",
        render: (text, record) => (
          <Button
            type="link"
            className="text-green hover:text-green"
            onClick={() => {
              setOpenDrawer(true);
              setCurrentItem(record);
            }}
            id="details"
          >
            Details
          </Button>
        ),
      },
    ],
    []
  );
  const broadCastonFinish = (values) => {
    insertNotification({
      variables: {
        object: {
          text: values.message,
          is_broadcast: true,
        },
      },
    }).then((res) => {
      let payload = [];
      userID.map((item) => {
        payload.push({
          notification_id: res?.data?.insert_notifications?.returning[0]?.id,
          user_id: item,
        });
      });
      insertNotificationUser({
        variables: {
          object: payload,
        },
      }).then(() => {
        let pushObj = {
          id: moment().valueOf(),
          title: "You have a new message from Kabadiwalla Connect",
          body: values.message,
          type: "notification",
        };
        sendPushNotifications({
          variables: {
            message: pushObj,
            title: "You have a new message from Kabadiwalla Connect",
            user_ids: userID,
          },
        }).then(() => {
          message.open({
            type: "success",
            content: "Message broadcasted successfully",
          });
          window.location.reload();
        });
      });
    });
  };

  return (
    <Spin
      spinning={
        locationLoading || userDetailsLoading || apartmentDetailsLoading
      }
    >
      <div className="flex flex-col gap-5 p-8 w-full justify-between ">
        <div className="flex flex-row gap-5 w-full ">
          <div style={{ width: "13%" }}>
            <Select
              placeholder="City"
              type="City"
              data={["Chennai"]}
              onChange={handleselectedValues}
              value="Chennai"
              // width="127px"
              allowClear={true}
            />
          </div>
          <div style={{ width: "20%" }}>
            <Select
              placeholder="Locality"
              type="Locality"
              mode="multiple"
              data={locality || []}
              onChange={handleselectedValues}
              value=""
              allowClear={true}
            />
          </div>
          <div style={{ width: "17%" }}>
            <Select
              placeholder="Apartment"
              type="Apartment"
              data={apartmentList || []}
              onChange={handleselectedValues}
              value=""
              mode="multiple"
              allowClear={true}
            />
          </div>
          <div style={{ width: "13%" }}>
            <Select
              placeholder="Status"
              type="Status"
              data={["Active", "Inactive", "Deactivated"]}
              onChange={handleselectedValues}
              value=""
              allowClear={true}
            />
          </div>
          <div style={{ width: "25%" }}>
            <DatePicker
              placeholder="Last sale on"
              separator="~"
              handleDate={handleDate}
            />
          </div>
          <div style={{ width: "16%" }}>
            <Button
              type="primary"
              id="broadcast"
              size="large"
              onClick={() => setOpenBroadCastDrawer(true)}
            >
              Broadcast message
            </Button>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-6 ">
            {(!userStatus || userStatus === "Deactivated") && (
              <CountCard
                count={noOfUsers}
                title={
                  userStatus === "Deactivated"
                    ? "Number of deactivated users"
                    : "Total number of users"
                }
                tab="users"
                className="CountCard"
              />
            )}
            {userStatus !== "Inactive" && userStatus !== "Deactivated" && (
              <CountCard
                count={activeUsers}
                title="Number of active users"
                tab="users"
                className="CountCard"
              />
            )}
            {userStatus !== "Active" && userStatus !== "Deactivated" && (
              <CountCard
                count={inActiveUsers}
                title="Number of inactive users"
                tab="users"
                className="CountCard"
              />
            )}
            <CountCard
              count={noOfCoupons}
              title="Number of coupons purchased"
              tab="users"
              className="CountCard"
            />
            <CountCard
              count={totCouponVal}
              title="Total value of coupons purchased"
              tab="users"
              className="CountCard"
              symbol="Rs. "
            />

            <CountCard
              count={totSavings}
              title="Total savings generated for user"
              tab="users"
              className="CountCard"
              symbol="Rs. "
            />
          </div>
        </div>
        <div className="w-full">
          <Table
            dataSource={!!tableData.length ? tableData : []}
            columns={columns}
            key="usersListing"
            pagination={true}
            scroll={{
              x: 1300,
            }}
          />
        </div>
        {openDrawer && (
          <UserDetailsDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            currentItem={currentItem}
          />
        )}
        {openBroadCastDrawer && (
          <BroadCastDrawer
            openDrawer={openBroadCastDrawer}
            setOpenDrawer={setOpenBroadCastDrawer}
            broadCastonFinish={broadCastonFinish}
          />
        )}
      </div>
    </Spin>
  );
}

export default Users;
