import { Badge, Select as ReactSelect, Typography } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import SelectSuffix from "../../assets/select.svg";

const { Option } = ReactSelect;
const { Paragraph } = Typography;

const SelectStyle = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #27ae60;
    cursor: pointer;
  }
  .ant-select-selection-overflow {
    width: 90%;
  }
  .ant-select-selection-overflow-item {
    max-width: 80%;
    position: relative;
    &:nth-child(1) {
      width: 100%;
    }
  }
  .ant-select-selection-overflow-item {
    font-size: 14px;
  }
  .ant-select-selection-placeholder {
    font-size: 14px;
    line-height: 20px;
    color: #27ae60;
    font-weight: 600;
  }
`;

export default function Select({
  onChange,
  data,
  value,
  type,
  width,
  symbol,
  ...rest
}) {
  const [selectValue, setSelectValue] = React.useState([]);
  useEffect(() => {
    setSelectValue(value || []);
  }, [value]);

  return (
    <SelectStyle>
      <ReactSelect
        // defaultValue="lucy"
        onChange={(value) => {
          setSelectValue(value);
          if (onChange) {
            onChange(value, type);
          }
        }}
        size={"large"}
        style={{
          width: "100%",
        }}
        value={selectValue}
        showArrow
        mode={type === "locality" ? "multiple" : ""}
        className={` border-green cursor-pointer`}
        suffixIcon={<img src={SelectSuffix} alt="select suffix" />}
        popupClassName=" customSelect border-green border border-solid bg-white shadow-1 mt-4"
        tagRender={(props) => {
          if (props.value === selectValue[0]) {
            return (
              <div className="pl-4 cursor-pointer">
                <Paragraph
                  ellipsis={true}
                  className={`!mb-0 ${props.value.length > 15 ? "w-3/4" : ""}`}
                >
                  {props.value}
                </Paragraph>
                {selectValue.length >= 2 && (
                  <div className="absolute right-0 top-0 bottom-0 flex items-center justify-center ">
                    <Badge
                      count={selectValue.length}
                      style={{
                        background: "#27AE60",
                        color: "#FFFFFF",
                      }}
                    ></Badge>
                  </div>
                )}
              </div>
            );
          }
        }}
        {...rest}
      >
        {data.map((item, index) => {
          return (
            <Option value={item} key={`${item}-type-index`} className=" py-2">
              {item} {symbol ? symbol : ""}
            </Option>
          );
        })}
      </ReactSelect>
    </SelectStyle>
  );
}
