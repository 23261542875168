import {
  Drawer,
  Divider,
  Button,
  Spin,
  Avatar,
  Tabs,
  Select,
  Form,
  Input,
  message,
  Image,
} from "antd";
import Slider from "react-slick";
import closeIcon from "../../assets/closeIcon.svg";
import UserOutlined from "../../assets/UserOutlined.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_SIGNED_URL,
  GET_USER_SALE_DETAIL,
  UPDATE_USER_SALE_STATUS,
  UPDATE_SALE_MATERIALS_STATUS,
  UPDATE_SALE_MATERIAL_VALUE,
  UPDATE_SALE_LOGS,
  SEND_PUSH_NOTIFICATIONS,
} from "./graphql";
import moment from "moment";
import { numFormatter } from "../../utils";
import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import Table from "../../components/Table";
import UpdateHistory from "./UpdateHistory";

const { TabPane } = Tabs;
const { Option } = Select;

function SalesDetailsDrawer(props) {
  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState();
  const [changed, setChanged] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [changeRow, setChangeRow] = useState();
  const [userId, setUserId] = useState([]);
  const [getSignedUrl] = useMutation(GET_SIGNED_URL);
  const [comment, setComment] = useState("");
  const [form] = Form.useForm();
  const {
    data: userSaleDetails,
    loading: userSaleLoading,
    refetch,
  } = useQuery(GET_USER_SALE_DETAIL, {
    variables: {
      id: props.currentItem.transactionID,
    },
  });
  const [updateSaleMaterialValue] = useMutation(UPDATE_SALE_MATERIAL_VALUE);
  const [updateStatus] = useMutation(UPDATE_USER_SALE_STATUS);
  const [updateSaleMaterialStatus] = useMutation(UPDATE_SALE_MATERIALS_STATUS);
  const [updateSaleLog] = useMutation(UPDATE_SALE_LOGS);
  const [sendPushNotifications] = useMutation(SEND_PUSH_NOTIFICATIONS);
  const [avatar, setAvatar] = useState();
  // const [invoice, setInvoice] = useState([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (props) {
      getCases(props, "profile");
      setStatus(props.currentItem.status);
    } else {
      setStatus("In review");
    }
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    if (userSaleDetails) {
      let newList = [];
      let userArray = [];
      let sum = 0;
      // displayInvoice(userSaleDetails.sales[0].receipt, "invoice");

      userArray.push(userSaleDetails.sales[0].user_id);

      userSaleDetails.sales[0].sales_materials.map((item) => {
        newList.push({
          id: item.id,
          key: item.id,
          materialName: item.material.name,
          volume: item.actual_quantity,
          value: item.price,
        });
        if (item.price) {
          sum += item.price;
        }
      });
      form.setFieldsValue({
        comments: userSaleDetails.sales[0].comment,
        points:
          userSaleDetails.sales[0].points !== 0
            ? userSaleDetails.sales[0].points
            : null,
      });
      setUserId(userArray);
      setTableData(newList);
      setTotalAmount(sum.toFixed(2));
    }
  }, [userSaleDetails]);

  useEffect(() => {
    if (changeRow) {
      let payload = {
        actual_quantity: changeRow.volume,
        price: changeRow.value,
      };
      updateSaleMaterialValue({
        variables: {
          id: changeRow.id,
          object: payload,
        },
      }).then((res) => {
        refetch();
        let transactionSum = 0;
        let logArray =
          res?.data?.update_sales_materials?.returning[0]?.sale?.sales_logs;
        let newLog = {
          title: "Material details updated",
          comment: null,
          timestamp: moment(),
        };
        logArray.logs.push(newLog);
        tableData.map((ele) => {
          transactionSum += parseInt(ele.value);
        });
        let obj = { sales_logs: logArray, total_price: transactionSum };
        updateSaleLog({
          variables: {
            id: res?.data?.update_sales_materials?.returning[0]?.sales_id,
            object: obj,
          },
        });
        message.open({
          type: "success",
          content: "Materials details updated successfully",
        });
      });
    }
  }, [changeRow]);

  const getCases = async (props, value) => {
    setLoading(true);
    let responseUrl = await getResponseUrl(props.currentItem.profilePic);
    setAvatar(responseUrl);
    setLoading(false);
  };

  // const displayInvoice = (file) => {
  //   let url = [];
  //   setLoading(true);
  //   file.forEach(async (ele, index) => {
  //     console.log(ele);
  //     let responseUrl = await getResponseUrl(ele);
  //     url[index] = responseUrl;
  //   });

  //   console.log(url, "file");
  //   setLoading(false);
  // };

  const getResponseUrl = (file) => {
    return new Promise((resolve) => {
      getSignedUrl({
        variables: {
          fileName: file,
        },
      }).then((res) => {
        resolve(res?.data?.get_signed_url?.url);
      });
    });
  };

  const onFinish = (values) => {
    let payload = {
      status: status,
      comment: values.comments ? values.comments : null,
      points: values.points ? values.points : 0,
    };
    updateStatus({
      variables: {
        id: props.currentItem.transactionID,
        object: payload,
      },
    }).then((res) => {
      res?.data?.update_sales?.returning[0]?.sales_materials.map((sale) => {
        updateSaleMaterialStatus({
          variables: {
            id: sale.id,
            status: status,
          },
        });
      });
      let logArray = res?.data?.update_sales?.returning[0]?.sales_logs;

      let newLog = {
        title:
          status === "Rejected"
            ? "Sale rejected"
            : status === "Approved"
            ? "Sale Approved"
            : status === "Update info"
            ? "Sale Update info"
            : status === "In review"
            ? "Sale Upload In review"
            : status,
        comment: values.comments,
        points: values.points,
        timestamp: moment(),
      };
      logArray.logs.push(newLog);

      let obj = { sales_logs: logArray };
      updateSaleLog({
        variables: {
          id: res?.data?.update_sales?.returning[0]?.id,
          object: obj,
        },
      }).then(() => {
        let pushObj = {
          id: moment().valueOf(),
          title:
            status === "Approved"
              ? "Your sale has been approved!"
              : status === "Rejected"
              ? "Your sale has been rejected!"
              : "Action Required for Your Sale!",
          user_id: userId[0],
          body:
            status === "Approved"
              ? `Congratulations! Your sale made on ${moment().format(
                  "MMMM DD, YYYY"
                )} has been approved and is now ready for you to claim your points. Head to your Orders to redeem them now!`
              : status === "Rejected"
              ? `Sorry! Your sale made on ${moment().format(
                  "MMMM DD, YYYY"
                )} has been rejected. Check your Orders for more details on the reason behind the rejection.`
              : `Your sale made on ${moment().format(
                  "MMMM DD, YYYY"
                )} needs to be updated for further review. Please visit your Orders for additional details and instructions.`,
          type: "order",
        };
        sendPushNotifications({
          variables: {
            message: pushObj,
            title:
              status === "Approved"
                ? "Your sale has been approved!"
                : "Your sale has been rejected!",
            user_ids: userId,
          },
        }).then(() => {
          message.open({
            type: "success",
            content: "Status updated successfully",
          });
          window.location.reload();
        });
      });
    });
  };

  const onClose = () => {
    props.onClose();
  };

  const defaultColumns = useMemo(
    () => [
      {
        title: "MATERIAL NAME",
        dataIndex: "materialName",
        key: "materialName",
      },
      {
        title: "VOLUME(KG)",
        dataIndex: "volume",
        key: "volume",
        editable: props.currentItem.status === "In review" ? true : false,
        render: (text) =>
          text ? (
            <span className="float-right">{numFormatter(text)} kg</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "TOTAL PRICE",
        dataIndex: "value",
        key: "value",
        editable: props.currentItem.status === "In review" ? true : false,
        render: (text) =>
          text ? (
            <span className="float-right">Rs.{numFormatter(text)}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
    ],
    []
  );

  const EditableContext = React.createContext(null);

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };
    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
            () => ({
              validator(_, value) {
                if (value && parseFloat(value) === 0.0) {
                  return Promise.reject(`${title} value cannot be 0.`);
                } else if (String(value).split(".")[1]?.length > 2) {
                  return Promise.reject(
                    `${title} value should not contain more than two decimals`
                  );
                } else if (value < 0) {
                  return Promise.reject(
                    `${title} value cannot be  negative values`
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };
  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = (row) => {
    const newData = [...tableData];
    const index = newData.findIndex((item) => row.key === item.key);
    if (
      newData[index].volume !== row.volume ||
      newData[index].value !== row.value
    ) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setChangeRow(row);
      setTableData(newData);
    }
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };
  return (
    <Spin spinning={loading || userSaleLoading}>
      <Drawer
        width={400}
        placement="right"
        closable={false}
        onClose={onClose}
        // maskClosable={false}
        open={props.openDrawer}
        rootStyle={{}}
      >
        <Spin spinning={false}>
          <div className="mb-4">
            <div className="grid grid-cols-3 gap-5">
              <span onClick={onClose} className="cursor-pointer">
                <img src={closeIcon} alt="closeIcon" />
              </span>
              <div className="flex flex-col">
                <p>
                  {avatar ? (
                    <Avatar
                      src={avatar}
                      size="large"
                      style={{ width: "110px", height: "110px" }}
                    />
                  ) : (
                    <Avatar
                      className=" bg-indigo"
                      style={{ width: "110px", height: "110px" }}
                      size="large"
                    >
                      <img src={UserOutlined} alt="useroutlined" />
                    </Avatar>
                  )}
                </p>
              </div>
            </div>
            <div>
              <p className="text-base text-green text-center mb-0 font-semibold">
                {props.currentItem.username}
              </p>
              <p className="text-sm text-green text-center mb-0 font-bold">
                {props.currentItem.userLocality}
              </p>
              <p className="text-sm text-gray text-center font-semibold">
                Sale on :{" "}
                {moment(props.currentItem.created_at).format("DD MMM YYYY")}{" "}
                <span className="bg-gray w-1.5 h-1.5  align-middle inline-block rounded-md"></span>{" "}
                {moment(props.currentItem.created_at).format("hh:mma")}
              </p>
            </div>
          </div>
          <div>
            <p>
              <span className="text-base font-semibold align-middle opacity-50">
                Sales details
              </span>
              <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

              <span className="align-middle font-normal text-base opacity-50">
                #SAL0000{props.currentItem.transactionID}
              </span>
            </p>
            <p>
              <span className="text-sm align-middle">
                {props.currentItem.location}
              </span>
              <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

              <span className="align-middle font-normal text-sm">
                {props.currentItem.locationCentre}
              </span>
            </p>
            <p>
              <span className="text-sm align-middle">Transaction value</span>
              <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

              <span className="align-middle font-normal text-sm">
                INR {totalAmount}
              </span>
            </p>
          </div>
          <Divider />
          <Tabs>
            <TabPane tab="Details" key="1">
              <>
                <div className="salesDrawer mb-4">
                  <Table
                    rowClassName={() => "editable-row"}
                    components={components}
                    dataSource={!!tableData.length ? tableData : []}
                    columns={columns}
                    key="salesListingDrawer"
                    pagination={false}
                    style={{ fontSize: "14px" }}
                  />
                </div>
                <p>
                  <span className="text-base font-bold text-charcoal">
                    Sales status
                  </span>
                  <span className="float-right text-charcoal w-2/4">
                    <Select
                      className="w-full"
                      value={status}
                      allowClear="true"
                      onChange={(value) => {
                        if (value) {
                          setStatus(value);
                          setChanged(value !== props.currentItem.status);
                          setComment("");
                        } else {
                          setStatus(props.currentItem.status);
                          setChanged(false);
                        }
                        if (value !== props.currentItem.status) {
                          form.setFieldsValue({
                            comments: "",
                          });
                        }
                      }}
                      disabled={
                        props.currentItem.status === "Approved" ||
                        props.currentItem.status === "Rejected"
                      }
                    >
                      <Option key="In review" value="In review">
                        In review
                      </Option>
                      <Option
                        key="Approved"
                        value="Approved"
                        disabled={props.currentItem.status === "Approved"}
                      >
                        <span className="text-green">Approved</span>
                      </Option>
                      <Option
                        key="Update info"
                        value="Update info"
                        disabled={props.currentItem.status === "Update info"}
                      >
                        <span className="text-[#1E40AF]">Update info</span>
                      </Option>
                      <Option
                        key="Rejected"
                        value="Rejected"
                        disabled={props.currentItem.status === "Rejected"}
                      >
                        <span className="text-red">Rejected</span>
                      </Option>
                    </Select>
                  </span>
                </p>
                {props.currentItem.status !== "Approved" && (
                  <Form
                    name="updateStatus"
                    onFinish={onFinish}
                    layout="vertical"
                    className="mt-6"
                    form={form}
                    requiredMark={false}
                  >
                    {status === "Approved" && (
                      <Form.Item
                        label="Add points"
                        name="points"
                        rules={[
                          () => ({
                            validator(_, value) {
                              let alphanumeric = /^[0-9]*$/;
                              if (value && !alphanumeric.test(value)) {
                                return Promise.reject(
                                  "Please enter proper value"
                                );
                              } else if (!value) {
                                return Promise.reject(
                                  "Please enter a points required"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    )}

                    {(status === "Rejected" ||
                      status === "Approved" ||
                      status === "Update info") && (
                      <Form.Item
                        label={
                          status === "Rejected"
                            ? "Add reason for rejection"
                            : status === "Approved"
                            ? "Share an optional message with user"
                            : status === "Update info"
                            ? "Share a message on what information needs update"
                            : ""
                        }
                        name="comments"
                        initialValue={props.currentItem.comments}
                        rules={[
                          {
                            required:
                              status === "Rejected" || status === "Update info",
                            message: `The message cannot be empty
                            ${
                              status === "Rejected"
                                ? ""
                                : status === "Approved"
                                ? ""
                                : status === "Update info"
                                ? ""
                                : ""
                            }`,
                          },
                        ]}
                      >
                        <Input.TextArea
                          disabled={status === props.currentItem.status}
                        />
                      </Form.Item>
                    )}
                    <Form.Item className="mb-2">
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="w-full"
                        disabled={!changed}
                      >
                        {status === "Approved" || status === "In review"
                          ? "Update Status"
                          : "Share comment with user"}
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="link"
                        className="w-full text-gray500"
                        onClick={() => onClose()}
                        disabled={!changed}
                      >
                        Cancel update
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </>
            </TabPane>
            <TabPane tab="Invoice Image" key="2">
              <div
              // style={{ width: "340px", height: "450px" }}
              // className="bg-bgGrayInvoice flex flex-row justify-center"
              >
                <Slider {...settings}>
                  {props.invoice && props.invoice.length > 0 ? (
                    props.invoice
                      .slice(0)
                      .reverse()
                      .map((item, index) => {
                        return (
                          <div>
                            <Image
                              src={item}
                              style={{ height: 450, width: 340 }}
                              className="mx-auto"
                            />
                          </div>
                        );
                      })
                  ) : (
                    <div className="flex items-center">
                      <p> Invoice image uploaded by user from app</p>
                    </div>
                  )}
                </Slider>
              </div>
            </TabPane>

            <TabPane tab="Update History" key="3">
              <UpdateHistory transactionID={props.currentItem.transactionID} />
            </TabPane>
          </Tabs>
        </Spin>
      </Drawer>
    </Spin>
  );
}

export default SalesDetailsDrawer;
