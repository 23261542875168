import React, { useEffect, useState } from "react";
import Routes from "./routes";
import "antd/dist/reset.css";
import "./index.css";
import NetworkStatus from "./components/NetworkStatus";
import useConnectionStatus from "./utils/useConnectionStatus";
import firebaseApp from "./firebase";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import AppLogout from "./AppLogout";
import TitleHeadingContext from "./context/TitleHeadingContext";

function App() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  let connected = useConnectionStatus();

  useEffect(() => {
    window.onunhandledrejection = (event) => {
      console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
    };
    window.addEventListener("unhandledrejection", function (event) {
      event.preventDefault();
    });
    const auth = getAuth(firebaseApp);

    auth.onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim =
          idTokenResult.claims["https://hasura.io/jwt/claims"];
        if (token) {
          if (hasuraClaim) {
            localStorage.setItem("token", token);
          } else {
            // Check if refresh is required.
            const metadataRef = firebaseApp
              .database()
              .ref("metadata/" + user.uid + "/refreshTime");
            //   console.log("metadataRef", metadataRef);
            metadataRef.on("value", async (data) => {
              if (!data.exists) return;
              // Force refresh to pick up the latest custom claims changes.
              const token = await user.getIdToken(true);
              localStorage.setItem("token", token);
            });
          }
        } else {
          navigate("/");
        }
      } else {
        localStorage.clear();
      }
    });
  }, []);

  return (
    <AppLogout>
      {connected ? (
        <TitleHeadingContext.Provider value={{ title, setTitle }}>
          <Routes />
        </TitleHeadingContext.Provider>
      ) : (
        <NetworkStatus />
      )}
    </AppLogout>
  );
}

export default App;
