import { useEffect, useState, useContext } from "react";
import { Form, Button, Input, Space, Spin, message, Modal } from "antd";
import CreateSelect from "../../../components/CreateSelect";
import deleteIcon from "../../../assets/deleteIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  MATERIAL_LIST,
  INSERT_MATERIAL,
  INSERT_COLLECTION_MATERIALS,
  GET_COLLECTION_DETAILS,
  DELETE_COLLECTION_MATERIAL,
} from "./graphql";
import { dupInArray } from "../../../utils";
import TitleHeadingContext from "../../../context/TitleHeadingContext";

function MaterialsHandled() {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [materialList, setMaterialList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [dropdownList, setDropdownList] = useState([]);
  const [arrayOfMaterials, setArrayOfMaterials] = useState([]);
  const [open, setOpen] = useState(false);
  const [removedList, setRemovedList] = useState([]);
  const [indexVal, setIndexVal] = useState();
  const [error, setError] = useState();
  const [materialObj, setMaterialObj] = useState();
  const [insertMaterial] = useMutation(INSERT_MATERIAL);
  const [deleteMaterials] = useMutation(DELETE_COLLECTION_MATERIAL);
  const [insertCollectionMaterials] = useMutation(INSERT_COLLECTION_MATERIALS);
  const titleHeading = useContext(TitleHeadingContext);

  const {
    data: materialsData,
    loading: materialLoading,
    refetch: materialRefetch,
  } = useQuery(MATERIAL_LIST);

  const {
    data: getCollectionMaterials,
    loading: getCollectionLoading,
    refetch: collectionRefetch,
  } = useQuery(
    GET_COLLECTION_DETAILS,

    {
      variables: {
        id: parseInt(location.pathname.split("/")[4]),
        centre_id: parseInt(location.pathname.split("/")[4]),
      },
    }
  );

  useEffect(() => {
    materialRefetch();
    collectionRefetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let list = [];
    let initValue = [];
    let materialObj = {};
    if (materialsData && getCollectionMaterials) {
      titleHeading.setTitle(getCollectionMaterials?.collection_centre[0]?.name);
      // eslint-disable-next-line
      materialsData.materials.map((item) => {
        if (item.is_global) {
          list.push(item.name);
        }
        materialObj[item.name.toLowerCase()] = item.id;
      });
      if (getCollectionMaterials.collection_material.length === 0) {
        initValue.push({ materialName: "", price: "" });
        setArrayOfMaterials(list);
      } else {
        let selectList = [];
        // eslint-disable-next-line
        getCollectionMaterials.collection_material.map((item, index) => {
          if (item.is_active) {
            selectList.push(item.material.name);
            initValue.push({
              materialName: item.material.name,
              price: item.price,
            });
          }
          if (!item.material.is_global)
            materialObj[item.material.name.toLowerCase()] = item.material.id;
        });

        let newList = list.filter((x) => !selectList.includes(x));
        setSelectedList(selectList);
        setArrayOfMaterials(newList);
      }
      form.setFieldsValue({ materials: initValue });
    }
    setMaterialObj(materialObj);
    setMaterialList(list);
    // eslint-disable-next-line
  }, [materialsData, getCollectionMaterials]);

  useEffect(() => {
    setDropdownList(arrayOfMaterials);
  }, [arrayOfMaterials]);

  useEffect(() => {
    let selectArray = [...selectedList];
    let newList = materialList.filter(
      (material) => !selectArray.includes(material)
    );
    setDropdownList(newList);
    // eslint-disable-next-line
  }, [selectedList]);

  const onFinish = (values) => {
    const lower = selectedList.map((element) => {
      return element.toLowerCase();
    });
    let status = false;
    // eslint-disable-next-line
    values.materials.map((item, index) => {
      if (
        selectedList.length > 0 &&
        selectedList[index] &&
        dupInArray(lower).includes(selectedList[index].toLowerCase())
      ) {
        status = true;
        setError(`Material name "${selectedList[index]}" is duplicated`);
      }
    });
    if (!status) {
      setError();
      // eslint-disable-next-line
      values.materials.map((item, index) => {
        if (selectedList.length > 0) {
          deleteMaterials({
            variables: {
              centre_id: parseInt(location.pathname.split("/")[4]),
            },
          }).then(() => {
            if (
              item.materialName &&
              materialObj[item.materialName.toLowerCase()]
            ) {
              let collectionPayload = {
                centre_id: parseInt(location.pathname.split("/")[4]),
                material_id: materialObj[item.materialName.toLowerCase()],
                price: item.price === "" ? "" : item.price,
                is_active: true,
              };
              insertCollectionMaterials({
                variables: {
                  collectionObject: collectionPayload,
                },
              }).then(() => {
                // status = true;
                // materialRefetch();
                // collectionRefetch();
              });
            } else if (item.materialName) {
              let payload = {
                name: item.materialName,
                is_global: false,
              };
              insertMaterial({
                variables: {
                  object: payload,
                },
              }).then((res) => {
                let collectionPayload = {
                  centre_id: parseInt(location.pathname.split("/")[4]),
                  material_id: res?.data?.insert_materials?.returning[0]?.id,
                  price: item.price,
                  is_active: true,
                };
                insertCollectionMaterials({
                  variables: {
                    collectionObject: collectionPayload,
                  },
                }).then(() => {
                  // status = true;
                  // materialRefetch();
                  // collectionRefetch();
                });
              });
            }
          });
        }
      });
      if (removedList.length > 0) {
        // eslint-disable-next-line
        removedList.map((item) => {
          if (!selectedList.includes(item)) {
            let collectionPayload = {
              centre_id: parseInt(location.pathname.split("/")[4]),
              material_id: materialObj[item.toLowerCase()],
              price: 0,
              is_active: false,
            };
            insertCollectionMaterials({
              variables: {
                collectionObject: collectionPayload,
              },
            }).then(() => {
              // status = true;
              // materialRefetch();
              // collectionRefetch();
            });
          }
        });
      }
      // if (status)
      if (location.pathname.split("/")[2] === "addLocations") {
        navigate(
          `/locations/addLocations/addtional-info/${parseInt(
            location.pathname.split("/")[4]
          )}`,
          { replace: true }
        );
      } else {
        message.open({
          type: "success",
          content: "Materials handled updated successfully",
        });
      }
    }
  };

  const handleValues = (value, index) => {
    let selectArray = [...selectedList];
    let initValue = [];
    selectArray[index] = value;
    let formArray = form.getFieldValue("materials");
    // eslint-disable-next-line
    selectArray.map((item, index) => {
      initValue.push({
        materialName: item,
        price: formArray[index].price,
      });
    });
    form.setFieldsValue({ materials: initValue });
    setSelectedList(selectArray);
  };

  const handleNewValues = (e, value, index, type) => {
    if (e !== "key") e.preventDefault();
    let initValue = [];
    let selectArray = [...selectedList];
    selectArray[index] = value;
    // eslint-disable-next-line
    let formArray = form.getFieldValue("materials");
    // eslint-disable-next-line
    selectArray.map((item, index) => {
      initValue.push({
        materialName: item,
        price: formArray[index] ? formArray[index].price : "",
      });
    });
    form.setFieldsValue({ materials: initValue });
    setSelectedList(selectArray);
  };

  return (
    <Spin spinning={getCollectionLoading || materialLoading}>
      <div className="bg-bgGray w-full min-h-screen py-4">
        <div className="w-45  p-4 bg-white rounded-md shadow-3xl m-auto ">
          <p className="text-lg mb-0">Materials Handled</p>
          <p className="text-sm text-gray500 font-normal">
            These information will be displayed publicly so please review
            carefully before you save
          </p>
          <p className="flex flex-row flex-start mb-2">
            <span className="text-sm font-medium" style={{ width: "300px" }}>
              Material name
            </span>
            <span className="text-sm font-medium">Price per kg</span>
          </p>
          <Form
            name="materialsHandled"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            preserve={true}
          >
            <Form.List initialValue={["materialList"]} name="materials">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Space
                      {...field}
                      key={field.key}
                      align="baseline"
                      name={[field.name, "materialList"]}
                      size={25}
                    >
                      <Form.Item name={[field.name, "materialName"]}>
                        <CreateSelect
                          placeholder="Material Name"
                          data={dropdownList}
                          onChange={handleValues}
                          handleNewValues={handleNewValues}
                          value=""
                          width="270px"
                          index={index}
                        ></CreateSelect>
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "price"]}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a price",
                          },
                          () => ({
                            validator(_, value) {
                              if (value && parseFloat(value) === 0.0) {
                                return Promise.reject(
                                  "Price value cannot be 0"
                                );
                              } else if (value < 0) {
                                return Promise.reject(
                                  "Price value cannot be in negative values"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="number"
                          size="large"
                          style={{ width: "98px" }}
                          step="0.5"
                          min={1}
                        />
                      </Form.Item>
                      <span
                        className={`${
                          fields.length > 1
                            ? "cursor-pointer"
                            : "cursor-not-allowed"
                        }`}
                        onClick={() => {
                          if (fields.length > 1) {
                            let newArrayList = form.getFieldValue("materials");

                            if (newArrayList[index] === undefined) {
                              remove(field.name);
                            } else {
                              setOpen(true);
                              setIndexVal(index);
                            }
                          }
                        }}
                      >
                        <img src={deleteIcon} alt="deleteIcon" />
                      </span>
                    </Space>
                  ))}

                  <Form.Item className="h-10 mb-0 block">
                    <Button
                      type="link"
                      className="text-base text-green p-0"
                      onClick={() => add()}
                    >
                      Add New
                    </Button>
                  </Form.Item>
                  {error && (
                    <Form.Item className="mb-0">
                      <p className="text-red mb-0">{error}</p>
                    </Form.Item>
                  )}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="float-right mr-4"
                    >
                      {location.pathname.split("/")[2] === "addLocations"
                        ? "Next"
                        : "Save"}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
          <Modal
            title="Confirmation"
            open={open}
            onOk={() => {
              let newArrayList = form.getFieldValue("materials");
              let newArray = [...selectedList];
              let removeArray = [...removedList];
              newArrayList.splice(indexVal, 1);
              removeArray.push(newArray[indexVal]);
              newArray.splice(indexVal, 1);
              setSelectedList(newArray);
              setRemovedList(removeArray);
              form.setFieldsValue({ materials: newArrayList });

              setOpen(false);
            }}
            onCancel={() => {
              let newArray = form.getFieldValue("materials");
              form.setFieldsValue({ materials: newArray });
              setOpen(false);
            }}
            okText="Ok"
            cancelText="Cancel"
          >
            <p>Are you sure want to delete the material?</p>
          </Modal>
        </div>
      </div>
    </Spin>
  );
}

export default MaterialsHandled;
