import { gql } from "@apollo/client";

export const GET_USER_REWARD = gql`
  query GET_USER_REWARD($where: rewards_bool_exp) {
    rewards(where: $where) {
      id
      location
      name
      details
      user_rewards {
        id
        code
        payment_at
        redeemed_at
        updated_at
        details
        status
        transaction_id
        reward {
          location
          name
          details
        }
        user {
          id
          email
          locality
          name
          phone
          created_at
        }
      }
    }
  }
`;

export const CHECK_THE_CODE = gql`
  query CHECK_THE_CODE($where: rewards_bool_exp) {
    rewards(where: $where) {
      user_rewards {
        id
        code
        status
      }
    }
  }
`;

export const UPDATE_REWARD_STATUS_TO_REDEEM = gql`
  mutation UPDATE_REWARD_STATUS_TO_REDEEM(
    $id: bigint
    $object: user_reward_set_input
  ) {
    update_user_reward(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
      returning {
        reward {
          id
          total_used_number
        }
      }
    }
  }
`;
export const SEND_PUSH_NOTIFICATIONS = gql`
  mutation SEND_PUSH_NOTIFICATIONS(
    $message: jsonb
    $title: String
    $user_ids: [Int]!
  ) {
    send_notifications(message: $message, title: $title, user_ids: $user_ids) {
      message
    }
  }
`;

export const GET_USER_REWARD_BY_CODE = gql`
  query GET_USER_REWARD($where: user_reward_bool_exp) {
    user_reward(where: $where) {
      id
      code
      payment_at
      redeemed_at
      details
      user_id
      status
      reward {
        id
        location
        name
        details
        user {
          name
        }
      }
      user {
        id
        email
        locality
        name
        phone
        created_at
      }
    }
  }
`;
