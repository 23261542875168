import { Drawer, Spin, Form, Input, Avatar, Divider } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import closeIcon from "../../../assets/closeIcon.svg";
import UserOutlined from "../../../assets/UserOutlined.svg";
import { GET_SIGNED_URL } from "./graphql";
import { useMutation } from "@apollo/client";
import { capitalizeFirst } from "../../../utils";
import Tag from "../../../components/Tag";
import moment from "moment";

function FinancialDrawer(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [vendorId, setVendorId] = useState();
  const [cardColor, setCardColor] = useState();
  const [avatar, setAvatar] = useState();

  const [getSignedUrl] = useMutation(GET_SIGNED_URL);

  useEffect(() => {
    if (props) {
      getCases(props.currentItem);
    }
    // eslint-disable-next-line
  }, [props]);

  const getCases = async (props) => {
    setLoading(true);
    if (props?.logo) {
      let responseUrl = await getResponseUrl(props?.logo);
      setAvatar(responseUrl);
    }
    setLoading(false);
  };

  const getResponseUrl = (file) => {
    return new Promise((resolve) => {
      getSignedUrl({
        variables: {
          fileName: file,
        },
      }).then((res) => {
        resolve(res?.data?.get_signed_url?.url);
      });
    });
  };

  const onClose = () => {
    props.handleClose("close");
  };

  return (
    <Spin spinning={false}>
      <Drawer
        width={400}
        placement="right"
        closable={false}
        onClose={onClose}
        // maskClosable={false}
        open={props.openDrawer}
        rootStyle={{}}
      >
        <div className="mb-4">
          <p className="flex flex-row justify-between items-center">
            <span
              onClick={onClose}
              style={{ width: "30%" }}
              className="cursor-pointer inline-block"
            >
              <img src={closeIcon} alt="closeIcon" />
            </span>
            <span className="text-center text-base text-semibold w-70">
              {props.currentItem.username}
            </span>
            <div className="w-30"></div>
          </p>
          <div className="flex flex-col justify-between items-center">
            <p>
              {avatar ? (
                <Avatar
                  src={avatar}
                  shape="square"
                  style={{
                    width: "120px",
                    height: "56px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              ) : (
                <Avatar
                  className=" bg-indigo"
                  style={{
                    width: "120px",
                    height: "56px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  shape="square"
                >
                  <img src={UserOutlined} alt="useroutlined" />
                </Avatar>
              )}
            </p>
            <p className="text-center text-base text-semibold mb-0">
              {props.currentItem.vendorName}
            </p>
          </div>
          <div>
            <p className="text-sm text-green text-center font-semibold">
              {capitalizeFirst(props.currentItem.vendorStatus)} since:{" "}
              {moment(props.currentItem.created_at).format("DD MMM YYYY")}
            </p>
          </div>
        </div>
        <div>
          <p className="h-7">
            <span className="text-base font-semibold float-left opacity-50">
              Campaign details
            </span>
          </p>
          <p>
            <span className="text-sm font-semibold align-middle">
              Campaign name
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              {props.currentItem.campaignDetails}
            </span>
          </p>
          <p>
            <span className="text-sm font-semibold align-middle">Locality</span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              {" "}
              {props.currentItem.locality}
            </span>
          </p>
        </div>
        <Divider />
        <div>
          <p className="h-7">
            <span className="text-base font-semibold float-left opacity-50">
              Coupon details
            </span>
          </p>
          <p>
            <span className="text-sm font-semibold align-middle">
              Coupon code
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              {props.currentItem.couponCode}
            </span>
          </p>
          <p>
            <span className="text-sm font-semibold align-middle">
              Coupon amount
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              Rs. {props.currentItem.amount}
            </span>
          </p>
          <p>
            <span className="text-sm font-semibold align-middle">
              Due date of coupon
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              {props.currentItem.dueDate}
            </span>
          </p>
        </div>

        <Divider />
        <div>
          <div className="h-7 mb-4">
            <span className="text-base font-semibold float-left inline-block">
              Coupon status
            </span>
            <span className="ml-4 inline-block">
              {props.currentItem.couponStatus.toLowerCase() === "paid" && (
                <Tag color="#D1FAE5" textColor="#065F46">
                  Paid
                </Tag>
              )}
              {props.currentItem.couponStatus.toLowerCase() === "redeemed" && (
                <Tag color="#FEF3C7" textColor="#92400E">
                  Redeemed
                </Tag>
              )}
            </span>
          </div>
          <p>
            <span className="text-sm font-semibold align-middle">
              Paid timestamp
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              {moment(props.currentItem.paidTimestamp).format(
                "DD MMM YYYY hh:mm a"
              )}
            </span>
          </p>
          <p>
            <span className="text-sm font-semibold align-middle">
              Payment Transaction ID
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              {props.currentItem.paymentTransactionID}
            </span>
          </p>
          {props.currentItem.couponStatus.toLowerCase() !== "paid" && (
            <>
              <p>
                <span className="text-sm font-semibold align-middle">
                  Redeem timestamp
                </span>
                <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                <span className="align-middle font-normal text-sm">
                  {moment(props.currentItem.redeemTimestamp).format(
                    "DD MMM YYYY hh:mm a"
                  )}
                </span>
              </p>
              <p>
                <span className="text-sm font-semibold align-middle">
                  Redeem Transaction ID
                </span>
                <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                <span className="align-middle font-normal text-sm">
                  {props.currentItem.redeemTransactionID}
                </span>
              </p>
            </>
          )}
        </div>
        {props.currentItem.couponStatus.toLowerCase() !== "paid" && (
          <>
            <Divider />
            <div>
              <div className="h-7 mb-4">
                <span className="text-base font-semibold float-left inline-block">
                  Vendor payment status
                </span>
                <span className="ml-4 inline-block">
                  {props.currentItem.payment === "cleared" && (
                    <Tag color="#D1FAE5" textColor="#065F46">
                      Cleared
                    </Tag>
                  )}
                  {props.currentItem.payment === "outstanding" && (
                    <Tag color="#FEF3C7" textColor="#92400E">
                      Outstanding
                    </Tag>
                  )}
                </span>
              </div>
              {props.currentItem.payment === "cleared" && (
                <>
                  <p>
                    <span className="text-sm font-semibold align-middle">
                      Payment date
                    </span>
                    <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                    <span className="align-middle font-normal text-sm">
                      {moment(props.currentItem.vendorPaymentDate).format(
                        "DD MMM YYYY hh:mm a"
                      )}
                    </span>
                  </p>
                  <p>
                    <span className="text-sm font-semibold align-middle">
                      Transaction ID
                    </span>
                    <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                    <span className="align-middle font-normal text-sm">
                      {props.currentItem.vendorTransactionID}
                    </span>
                  </p>
                </>
              )}
            </div>
          </>
        )}
      </Drawer>
    </Spin>
  );
}
export default FinancialDrawer;
