import { Button, Card, Form, Input, message, Spin, notification } from "antd";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";

import { useLazyQuery } from "@apollo/client";
import { GET_USER_STATUS } from "./graphql";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase";
// import "firebase/auth";

function VendorLogin(props) {
  const navigate = useNavigate();
  // const location = useLocation();
  const [code, setCode] = useState();
  const [enableLoader, setEnableLoader] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [countryCode] = useState("+91");
  let mobile = /^[0-9]{10}$/;
  const [loginForm] = Form.useForm();
  const [loginFormOTP] = Form.useForm();
  // const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [switchToPage, setSwitchToPage] = useState(false);
  const [accessToken, setAccessToken] = useState();
  const [getUserStatus, { data: statusData, loading, error }] =
    useLazyQuery(GET_USER_STATUS);

  useEffect(() => {
    if (
      localStorage.token &&
      jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
        "x-hasura-allowed-roles"
      ][0] === "vendor"
    ) {
      navigate("/vendor/dashboard");
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      navigate("/VendorLoginError");
    }
  }, [error, navigate]);

  // useEffect(() => {
  //   const auth = getAuth();
  //   let recaptchaVerifier = new RecaptchaVerifier(
  //     "login-button",
  //     {
  //       size: "invisible",
  //       callback: (response) => {
  //         console.log(response);
  //         // reCAPTCHA solved, allow signInWithPhoneNumber.
  //         // onSignInSubmit();
  //       },
  //     },
  //     auth
  //   );
  //   setRecaptchaVerifier(recaptchaVerifier);
  // }, []);

  useEffect(() => {
    if (accessToken) {
      getUserStatus({
        variables: {
          phone_number: `%${phoneNumber}%`,
        },
      });
      setEnableLoader(false);
    }
  }, [accessToken, getUserStatus, phoneNumber]);

  useEffect(() => {
    if (statusData) {
      console.log(statusData, "statusData");
      let obj = statusData.users.find((o) => o.role_id === 3);
      if (obj.status === "Disabled" && obj.role_id === 3) {
        navigate("/vendorError");
      } else if (obj.role_id !== 3) {
        navigate("/VendorLoginError");
      } else {
        navigate("/vendor/dashboard");
      }
    }
  }, [statusData, navigate]);

  const onVerifyOTP = (values) => {
    setEnableLoader(true);

    const otpcode = code;

    if (switchToPage) {
      switchToPage
        .confirm(code)
        .then((result) => {
          // User signed in successfully
          const user = result.user;
          localStorage.setItem("token", user.accessToken);
          setAccessToken(user.accessToken);
          console.log("User is signed in", user);
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          notification.error({
            message: "Please enter a valid otp",
          });
          localStorage.clear();
          setEnableLoader(false);
        });
    }

    // switchToPage
    //   .confirm(otpcode)
    //   .then((result) => {
    //     // User signed in successfully.
    //     const user = result.user;
    //     localStorage.setItem("token", user.accessToken);
    //     setAccessToken(user.accessToken);
    //   })
    //   .catch((error) => {
    //     notification.error({
    //       message: "Please enter a valid otp",
    //     });
    //     localStorage.clear();
    //     setEnableLoader(false);
    //     // User couldn't sign in (bad verification code?)
    //     // ...
    //   });
  };

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "login-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber
        },
      },
      auth
    );
  };

  const onSignInWithPhoneNumber = (values) => {
    setEnableLoader(true);
    setUpRecaptcha();
    setPhoneNumber(values.phone_number);
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      auth,
      `${countryCode}${values.phone_number}`,
      appVerifier
    )
      .then((confirmationResult) => {
        // SMS sent. Save confirmation result to verify later
        window.confirmationResult = confirmationResult;
        setSwitchToPage(confirmationResult);
        setEnableLoader(false);
      })
      .catch((error) => {
        // Error; SMS not sent
        setEnableLoader(false);
        console.error(error);
        if (error.code === "auth/too-many-requests")
          message.error(error.message);
        navigate("/VendorLoginError");
      });
    // setPhoneNumber(values.phone_number);
    // setEnableLoader(true);
    // const appVerifier = recaptchaVerifier;
    // const auth = getAuth(fireBaseApp);
    // signInWithPhoneNumber(
    //   auth,
    //   `${countryCode}${values.phone_number}`,
    //   appVerifier
    // )
    //   .then((confirmationResult) => {
    //     window.confirmationResult = confirmationResult;
    //     setSwitchToPage(confirmationResult);
    //     onVerifyCaptchaVerifier();
    //     setEnableLoader(false);
    //   })
    //   .catch((error) => {
    //     setEnableLoader(false);
    //     console.log(error);

    //     if (error.code === "auth/too-many-requests")
    //       message.error(error.message);
    //     navigate("/VendorLoginError");
    //   });
  };

  // const onVerifyCaptchaVerifier = () => {
  //   const auth = getAuth();
  //   let recaptchaVerifier = new RecaptchaVerifier(
  //     "otp-button",
  //     {
  //       size: "invisible",
  //       callback: () => {},
  //     },
  //     auth
  //   );
  //   setRecaptchaVerifier(recaptchaVerifier);
  // };

  return (
    <Spin spinning={enableLoader || loading}>
      <div className="flex justify-center items-center h-screen">
        <Card
          style={{
            width: `${props.title === "signup" ? "448px" : "460px"} `,
          }}
          bordered={false}
        >
          <div className={` text-center `}>
            <img src={logo} alt="Kabadiwalla Connect" className="mx-auto " />
            <>
              <p className="text3xl mt-8 mb-0">Login in to your acccount</p>
            </>
          </div>
          <div className={`p-6 shadow-2 rounded-lg`}>
            {switchToPage ? (
              <Form
                name="loginForm"
                // onFinish={onVerifyOTP}
                layout="vertical"
                form={loginForm}
                requiredMark={false}
              >
                <Form.Item
                  label={<span className="textsm">Enter the OTP</span>}
                  name="otp"
                  rules={[{ required: true, message: "Please enter a otp" }]}
                >
                  <Input
                    size="large"
                    type="text"
                    placeholder="Enter your OTP"
                    style={{ borderRadius: "6px" }}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    id="otp-button"
                    type="primary"
                    // htmlType="submit"
                    className="uppercase"
                    block
                    size="large"
                    onClick={() => onVerifyOTP()}
                  >
                    LOG IN
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <Form
                name="loginOtp"
                layout="vertical"
                requiredMark={false}
                onFinish={onSignInWithPhoneNumber}
                form={loginFormOTP}
              >
                <Form.Item
                  label={<span className="textsm">Phone number</span>}
                  name="phone_number"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!mobile.test(value)) {
                          return Promise.reject(
                            "Please enter a valid phone number"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    size="large"
                    type="text"
                    placeholder="Enter your phone number"
                    style={{ borderRadius: "6px" }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    id="login-button"
                    type="primary"
                    htmlType="submit"
                    className="uppercase"
                    block
                    size="large"
                  >
                    GET OTP
                  </Button>
                </Form.Item>
              </Form>
            )}
          </div>
        </Card>
      </div>
    </Spin>
  );
}

export default VendorLogin;
