import { Drawer, Divider, Button, Spin } from "antd";
import closeIcon from "../../assets/closeIcon.svg";
import {
  GET_USER_REWARD_BY_CODE,
  UPDATE_REWARD_STATUS_TO_REDEEM,
  SEND_PUSH_NOTIFICATIONS,
} from "./graphql";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import moment from "moment";

function VendorRedeemDrawer(props) {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [updateToRedeem, { loading: redeemedLoading }] = useMutation(
    UPDATE_REWARD_STATUS_TO_REDEEM
  );
  const [sendPushNotifications] = useMutation(SEND_PUSH_NOTIFICATIONS);
  // const [updateCount, { loading: countLoading }] =
  //   useMutation(UPDATE_REWARD_COUNT);
  const { data: userCouponDetail, loading } = useQuery(
    GET_USER_REWARD_BY_CODE,
    {
      variables: {
        where: { code: { _eq: location.pathname.split("/")[3] } },
      },
    }
  );

  useEffect(() => {
    if (userCouponDetail) {
      let newList = [];
      userCouponDetail?.user_reward?.map((item) => {
        console.log(item);
        newList.push({
          id: item.id,
          rewardId: item.reward.id,
          username: item.user.name,
          vendorusername: item.reward.user.name,
          location: item.reward.location,
          status: item.status,
          email: item.user.email,
          phone: item.user.phone,
          userID: item.user.id,
          couponCode: item.code,
          paidOn: item.payment_at,
          couponID: `${item.id}${item.code}`,
          createdAt: item.user.created_at,
        });
      });
      setData(newList);
    }
  }, [userCouponDetail]);

  const handleRedeem = (URid) => {
    console.log(data[0].location);
    let payload = {
      status: "Submitted",
    };
    updateToRedeem({
      variables: {
        id: URid,
        object: payload,
      },
    }).then((res) => {
      let pushObj = {
        id: moment().valueOf(),
        title: "Your coupon is being redeemed!",
        body: `Your coupon for ${data[0].vendorusername} is being redeemed at ${data[0].location}. Please proceed to approve this.`,
        type: "reward",
        user_reward_id: userCouponDetail?.user_reward[0]?.id,
      };

      sendPushNotifications({
        variables: {
          message: pushObj,
          title: "Your coupon is being redeemed!",
          user_ids: userCouponDetail?.user_reward[0]?.user_id,
        },
      }).then(() => {
        window.location.href = "/vendor/dashboard";
      });
    });
  };
  console.log(data, "ddd");
  return (
    <Spin spinning={loading || redeemedLoading}>
      <Drawer
        width={400}
        placement="right"
        closable={false}
        onClose={props.handleClose}
        maskClosable={false}
        open={props.openDrawer}
        rootStyle={{}}
      >
        {data && data.length > 0 && (
          <Spin spinning={loading || redeemedLoading}>
            <div className="mb-4">
              <div className="w-full">
                <p
                  onClick={props.handleClose}
                  className="cursor-pointer w-4 float-left"
                >
                  <img src={closeIcon} alt="closeIcon" />
                </p>
                {/* <p className="flex flex-col"> */}
                <p className="text-base text-green text-center mb-0 font-semibold w-95 float-right">
                  {data[0].username}
                </p>
                {/* </p> */}
              </div>
              <div className="clear-both"></div>
              <div className="mb-4">
                <p className="text-sm text-gray text-center font-semibold">
                  Recycling champion since{" "}
                  {moment(data[0].created_at).format("MMM DD, YYYY")}
                </p>
              </div>
              <div>
                <p className="h-7">
                  <span className="text-base font-semibold float-left opacity-50">
                    About User
                  </span>
                </p>
                <p>
                  <span className="text-sm font-semibold align-middle">
                    Email
                  </span>
                  <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                  <span className="align-middle font-normal text-sm">
                    {data[0].email}
                  </span>
                </p>
                <p>
                  <span className="text-sm font-semibold align-middle">
                    Mobile
                  </span>
                  <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                  <span className="align-middle font-normal text-sm">
                    {data[0].phone}
                  </span>
                </p>
                <p>
                  <span className="text-sm font-semibold align-middle">
                    UserID
                  </span>
                  <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>
                  <span className="align-middle font-normal text-sm">
                    {`USID${`${data[0].userID}`.padStart(6, "0")}`}
                  </span>
                </p>
              </div>
              <Divider />
              <div>
                <p className="h-7">
                  <span className="text-base font-semibold float-left opacity-50">
                    Coupon details
                  </span>
                </p>
                <p>
                  <span className="text-sm font-semibold align-middle">
                    Coupon code
                  </span>
                  <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                  <span className="align-middle font-normal text-sm">
                    {data[0].couponCode}
                  </span>
                </p>
                <p>
                  <span className="text-sm font-semibold align-middle">
                    Paid on
                  </span>
                  <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                  <span className="align-middle font-normal text-sm">
                    {" "}
                    {moment(data[0].paidOn).format("MMM DD, YYYY")}
                  </span>
                </p>
                <p>
                  <span className="text-sm font-semibold align-middle">
                    CampaignID
                  </span>
                  <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                  <span className="align-middle font-normal text-sm">
                    {`CAM${`${data[0].rewardId}`.padStart(6, "0")}`}
                  </span>
                </p>
              </div>
              <div className="w-full mt-8">
                <Button
                  type="primary"
                  size="large"
                  className="w-full"
                  disabled={data[0].status === "Submitted" ? true : false}
                  onClick={() => handleRedeem(data[0].id)}
                >
                  {data[0].status === "Submitted"
                    ? "Sent for Approval"
                    : "Mark as redeemed"}
                </Button>
              </div>
            </div>
          </Spin>
        )}
      </Drawer>
    </Spin>
  );
}

export default VendorRedeemDrawer;
