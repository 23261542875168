import { useState, useMemo, useEffect, useContext } from "react";
import { Spin, Button } from "antd";
import DatePicker from "../../../components/DatePicker";
import Select from "../../../components/Select";
import Table from "../../../components/Table";
import Tag from "../../../components/Tag";
import { GET_TRANSACTION, GET_SIGNED_URL } from "./graphql";
import { useLocation } from "react-router-dom";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import TitleHeadingContext from "../../../context/TitleHeadingContext";
import { numFormatter, getColumnSearchProps } from "../../../utils";
import SalesDetailsDrawer from "../../sales/SalesDetailDrawer";

function Transactions(props) {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState({});
  const [status, setStatus] = useState();
  const [daterange, setDaterange] = useState();
  const [materialList, setMaterialList] = useState([]);
  const [material, setMaterial] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentItem, setCurrentItem] = useState([]);
  const [invoice, setInvoice] = useState([]);

  const [getSignedUrl] = useMutation(GET_SIGNED_URL);
  const titleHeading = useContext(TitleHeadingContext);
  const [getTransaction, { data: transactionDetails, loading: tranLoading }] =
    useLazyQuery(GET_TRANSACTION, { fetchPolicy: "no-cache" });

  const { data: materialListData, loading: listLoading } = useQuery(
    GET_TRANSACTION,
    {
      variables: {
        where: {
          centre_id: { _eq: parseInt(location.pathname.split("/")[4]) },
        },
        id: parseInt(location.pathname.split("/")[4]),
      },
    }
  );

  useEffect(() => {
    if (materialListData) {
      let materialArray = [];
      materialListData.sales.map((item) => {
        let salesList = [];
        item.sales_materials.map((ele) => {
          if (!materialArray.includes(ele.material.name))
            materialArray.push(ele.material.name);
          salesList.push(ele.material.name);
        });
      });
      setMaterialList(materialArray);
    }
  }, [materialListData]);

  useEffect(() => {
    setSearchTerm({
      centre_id: { _eq: parseInt(location.pathname.split("/")[4]) },
    });
  }, []);

  useEffect(() => {
    getTransaction({
      variables: {
        where: searchTerm,
        id: parseInt(location.pathname.split("/")[4]),
      },
    });
  }, [searchTerm]);

  useEffect(() => {
    if (status && daterange) {
      setSearchTerm({
        _and: [
          {
            centre_id: {
              _eq: parseInt(location.pathname.split("/")[4]),
            },
            status: { _eq: status },
            updated_at: { _gte: daterange[0], _lte: daterange[1] },
          },
        ],
      });
    } else if (status) {
      setSearchTerm({
        _and: [
          {
            centre_id: {
              _eq: parseInt(location.pathname.split("/")[4]),
            },
            status: { _eq: status },
          },
        ],
      });
    } else if (daterange) {
      setSearchTerm({
        _and: [
          {
            centre_id: {
              _eq: parseInt(location.pathname.split("/")[4]),
            },
            updated_at: { _gte: daterange[0], _lte: daterange[1] },
          },
        ],
      });
    } else {
      setSearchTerm({
        centre_id: { _eq: parseInt(location.pathname.split("/")[4]) },
      });
    }
  }, [status, daterange]);

  useEffect(() => {
    if (transactionDetails) {
      titleHeading.setTitle(transactionDetails?.collection_centre[0]?.name);
      let newList = [];
      let materialArray = [];
      transactionDetails.sales.map((item) => {
        let salesList = [];
        if (item.status !== "Draft") {
          item.sales_materials.map((ele) => {
            if (!materialArray.includes(ele.material.name))
              materialArray.push(ele.material.name);
            salesList.push(ele.material.name);
          });
          newList.push({
            // id: `TRANS0000${item.id}`,
            transactionID: item.id,
            name: item.user.name,
            status: item.status,
            userLocality: item.user.locality,
            username: item.user.name,
            created_at: item.created_at,
            location: item.collection_centre.name,
            receipt: item.receipt,
            locationCentre: item.collection_centre.locality,
            profilePic: item.user.details ? item.user.details.image : null,
            volumePurchased:
              item.sales_materials_aggregate.aggregate.sum.actual_quantity,
            salesList: salesList,
            revenue: item.total_price,
            details: `${moment(item.updated_at).format("DD MMM YYYY")}-${moment(
              item.updated_at
            ).format("hh:mma")}`,
          });
        }
      });

      if (material.length > 0) {
        const DisplayData = newList?.filter((ele) =>
          ele.salesList.some((item) => material.includes(item))
        );

        setData(DisplayData);
      } else {
        setData(newList);
      }
    }
  }, [transactionDetails, material]);

  const handleselectedValues = (value, type) => {
    if (type === "Status") setStatus(value);
    if (type === "Materials") setMaterial(value);
  };

  const handleDate = (values) => {
    setDaterange(values);
  };

  const handleDrawer = (record) => {
    let url = [];
    record.receipt.forEach(async (ele, index) => {
      let responseUrl = await getResponseUrl(ele);
      url[index] = responseUrl;
    });
    setInvoice(url);
    setOpenDrawer(true);
    setCurrentItem(record);
  };

  const getResponseUrl = (file) => {
    return new Promise((resolve) => {
      getSignedUrl({
        variables: {
          fileName: file,
        },
      }).then((res) => {
        resolve(res?.data?.get_signed_url?.url);
      });
    });
  };

  const onClose = () => {
    setOpenDrawer(false);
    setCurrentItem([]);
  };

  const columns = useMemo(
    () => [
      {
        title: "TRANSACTION ID",
        dataIndex: "transactionID",
        key: "transactionID",
        className: "w-48",
        ...getColumnSearchProps("id"),
        render: (text) => <>{`SAL${`${text}`.padStart(6, "0")}`}</>,
      },
      {
        title: "NAME",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "STATUS",
        dataIndex: "status",
        key: "status",
        render: (text) => {
          return (
            <>
              {text === "In review" && (
                <Tag color="#F3F4F6" textColor="#1F2937">
                  In review
                </Tag>
              )}
              {text === "Approved" && (
                <Tag color="#D1FAE5" textColor="#065F46">
                  Approved
                </Tag>
              )}
              {text === "Rejected" && (
                <Tag color="#FEE2E2" textColor="#991B1B">
                  Rejected
                </Tag>
              )}
              {text === "Update info" && (
                <Tag color="#DBEAFE" textColor="#1E40AF">
                  Update info
                </Tag>
              )}
            </>
          );
        },
      },
      {
        title: "VOLUME PURCHASED",
        dataIndex: "volumePurchased",
        key: "volumePurchased",
        sorter: (a, b) => a.volumePurchased - b.volumePurchased,
        render: (text) =>
          text ? (
            <span className="float-right">{numFormatter(text)} kg</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "Revenue",
        dataIndex: "revenue",
        key: "revenue",
        sorter: (a, b) => a.revenue - b.revenue,
        render: (text) =>
          text ? (
            <span className="float-right">Rs. {numFormatter(text)}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "DETAILS",
        dataIndex: "details",
        key: "details",
        render: (text, record) => {
          return (
            <>
              <Button
                type="link"
                className="text-green hover:text-green"
                onClick={() => {
                  handleDrawer(record);
                }}
                id="transactionDetails"
              >
                <span className="text-green">{text.split("-")[0]}</span>
                <span className="bg-green w-1.5 h-1.5 mx-2 align-middle inline-block rounded-md"></span>

                <span className="text-green">{text.split("-")[1]}</span>
              </Button>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <Spin spinning={listLoading || tranLoading}>
      <div className="flex flex-col gap-5 p-8 w-full justify-between ">
        <div className="flex gap-5">
          <div style={{ width: "18%" }}>
            <Select
              placeholder="Materials"
              type="Materials"
              data={materialList || []}
              onChange={handleselectedValues}
              value=""
              // width="270px"
              mode="multiple"
              allowClear={true}
            />
          </div>
          <div style={{ width: "13%" }}>
            <Select
              placeholder="Status"
              type="Status"
              data={["In review", "Approved", "Rejected","Update info"]}
              onChange={handleselectedValues}
              value=""
              // width="270px"
              allowClear={true}
            />
          </div>
          <DatePicker
            placeholder="Date"
            separator="~"
            handleDate={handleDate}
          />
        </div>

        <div className="w-full">
          <Table
            dataSource={!!data.length ? data : []}
            columns={columns}
            key="transactionsListing"
            pagination={true}
            locale={{ emptyText: "No transactions found" }}
          />
        </div>
        {openDrawer && (
          <SalesDetailsDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            onClose={onClose}
            invoice={invoice}
          />
        )}
      </div>
    </Spin>
  );
}

export default Transactions;
