import { Spin, List, Button, Avatar, Modal, message } from "antd";
import { useLocation } from "react-router-dom";
import deleteIcon from "../../../assets/deleteIcon.svg";
import greenTick from "../../../assets/greenTick.svg";
import grayTick from "../../../assets/grayTick.svg";
import mail from "../../../assets/mail.svg";
import {
  GET_RESIDENT_LIST,
  GET_SIGNED_URL,
  REMOVE_THE_USER_FROM_APARTMENT,
  INSERT_NOTIFICATION,
  SEND_PUSH_NOTIFICATIONS,
  INSERT_NOTIFICATION_USER,
} from "../graphql";
import { useEffect, useState, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import TitleHeadingContext from "../../../context/TitleHeadingContext";
import moment from "moment";
import BroadCastDrawer from "../../../components/BroadcastDrawer";
import UserDetailsDrawer from "../../users/UserDetailsDrawer";

function ResidentList() {
  const location = useLocation();
  const search = useLocation().search;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [avatar, setAvatar] = useState([]);
  const [searchTerm, setSearchTerm] = useState({});
  const [userTerm, setUserTerm] = useState({});
  const [searchLocName, setSearchLocName] = useState();
  const [category, setCategory] = useState();
  const [currentUserItem, setCurrentUserItem] = useState([]);
  const [userID, setUserID] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openBroadCastDrawer, setOpenBroadCastDrawer] = useState(false);
  const [getSignedUrl] = useMutation(GET_SIGNED_URL);
  const titleHeading = useContext(TitleHeadingContext);
  const [removeUser] = useMutation(REMOVE_THE_USER_FROM_APARTMENT);
  const [insertNotificationUser] = useMutation(INSERT_NOTIFICATION_USER);
  const [insertNotification] = useMutation(INSERT_NOTIFICATION);
  const [sendPushNotifications] = useMutation(SEND_PUSH_NOTIFICATIONS);
  const [getResidentList, { data: residentList, loading: residentLoading }] =
    useLazyQuery(GET_RESIDENT_LIST, { fetchPolicy: "no-cache" });

  useEffect(() => {
    getResidentList({
      variables: {
        apartmentWhere: {
          id: { _eq: parseInt(location.pathname.split("/")[4]) },
        },
        userWhere: { name: {} },
      },
    });
  }, []);

  useEffect(() => {
    getResidentList({
      variables: {
        apartmentWhere: searchTerm,
        userWhere: userTerm,
      },
    });
  }, [searchTerm, userTerm]);

  useEffect(() => {
    setSearchTerm({
      id: { _eq: parseInt(location.pathname.split("/")[4]) },
    });
    setUserTerm(
      new URLSearchParams(search).get("searchText") &&
        new URLSearchParams(search).get("category") === "User name"
        ? { name: { _ilike: `%${searchLocName}%` } }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "Mobile number"
        ? { phone: { _ilike: `%${searchLocName}%` } }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "User ID"
        ? { id: !isNaN(searchLocName) ? { _eq: parseInt(searchLocName) } : {} }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "null"
        ? {
            _or: [
              {
                name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
              },
              { phone: searchLocName ? { _ilike: `%${searchLocName}%` } : {} },
              !isNaN(searchLocName) && {
                id: { _eq: parseInt(searchLocName) },
              },
            ],
          }
        : {}
    );
  }, [searchLocName, category]);

  useEffect(() => {
    if (residentList) {
      titleHeading.setTitle(residentList.apartments[0].name);
      getCases();
    }
  }, [residentList]);

  useEffect(() => {
    setSearchLocName(new URLSearchParams(search).get("searchText"));
    setCategory(new URLSearchParams(search).get("category"));
  }, [location]);

  const getCases = async () => {
    let url = [];
    let residentArray = [];
    let userListID = [];
    setLoading(true);
    residentList.apartments[0].users.forEach(async (ele, index) => {
      let responseUrl = await getResponseUrl(ele.details.image);
      url[index] = responseUrl;
    });
    setAvatar(url);
    setLoading(false);
    residentList.apartments[0].users.forEach(async (ele, index) => {
      residentArray.push({
        id: ele.id,
        title: ele.name,
        email: ele.email,
        details: ele.details,
        avatar: avatar[index],
        description: ele.phone,
        created_at: ele.created_at,
        recyclablesSold: ele.sales_aggregate.aggregate.count,
      });
      userListID.push(ele.id);
    });
    setData(residentArray);
    setUserID(userListID);
  };
  const getResponseUrl = (file) => {
    return new Promise((resolve) => {
      getSignedUrl({
        variables: {
          fileName: file,
        },
      }).then((res) => {
        resolve(res?.data?.get_signed_url?.url);
      });
    });
  };

  const broadCastonFinish = (values) => {
    insertNotification({
      variables: {
        object: {
          text: values.message,
          is_broadcast: true,
        },
      },
    }).then((res) => {
      let payload = [];
      userID.map((item) => {
        payload.push({
          notification_id: res?.data?.insert_notifications?.returning[0]?.id,
          user_id: item,
        });
      });
      insertNotificationUser({
        variables: {
          object: payload,
        },
      }).then(() => {
        let pushObj = {
          id: moment().valueOf(),
          title: "You have a new message from Kabadiwalla Connect",
          body: values.message,
          type: "notification",
        };
        sendPushNotifications({
          variables: {
            message: pushObj,
            title: "You have a new message from Kabadiwalla Connect",
            user_ids: userID,
          },
        }).then(() => {
          message.open({
            type: "success",
            content: "Message broadcasted successfully",
          });
          window.location.reload();
        });
      });
    });
  };

  return (
    <Spin spinning={loading || residentLoading}>
      <>
        <div className="flex flex-col gap-5 p-8 w-full justify-between ">
          <div>
            <span className="text-lg font-normal">
              Residents tagged to this apartment : {data.length}
            </span>

            <Button
              type="primary"
              id="broadcast"
              size="large"
              className="float-right"
              disabled={data.length === 0 ? true : false}
              onClick={() => setOpenBroadCastDrawer(true)}
            >
              Broadcast message
            </Button>
          </div>
          {data.length > 0 ? (
            <List
              className="demo-loadmore-list"
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <>
                      <span
                        className="mr-8 cursor-pointer"
                        onClick={() => {
                          setOpenDrawer(true);
                          setCurrentUserItem(item);
                        }}
                      >
                        <img src={mail} alt="mail" />
                      </span>
                      <span
                        className="ml-4 cursor-pointer"
                        onClick={() => {
                          setOpen(true);
                          setCurrentItem(item);
                        }}
                      >
                        <img src={deleteIcon} alt="deleteIcon" />
                      </span>
                    </>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Avatar src={avatar[index]} />}
                    title={
                      <span className="text-green">
                        {item.title} (USID0000{item.id})
                      </span>
                    }
                    description={item.description}
                  />
                  <div className="w-45 flex flex-col">
                    <p className="mb-1">
                      Joined on {moment(item.created_at).format("DD MMM YYYY")}
                    </p>
                    <p className="text-[#00000073]">
                      <img
                        src={item.recyclablesSold > 0 ? greenTick : grayTick}
                      />{" "}
                      Recyclables sold -{" "}
                      {item.recyclablesSold > 0
                        ? `${item.recyclablesSold} times`
                        : "None"}
                    </p>
                  </div>
                </List.Item>
              )}
            />
          ) : (
            <div className="flex flex-col justify-center items-center py-4">
              <p className="text-lg-l5-semibold text-gray-500 mt-4 opacity-50">
                No residents added for this apartment
              </p>
            </div>
          )}
        </div>
        {openBroadCastDrawer && (
          <BroadCastDrawer
            openDrawer={openBroadCastDrawer}
            setOpenDrawer={setOpenBroadCastDrawer}
            broadCastonFinish={broadCastonFinish}
          />
        )}
        {openDrawer && (
          <UserDetailsDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            currentItem={currentUserItem}
            activeTab="2"
          />
        )}
        <Modal
          title="Confirmation"
          open={open}
          onOk={() => {
            removeUser({
              variables: {
                id: parseInt(currentItem.id),
              },
            }).then(() => window.location.reload());
          }}
          onCancel={() => {
            setOpen(false);
          }}
          okText="Ok"
          cancelText="Cancel"
        >
          <p>Are you sure you want to remove the user?</p>
        </Modal>
      </>
    </Spin>
  );
}
export default ResidentList;
