import {
  DashboardIcon,
  LocationIcon,
  UserIcon,
  SaleIcon,
  RewardsIcon,
  ApartmentIcon,
  GlobalMaterialIcon,
  FeedbackIcon,
  AboutIcon,
} from "../../assets/svg";

export const data = {
  vendorScreen: [
    {
      icon: <DashboardIcon />,
      title: "Dashboard",
      className: "fill",
      href: `/vendor/dashboard`,
    },
  ],
  MainScreen: [
    {
      icon: <DashboardIcon />,
      title: "Dashboard",
      className: "fill",
      href: `/dashboard`,
    },
    {
      icon: <LocationIcon />,
      title: "Locations",
      className: "stroke",
      href: "/locations",
    },
    { icon: <UserIcon />, title: "Users", className: "stroke", href: "/users" },
    { icon: <SaleIcon />, title: "Sales", className: "stroke", href: "/sales" },
    {
      icon: <RewardsIcon />,
      title: "Rewards",
      className: "stroke",
      href: "/rewards",
    },
    {
      icon: <ApartmentIcon />,
      title: "Apartments",
      className: "stroke",
      href: "/apartments",
    },
    {
      icon: <GlobalMaterialIcon />,
      title: "Global Materials",
      className: "stroke",
      href: "/globalMaterials",
    },
    // {
    //   icon: <FeedbackIcon />,
    //   title: "Feedback",
    //   className: "stroke",
    //   href: "/feedback",
    // },
  ],
  vendor: [
    {
      icon: <AboutIcon />,
      title: "Vendor details",
      className: "stroke",
      href: `/rewards/addRewards/vendorDetails`,
    },
    {
      icon: <LocationIcon />,
      title: "Campaign",
      className: "stroke",
      href: `/rewards/addRewards/campaignDetails`,
    },
    {
      icon: <SaleIcon />,
      title: "Financials",
      className: "stroke",
      href: `/rewards/addRewards/financials`,
    },
    {
      icon: <SaleIcon />,
      title: "Outstanding",
      className: "stroke",
      href: `/rewards/addRewards/outstanding`,
    },
    {
      icon: <SaleIcon />,
      title: "Past payments",
      className: "stroke",
      href: `/rewards/addRewards/pastPayments`,
    },
  ],
  editVendor: [
    {
      icon: <AboutIcon />,
      title: "Vendor details",
      className: "stroke",
      href: `/rewards/editRewards/vendorDetails`,
    },
    {
      icon: <LocationIcon />,
      title: "Campaign",
      className: "stroke",
      href: `/rewards/editRewards/campaignDetails`,
    },
    {
      icon: <SaleIcon />,
      title: "Financials",
      className: "stroke",
      href: `/rewards/editRewards/financials`,
    },
    {
      icon: <SaleIcon />,
      title: "Outstanding",
      className: "stroke",
      href: `/rewards/editRewards/outstanding`,
    },
    {
      icon: <SaleIcon />,
      title: "Past payments",
      className: "stroke",
      href: `/rewards/editRewards/pastPayments`,
    },
  ],
  viewUser: [
    {
      icon: <SaleIcon />,
      title: "Sale details",
      className: "stroke",
      href: `/users/viewUser/sales`,
    },
    {
      icon: <UserIcon />,
      title: "coupons",
      className: "stroke",
      href: `/users/viewUser/coupons`,
    },
  ],
  addlocations: [
    {
      icon: <AboutIcon />,
      title: "About",
      className: "stroke",
      href: "/about",
    },
    {
      icon: <LocationIcon />,
      title: "Address",
      className: "stroke",
      href: "/address",
    },
    {
      icon: <DashboardIcon />,
      title: "Materials handled",
      className: "fill",
      href: "/materials-handled",
      disable: true,
    },
    {
      icon: <UserIcon />,
      title: "Additional info",
      className: "stroke",
      href: "/addtional-info",
      disable: true,
    },
  ],
  editlocations: [
    {
      icon: <AboutIcon />,
      title: "About",
      className: "stroke",
      href: "/about",
    },
    {
      icon: <LocationIcon />,
      title: "Address",
      className: "stroke",
      href: "/address",
    },
    {
      icon: <DashboardIcon />,
      title: "Materials handled",
      className: "fill",
      href: "/materials-handled",
      disable: true,
    },
    {
      icon: <UserIcon />,
      title: "Additional info",
      className: "stroke",
      href: "/addtional-info",
      disable: true,
    },
    {
      icon: <SaleIcon />,
      title: "Transactions",
      className: "stroke",
      href: "/transactions",
      disable: true,
    },
  ],
  addApartment: [
    {
      icon: <AboutIcon />,
      title: "Apartment details",
      className: "stroke",
      href: `/apartments/addApartment/details`,
    },
    {
      icon: <UserIcon />,
      title: "Resident List",
      className: "stroke",
      href: `/apartments/addApartment/residentList`,
    },
  ],
  editApartment: [
    {
      icon: <AboutIcon />,
      title: "Apartment details",
      className: "stroke",
      href: `/apartments/editApartment/details`,
    },
    {
      icon: <UserIcon />,
      title: "Resident List",
      className: "stroke",
      href: `/apartments/editApartment/residentList`,
    },
  ],
};
