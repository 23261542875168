import { gql } from "@apollo/client";

export const GET_CENTRE_TRANSACTION = gql`
  query GET_CENTRE_TRANSACTION($id: bigint) {
    collection_centre(where: { id: { _eq: $id } }) {
      status
      lat
      long
      locality
    }
  }
`;
