import { gql } from "@apollo/client";

export const UPDATE_EMAIL_STATUS = gql`
  mutation UPDATE_EMAIL_STATUS($id: bigint, $email: String) {
    update_users(
      where: { _and: { id: { _eq: $id }, email: { _eq: $email } } }
      _set: { verified_email: true }
    ) {
      affected_rows
    }
  }
`;
