import { Card } from "antd";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import verification from "../../assets/verification.svg";
import { UPDATE_EMAIL_STATUS } from "./graphql";
import { useMutation } from "@apollo/client";

export default function Verification() {
  const search = useLocation().search;
  const location = useLocation();
  const [updateEmaiStatus] = useMutation(UPDATE_EMAIL_STATUS);

  useEffect(() => {
    updateEmaiStatus({
      variables: {
        email: new URLSearchParams(search).get("email"),
        id: parseInt(location.pathname.split("/")[2]),
      },
    });
  }, []);

  return (
    <>
      <div className="flex justify-center items-center h-screen emailVerification">
        <Card bordered={false}>
          <p className="text-center">
            <img src={verification} class="img" />
          </p>
          <p className="text-base font-normal text-gray-900 text-center mb-0">
            Your email has been verified.
          </p>
          <p className="text-base font-normal text-gray-900 text-center">
            You can continue using the application.
          </p>
        </Card>
      </div>
    </>
  );
}
