import { Button, Spin, Tabs } from "antd";
import Select from "../../components/Select";
import DatePicker from "../../components/DatePicker";
import CountCard from "../../components/CountCard";
import SaleBarChart from "../../components/BarChart";
import { useState, useMemo, useEffect } from "react";
import SaleLineChart from "../../components/LineChart";
import Table from "../../components/Table";
import Tag from "../../components/Tag";
import { useLocation } from "react-router-dom";
import SalesDetailsDrawer from "./SalesDetailDrawer";
import { removeDuplicates, numFormatter } from "../../utils";
import moment from "moment";
import {
  GET_SALES_LISTING,
  GET_APARTMENTS,
  GET_USERS_LOCALITY_LIST,
  GET_SALES_MATERIAL,
  GET_TOP_MATERIALS,
  GET_STATS,
  GET_SIGNED_URL,
} from "./graphql";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { groupBy, getColumnSearchProps } from "../../utils";

const { TabPane } = Tabs;

function Sales() {
  const location = useLocation();
  const search = useLocation().search;
  const [searchLocName, setSearchLocName] = useState();
  const [category, setCategory] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const [apartmentList, setApartmentList] = useState([]);
  const [salesWhereTerm, setSalesWhereTerm] = useState({});
  const [searchTerm, setSearchTerm] = useState({});
  const [materiallist, setMaterialList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentItem, setCurrentItem] = useState([]);
  const [userLocality, setUserLocality] = useState([]);
  const [noOfTransaction, setNoOfTransactions] = useState(0);
  const [avgTransaction, setAvgTransaction] = useState(0);
  const [avgValue, setAvgValue] = useState(0);
  const [approvedTransaction, setApprovedTransaction] = useState(0);
  const [reviewTransaction, setReviewTransaction] = useState(0);
  const [rejectedTransaction, setRejectedTransaction] = useState(0);
  // const [uploadTransaction, setUploadTransaction] = useState(0);
  const [selectLocation, setSelectLocation] = useState();
  // const [apartmentValue, setApartmentValue] = useState([]);
  const [selectUserLocality, setSelectUserLocality] = useState();
  const [daterange, setDaterange] = useState();
  const [collectionName, setCollectionName] = useState();
  const [selectApartment, setSelectApartment] = useState();
  const [selectMaterials, setSelectMaterials] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const [apartmentSearchTerm, setApartmentSearchTerm] = useState({});
  const [data, setData] = useState([]);
  const [linedata, setLineData] = useState([]);
  const [invoice, setInvoice] = useState([]);

  const [getSignedUrl] = useMutation(GET_SIGNED_URL);

  const [getSalesData, { data: salesDetails, loading: salesLoading }] =
    useLazyQuery(GET_SALES_LISTING, { fetchPolicy: "no-cache" });

  const [getStatsData, { data: statsDetails, loading: statsLoading }] =
    useLazyQuery(GET_STATS, { fetchPolicy: "no-cache" });

  const [
    getTopMaterials,
    { data: topMaterials, loading: topMaterialsLoading },
  ] = useLazyQuery(GET_TOP_MATERIALS, { fetchPolicy: "no-cache" });

  const { data: locationList, loading: locationLoading } = useQuery(
    GET_USERS_LOCALITY_LIST
  );

  useEffect(() => {
    if (searchTerm) {
      getSalesData({
        variables: {
          where: searchTerm,
          salesWhere: salesWhereTerm,
        },
      });
    }
  }, [searchTerm, salesWhereTerm]);

  const { data: salesMaterialist, loading: salesMaterialLoading } =
    useQuery(GET_SALES_MATERIAL);

  const [
    getApartments,
    { data: apartmentDetails, loading: apartmentDetailsLoading },
  ] = useLazyQuery(GET_APARTMENTS, { fetchPolicy: "no-cache" });

  useEffect(() => {
    let startdate = moment()
      .subtract(30, "day")
      .format("YYYY-MM-DDT00:00:00.000Z");
    let last7day = moment()
      .subtract(7, "day")
      .format("YYYY-MM-DDT00:00:00.000Z");
    let enddate = moment().format("YYYY-MM-DDT23:59:59.000Z");
    getSalesData({ variables: { where: {}, salesWhere: {} } });
    getTopMaterials({ variables: { startDate: startdate, endDate: enddate } });
    getStatsData({ variables: { start: last7day, end: enddate } });
    getApartments({
      variables: {
        where: {},
      },
    });
  }, []);

  useEffect(() => {
    if (topMaterials) {
      let object = JSON.parse(JSON.stringify(topMaterials.sales_materials));
      const groupedData = groupBy(object, "material_id");
      let newarray = [];

      Object.keys(groupedData).forEach(function (key, index) {
        let sum = 0;
        let materialName;
        groupedData[key].map((item) => {
          if (activeTab === "1") {
            sum += item.actual_quantity;
          } else {
            sum += item.price;
          }
          materialName = item.material.name;
        });
        if (activeTab === "2") {
          newarray.push({
            name: materialName,
            value: Math.abs(sum / groupedData[key].length),
          });
        } else {
          newarray.push({
            name: materialName,
            value: Math.abs(sum),
          });
        }
      });
      const numDescending = [...newarray].sort((a, b) => b.value - a.value);

      let first3Expense = numDescending.slice(0, 10);

      setData(first3Expense);
    }
  }, [topMaterials, activeTab]);

  const listOfDates = (date, value) => {
    let daysAgo = [];
    for (let i = 0; i <= value - 1; i++) {
      daysAgo.push(moment(date).subtract(i, "days").format("DD/MM/YY"));
    }
    return daysAgo;
  };

  useEffect(() => {
    setSearchLocName(new URLSearchParams(search).get("searchText"));
    setCategory(new URLSearchParams(search).get("category"));
  }, [location]);

  useEffect(() => {
    if (statsDetails) {
      let noOfDays =
        daterange && daterange[0] && daterange[1]
          ? moment(daterange[1]).diff(moment(daterange[0]), "days") + 1
          : 7;
      let dayData = {};
      let weekDataArray = [];
      let object = JSON.parse(JSON.stringify(statsDetails.sales));

      object.map((sale) => {
        sale.updated_at = moment(sale.updated_at).format("DD/MM/YY");
      });
      const groupedData = groupBy(object, "updated_at");
      Object.keys(groupedData).forEach(function (key, index) {
        groupedData[key].noOfTransaction = groupedData[key].length;
        let volume = 0;
        let price = 0;
        groupedData[key].map((item) => {
          volume +=
            item.sales_materials_aggregate.aggregate.sum.actual_quantity;
          price += item.total_price;
        });
        dayData[key] = {
          noOfTransaction: groupedData[key].length,
          Volume: volume,
          Revenue: price,
          avgPrice: price / groupedData[key].length,
        };
      });
      let datesList =
        daterange && daterange[0] && daterange[1]
          ? listOfDates(moment(daterange[1]), noOfDays)
          : listOfDates(moment(), noOfDays);
      let linearray = [];
      datesList.map((date) => {
        if (dayData[date]) {
          linearray.push({
            ...dayData[date],
            name: date,
          });
        } else {
          linearray.push({
            name: date,
            noOfTransaction: 0,
            Volume: 0,
            Revenue: 0,
            avgPrice: 0,
          });
        }
      });
      if (noOfDays <= 10) {
        noOfDays === 7
          ? linearray.slice(0, 6)
          : linearray.slice(0, noOfDays - 1);
        setLineData(linearray);
      } else {
        const datesGroupByComponent = (dates, token) => {
          return dates.reduce(function (val, obj) {
            let comp = moment(obj["name"], "DD/MM/YY").format(token);
            (val[comp] = val[comp] || []).push(obj);
            return val;
          }, {});
        };
        let weekdata = datesGroupByComponent(linearray, "w");

        Object.keys(weekdata).forEach(function (key, index) {
          // weekdata[key].noOfTransaction = weekdata[key].length;
          let volume = 0;
          let price = 0;
          let noOfTransactionCount = 0;
          let avgPriceCount = 0;
          weekdata[key].map((item) => {
            volume += item.volume;
            price += item.revenue;
            noOfTransactionCount += item.noOfTransaction;
            avgPriceCount += item.avgPrice;
          });
          let startDate = moment(weekdata[key][0].name, "DD/MM/YYYY").format(
            "DD MMM"
          );
          let endDate = moment(
            weekdata[key][weekdata[key].length - 1].name,
            "DD/MM/YYYY"
          ).format("DD MMM");
          weekDataArray.push({
            name: endDate + "-" + startDate,
            noOfTransaction: noOfTransactionCount,
            volume: volume,
            revenue: price,
            avgPrice: avgPriceCount,
          });
        });
        setLineData(weekDataArray);
      }
    }
  }, [statsDetails]);

  useEffect(() => {
    if (locationList) {
      let newList = [];
      let collectionLocation = [];
      locationList.users.map((item) => {
        if (item.locality && item.sales.length > 0) newList.push(item.locality);
      });
      locationList.collection_centre.map((item) => {
        if (item.locality && item.sales.length > 0)
          collectionLocation.push(item.name);
      });
      let list = newList.filter(
        (item, index) => newList.indexOf(item) === index
      );
      list.sort();
      let collectionList = collectionLocation.filter(
        (item, index) => collectionLocation.indexOf(item) === index
      );
      collectionList.sort();
      setCollectionName(collectionList);
      setUserLocality(list);
    }
  }, [locationList]);

  useEffect(() => {
    let startdate = daterange
      ? moment(daterange[0]).format("YYYY-MM-DDT00:00:00.000Z")
      : moment().subtract(30, "day").format("YYYY-MM-DDT00:00:00.000Z");
    let startLineChart = daterange
      ? moment(daterange[0]).format("YYYY-MM-DDT00:00:00.000Z")
      : moment().subtract(7, "day").format("YYYY-MM-DDT00:00:00.000Z");
    let enddate = daterange
      ? moment(daterange[1]).format("YYYY-MM-DDT23:59:59.000Z")
      : moment().format("YYYY-MM-DDT23:59:59.000Z");
    getStatsData({ variables: { start: startLineChart, end: enddate } });
    getTopMaterials({
      variables: { startDate: startdate, endDate: enddate },
    });
  }, [daterange]);

  useEffect(() => {
    if (salesMaterialist) {
      let newList = [];
      salesMaterialist.sales_materials.map((item) =>
        newList.push(item.material.name)
      );

      setMaterialList(newList);
    }
  }, [salesMaterialist]);

  useEffect(() => {
    if (
      apartmentSearchTerm &&
      Object.keys(apartmentSearchTerm).length === 0 &&
      apartmentSearchTerm.constructor === Object
    )
      getApartments({ variables: { where: apartmentSearchTerm } });
  }, [apartmentSearchTerm]);

  useEffect(() => {
    // if (
    //   selectLocation ||
    //   selectUserLocality ||
    //   selectStatus ||
    //   selectMaterials ||
    //   searchLocName ||
    //   category
    // )
    setSearchTerm(
      new URLSearchParams(search).get("searchText") &&
        new URLSearchParams(search).get("category") === "Name"
        ? {
            status: selectStatus ? { _eq: selectStatus } : {},
            user: {
              locality:
                selectUserLocality && selectUserLocality.length > 0
                  ? { _in: selectUserLocality }
                  : {},
            },
            collection_centre: {
              name:
                selectLocation && selectLocation.length > 0
                  ? { _in: selectLocation }
                  : {},
            },
            sales_materials: {
              material: {
                name:
                  selectMaterials && selectMaterials.length > 0
                    ? { _in: selectMaterials }
                    : {},
              },
            },

            user: {
              name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
            },
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "Location"
        ? {
            status: selectStatus ? { _eq: selectStatus } : {},
            user: {
              locality:
                selectUserLocality && selectUserLocality.length > 0
                  ? { _in: selectUserLocality }
                  : {},
            },

            collection_centre: {
              name:
                selectLocation && searchLocName
                  ? { _ilike: `%${searchLocName}%`, _in: selectLocation }
                  : selectLocation
                  ? { _in: selectLocation.length > 0 ? selectLocation : {} }
                  : searchLocName
                  ? { _ilike: `%${searchLocName}%` }
                  : {},
            },
            sales_materials: {
              material: {
                name:
                  selectMaterials && selectMaterials.length > 0
                    ? { _in: selectMaterials }
                    : {},
              },
            },
            user: {
              name: {},
            },
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "User Locality"
        ? {
            status: selectStatus ? { _eq: selectStatus } : {},
            user: {
              locality:
                selectUserLocality && searchLocName
                  ? { _ilike: `%${searchLocName}%`, _in: selectUserLocality }
                  : selectUserLocality
                  ? {
                      _in:
                        selectUserLocality.length > 0 ? selectUserLocality : {},
                    }
                  : searchLocName
                  ? { _ilike: `%${searchLocName}%` }
                  : {},
            },
            collection_centre: {
              name:
                selectLocation && selectLocation.length > 0
                  ? { _in: selectLocation }
                  : {},
            },
            sales_materials: {
              material: {
                name:
                  selectMaterials && selectMaterials.length > 0
                    ? { _in: selectMaterials }
                    : {},
              },
            },
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "null"
        ? {
            _and: [
              {
                status: selectStatus ? { _eq: selectStatus } : {},
                user: {
                  locality:
                    selectUserLocality && selectUserLocality.length > 0
                      ? { _in: selectUserLocality }
                      : {},
                },
                sales_materials: {
                  material: {
                    name:
                      selectMaterials && selectMaterials.length > 0
                        ? { _in: selectMaterials }
                        : {},
                  },
                },
                collection_centre: {
                  name:
                    selectLocation && selectLocation.length > 0
                      ? { _in: selectLocation }
                      : {},
                },
              },
              {
                _or: [
                  {
                    user: {
                      name: searchLocName
                        ? { _ilike: `%${searchLocName}%` }
                        : {},
                    },
                  },
                  {
                    user: {
                      locality: searchLocName
                        ? { _ilike: `%${searchLocName}%` }
                        : {},
                    },
                  },
                  {
                    collection_centre: {
                      name: searchLocName
                        ? { _ilike: `%${searchLocName}%` }
                        : {},
                    },
                  },
                ],
              },
            ],
          }
        : {
            status: selectStatus ? { _eq: selectStatus } : {},
            user: {
              locality:
                selectUserLocality && selectUserLocality.length > 0
                  ? { _in: selectUserLocality }
                  : {},
            },
            sales_materials: {
              material: {
                name:
                  selectMaterials && selectMaterials.length > 0
                    ? { _in: selectMaterials }
                    : {},
              },
            },

            collection_centre: {
              name:
                selectLocation && selectLocation.length > 0
                  ? { _in: selectLocation }
                  : {},
            },
          }
    );

    if (selectMaterials && selectMaterials.length > 0)
      setSalesWhereTerm({ material: { name: { _in: selectMaterials } } });
  }, [
    selectLocation,
    selectUserLocality,
    selectStatus,
    selectMaterials,
    searchLocName,
    category,
  ]);

  useEffect(() => {
    setApartmentSearchTerm({
      locality:
        selectUserLocality && selectUserLocality.length > 0
          ? { _in: selectUserLocality }
          : { _in: userLocality },
    });
  }, [userLocality, selectUserLocality]);

  useEffect(() => {
    if (apartmentDetails) {
      let newList = ["Individual"];
      apartmentDetails?.apartments?.map((item) => {
        newList.push(item.name);
      });
      setApartmentList(newList);
    }
  }, [apartmentDetails]);

  useEffect(() => {
    if (salesDetails) {
      let newList = [];
      salesDetails.sales.map((sale) => {
        if (sale.status !== "Draft") {
          newList.push({
            // id: `SAL0000${sale.id}`,
            transactionID: sale.id,
            username: sale.user.name,
            profilePic: sale.user.details ? sale.user.details.image : null,
            location: sale.collection_centre.name,
            locationCentre: sale.collection_centre.locality,
            userLocality: sale.user.locality,
            status: sale.status,
            volume:
              sale.sales_materials_aggregate.aggregate.sum.actual_quantity,
            // volume:
            //   sale.sales_materials_aggregate.aggregate.sum.actual_quantity.toFixed(
            //     2
            //   ),
            amount: sale.total_price,
            created_at: sale.created_at,
            receipt: sale.receipt,
            apartment: sale.user.apartment
              ? sale.user.apartment.name
              : "Individual",
            details: `${moment(sale.created_at).format("DD MMM YYYY")}-${moment(
              sale.created_at
            ).format("hh:mma")}`,
          });
        }
      });
      if (daterange && selectApartment && selectApartment.length > 0) {
        let DisplayData = newList?.filter((ele) => {
          return (
            moment(ele.created_at).isBetween(daterange[0], daterange[1]) &&
            selectApartment.includes(ele.apartment)
          );
        });
        setTableData(DisplayData);
      } else if (daterange) {
        let DisplayData = newList?.filter((ele) => {
          const start = moment(daterange[0]).format("YYYY-MM-DDT00:00:00.000Z");
          const end = moment(daterange[1]).format("YYYY-MM-DDT23:59:59.000Z");
          return moment(ele.created_at).isBetween(start, end);
        });
        setTableData(DisplayData);
      } else if (selectApartment && selectApartment.length > 0) {
        let DisplayData = newList?.filter((ele) => {
          return selectApartment.includes(ele.apartment);
        });
        setTableData(DisplayData);
      } else {
        setTableData(newList);
      }
    }
  }, [salesDetails, daterange, selectApartment]);

  useEffect(() => {
    if (
      tableData &&
      tableData.length > 0 &&
      salesDetails &&
      salesDetails.users
    ) {
      let userList = [];
      let sum = 0;
      let approved = 0;
      let rejected = 0;
      let inReview = 0;
      // let UpdateInfo = 0;
      tableData.map((item) => {
        userList.push(item.username);
        sum += item.amount;
        if (item.status === "Approved") approved++;
        if (item.status === "Rejected") rejected++;
        if (item.status === "In review") inReview++;
        // if (item.status === "Update info") uploadInfo++;
      });
      let avgTransaction = salesDetails.users.length;
      setNoOfTransactions(tableData.length);
      setAvgTransaction((tableData.length / avgTransaction).toFixed(2));
      setAvgValue(sum / tableData.length);
      setApprovedTransaction(approved);
      setRejectedTransaction(rejected);
      setReviewTransaction(inReview);
      // setUploadTransaction(uploadInfo)
    } else {
      setNoOfTransactions(0);
      setAvgTransaction(0);
      setAvgValue(0);
      setApprovedTransaction(0);
      setRejectedTransaction(0);
      setReviewTransaction(0);
      // setUploadTransaction(0)
    }
  }, [tableData]);

  const columns = useMemo(
    () => [
      {
        title: "TRANSACTION ID",
        dataIndex: "transactionID",
        key: "transactionID",
        className: "w-48",
        ...getColumnSearchProps("id"),
        render: (text) => <>{`SAL${`${text}`.padStart(6, "0")}`}</>,
      },
      {
        title: "NAME",
        dataIndex: "username",
        key: "username",
        className: "w-40",
      },
      {
        title: "LOCATION",
        dataIndex: "location",
        key: "location",
        className: "w-40",
      },
      {
        title: "USER LOCALITY",
        dataIndex: "userLocality",
        key: "userLocality",
        className: "w-40",
      },
      {
        title: "STATUS",
        dataIndex: "status",
        key: "status",
        className: "w-40",
        render: (text) => {
          return (
            <>
              {/* {text === "New sale" && (
                <Tag color="#FEF3C7" textColor="#92400E">
                  New sale
                </Tag>
              )} */}
              {text === "In review" && (
                <Tag color="#F3F4F6" textColor="#1F2937">
                  In review
                </Tag>
              )}
              {text === "Approved" && (
                <Tag color="#D1FAE5" textColor="#065F46">
                  Approved
                </Tag>
              )}
              {text === "Rejected" && (
                <Tag color="#FEE2E2" textColor="#991B1B">
                  Rejected
                </Tag>
              )}
              {text === "Update info" && (
                <Tag color="#DBEAFE" textColor="#1E40AF">
                  Update info
                </Tag>
              )}
            </>
          );
        },
      },
      {
        title: "VOLUME(KG)",
        dataIndex: "volume",
        key: "volume",
        className: "w-40",
        sorter: (a, b) => a.volume - b.volume,
        render: (text) =>
          text ? (
            <span className="float-right">{numFormatter(text)} kg</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "AMOUNT(RS.)",
        dataIndex: "amount",
        key: "amount",
        className: "w-40",
        sorter: (a, b) => a.amount - b.amount,
        render: (text) =>
          text ? (
            <span className="float-right">
              Rs. {numFormatter(text.toFixed(2))}
            </span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "DETAILS",
        dataIndex: "details",
        key: "details",
        fixed: "right",
        className: "w-52",
        render: (text, record) => {
          return (
            <>
              <Button
                type="link"
                className="text-green hover:text-green"
                onClick={() => {
                  handleDrawer(record);
                }}
                id="details"
              >
                <span className="text-green">{text.split("-")[0]}</span>
                <span className="bg-green w-1.5 h-1.5 mx-2 align-middle inline-block rounded-md"></span>

                <span className="text-green">{text.split("-")[1]}</span>
              </Button>
            </>
          );
        },
      },
    ],
    []
  );

  const handleselectedValues = (value, type) => {
    if (type === "Location") {
      setSelectLocation(value);
    }
    if (type === "Apartment") {
      setSelectApartment(value);
      setSelectLocation(selectLocation);
    }
    if (type === "userLocation") {
      setSelectUserLocality(value);
    }
    if (type === "Materials") {
      setSelectMaterials(value);
    }
    if (type === "status") {
      setSelectStatus(value);
    }
  };

  const handleDate = (values) => {
    setDaterange(values);
  };

  const onChange = (key) => {
    setActiveTab(key);
  };

  const onClose = () => {
    getSalesData({
      variables: {
        where: searchTerm,
        salesWhere: salesWhereTerm,
      },
    });
    setOpenDrawer(false);
    setCurrentItem([]);
  };

  const handleDrawer = (record) => {
    let url = [];
    record.receipt.forEach(async (ele, index) => {
      let responseUrl = await getResponseUrl(ele);
      url[index] = responseUrl;
    });
    setInvoice(url);
    setOpenDrawer(true);
    setCurrentItem(record);
  };

  const getResponseUrl = (file) => {
    return new Promise((resolve) => {
      getSignedUrl({
        variables: {
          fileName: file,
        },
      }).then((res) => {
        resolve(res?.data?.get_signed_url?.url);
      });
    });
  };

  return (
    <Spin
      spinning={
        salesLoading ||
        apartmentDetailsLoading ||
        locationLoading ||
        salesMaterialLoading ||
        statsLoading
      }
    >
      <div className="sales flex flex-col gap-5 p-8 w-full justify-between ">
        <div className="flex flex-row gap-5 w-full ">
          <div style={{ width: "13%" }}>
            <Select
              placeholder="City"
              type="City"
              data={["Chennai"]}
              onChange={handleselectedValues}
              value="Chennai"
              // width="127px"
              allowClear={true}
            />
          </div>
          <div style={{ width: "15%" }}>
            <Select
              placeholder="Location"
              type="Location"
              mode="multiple"
              data={collectionName || []}
              onChange={handleselectedValues}
              value=""
              allowClear={true}
            />
          </div>
          <div style={{ width: "18%" }}>
            <Select
              placeholder="User locality"
              type="userLocation"
              mode="multiple"
              data={userLocality || []}
              onChange={handleselectedValues}
              value=""
              allowClear={true}
            />
          </div>
          <div style={{ width: "15%" }}>
            <Select
              placeholder="Apartment"
              type="Apartment"
              mode="multiple"
              data={apartmentList || []}
              onChange={handleselectedValues}
              value=""
              allowClear={true}
            />
          </div>
          <div style={{ width: "16%" }}>
            <Select
              placeholder="Materials"
              type="Materials"
              data={materiallist || []}
              onChange={handleselectedValues}
              value=""
              // width="270px"
              mode="multiple"
              allowClear={true}
            />
          </div>
          <div style={{ width: "12%" }}>
            <Select
              placeholder="Status"
              type="status"
              data={["In review", "Approved", "Rejected", "Update info"]}
              onChange={handleselectedValues}
              value=""
              // width="270px"
              allowClear={true}
            />
          </div>
          <div style={{ width: "26%" }}>
            <DatePicker
              placeholder="Date"
              separator="~"
              handleDate={handleDate}
            />
          </div>
        </div>
        <div className="grid grid-cols-6 ">
          <CountCard
            count={noOfTransaction}
            title="Total number of transactions"
            tab="sales"
            percentageValue=""
            className="CountCard"
          />
          <CountCard
            count={avgTransaction}
            title="Avg. transactions per user"
            tab="sales"
            percentageValue=""
            className="CountCard"
          />
          <CountCard
            count={avgValue}
            title="Avg value per transaction"
            tab="sales"
            percentageValue=""
            className="CountCard"
          />
          <CountCard
            count={approvedTransaction}
            title="Transactions approved"
            tab="sales"
            className="CountCard"
            percentageValue={
              approvedTransaction > 0
                ? (approvedTransaction / noOfTransaction) * 100
                : 0
            }
          />
          <CountCard
            count={reviewTransaction}
            title="Transactions in review"
            tab="sales"
            className="CountCard"
            percentageValue={
              reviewTransaction > 0
                ? (reviewTransaction / noOfTransaction) * 100
                : 0
            }
          />

          <CountCard
            count={rejectedTransaction}
            title="Transactions rejected"
            tab="sales"
            className="CountCard"
            percentageValue={
              rejectedTransaction > 0
                ? (rejectedTransaction / noOfTransaction) * 100
                : 0
            }
          />
          {/* <CountCard
            count={uploadTransaction}
            title="Transactions Update info"
            tab="sales"
            className="CountCard"
            percentageValue={
              uploadTransaction > 0
                ? (uploadTransaction / noOfTransaction) * 100
                : 0
            }
          /> */}
        </div>
        <div className="shadow-lg px-5 pt-5">
          <Tabs
            defaultActiveKey="1"
            onChange={onChange}
            activeKey={activeTab}
            tabBarExtraContent={{
              left: (
                <>
                  <span
                    className={`font-medium text-lg cursor-pointer text-green`}
                  >
                    Top Materials
                  </span>
                </>
              ),
            }}
          >
            <TabPane key="1" tab={<span className="textHeadings">Volume</span>}>
              <Spin spinning={topMaterialsLoading}>
                <SaleBarChart data={data} />
              </Spin>
            </TabPane>
            <TabPane
              tab={<span className="textHeadings">Average price</span>}
              key="2"
            >
              <Spin spinning={topMaterialsLoading}>
                <SaleBarChart data={data} />
              </Spin>
            </TabPane>
            <TabPane
              tab={<span className="textHeadings">Revenue</span>}
              key="3"
            >
              <Spin spinning={topMaterialsLoading}>
                <SaleBarChart data={data} />
              </Spin>
            </TabPane>
          </Tabs>
        </div>
        <div>
          <p className={`font-medium text-lg  text-green`}>Stats</p>
          <SaleLineChart data={linedata} />
        </div>
        <div className="w-full mt-8">
          <Table
            dataSource={!!tableData.length ? tableData : []}
            columns={columns}
            key="usersListing"
            pagination={true}
            scroll={{
              x: 1300,
            }}
          />
        </div>
        {openDrawer && (
          <SalesDetailsDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            onClose={onClose}
            invoice={invoice}
          />
        )}
      </div>
    </Spin>
  );
}

export default Sales;
