import { useState, useMemo, useEffect } from "react";
import { Button, Spin } from "antd";
import Select from "../../components/Select";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import Tag from "../../components/Tag";
import Table from "../../components/Table";
import { useNavigate, useLocation } from "react-router-dom";
import LocationDrawer from "./locationDrawer";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_SUGGESTED_LOCATIONS,
  GET_SUGGESTED_LOCATION_LIST,
} from "./graphql";
import moment from "moment";
import { getColumnSearchProps } from "../../utils";

function SuggestedLocations(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const [data, setData] = useState([]);
  const [locality, setLocality] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchTerm, setSearchTerm] = useState({});
  const [selectLocation, setselectLocation] = useState();
  const [collectionStatus, setCollectionStatus] = useState();
  const [searchLocName, setSearchLocName] = useState();
  const [category, setCategory] = useState();
  const [bounds, setBounds] = useState();

  const [
    getSuggestedLocation,
    { data: suggestedLocationDetails, loading: suggestedCollectionLoading },
  ] = useLazyQuery(GET_SUGGESTED_LOCATIONS, { fetchPolicy: "no-cache" });

  const { data: locationList, loading: locationLoading } = useQuery(
    GET_SUGGESTED_LOCATION_LIST
  );

  useEffect(() => {
    if (locationList) {
      let newList = [];
      locationList.collection_centre.map((item) => {
        newList.push(item.locality);
      });

      let list = newList.filter(
        (item, index) => newList.indexOf(item) === index
      );
      list.sort();
      setLocality(list);
    }
  }, [locationList]);

  useEffect(() => {
    if (searchTerm !== {})
      getSuggestedLocation({ variables: { where: searchTerm } });
  }, [searchTerm]);

  useEffect(() => {
    getSuggestedLocation({
      variables: {
        where: { user: { role_id: { _eq: 1 } } },
      },
    });
  }, []);

  useEffect(() => {
    setSearchTerm(
      new URLSearchParams(search).get("searchText") &&
        new URLSearchParams(search).get("category") === "Name"
        ? {
            locality: selectLocation
              ? { _in: selectLocation.length > 0 ? selectLocation : locality }
              : {},
            status: collectionStatus
              ? { _eq: collectionStatus.toLowerCase() }
              : {},
            user: { role_id: { _eq: 1 } },
            name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "Locality"
        ? {
            locality:
              selectLocation && searchLocName
                ? { _ilike: `%${searchLocName}%`, _in: selectLocation }
                : selectLocation
                ? { _in: selectLocation.length > 0 ? selectLocation : locality }
                : searchLocName
                ? { _ilike: `%${searchLocName}%` }
                : {},
            status: collectionStatus
              ? { _eq: collectionStatus.toLowerCase() }
              : {},
            user: { role_id: { _eq: 1 } },
            name: {},
          }
        : new URLSearchParams(search).get("searchText") &&
          new URLSearchParams(search).get("category") === "null"
        ? {
            locality:
              selectLocation && searchLocName
                ? { _ilike: `%${searchLocName}%`, _in: selectLocation }
                : selectLocation
                ? { _in: selectLocation.length > 0 ? selectLocation : locality }
                : searchLocName
                ? { _ilike: `%${searchLocName}%` }
                : {},
            status: collectionStatus
              ? { _eq: collectionStatus.toLowerCase() }
              : {},
            user: { role_id: { _eq: 1 } },
            name: searchLocName ? { _ilike: `%${searchLocName}%` } : {},
          }
        : {
            locality: selectLocation
              ? { _in: selectLocation.length > 0 ? selectLocation : locality }
              : {},
            status: collectionStatus
              ? { _eq: collectionStatus.toLowerCase() }
              : {},
            user: { role_id: { _eq: 1 } },
          }
    );
  }, [selectLocation, collectionStatus, searchLocName, category]);

  // useEffect(() => {
  //   setSearchTerm({
  //     locality: selectLocation
  //       ? { _in: selectLocation.length > 0 ? selectLocation : locality }
  //       : {},
  //     status: collectionStatus ? { _eq: collectionStatus.toLowerCase() } : {},
  //     user: { role_id: { _eq: 1 } },
  //   });
  // }, [selectLocation, collectionStatus, searchLocName, category]);

  useEffect(() => {
    setSearchLocName(new URLSearchParams(search).get("searchText"));
    setCategory(new URLSearchParams(search).get("category"));
  }, [location]);

  useEffect(() => {
    if (suggestedLocationDetails) {
      let bounds = new props.google.maps.LatLngBounds();
      let newArray = [];
      suggestedLocationDetails.collection_centre.map((item) => {
        newArray.push({
          id: item.id,
          locationID: `LOC0000${item.id}`,
          locationName: item.name,
          locality: item.locality,
          addedBy: item.user.name,
          addedOn: moment(item.created_at).format("DD MMM YYYY"),
          status: item.status,
          created_at: item.created_at,
          id: item.id,
          lat: item.lat,
          long: item.long,
        });
        if (item.lat && item.long) {
          bounds.extend({
            lat: parseFloat(item.lat),
            lng: parseFloat(item.long),
          });
        }
      });
      newArray.sort((a, b) => {
        return moment(b.created_at) - moment(a.created_at);
      });
      setBounds(bounds);
      setData(newArray);
    }
  }, [suggestedLocationDetails]);

  const handleselectedValues = (value, type) => {
    if (type === "Locality") setselectLocation(value);
    if (type === "Status") {
      setCollectionStatus(value);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "LOCATION ID",
        dataIndex: "locationID",
        key: "locationID",
        className: "w-40",
        ...getColumnSearchProps("locationID"),
      },
      {
        title: "LOCATION NAME",
        dataIndex: "locationName",
        key: "locationName",
      },
      {
        title: "LOCALITY",
        dataIndex: "locality",
        key: "locality",
      },
      {
        title: "ADDED BY",
        dataIndex: "addedBy",
        key: "addedBy",
      },
      {
        title: "ADDED ON",
        dataIndex: "addedOn",
        key: "addedOn",
      },
      {
        title: "STATUS",
        dataIndex: "status",
        key: "status",
        render: (text) => (
          <>
            {text === "disabled" && (
              <Tag color="#FEE2E2" textColor="#991B1B">
                Disabled
              </Tag>
            )}
            {text === "active" && (
              <Tag color="#D1FAE5" textColor="#065F46">
                Active
              </Tag>
            )}
            {text === "inactive" && (
              <Tag color="#FEF3C7" textColor="#92400E">
                Inactive
              </Tag>
            )}
            {text === "new" && (
              <Tag color="#DBEAFE" textColor="#1E40AF">
                New
              </Tag>
            )}
          </>
        ),
      },
      {
        title: "MANAGE",
        dataIndex: "",
        key: "",
        render: (text, record) => (
          <Button
            type="link"
            className="text-green hover:text-green pl-0"
            id="review"
            onClick={() =>
              navigate(
                `/locations/editLocations/about/${record.id}?back=suggestedLocations`
              )
            }
          >
            Review
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <Spin spinning={suggestedCollectionLoading}>
      <div className="flex flex-col gap-5 p-8 w-full justify-between suggestedLocations">
        <div className="flex gap-5">
          <div style={{ width: "13%" }}>
            <Select
              placeholder="City"
              type="City"
              data={["Chennai"]}
              onChange={handleselectedValues}
              value="Chennai"
              width="127px"
            />
          </div>
          <div style={{ width: "13%" }}>
            <Select
              placeholder="Locality"
              type="Locality"
              mode="multiple"
              data={locality || []}
              onChange={handleselectedValues}
              value=""
              width="160px"
              allowClear={true}
            />
          </div>
          <div style={{ width: "13%" }}>
            <Select
              placeholder="Status"
              type="Status"
              data={["New", "Active", "Inactive", "Disabled"]}
              onChange={handleselectedValues}
              value=""
              width="150px"
              allowClear={true}
            />
          </div>
          <Button
            type="primary"
            id="addlocations"
            size="large"
            onClick={() => navigate("/locations")}
            className="ml-auto bg-bgGray hoverCloseButton "
          >
            <span className="text-red">Close</span>
          </Button>
        </div>
        <div className="w-full min-h-36">
          <Map
            google={props.google}
            style={{ height: "35vh", maxWidth: "100%" }}
            containerStyle={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
            bounds={bounds}
          >
            {data &&
              data.length > 0 &&
              data.map((location, locationIndex) => {
                return (
                  <Marker
                    key={locationIndex}
                    name={location.locationName}
                    title={location.locationName}
                    id={location.id}
                    data={location}
                    position={
                      location.lat && location.long
                        ? {
                            lat: parseFloat(location.lat),
                            lng: parseFloat(location.long),
                          }
                        : {}
                    }
                  />
                );
              })}
          </Map>
        </div>
        <div className="w-full">
          <Table
            dataSource={!!data.length ? data : []}
            columns={columns}
            key="suggestedlocationListing"
            pagination={true}
          />
        </div>
        {openDrawer && (
          <LocationDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
          />
        )}
      </div>
    </Spin>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_API_KEY,
})(SuggestedLocations);
