import React, { useState, useMemo, useEffect, useRef, useContext } from "react";
import Select from "../../../components/Select";
import Table from "../../../components/Table";
import { getColumnSearchProps, numFormatter } from "../../../utils";
import Tag from "../../../components/Tag";
import { GET_USER_REWARD } from "../graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { Button, Input, Space, Spin } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import TitleHeadingContext from "../../../context/TitleHeadingContext";
import FinancialDrawer from "../../rewards/addRewards/financialDrawer";
import moment from "moment";

function CouponDetails() {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [couponStatus, setCouponStatus] = useState();
  const [searchValue, setSearchValue] = useState({});
  const [locality, setLocality] = useState([]);
  const [selectLocation, setselectLocation] = useState([]);
  const [currentItem, setCurrentItem] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const titleHeading = useContext(TitleHeadingContext);

  const { data: localityList, loading: localityLoading } = useQuery(
    GET_USER_REWARD,
    { variables: { id: parseInt(location.pathname.split("/")[4]) || 0 } }
  );

  const [getCouponDetails, { data: couponDetails, loading }] = useLazyQuery(
    GET_USER_REWARD,
    { fetchPolicy: "no-cache" }
  );

  useEffect(() => {
    getCouponDetails({
      variables: {
        id: parseInt(location.pathname.split("/")[4]) || 0,
        where: {
          _and: {
            user_id: { _eq: parseInt(location.pathname.split("/")[4]) },
          },
        },
      },
    });
  }, []);

  useEffect(() => {
    getCouponDetails({
      variables: {
        where: searchValue,
        id: parseInt(location.pathname.split("/")[4]) || 0,
      },
    });
  }, [searchValue]);

  useEffect(() => {
    if (localityList) {
      let localityArray = [];

      localityList.user_reward.map((item) => {
        if (
          item.status === "Paid" ||
          item.status === "Redeemed" ||
          item.status === "Approved"
        )
          localityArray.push(item.reward.location);
      });
      let list = localityArray.filter(
        (item, index) => localityArray.indexOf(item) === index
      );
      list.sort();
      setLocality(list);
    }
  }, [localityList]);

  useEffect(() => {
    if (couponDetails) {
      let newList = [];
      titleHeading.setTitle(couponDetails.users[0].name);
      couponDetails.user_reward.map((item) => {
        if (
          item.status === "Paid" ||
          item.status === "Redeemed" ||
          item.status === "Approved"
        )
          // newList.push({
          //   campaignDetails: item.reward.name,
          //   locality: item.reward.location,
          //   status: item.status,
          //   amount: parseInt(item.reward.details.couponsCost),
          // });

          newList.push({
            campaignID: item.reward_id,
            username: item.user.name,
            logo: item.reward.vendor.details.logo,
            vendorName: item.reward.vendor.name,
            vendorStatus: item.reward.vendor.status,
            created_at: item.reward.vendor.created_at,
            campaignDetails: item.reward.name,
            locality: item.reward.location,
            status: item.status,
            couponStatus: item.status,
            couponCode: item.code,
            dueDate: moment(item.reward.details.expiryDate).format(
              "DD MMM YYYY"
            ),
            paidTimestamp: item.payment_at,
            paymentTransactionID: item.razorpay_transaction_id,
            redeemTimestamp: item.redeemed_at,
            redeemTransactionID: `RDM0000${item.id}`,
            amount: item.reward.details.vendorCost,
            vendorTransactionID:
              item.transaction_id !== null
                ? item.transaction.payment_details.transactionID
                : null,
            vendorPaymentDate:
              item.transaction_id !== null ? item.transaction.created_at : null,
            payment:
              item.status === "Paid"
                ? "-"
                : item.transaction_id !== null
                ? "cleared"
                : "outstanding",
            date:
              item.status === "Paid"
                ? "-"
                : item.transaction_id !== null
                ? moment(item.transaction.created_at).format("DD MMM YYYY")
                : "-",
          });
      });
      if (selectLocation && selectLocation.length > 0) {
        let DisplayData = newList?.filter((ele) => {
          return selectLocation.includes(ele.locality);
        });
        setData(DisplayData);
      } else {
        setData(newList);
      }
    }
  }, [couponDetails, selectLocation]);

  useEffect(() => {
    setSearchValue({
      _and: {
        user_id: { _eq: parseInt(location.pathname.split("/")[4]) },
        status: couponStatus ? { _eq: couponStatus } : {},
      },
    });
  }, [couponStatus]);

  const handleselectedValues = (value, type) => {
    if (type === "Locality") {
      setselectLocation(value);
    } else {
      setselectLocation();
    }
    if (type === "Status") {
      setCouponStatus(value);
    }
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const columns = useMemo(
    () => [
      {
        title: "CAMPAIGN ID",
        dataIndex: "campaignID",
        key: "campaignID",
        className: "w-40",
        ...getColumnSearchProps("campaignID"),
        render: (text) => <>{`CAM${`${text}`.padStart(6, "0")}`}</>,
      },
      {
        title: "CAMPAIGN DETAILS",
        dataIndex: "campaignDetails",
        key: "campaignDetails",
        ...getColumnSearchProps("campaignDetails"),
      },
      {
        title: "LOCALITY",
        dataIndex: "locality",
        key: "locality",
      },
      {
        title: "COUPON STATUS",
        dataIndex: "status",
        key: "status",
        render: (text) => {
          return (
            <>
              {text === "Paid" && (
                <Tag color="#D1FAE5" textColor="#065F46">
                  Paid
                </Tag>
              )}
              {(text === "Redeemed" || text === "Approved") && (
                <Tag color="#FEF3C7" textColor="#92400E">
                  Redeemed
                </Tag>
              )}
            </>
          );
        },
      },
      {
        title: "AMOUNT",
        dataIndex: "amount",
        key: "amount",
        sorter: (a, b) => a.amount - b.amount,
        render: (text) =>
          text ? (
            <>
              <span className="float-right">Rs. {numFormatter(text)} </span>
            </>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "DETAILS",
        dataIndex: "details",
        key: "details",
        render: (text, record) => (
          <Button
            type="link"
            className="text-green hover:text-green"
            onClick={() => {
              setOpenDrawer(true);
              setCurrentItem(record);
            }}
            id="details"
          >
            Details
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <Spin spinning={loading || localityLoading}>
      <div className="flex flex-col gap-5 p-8 w-full justify-between ">
        <div className="flex gap-5">
          <div style={{ width: "20%" }}>
            <Select
              placeholder="Locality"
              type="Locality"
              mode="multiple"
              data={locality || []}
              onChange={handleselectedValues}
              value=""
              allowClear={true}
            />
          </div>
          <div style={{ width: "13%" }}>
            <Select
              placeholder="Status"
              type="Status"
              data={["Paid", "Redeemed"]}
              onChange={handleselectedValues}
              value=""
              // width="270px"
              allowClear={true}
            />
          </div>
        </div>
        <div className="w-full">
          <Table
            dataSource={!!data.length ? data : []}
            columns={columns}
            key="transactionsListing"
            pagination={true}
            // locale={{ emptyText: "No transactions found" }}
          />
        </div>
        {openDrawer && (
          <FinancialDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleClose={handleClose}
            currentItem={currentItem}
          />
        )}
      </div>
    </Spin>
  );
}

export default CouponDetails;
