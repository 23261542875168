import {
  Drawer,
  Divider,
  Button,
  Spin,
  Avatar,
  Tabs,
  Dropdown,
  Form,
  List,
  Input,
  message,
} from "antd";
import closeIcon from "../../assets/closeIcon.svg";
import { DownOutlined } from "@ant-design/icons";
import UserOutlined from "../../assets/UserOutlined.svg";
import allTransactions from "../../assets/allTransactions.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_SIGNED_URL,
  UPDATE_USER_STATUS,
  GET_USER_STATUS,
  GET_NOTIFICATION_MESSAGES,
  DELETE_IN_FIREBASE,
  INSERT_NOTIFICATION,
  INSERT_NOTIFICATION_USER,
  SEND_PUSH_NOTIFICATIONS,
} from "./graphql";
import { capitalizeFirst } from "../../utils";
import { useState, useEffect } from "react";

const { TabPane } = Tabs;

function UserDetailsDrawer(props) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(
    props.activeTab ? props.activeTab : "1"
  );
  const [getSignedUrl] = useMutation(GET_SIGNED_URL);
  const [updateStatus] = useMutation(UPDATE_USER_STATUS);
  const [insertNotificationUser] = useMutation(INSERT_NOTIFICATION_USER);
  const [insertNotification] = useMutation(INSERT_NOTIFICATION);
  const [sendPushNotifications] = useMutation(SEND_PUSH_NOTIFICATIONS);
  const [deleteInFirebase] = useMutation(DELETE_IN_FIREBASE);
  const { data: notificationData, loading: notificationLoading } = useQuery(
    GET_NOTIFICATION_MESSAGES,
    {
      variables: {
        id: props.currentItem.id,
      },
    }
  );
  const {
    data: userData,
    loading: loadingStatus,
    refetch,
  } = useQuery(GET_USER_STATUS, {
    variables: {
      id: props.currentItem.id,
      startTime: moment().startOf("year"),
      endTime: moment().endOf("year"),
    },
  });
  const [avatar, setAvatar] = useState();
  const [textValue, setTextValue] = useState();
  const [listData, setListData] = useState([]);
  const [oldNumber, setOldnumber] = useState();

  useEffect(() => {
    if (props) {
      getCases(props.currentItem);
    }
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (notificationData) {
      const Listdata = [];
      // eslint-disable-next-line
      notificationData.notification_user.map((item) => {
        Listdata.push({
          title: item.notification.created_at,
          description: item.notification.text,
        });
      });
      setListData(Listdata);
    }
  }, [notificationData]);

  useEffect(() => {
    if (userData) {
      let newList = [];
      // eslint-disable-next-line
      userData.users.map((item) => {
        let salesVolume = 0;
        let salesPrice = 0;
        let savings = 0;
        let dateCheck = true;
        let couponVal = 0;
        let rewardPoints = 0;
        let redeemedPoints = 0;
        if (item.sales.length > 0) {
          // eslint-disable-next-line
          item.sales.map((ele) => {
            if (ele.status === "Approved") {
              salesVolume +=
                ele.sales_materials_aggregate.aggregate.sum.actual_quantity;
              salesPrice += ele.total_price;
              rewardPoints += parseInt(ele.points);
            }
          });
          dateCheck = moment(item.sales[0].created_at).isBetween(
            moment().subtract(4, "week"),
            moment()
          );
        }
        if (item.user_rewards.length > 0) {
          // eslint-disable-next-line
          item.user_rewards.map((ele) => {
            // if (ele.details && ele.reward) {
            couponVal += parseInt(ele.reward.details.couponValue);

            ele.redeemed_points
              ? (redeemedPoints += parseInt(ele.redeemed_points))
              : (redeemedPoints += 0);
            savings +=
              (parseInt(ele.reward.details.couponValue) -
                parseInt(ele.reward.details.couponsCost)) *
              ele.reward.total_used_number;
            // }
          });
        }
        newList.push({
          id: item.id,
          username: item.name ? capitalizeFirst(item.name) : "",
          locality: item.locality ? item.locality : "-",
          details: item.details,
          email: item.email,
          status:
            item.status === "Deactivated"
              ? item.status
              : dateCheck === false
              ? "Inactive"
              : "Active",
          phone: item.phone,
          apartment: item.apartment ? item.apartment.name : "Individual",
          noOfTransaction: item.sales_aggregate.aggregate.count,
          volume: salesVolume,
          value: salesPrice,
          avgValue:
            salesVolume === 0
              ? 0
              : (salesVolume / item.sales_aggregate.aggregate.count).toFixed(2),
          noOfTicketsRaised: "-",
          noOfTransactionsMonth: Math.ceil(
            item.transactionsCount.aggregate.count / 12
          ),
          noOfCouponsPurchased: item.user_rewards_aggregate.aggregate.count,
          valueOfCouponsPurchased: couponVal,
          totalSavings: savings,
          noOfrewardPoints: rewardPoints,
          noOfredeemedPoints: redeemedPoints,
          lastSaleOn:
            item.sales.length > 0
              ? moment(item.sales[0].created_at).format("DD MMM YYYY")
              : "-",
          created_at: item.created_at,
        });
      });

      setData(newList);
      setOldnumber(userData.users[0].phone);
      setStatus(userData.users[0].status);
    }
  }, [userData]);

  const getCases = async (props) => {
    setLoading(true);
    if (props.details && props.details?.image) {
      let responseUrl = await getResponseUrl(props.details?.image);
      setAvatar(responseUrl);
    }
    setLoading(false);
  };

  const getResponseUrl = (file) => {
    return new Promise((resolve) => {
      getSignedUrl({
        variables: {
          fileName: file,
        },
      }).then((res) => {
        resolve(res?.data?.get_signed_url?.url);
      });
    });
  };

  const onClose = () => {
    props.setOpenDrawer(false);
  };

  const items = [
    {
      label: "Deactivate account",
      key: "Deactivate account",
      disabled: status === "Deactivated" ? true : false,
    },
    {
      label: <a href={`mailto:${props.currentItem.email}`}>Email user</a>,
      key: "Email user",
      disabled: props.currentItem.email ? false : true,
    },
  ];

  const handleMenuClick = (item) => {
    if (item.key === "Deactivate account") {
      let obj = {
        status: "Deactivated",
        name: "Deleted user",
        phone: null,
        email: null,
        auth_id: null,
      };
      deleteInFirebase({
        variables: {
          phone: oldNumber,
        },
      }).then(() => {
        updateStatus({
          variables: {
            id: props.currentItem.id,
            object: obj,
          },
        }).then(() => window.location.reload());
      });
    }
    // else if (item.key === "Activate account") {
    //   updateStatus({
    //     variables: {
    //       id: props.currentItem.id,
    //       status: "Active",
    //     },
    //   }).then(() => refetch());
    // }
  };

  const onFinish = (values) => {
    let userID = [];
    insertNotification({
      variables: {
        object: {
          text: values.message,
          is_broadcast: false,
        },
      },
    }).then((res) => {
      let payload = {
        notification_id: res?.data?.insert_notifications?.returning[0]?.id,
        user_id: props.currentItem.id,
      };

      insertNotificationUser({
        variables: {
          object: payload,
        },
      }).then(() => {
        userID.push(props.currentItem.id);
        let pushObj = {
          id: moment().valueOf(),
          title: "You have a new message from Kabadiwalla Connect",
          body: values.message,
          type: "notification",
        };
        sendPushNotifications({
          variables: {
            message: pushObj,
            title: "You have a new message from Kabadiwalla Connect",
            user_ids: userID,
          },
        }).then(() => {
          message.open({
            type: "success",
            content: "Message broadcasted successfully",
          });
          window.location.reload();
        });
      });
    });
  };

  return (
    <Spin spinning={loading || loadingStatus || notificationLoading}>
      <Drawer
        width={400}
        placement="right"
        closable={false}
        onClose={onClose}
        // maskClosable={false}
        open={props.openDrawer}
        rootStyle={{}}
      >
        {data && data.length > 0 && (
          <>
            <div className="mb-4">
              <div className="grid grid-cols-3 gap-5">
                <span onClick={onClose} className="cursor-pointer">
                  <img src={closeIcon} alt="closeIcon" />
                </span>
                <div className="flex flex-col">
                  <p>
                    {avatar ? (
                      <Avatar
                        src={avatar}
                        size="large"
                        style={{ width: "110px", height: "110px" }}
                      />
                    ) : (
                      <Avatar
                        className=" bg-indigo"
                        style={{ width: "110px", height: "110px" }}
                        size="large"
                      >
                        <img src={UserOutlined} alt="useroutlined" />
                      </Avatar>
                    )}
                  </p>
                </div>
                <div>
                  <Dropdown
                    menu={{
                      items,
                      onClick: handleMenuClick,
                    }}
                  >
                    <Button className="w-11 h-9 float-right">
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
              <div>
                <p className="text-base text-green text-center mb-0 font-semibold">
                  {data[0].username}
                </p>
                <p className="text-sm text-gray text-center font-semibold">
                  {data[0].status}{" "}
                  <span className="bg-gray w-1.5 h-1.5  align-middle inline-block rounded-md"></span>{" "}
                  Recycling champion since{" "}
                  {status === "Deactivated"
                    ? moment(data[0].updated_at).format("MMM DD, YYYY")
                    : moment(data[0].created_at).format("MMM DD, YYYY")}
                </p>
              </div>
            </div>
            <div>
              <p className="h-7">
                <span className="text-base font-semibold float-left opacity-50">
                  About User
                </span>
              </p>
              <p>
                <span className="text-sm font-semibold align-middle">
                  Locality
                </span>
                <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                <span className="align-middle font-normal text-sm">
                  {data[0].locality}
                </span>
              </p>
              <p>
                <span className="text-sm font-semibold align-middle">
                  Mobile
                </span>
                <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                <span className="align-middle font-normal text-sm">
                  {data[0].phone}
                </span>
              </p>
              <p>
                {data[0].lastSaleOn && data[0].lastSaleOn !== "-" ? (
                  <>
                    <span className="text-sm font-semibold align-middle">
                      Last sale
                    </span>
                    <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>
                    <span className="align-middle font-normal text-sm">
                      {data[0].lastSaleOn}
                    </span>
                  </>
                ) : null}
              </p>
            </div>
            <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
              <TabPane tab="Summary" key="1">
                <div>
                  <p className="h-7">
                    <span className="text-base font-semibold float-left opacity-50">
                      Recycling summary
                    </span>
                  </p>
                  <p>
                    <span className="text-sm font-semibold align-middle">
                      Number of sales
                    </span>
                    <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                    <span className="align-middle font-normal text-sm">
                      {data[0].noOfTransaction}
                    </span>
                  </p>
                  <p>
                    <span className="text-sm font-semibold align-middle">
                      Total volume sold
                    </span>
                    <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                    <span className="align-middle font-normal text-sm">
                      {data[0].volume}
                    </span>
                  </p>
                  <p>
                    <span className="text-sm font-semibold align-middle">
                      Total Value Generated
                    </span>
                    <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                    <span className="align-middle font-normal text-sm">
                      INR {data[0].value}
                    </span>
                  </p>
                  <Divider />
                  <div>
                    <p className="h-7">
                      <span className="text-base font-semibold float-left opacity-50">
                        Rewards Summary
                      </span>
                    </p>
                    <p>
                      <span className="text-sm font-semibold align-middle">
                        Total points earned
                      </span>
                      <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                      <span className="align-middle font-normal text-sm">
                        {data[0].noOfrewardPoints}
                      </span>
                    </p>
                    <p>
                      <span className="text-sm font-semibold align-middle">
                        Total points redeemed
                      </span>
                      <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                      <span className="align-middle font-normal text-sm">
                        {data[0].noOfredeemedPoints}
                      </span>
                    </p>
                    <p>
                      <span className="text-sm font-semibold align-middle">
                        Total coupon purchase value
                      </span>
                      <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                      <span className="align-middle font-normal text-sm">
                        INR {data[0].valueOfCouponsPurchased}
                      </span>
                    </p>
                    <Button
                      type="link"
                      className="mt-3 p-0 w-full text-left"
                      onClick={() =>
                        navigate(
                          `/users/viewUser/sales/${props.currentItem.id}`
                        )
                      }
                    >
                      <img src={allTransactions} alt="allTransactions" />
                      <span className="text-sm font-semibold align-middle text-green ml-3">
                        View all sale details
                      </span>
                    </Button>
                    <Button
                      type="link"
                      className="mt-3 p-0 w-full text-left"
                      onClick={() =>
                        navigate(
                          `/users/viewUser/coupons/${props.currentItem.id}`
                        )
                      }
                    >
                      <img src={allTransactions} alt="allTransactions" />
                      <span className="text-sm font-semibold align-middle text-green ml-3">
                        View all coupon details
                      </span>
                    </Button>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Messages" key="2">
                <Form
                  name="broadcastUser"
                  onFinish={onFinish}
                  layout="vertical"
                  form={form}
                  requiredMark={false}
                >
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label={
                      <>
                        <span>Compose new message</span>
                        <Button
                          type="link"
                          className="float-right text-green pr-0"
                          onClick={() => {
                            if (form.getFieldValue("message")) {
                              form.setFieldsValue({
                                message: "",
                              });
                            }
                          }}
                          disabled={textValue ? false : true}
                        >
                          Clear
                        </Button>
                      </>
                    }
                    name="message"
                  >
                    <Input.TextArea
                      onChange={(e) => {
                        setTextValue(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="Submit"
                      size="large"
                      className="w-full"
                      disabled={textValue ? false : true}
                    >
                      Send
                    </Button>
                  </Form.Item>
                </Form>
                <Form name="messages" layout="vertical">
                  {listData.length > 0 ? (
                    <Form.Item label={<span>Previous message</span>}>
                      <List
                        className="demo-loadmore-list"
                        itemLayout="vertical"
                        dataSource={listData}
                        renderItem={(item, index) => (
                          <List.Item style={{ paddingLeft: 0 }}>
                            <List.Item.Meta
                              description={
                                <p className="mb-1">
                                  <span className="text-sm font-semibold align-middle">
                                    {moment(item.title).format("DD MMM YYYY")}
                                  </span>
                                  <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

                                  <span className="align-middle font-normal text-sm">
                                    {moment(item.title).format("hh:mm a")}
                                  </span>
                                </p>
                              }
                              style={{ marginBottom: 0 }}
                            />

                            <p className="mb-1">{item.description}</p>
                          </List.Item>
                        )}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item>
                      <div className="flex flex-col justify-center items-center py-4">
                        <p className="text-lg-l5-semibold text-gray-500 mt-4">
                          There is no messages added yet
                        </p>
                      </div>
                    </Form.Item>
                  )}
                </Form>
              </TabPane>
            </Tabs>
          </>
        )}
      </Drawer>
    </Spin>
  );
}

export default UserDetailsDrawer;
