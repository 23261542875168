import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import Logo from "../../assets/logo.svg";
import { Spin } from "antd";
import { GET_CENTRE_TRANSACTION } from "./graphql";
import { useQuery } from "@apollo/client";
import { data } from "./list";

const MenuItem = ({ icon, title, className, href, isDisabled }) => {
  return (
    <NavLink
      to={href || "/"}
      className={(props) =>
        `flex items-center no-underline ${
          props.isActive ? "text-green active" : "text-charcoal"
        }  ${
          isDisabled
            ? "hover:text-accentGray pointer-events-none text-accentGray isdisabled"
            : "hover:text-green cursor-pointer"
        } menuItem mb-9 ${className || ""}`
      }
    >
      <div>{icon}</div>
      <div className="ml-3 text-sm ">{title}</div>
    </NavLink>
  );
};
export default function Sidebar() {
  const location = useLocation();
  const search = useLocation().search;
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [enable, setEnable] = useState(false);
  const {
    data: centreStatus,
    loading,
    refetch,
  } = useQuery(GET_CENTRE_TRANSACTION, {
    variables: {
      id: parseInt(location.pathname.split("/")[4]),
    },
  });

  const tabNames = [
    "dashboard",
    "locations",
    "users",
    "sales",
    "rewards",
    "apartments",
    "globalMaterials",
    "feedback",
    "suggestedLocations",
  ];

  useEffect(() => {
    refetch();
    if (location.pathname.split("/")[2] === "viewUser") {
      setDataList(data.viewUser);
    } else if (location.pathname.split("/")[2] === "addRewards") {
      setDataList(data.vendor);
    } else if (location.pathname.split("/")[2] === "editRewards") {
      setDataList(data.editVendor);
    } else if (
      location.pathname.split("/")[2] === "addLocations" ||
      new URLSearchParams(search).get("back")
    ) {
      setDataList(data.addlocations);
    } else if (
      location.pathname.split("/")[2] === "editLocations" &&
      !new URLSearchParams(search).get("back")
    ) {
      setDataList(data.editlocations);
    } else if (location.pathname.split("/")[2] === "drawer") {
      setDataList(data.MainScreen);
    } else if (location.pathname.split("/")[2] === "addApartment") {
      setDataList(data.addApartment);
    } else if (location.pathname.split("/")[2] === "editApartment") {
      setDataList(data.editApartment);
    } else {
      setDataList([]);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (centreStatus && location.pathname.split("/")[1] === "locations") {
      if (centreStatus?.collection_centre[0]?.status === "new") {
        setDataList(data.addlocations);
      }
      if (
        !centreStatus.collection_centre[0].lat &&
        !centreStatus.collection_centre[0].long &&
        !centreStatus.collection_centre[0].locality
      ) {
        setEnable(true);
      } else {
        setEnable(false);
      }
    }

    // eslint-disable-next-line
  }, [centreStatus]);

  return (
    <Spin spinning={loading}>
      <div className="w-full sticky">
        {tabNames.includes(location.pathname.split("/")[1]) &&
          !location.pathname.split("/")[2] && (
            <div className="sticky top-0  pt-4 px-3">
              <img
                src={Logo}
                alt="Logo"
                style={{ width: "200px" }}
                className="px-5 pt-4 pb-2.5"
              />
              <div className="pt-8 px-5">
                {data.MainScreen.map((item) => {
                  return (
                    <MenuItem
                      icon={item.icon}
                      title={item.title}
                      className={item.className}
                      href={item.href}
                    />
                  );
                })}
              </div>
            </div>
          )}
        {location.pathname.split("/")[1] === "vendor" && (
          <div className="sticky top-0  pt-4 px-3">
            <img
              src={Logo}
              alt="Logo"
              style={{ width: "200px" }}
              className="px-5 pt-4 pb-2.5"
            />
            <div className="pt-8 px-5">
              {data.vendorScreen.map((item) => {
                return (
                  <MenuItem
                    icon={item.icon}
                    title={item.title}
                    className={item.className}
                    href={item.href}
                  />
                );
              })}
            </div>
          </div>
        )}
        {dataList.length > 0 && (
          <div className="sticky top-0  pt-4 px-3">
            <p
              className="pt-4 px-5 mb-0 cursor-pointer w-32"
              onClick={() => {
                new URLSearchParams(search).get("back")
                  ? navigate(`/${new URLSearchParams(search).get("back")}`)
                  : navigate(`/${location.pathname.split("/")[1]}`);
              }}
            >
              <LeftOutlined
                style={{
                  color: "#000000",
                  strokeWidth: "100", // --> higher value === more thickness the filled area
                  stroke: "black",
                }}
              />
              <span className="ml-5 align-middle text-lg">Back</span>
            </p>
            <div className="pt-8 px-5">
              {dataList.map((item) => {
                return (
                  <>
                    <MenuItem
                      icon={item.icon}
                      title={item.title}
                      className={item.className}
                      href={
                        location.pathname.split("/")[2] === "addLocations" ||
                        location.pathname.split("/")[2] === "editLocations"
                          ? `${`/locations/${location.pathname.split("/")[2]}${
                              item.href
                            }${
                              location.pathname.split("/")[4] !== undefined
                                ? `/${location.pathname.split("/")[4]}`
                                : ""
                            }`}${
                              new URLSearchParams(search).get("back")
                                ? "?back=suggestedLocations"
                                : ""
                            }`
                          : `${item.href}${
                              location.pathname.split("/")[4] !== undefined
                                ? `/${location.pathname.split("/")[4]}`
                                : ""
                            }`
                      }
                      isDisabled={
                        location.pathname.split("/")[4] === undefined &&
                        item.title !== "Vendor details" &&
                        item.title !== "About" &&
                        item.title !== "Apartment details"
                          ? true
                          : enable && item.disable
                          ? true
                          : false
                      }
                    />
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
}
