import React, { useState, useMemo, useEffect, useRef, useContext } from "react";
import Select from "../../../components/Select";
import Table from "../../../components/Table";
import DatePicker from "../../../components/DatePicker";
import Tag from "../../../components/Tag";
import { useLocation } from "react-router-dom";
import { Button, Input, Space, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { GET_SALES_DETAILS, GET_SIGNED_URL } from "../graphql";
import _ from "lodash";
import moment from "moment";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import {
  capitalizeFirst,
  numFormatter,
  getColumnSearchProps,
} from "../../../utils";
import Highlighter from "react-highlight-words";
import TitleHeadingContext from "../../../context/TitleHeadingContext";
import SalesDetailsDrawer from "../../sales/SalesDetailDrawer";

function SalesDetails() {
  const location = useLocation();
  const search = useLocation().search;
  const [saleStatus, setSaleStatus] = useState();
  const [dateRange, setDaterange] = useState();
  const [searchValue, setSearchValue] = useState({});
  const titleHeading = useContext(TitleHeadingContext);
  const [data, setData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentItem, setCurrentItem] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [material, setMaterial] = useState([]);
  const [invoice, setInvoice] = useState([]);

  const [getSignedUrl] = useMutation(GET_SIGNED_URL);
  const [getSalesDetails, { data: salesDetails, loading }] = useLazyQuery(
    GET_SALES_DETAILS,
    { fetchPolicy: "no-cache" }
  );

  const { data: saleListData } = useQuery(GET_SALES_DETAILS, {
    variables: {
      where: { user_id: { _eq: parseInt(location.pathname.split("/")[4]) } },
      id: parseInt(location.pathname.split("/")[4]),
    },
  });

  useEffect(() => {
    if (saleListData) {
      let materialArray = [];
      saleListData.sales.map((item) => {
        let salesList = [];
        item.sales_materials.map((ele) => {
          if (!materialArray.includes(ele.material.name))
            materialArray.push(ele.material.name);
          salesList.push(ele.material.name);
        });
      });
      setMaterialList(materialArray);
    }
  }, [saleListData]);

  useEffect(() => {
    getSalesDetails({
      variables: {
        id: parseInt(location.pathname.split("/")[4]) || 0,
        where: {
          _and: {
            user_id: { _eq: parseInt(location.pathname.split("/")[4]) },
          },
        },
      },
    });
  }, []);

  useEffect(() => {
    getSalesDetails({
      variables: {
        where: searchValue,
        id: parseInt(location.pathname.split("/")[4]) || 0,
      },
    });
  }, [searchValue]);

  useEffect(() => {
    setSearchValue({
      _and: {
        user_id: { _eq: parseInt(location.pathname.split("/")[4]) },
        status: saleStatus ? { _eq: saleStatus } : {},
        created_at: dateRange ? { _gte: dateRange[0], _lte: dateRange[1] } : {},
      },
    });
  }, [saleStatus, dateRange]);

  useEffect(() => {
    if (salesDetails) {
      let newList = [];
      titleHeading.setTitle(salesDetails.users[0].name);
      salesDetails.sales.map((sale) => {
        newList.push({
          transactionID: sale.id,
          username: salesDetails.users[0].name,
          profilePic: salesDetails.users[0].details
            ? salesDetails.users[0].details.image
            : null,
          userLocality: salesDetails.users[0].locality,
          locationName: capitalizeFirst(sale.collection_centre.name),
          locality: sale.collection_centre.locality,
          location: sale.collection_centre.name,
          locationCentre: sale.collection_centre.locality,
          status: sale.status,
          receipt: sale.receipt,
          volume: sale.sales_materials_aggregate.aggregate.sum.actual_quantity,
          revenue: sale.total_price,
          created_at: sale.created_at,
          details: `${moment(sale.created_at).format("DD MMM YYYY")}-${moment(
            sale.created_at
          ).format("hh:mma")}`,
        });
      });
      setData(newList);
    }
  }, [salesDetails]);

  const onClose = () => {
    setOpenDrawer(false);
    setCurrentItem([]);
  };

  const handleDrawer = (record) => {
    let url = [];
    record.receipt.forEach(async (ele, index) => {
      let responseUrl = await getResponseUrl(ele);
      url[index] = responseUrl;
    });
    setInvoice(url);
    setOpenDrawer(true);
    setCurrentItem(record);
  };

  const getResponseUrl = (file) => {
    return new Promise((resolve) => {
      getSignedUrl({
        variables: {
          fileName: file,
        },
      }).then((res) => {
        resolve(res?.data?.get_signed_url?.url);
      });
    });
  };

  const handleselectedValues = (value, type) => {
    if (type === "Status") {
      setSaleStatus(value);
    }
    if (type === "Materials") setMaterial(value);
  };

  const handleDate = (values) => {
    setDaterange(values);
  };

  const columns = useMemo(
    () => [
      {
        title: "TRANSACTION ID",
        dataIndex: "transactionID",
        key: "transactionID",
        className: "w-48",
        ...getColumnSearchProps("id"),
        render: (text) => <>{`SAL${`${text}`.padStart(6, "0")}`}</>,
      },
      {
        title: "LOCATION NAME",
        dataIndex: "locationName",
        key: "locationName",
        ...getColumnSearchProps("locationName"),
      },
      {
        title: "LOCALITY",
        dataIndex: "locality",
        key: "locality",
        ...getColumnSearchProps("locality"),
      },
      {
        title: "STATUS",
        dataIndex: "status",
        key: "status",
        render: (text) => {
          return (
            <>
              {text === "In review" && (
                <Tag color="#F3F4F6" textColor="#1F2937">
                  In review
                </Tag>
              )}
              {text === "Approved" && (
                <Tag color="#D1FAE5" textColor="#065F46">
                  Approved
                </Tag>
              )}
              {text === "Rejected" && (
                <Tag color="#FEE2E2" textColor="#991B1B">
                  Rejected
                </Tag>
              )}
              {text === "Update info" && (
                <Tag color="#DBEAFE" textColor="#1E40AF">
                  Update info
                </Tag>
              )}
            </>
          );
        },
      },
      {
        title: "VOLUME (KG)",
        dataIndex: "volume",
        key: "volume",
        sorter: (a, b) => a.volume - b.volume,
        render: (text) =>
          text ? (
            <span className="float-right">{numFormatter(text)} kg</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "Revenue",
        dataIndex: "revenue",
        key: "revenue",
        className: "w-40",
        sorter: (a, b) => a.revenue - b.revenue,
        render: (text) =>
          text ? (
            <span className="float-right">Rs. {numFormatter(text)}</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "DETAILS",
        dataIndex: "details",
        key: "details",
        render: (text, record) => {
          return (
            <>
              <Button
                type="link"
                className="text-green hover:text-green"
                onClick={() => {
                  handleDrawer(record);
                }}
                id="details"
              >
                <span className="text-green">{text.split("-")[0]}</span>
                <span className="bg-green w-1.5 h-1.5 mx-2 align-middle inline-block rounded-md"></span>

                <span className="text-green">{text.split("-")[1]}</span>
              </Button>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <Spin spinning={loading}>
      <div className="flex flex-col gap-5 p-8 w-full justify-between ">
        <div className="flex gap-5">
          <div style={{ width: "18%" }}>
            <Select
              placeholder="Materials"
              type="Materials"
              data={materialList || []}
              onChange={handleselectedValues}
              value=""
              // width="270px"
              mode="multiple"
              allowClear={true}
            />
          </div>
          <div style={{ width: "13%" }}>
            <Select
              placeholder="Status"
              type="Status"
              data={["In review", "Approved", "Rejected", "Update info"]}
              onChange={handleselectedValues}
              value=""
              // width="270px"
              allowClear={true}
            />
          </div>
          <DatePicker
            placeholder="Date"
            separator="~"
            handleDate={handleDate}
          />
        </div>
        <div className="w-full">
          <Table
            dataSource={!!data.length ? data : []}
            columns={columns}
            key="transactionsListing"
            pagination={true}
          // locale={{ emptyText: "No transactions found" }}
          />
        </div>
        {openDrawer && (
          <SalesDetailsDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            onClose={onClose}
            invoice={invoice}
          />
        )}
      </div>
    </Spin>
  );
}

export default SalesDetails;
