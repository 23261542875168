import { gql } from "@apollo/client";

export const GET_SIGNED_URL = gql`
  mutation GET_SIGNED_URL($fileName: String) {
    get_signed_url(file_name: $fileName) {
      message
      url
    }
  }
`;

export const INSERT_VENDOR = gql`
  mutation INSERT_VENDOR($object: [vendors_insert_input!]!) {
    insert_vendors(objects: $object) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const GET_VENDOR_DETAILS = gql`
  query GET_VENDOR_DETAILS($id: bigint) {
    vendors(where: { id: { _eq: $id } }) {
      name
      locality
      email
      details
      id
      phone
      status
      user_id
      rewards {
        id
      }
    }
    phone: vendors {
      phone
      email
    }
  }
`;
export const GET_PHONE_EMAIL = gql`
  query GET_VENDOR_DETAILS {
    users {
      email
      phone
    }
  }
`;

export const UPDATE_VENDOR = gql`
  mutation UPDATE_VENDOR($id: bigint, $object: vendors_set_input) {
    update_vendors(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
      returning {
        rewards {
          id
          status
        }
      }
    }
  }
`;

export const INSERT_REWARD = gql`
  mutation INSERT_REWARD($object: [rewards_insert_input!]!) {
    insert_rewards(objects: $object) {
      affected_rows
    }
  }
`;

export const GET_REWARDS = gql`
  query GET_REWARDS($where: rewards_bool_exp) {
    rewards(where: $where, order_by: { id: desc }) {
      details
      location
      id
      total_used_number
      status
      vendor_id
      name
      vendor {
        status
        details
      }
    }
  }
`;

export const GET_REWARDS_ONE = gql`
  query GET_REWARDS_ONE($id: bigint) {
    rewards(where: { id: { _eq: $id } }, order_by: { id: desc }) {
      details
      location
      id
      total_used_number
      status
      vendor_id
      vendor {
        details
      }
    }
  }
`;

export const UPDATE_REWARD = gql`
  mutation UPDATE_REWARD($object: rewards_set_input, $id: bigint) {
    update_rewards(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
    }
  }
`;

export const GET_REWARDS_LOCATIONS = gql`
  query GET_REWARDS_LOCATIONS($id: Int) {
    rewards(order_by: { location: asc }, where: { vendor_id: { _eq: $id } }) {
      location
      status
      vendor {
        name
      }
    }
  }
`;

export const UPDATE_REWARDS_STATUS = gql`
  mutation UPDATE_REWARDS_STATUS($id: bigint, $status: String) {
    update_rewards(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const INSERT_USERS = gql`
  mutation INSERT_USERS($object: [users_insert_input!]!) {
    insert_users(objects: $object) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER($user_id: bigint, $object: users_set_input) {
    update_users(where: { id: { _eq: $user_id } }, _set: $object) {
      affected_rows
    }
  }
`;

export const GET_USER_REWARD = gql`
  query GET_USER_REWARD($where: user_reward_bool_exp) {
    user_reward(where: $where, order_by: { id: desc }) {
      id
      reward_id
      code
      is_redeemed
      payment_at
      razorpay_transaction_id
      redeemed_at
      reward {
        name
        location
        details
        vendor {
          name
          locality
          details
          status
          created_at
        }
      }
      details
      status
      transaction_id
      transaction {
        created_at
        payment_details
      }
      user {
        name
      }
    }
  }
`;

export const GET_VENDOR_REWARD_DETAILS = gql`
  query GET_VENDOR_REWARD_DETAILS($id: Int) {
    rewards(where: { vendor_id: { _eq: $id } }) {
      location
      name
    }
  }
`;

export const INSERT_TRANSACTION = gql`
  mutation INSERT_TRANSACTION($object: [transactions_insert_input!]!) {
    insert_transactions(objects: $object) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_USER_REWARD = gql`
  mutation UPDATE_USER_REWARD($id: bigint, $transactionID: Int) {
    update_user_reward(
      where: { id: { _eq: $id } }
      _set: { transaction_id: $transactionID }
    ) {
      affected_rows
    }
  }
`;

export const GET_TRANSACTIONS = gql`
  query GET_TRANSACTIONS($id: Int) {
    transactions(where: { vendor_id: { _eq: $id } }, order_by: { id: desc }) {
      id
      amount
      created_at
      payment_details
      vendor_id
    }
  }
`;

export const ADD_VENDOR_IN_FIREBASE = gql`
  mutation ADD_VENDOR_IN_FIREBASE($hasura_id: String!, $phone: String!) {
    add_vendor(hasura_id: $hasura_id, phone: $phone) {
      data
      message
    }
  }
`;

export const DELETE_IN_FIREBASE = gql`
  mutation DELETE_IN_FIREBASE($phone: String!) {
    delete_vendor(phone: $phone) {
      message
    }
  }
`;
