/* eslint-disable array-callback-return */
import { Spin, Tabs } from "antd";
import CountCard from "../../components/CountCard";
import SaleBarChart from "../../components/BarChart";
import { useState, useEffect } from "react";
import SaleLineChart from "../../components/LineChart";
import { GET_COLLECTION_CENTRE } from "../location/graphql";
import { GET_USER_DETAILS } from "../users/graphql";
import { GET_CAMPAIGN_DETAILS } from "../rewards/graphql";
import {
  GET_SALES_LISTING,
  GET_TOP_MATERIALS,
  GET_STATS,
} from "../sales/graphql";
import { useQuery } from "@apollo/client";
import { removeDuplicates, groupBy } from "../../utils";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

function Dashboard() {
  const navigate = useNavigate();
  const [noOfLocations, setNoOfLocations] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [noOfTransactions, setNoOfTransactions] = useState(0);
  const [totalSaleAmount, setTotalSaleAmount] = useState(0);
  const [averageVolume, setAverageVolume] = useState(0);
  const [noOfUsers, setNoOfUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [inActiveUsers, setInActiveUsers] = useState(0);
  const [noOfCoupons, setNoOfCoupons] = useState(0);
  const [totSavings, setTotSavings] = useState(0);
  const [totCouponVal, setTotCouponVal] = useState(0);
  const [noOfSaleTransaction, setNoOfSaleTransactions] = useState(0);
  const [avgTransaction, setAvgTransaction] = useState(0);
  const [avgValue, setAvgValue] = useState(0);
  const [approvedTransaction, setApprovedTransaction] = useState(0);
  const [reviewTransaction, setReviewTransaction] = useState(0);
  const [rejectedTransaction, setRejectedTransaction] = useState(0);
  const [onboardVendor, setOnnboardVendor] = useState(0);
  const [activeVendor, setActiveVendor] = useState(0);
  const [activeCampaign, setActiveCampaign] = useState(0);
  const [couponsSold, setCouponsSold] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [kcprofit, setKcprofit] = useState(0);
  const [activeTab, setActiveTab] = useState("1");
  const [data, setData] = useState([]);
  const [linedata, setLineData] = useState([]);
  const [wasteDiverted, setWasteDiverted] = useState(0);

  const {
    data: locationDetails,
    loading: collectionLoading,
    refetch: collectionRefetch,
  } = useQuery(GET_COLLECTION_CENTRE, {
    variables: {
      where: { _and: { locality: {}, status: { _eq: "active" } } },
    },
  });

  const {
    data: userDetails,
    loading: userDetailsLoading,
    refetch: userRefetch,
  } = useQuery(GET_USER_DETAILS, {
    variables: {
      where: {
        _and: {
          locality: {},
          status: {},
          role_id: { _eq: 1 },
        },
      },
      startTime: moment().startOf("year"),
      endTime: moment().endOf("year"),
    },
  });

  const {
    data: salesDetails,
    loading: salesLoading,
    refetch: salesRefetch,
  } = useQuery(GET_SALES_LISTING, { variables: { where: {}, salesWhere: {} } });

  const {
    data: topMaterials,
    loading: topMaterialsLoading,
    refetch: materialRefetch,
  } = useQuery(GET_TOP_MATERIALS, {
    variables: {
      startDate: moment()
        .subtract(30, "day")
        .format("YYYY-MM-DDT00:00:00.000Z"),
      endDate: moment().format("YYYY-MM-DDT23:59:59.000Z"),
    },
  });

  const {
    data: statsDetails,
    loading: statsLoading,
    refetch: statsRefetch,
  } = useQuery(GET_STATS, {
    variables: {
      start: moment().subtract(7, "day").format("YYYY-MM-DDT00:00:00.000Z"),
      end: moment().format("YYYY-MM-DDT23:59:59.000Z"),
    },
  });

  const {
    data: campaignDetails,
    loading: campaignLoading,
    refetch: campaignRefetch,
  } = useQuery(
    GET_CAMPAIGN_DETAILS,
    {
      variables: {
        where: {
          _and: { name: {} },
        },
      },
    },
    { fetchPolicy: "no-cache" }
  );

  useEffect(() => {
    collectionRefetch();
    salesRefetch();
    materialRefetch();
    statsRefetch();
    userRefetch();
    campaignRefetch();
  }, []);

  useEffect(() => {
    if (locationDetails) {
      let newList = [];
      locationDetails.collection_centre.map((item) => {
        let sum = 0;
        if (item.sales.length > 0) {
          item.sales.map((ele) => {
            if (ele.status === "Approved") {
              return (sum += ele?.sales?.aggregate?.sum?.actual_quantity);
            }
          });
        }
        newList.push({
          volumePurchased: item.sales.length > 0 ? sum : 0,
          transaction:
            item.sales.length > 0 ? item?.approved?.aggregate?.count : 0,
        });
      });
      let totVol = 0;
      let totTransactions = 0;
      let avgVol;

      newList.map((item) => {
        totVol += item.volumePurchased;
        totTransactions += item.transaction;
        avgVol = totVol / totTransactions;
      });
      setNoOfLocations(newList.length);
      setNoOfTransactions(totTransactions);
      setTotalVolume(totVol);
      setAverageVolume(isNaN(avgVol) ? 0 : avgVol);
    }
  }, [locationDetails]);

  useEffect(() => {
    if (userDetails) {
      let newList = [];
      let locationList = [];
      let userIDList = [];
      userDetails.users.map((item) => {
        let salesVolume = 0;
        let salesPrice = 0;
        let savings = 0;
        let dateCheck = true;
        let couponVal = 0;
        let rewardPoints = 0;
        let redeemedPoints = 0;
        if (item.sales.length > 0) {
          item.sales.map((ele) => {
            if (ele.status === "Approved") {
              salesVolume +=
                ele.sales_materials_aggregate.aggregate.sum.actual_quantity;
              salesPrice += ele.total_price;
              rewardPoints += parseInt(ele.points);
            }
          });
          dateCheck = moment(item.sales[0].created_at).isBetween(
            moment().subtract(4, "week"),
            moment()
          );
        }
        if (item.user_rewards.length > 0) {
          item.user_rewards.map((ele) => {
            // if (ele.details && ele.reward) {
            couponVal += parseInt(ele.reward.details.couponValue);

            ele.redeemed_points
              ? (redeemedPoints += parseInt(ele.redeemed_points))
              : (redeemedPoints += 0);
            savings +=
              (parseInt(ele.reward.details.couponValue) -
                parseInt(ele.reward.details.couponsCost)) *
              ele.reward.total_used_number;
            // }
          });
        }
        userIDList.push(item.id);
        newList.push({
          status:
            item.status === "Deactivated"
              ? item.status
              : dateCheck === false
              ? "Inactive"
              : "Active",
          noOfCouponsPurchased: item.user_rewards_aggregate.aggregate.count,
          valueOfCouponsPurchased: couponVal,
          totalSavings: savings,
        });
      });

      let active = 0;
      let totCoupon = 0;
      let inactive = 0;
      let totCouponVal = 0;
      let savingsVal = 0;
      newList.map((item) => {
        if (item.status === "Active") {
          active += 1;
        } else if (item.status === "Inactive") {
          inactive += 1;
        }
        totCoupon += item.noOfCouponsPurchased;
        totCouponVal +=
          item.noOfCouponsPurchased > 0 ? item.valueOfCouponsPurchased : 0;
        savingsVal += item.noOfCouponsPurchased > 0 ? item.totalSavings : 0;
      });
      setNoOfCoupons(totCoupon);
      setTotCouponVal(totCouponVal);
      setTotSavings(savingsVal);
      setActiveUsers(active);
      setInActiveUsers(inactive);

      setNoOfUsers(newList.length);
    }
  }, [userDetails]);

  useEffect(() => {
    if (salesDetails && salesDetails.users) {
      let newList = [];
      salesDetails.sales.map((sale) => {
        if (sale.status !== "Draft") {
          newList.push({
            username: sale.user.name,
            status: sale.status,
            amount: sale.total_price,
            volume:
              sale.sales_materials_aggregate.aggregate.sum.actual_quantity,
          });
        }
      });

      let userList = [];
      let sum = 0;
      let approved = 0;
      let rejected = 0;
      let inReview = 0;
      let totalVol = 0;
      newList.map((item) => {
        userList.push(item.username);
        if (item.status === "Approved") {
          approved++;
          totalVol += item.volume;
          sum += item.amount;
        }
        if (item.status === "Rejected") rejected++;
        if (item.status === "In review") inReview++;
      });
      let avgTransaction = salesDetails.users.length;
      setNoOfSaleTransactions(newList.length);
      setAvgTransaction(
        avgTransaction === 0 ? 0 : (newList.length / avgTransaction).toFixed(2)
      );
      setTotalSaleAmount(sum);
      setAvgValue(newList.length === 0 ? 0 : sum / newList.length);
      setApprovedTransaction(approved);
      setRejectedTransaction(rejected);
      setReviewTransaction(inReview);
      setWasteDiverted(totalVol);
    }
  }, [salesDetails]);

  useEffect(() => {
    if (topMaterials) {
      let object = JSON.parse(JSON.stringify(topMaterials.sales_materials));
      const groupedData = groupBy(object, "material_id");
      let newarray = [];

      Object.keys(groupedData).forEach(function (key, index) {
        let sum = 0;
        let materialName;
        groupedData[key].map((item) => {
          if (activeTab === "1") {
            sum += item.actual_quantity;
          } else {
            sum += item.price;
          }
          materialName = item.material.name;
        });
        if (activeTab === "2") {
          newarray.push({
            name: materialName,
            value: Math.abs(sum / groupedData[key].length),
          });
        } else {
          newarray.push({
            name: materialName,
            value: Math.abs(sum),
          });
        }
      });
      const numDescending = [...newarray].sort((a, b) => b.value - a.value);

      let first3Expense = numDescending.slice(0, 10);

      setData(first3Expense);
    }
  }, [topMaterials, activeTab]);

  useEffect(() => {
    if (campaignDetails) {
      let newList = [];
      let vendorArray = [];
      let activeVendorArr = [];
      let campaignArr = [];
      let couponsSoldArr = 0;
      let revenue = 0;
      let profitsum = 0;
      campaignDetails?.vendors.map((vendor) => {
        vendorArray.push(vendor.name);
        if (vendor.status === "active") activeVendorArr.push(vendor.name);
        // if (vendor.status === "active") campaignArr.push(vendor.id);
        if (vendor.rewards.length > 0) {
          vendor.rewards.map((item) => {
            newList.push({
              couponCost: item.details.couponsCost,
              couponUsed: item.total_used_number,
              campaignID: item.id,
              profit:
                item.total_used_number === 0
                  ? 0
                  : (item.details.couponsCost - item.details.vendorCost) *
                    item.total_used_number,
            });
          });
          if (vendor.rewards[0].status === "active")
            campaignArr.push(vendor.id);
        } else {
          newList.push({
            couponUsed: 0,
            couponCost: 0,
            campaignID: "-",
          });
        }
      });
      newList.sort((a, b) => {
        return b.campaignID - a.campaignID;
      });
      newList.map((ele) => {
        if (ele.campaignID !== "-") {
          couponsSoldArr += parseInt(ele.couponUsed);
          if (ele.couponUsed !== 0)
            revenue += parseInt(ele.couponCost * ele.couponUsed);
          profitsum += parseInt(ele.profit);
        }
      });

      setOnnboardVendor(vendorArray.length);
      setActiveVendor(activeVendorArr.length);
      setActiveCampaign(campaignArr.length);
      setCouponsSold(couponsSoldArr);
      setTotalRevenue(revenue);
      setKcprofit(profitsum);
    }
  }, [campaignDetails]);

  const listOfDates = (date, value) => {
    let daysAgo = [];
    for (let i = 0; i <= value - 1; i++) {
      daysAgo.push(moment(date).subtract(i, "days").format("DD/MM/YY"));
    }
    return daysAgo;
  };

  useEffect(() => {
    if (statsDetails) {
      let noOfDays = 7;
      let dayData = {};
      let weekDataArray = [];
      let object = JSON.parse(JSON.stringify(statsDetails.sales));

      object.map((sale) => {
        sale.updated_at = moment(sale.updated_at).format("DD/MM/YY");
      });
      const groupedData = groupBy(object, "updated_at");
      Object.keys(groupedData).forEach(function (key, index) {
        groupedData[key].noOfTransaction = groupedData[key].length;
        let volume = 0;
        let price = 0;
        groupedData[key].map((item) => {
          volume +=
            item.sales_materials_aggregate.aggregate.sum.actual_quantity;
          price += item.total_price;
        });
        dayData[key] = {
          noOfTransaction: groupedData[key].length,
          Volume: volume,
          Revenue: price,
          avgPrice: price / groupedData[key].length,
        };
      });
      let datesList = listOfDates(moment(), noOfDays);
      let linearray = [];
      datesList.map((date) => {
        if (dayData[date]) {
          linearray.push({
            ...dayData[date],
            name: date,
          });
        } else {
          linearray.push({
            name: date,
            noOfTransaction: 0,
            Volume: 0,
            Revenue: 0,
            avgPrice: 0,
          });
        }
      });
      if (noOfDays <= 10) {
        // noOfDays === 7?
        linearray.slice(0, 6);
        // : linearray.slice(0, noOfDays - 1);
        setLineData(linearray);
      }
    }
  }, [statsDetails]);

  const onChange = (key) => {
    setActiveTab(key);
  };

  return (
    <Spin
      spinning={
        collectionLoading ||
        userDetailsLoading ||
        salesLoading ||
        topMaterialsLoading ||
        statsLoading ||
        campaignLoading
      }
    >
      <div className="flex flex-col gap-4 p-8 w-full justify-between ">
        <div className="grid grid-cols-3 gap-5">
          <CountCard
            count={wasteDiverted}
            title="Waste diverted from landfills"
            // width="280px"
            type="dashboard"
            link="/sales"
            metric={wasteDiverted === 0 ? "" : " kg"}
          />
          <CountCard
            count={noOfUsers}
            title="Total users"
            // width="280px"
            type="dashboard"
            link="/users"
          />
          <CountCard
            count={totalSaleAmount}
            title="Total sales"
            // width="280px"
            type="dashboard"
            link="/sales"
            symbol="Rs. "
          />
        </div>
        <div>
          <div>
            <span
              className="text-green text-base cursor-pointer"
              onClick={() => navigate("/locations")}
            >
              Locations
            </span>
          </div>
          <div className="grid grid-cols-4 gap-5">
            <CountCard
              count={noOfLocations}
              title="Number of locations"
              width="280px"
              type="dashboard"
            />
            <CountCard
              count={totalVolume}
              title="Total volume purchased (kg)"
              width="280px"
              type="dashboard"
            />
            <CountCard
              count={noOfTransactions}
              title="Total number of transactions"
              width="280px"
              type="dashboard"
            />
            <CountCard
              count={averageVolume}
              title="Average volume / transaction (kg)"
              width="280px"
              type="dashboard"
            />
          </div>
        </div>
        <div>
          <div>
            <span
              className="text-green text-base cursor-pointer"
              onClick={() => navigate("/users")}
            >
              Users
            </span>
          </div>
          <div className="grid grid-cols-6 gap-2">
            <CountCard
              count={noOfUsers}
              title="Total number of users"
              tab="users"
              type="dashboard"
            />

            <CountCard
              count={activeUsers}
              title="Number of active users"
              tab="users"
              type="dashboard"
            />

            <CountCard
              count={inActiveUsers}
              title="Number of inactive users"
              tab="users"
              type="dashboard"
            />

            <CountCard
              count={noOfCoupons}
              title="Number of coupons purchased"
              tab="users"
              type="dashboard"
            />
            <CountCard
              count={totCouponVal}
              title="Total value of coupons purchased"
              tab="users"
              type="dashboard"
              symbol="Rs. "
            />

            <CountCard
              count={totSavings}
              title="Total savings generated for user"
              tab="users"
              type="dashboard"
              symbol="Rs. "
            />
          </div>
        </div>
        <div>
          <div>
            <span
              className="text-green text-base cursor-pointer"
              onClick={() => navigate("/sales")}
            >
              Sales
            </span>
          </div>
          <div className="grid grid-cols-6 gap-2">
            <CountCard
              count={noOfSaleTransaction}
              title="Total number of transactions"
              tab="sales"
              percentageValue=""
              type="dashboard"
            />
            <CountCard
              count={avgTransaction}
              title="Avg. transactions per user"
              tab="sales"
              percentageValue=""
              type="dashboard"
            />
            <CountCard
              count={avgValue}
              title="Avg value per transaction"
              tab="sales"
              percentageValue=""
              type="dashboard"
            />
            <CountCard
              count={approvedTransaction}
              title="Transactions approved"
              tab="sales"
              type="dashboard"
            />
            <CountCard
              count={reviewTransaction}
              title="Transactions in review"
              tab="sales"
              type="dashboard"
            />

            <CountCard
              count={rejectedTransaction}
              title="Transactions rejected"
              tab="sales"
              type="dashboard"
            />
          </div>
          <div className="dashboard">
            <div className="shadow-lg px-5 pt-5">
              <Tabs
                defaultActiveKey="1"
                onChange={onChange}
                activeKey={activeTab}
                tabBarExtraContent={{
                  left: (
                    <>
                      <span className={` text-base text-green`}>
                        Top Materials
                      </span>
                    </>
                  ),
                }}
              >
                <TabPane
                  key="1"
                  tab={<span className="text-base">Volume</span>}
                >
                  <Spin spinning={topMaterialsLoading}>
                    <SaleBarChart data={data} />
                  </Spin>
                </TabPane>
                <TabPane
                  tab={<span className="text-base ">Average price</span>}
                  key="2"
                >
                  <Spin spinning={topMaterialsLoading}>
                    <SaleBarChart data={data} />
                  </Spin>
                </TabPane>
                <TabPane
                  tab={<span className="text-base ">Revenue</span>}
                  key="3"
                >
                  <Spin spinning={topMaterialsLoading}>
                    <SaleBarChart data={data} />
                  </Spin>
                </TabPane>
              </Tabs>
            </div>
          </div>
          <div className="mt-4 px-5">
            <p className={` text-base text-green`}>Stats</p>
            <SaleLineChart data={linedata} />
          </div>
        </div>

        <div>
          <span
            className="text-green text-base cursor-pointer"
            onClick={() => navigate("/rewards")}
          >
            Rewards
          </span>
        </div>
        <div className="grid grid-cols-6 gap-3">
          <CountCard
            count={onboardVendor}
            title="Total vendors onboarded"
            type="dashboard"
          />
          <CountCard
            count={activeVendor}
            title="Total active vendors"
            type="dashboard"
          />
          <CountCard
            count={activeCampaign}
            title="Total active campaigns"
            type="dashboard"
          />
          <CountCard
            count={couponsSold}
            title="Total coupons sold"
            tab="rewards"
            type="dashboard"
          />
          <CountCard
            count={totalRevenue}
            title="Total revenue from coupon sales"
            type="dashboard"
            symbol="Rs. "
          />
          <CountCard
            count={kcprofit}
            title="Total profits for KC"
            tab="rewards"
            type="dashboard"
            symbol="Rs. "
          />
        </div>
      </div>
    </Spin>
  );
}
export default Dashboard;
