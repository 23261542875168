import { DatePicker as ReactDatePicker } from "antd";
import React, { useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import SelectSuffix from "../assets/select.svg";
const { RangePicker } = ReactDatePicker;

const PickerStyle = styled.div`
  .ant-picker {
    border-color: #27ae60;
    cursor: pointer;
  }
  &.disableEndDate {
    .ant-picker-input:nth-child(3) {
      display: none;
    }
    .ant-picker-range-separator {
      diaplay: none;
    }
    .ant-picker-range-separator {
      display: none;
    }
  }
`;
const GlobalStyle = createGlobalStyle`

.ant-picker-input.ant-picker-input-active input::placeholder {
  color: #27AE60;
 }
 
.ant-picker-header-prev-btn:nth-child(1){
  visibility: hidden !important;
}
.ant-picker-header-next-btn:nth-child(1){
  visibility: hidden !important;
}
.ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn,.ant-picker-range-arrow{
  display: none !important;
}
.ant-picker-header{
  border-bottom: 0;
}
.ant-picker-panel-container .ant-picker-panel-focused,.ant-picker-panel{
  border:0;
}
.ant-picker-panels{
  border:1px solid #27AE60;
  border-radius:10px
}
.ant-picker-panel-container{
  box-shadow: none;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before{
  background:#27AE60
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner,.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner,.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border-radius: 0;
}
.ant-picker-footer{
  min-width: initial;
  width:170px;
  margin-top: -28px;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  background: initial;
  border: initial;
}

`;

export default function DatePicker({
  handleDate,
  value,
  placeholder,
  separator,
  ...rest
}) {
  const dateFormat = "DD/MM/YYYY";
  const [dateValue, setDateValue] = React.useState([]);

  useEffect(() => {
    if (value && value.length !== 0 && value[0] && value[1]) {
      setDateValue(value);
    }
  }, [value]);

  return (
    <>
      <PickerStyle
        className={
          (dateValue || [])?.length === 0 ? "disableEndDate" : "Enabledate"
        }
      >
        <GlobalStyle />
        <RangePicker
          name="dataPickerCustom"
          size="large"
          popupClassName="rangePicker"
          onCalendarChange={(dates) => {
            setDateValue(dates);
            handleDate(dates);
          }}
          placeholder={
            placeholder ? [placeholder, ""] : ["Start date", "End date"]
          }
          separator={separator}
          value={dateValue}
          format={dateFormat}
          suffixIcon={<img src={SelectSuffix} alt="suffix select" />}
        />
        <div id="datePicker"></div>
      </PickerStyle>
    </>
  );
}
