import { Button, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import Table from "../../../components/Table";
import { numFormatter, getColumnSearchProps } from "../../../utils";
import VendorPaymentDrawer from "./vendorPaymentDrawer";
import { GET_TRANSACTIONS } from "./graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import moment from "moment";

function PastPayments() {
  const location = useLocation();
  const search = useLocation().search;
  const [tableData, setTableData] = useState([]);
  const [searchLocName, setSearchLocName] = useState();
  const [currentItem, setCurrentItem] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [
    getPastPayments,
    { data: pastPayments, loading: pastPaymentsLaoding },
  ] = useLazyQuery(GET_TRANSACTIONS, { fetchPolicy: "no-cache" });

  useEffect(() => {
    getPastPayments({
      variables: { id: parseInt(location.pathname.split("/")[4]) },
    });
  }, []);

  useEffect(() => {
    setSearchLocName(new URLSearchParams(search).get("searchText"));
  }, [location]);

  useEffect(() => {
    if (pastPayments) {
      let newList = [];
      pastPayments.transactions.map((item) => {
        newList.push({
          paymentId: item.id,
          paymentDate: moment(item.created_at).format("DD MMM YYYY"),
          amount: item.amount,
          transactionID: item.payment_details.transactionID,
          comments: item.payment_details.comments,
          transactionImage: item.payment_details.transactionImage,
        });
      });
      if (searchLocName) {
        let DisplayData = newList?.filter((ele) => {
          return ele.transactionID
            .toLowerCase()
            .includes(searchLocName.toLowerCase());
        });
        setTableData(DisplayData);
      } else {
        setTableData(newList);
      }
    }
  }, [pastPayments, searchLocName]);

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const columns = useMemo(
    () => [
      {
        title: "CAMPAIGN ID",
        dataIndex: "paymentId",
        key: "paymentId",
        className: "w-40",
        ...getColumnSearchProps("paymentId"),
        render: (text) => <>{`TRANS${`${text}`.padStart(6, "0")}`}</>,
      },
      {
        title: "PAYMENT DATE",
        dataIndex: "paymentDate",
        key: "campaignName",
        className: "w-40",
      },
      {
        title: "AMOUNT",
        dataIndex: "amount",
        key: "amount",
        className: "w-40",
        sorter: (a, b) => a.amount - b.amount,
        render: (text) =>
          text ? (
            <>
              <span className="float-right">Rs. {numFormatter(text)} </span>
            </>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "TRANSACTION ID",
        dataIndex: "transactionID",
        key: "transactionID",
        className: "w-40",
      },
      {
        title: "COMMENTS",
        dataIndex: "comments",
        key: "comments",
        className: "w-40",
        render: (text) => (
          <>
            <p className="truncate w-36">{text} </p>
          </>
        ),
      },
      {
        title: "VIEW",
        dataIndex: "",
        key: "",
        fixed: "right",
        className: "w-32",
        render: (text, record) => (
          <Button
            type="link"
            className="text-green hover:text-green"
            onClick={() => {
              setOpenDrawer(true);
              setCurrentItem(record);
            }}
            id="details"
          >
            Details
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <Spin spinning={pastPaymentsLaoding}>
      <div className="flex flex-col gap-5 p-8 w-full justify-between ">
        <div className="w-full">
          <Table
            dataSource={!!tableData.length ? tableData : []}
            columns={columns}
            key="usersListing"
            pagination={true}
          />
        </div>
        {openDrawer && (
          <VendorPaymentDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            currentItem={currentItem}
            handleClose={handleClose}
          />
        )}
      </div>
    </Spin>
  );
}
export default PastPayments;
