import { gql } from "@apollo/client";

export const MATERIAL_LIST = gql`
  query MATERIAL_LIST {
    materials {
      name
      is_global
      id
    }
  }
`;

export const INSERT_MATERIAL = gql`
  mutation INSERT_MATERIAL($object: [materials_insert_input!]!) {
    insert_materials(objects: $object) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const INSERT_COLLECTION_CENTRE = gql`
  mutation INSERT_COLLECTION_CENTRE(
    $object: [collection_centre_insert_input!]!
  ) {
    insert_collection_centre(objects: $object) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const GET_COLLECTION_MATERIALS = gql`
  query COLLECTION_CENTRE($id: bigint!) {
    collection_centre(where: { id: { _eq: $id } }) {
      id
      details
      locality
      name
      long
      lat
      phone
      status
      created_by
      points
      user {
        id
        name
        role_id
      }
    }
  }
`;

export const GET_SIGNED_URL = gql`
  mutation GET_SIGNED_URL($fileName: String) {
    get_signed_url(file_name: $fileName) {
      message
      url
    }
  }
`;

export const UPDATE_COLLECTION_CENTRE = gql`
  mutation UPDATE_COLLECTION_CENTRE(
    $id: bigint
    $object: collection_centre_set_input
  ) {
    update_collection_centre(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
    }
  }
`;

export const INSERT_COLLECTION_MATERIALS = gql`
  mutation INSEERT_COLLECTION_MATERIALS(
    $collectionObject: [collection_material_insert_input!]!
  ) {
    insert_collection_material(objects: $collectionObject) {
      affected_rows
    }
  }
`;

export const GET_COLLECTION_DETAILS = gql`
  query GET_COLLECTION_DETAILS($id: Int, $centre_id: bigint) {
    collection_material(
      where: { centre_id: { _eq: $id } }
      order_by: { material_id: asc }
    ) {
      id
      price
      is_active
      material {
        id
        name
        is_global
      }
    }
    collection_centre(where: { id: { _eq: $centre_id } }) {
      name
    }
  }
`;

export const DELETE_COLLECTION_MATERIAL = gql`
  mutation DELETE_COLLECTION_MATERIAL($centre_id: Int) {
    delete_collection_material(where: { centre_id: { _eq: $centre_id } }) {
      affected_rows
    }
  }
`;

export const GET_TRANSACTION = gql`
  query GET_TRANSACTION($where: sales_bool_exp, $id: bigint) {
    sales(where: $where, order_by: { updated_at: desc }) {
      id
      centre_id
      receipt
      collection_centre {
        name
        locality
      }
      user {
        id
        details
        name
        apartment {
          name
        }
        locality
      }
      status
      sales_materials_aggregate {
        aggregate {
          sum {
            actual_quantity
          }
        }
      }
      updated_at
      sales_materials(order_by: { material: { name: asc } }) {
        material {
          name
        }
      }
      total_price
    }
    collection_centre(where: { id: { _eq: $id } }) {
      name
      locality
    }
  }
`;

export const SEND_PUSH_NOTIFICATIONS = gql`
  mutation SEND_PUSH_NOTIFICATIONS(
    $message: jsonb
    $title: String
    $user_ids: [Int]!
  ) {
    send_notifications(message: $message, title: $title, user_ids: $user_ids) {
      message
    }
  }
`;
