import { Divider, Button } from "antd";
import allTransactions from "../../assets/allTransactions.svg";
import { useNavigate } from "react-router-dom";

function Stats(props) {
  const navigate = useNavigate();
  return (
    <div>
      <p>
        <span className="text-sm font-semibold align-middle">
          Coupons sold in this campaign
        </span>
        <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

        <span className="align-middle font-normal text-sm">
          {props.currentItem.couponUsed}
        </span>
      </p>
      <p>
        <span className="text-sm font-semibold align-middle">
          Revenue from sales
        </span>
        <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

        <span className="align-middle font-normal text-sm">
          INR {props.currentItem.couponCost * props.currentItem.couponUsed}
        </span>
      </p>
      <p>
        <span className="text-sm font-semibold align-middle">
          Profits for KC
        </span>
        <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

        <span className="align-middle font-normal text-sm">
          INR {props.currentItem.profit}
        </span>
      </p>
      <Divider />
      {props.data && props.data.length > 0 && (
        <div>
          <p>
            <span className="text-sm font-semibold align-middle">
              Active campaigns
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              {props.data[0].activeCampaign}
            </span>
          </p>
          <p>
            <span className="text-sm font-semibold align-middle">
              Total coupons sold
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              {props.data[0].couponsSold}
            </span>
          </p>
          <p>
            <span className="text-sm font-semibold align-middle">
              Total revenue from sales
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              INR {props.data[0].revenueFromSales}
            </span>
          </p>

          <p>
            <span className="text-sm font-semibold align-middle">
              Total profits for KC
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              INR {props.data[0].profitForKC}
            </span>
          </p>
          <p>
            <span className="text-sm font-semibold align-middle">
              Total amount paid
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              INR {props.data[0].totalAmount}
            </span>
          </p>
          {props.data[0].lastPaymentOn !== "" && (
            <p>
              <span className="text-sm font-semibold align-middle">
                Last payment made on
              </span>
              <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

              <span className="align-middle font-normal text-sm">
                {props.data[0].lastPaymentOn}
              </span>
            </p>
          )}
          <p>
            <span className="text-sm font-semibold align-middle">
              Outstanding amount
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              INR {props.data[0].outStandingAmount}
            </span>
          </p>
          <p>
            <span className="text-sm font-semibold align-middle">
              Payment cycle
            </span>
            <span className="bg-lightGray w-1.5 h-1.5 mx-2 align-middle inline-block mt-1 rounded-md"></span>

            <span className="align-middle font-normal text-sm">
              {props.data[0].paymentCycle}
            </span>
          </p>
          <Button
            type="link"
            className="mt-3 p-0 w-full text-left"
            onClick={() =>
              navigate(
                `/rewards/editRewards/financials/${props.currentItem.id}`
              )
            }
          >
            <img src={allTransactions} alt="allTransactions" />
            <span className="text-sm font-semibold align-middle text-green ml-3">
              Manage / review payments
            </span>
          </Button>
        </div>
      )}
    </div>
  );
}

export default Stats;
