import {
  ApolloClient,
  split,
  concat,
  HttpLink,
  InMemoryCache,
  Observable,
} from "@apollo/client";
// import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import firebaseApp from "../firebase";
import {
  getAuth,
  // onAuthStateChanged,
} from "firebase/auth";

// const wsLink = new WebSocketLink({
//   uri: process.env.REACT_APP_GQL_SUBSCRIPTION_ENDPOINT,
//   options: {
//     reconnect: true,
//     connectionParams: {
//       headers: {
//         authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     },
//   },
// });

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API,
});

const authLink = setContext((_, { headers }) => {
  let userToken = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      ...(userToken ? { authorization: `Bearer ${userToken}` } : {}),
      // ...(userToken ? { "x-hasura-admin-secret": "Remember001" } : {}),
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        const auth = getAuth(firebaseApp);
        switch (err.extensions.code) {
          case "access-denied":
            if (auth.currentUser) {
              auth
                .signOut()
                .then((res) => console.log("Logged out user."))
                .catch((err) => console.log("Logout Error => ", err));
            }
            localStorage.clear();
            window.location.href = "/";
            break;
          case "invalid-jwt":
            if (!auth.currentUser) {
              localStorage.clear();
              window.location.href = "/";
            }
            return new Observable((observer) => {
              auth.currentUser
                .getIdToken(true)
                .then((token_) => {
                  localStorage.setItem("token", token_);
                  let oldHeaders = operation.getContext().headers;
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      Authorization: `Bearer ${token_}`,
                    },
                  });
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };
                  // Retry last failed request
                  return forward(operation).subscribe(subscriber);
                })
                .catch((error) => {
                  // No refresh or client token available, we force user to login
                  observer.error(error);
                });
            });
          default:
          // console.log("default error handler");
        }
      }
    }
  }
);

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition"
      // &&
      // definition.operation === "subscription"
    );
  },
  httpLink
);

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: errorLink.concat(concat(authLink, link)),
});

export default client;
