import {
  Form,
  Input,
  Upload,
  Avatar,
  Button,
  TimePicker,
  notification,
  message,
  Spin,
  Modal,
  Image,
} from "antd";
import AddImage from "../../../assets/AddImage.svg";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useCallback, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import deleteIcon from "../../../assets/deleteIcon.svg";
import {
  INSERT_COLLECTION_CENTRE,
  GET_SIGNED_URL,
  GET_COLLECTION_MATERIALS,
  UPDATE_COLLECTION_CENTRE,
} from "./graphql";
import { getS3UploadUrl, uploadFiles } from "../../../utils";
import moment from "moment";
import jwtDecode from "jwt-decode";
import TitleHeadingContext from "../../../context/TitleHeadingContext";

function AddLocation() {
  const navigate = useNavigate();
  const location = useLocation();
  // const [formChange, setFormChange] = useState(false);
  const [fileURL, setFileURL] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [avatar, setAvatar] = useState(null);
  const [kcImage, setKcImage] = useState({});
  const [fileName, setFileName] = useState([]);
  const [insertCollectionCentre] = useMutation(INSERT_COLLECTION_CENTRE);
  const [updateCollectionCentre] = useMutation(UPDATE_COLLECTION_CENTRE);
  const [getSignedUrl] = useMutation(GET_SIGNED_URL);
  const [loading, setLoading] = useState(false);
  const titleHeading = useContext(TitleHeadingContext);
  let mobile = /^[0-9]{10}$/;

  const {
    data: getCollectionMaterials,
    loading: getCollectionLoading,
    refetch,
  } = useQuery(
    GET_COLLECTION_MATERIALS,

    {
      variables: {
        id: parseInt(location.pathname.split("/")[4]),
      },
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getCollectionMaterials) {
      titleHeading.setTitle(getCollectionMaterials?.collection_centre[0]?.name);
      getCases();
    }
    // eslint-disable-next-line
  }, [getCollectionMaterials]);

  const getCases = async () => {
    setLoading(true);

    let fileListArray = [];
    if (getCollectionMaterials?.collection_centre[0]?.details?.avatar)
      setAvatar(getCollectionMaterials?.collection_centre[0]?.details?.avatar);

    if (
      getCollectionMaterials?.collection_centre[0]?.details?.cover_image &&
      getCollectionMaterials?.collection_centre[0]?.details?.cover_image
        .length > 0
    ) {
      getCollectionMaterials?.collection_centre[0]?.details?.cover_image
        // eslint-disable-next-line
        .forEach(async (file, index) => {
          let responseUrl = await getResponseUrl(file);
          fileListArray.push({
            uid: moment().valueOf(),
            name: file,
            status: "done",
            url: responseUrl,
          });
        });

      setFileName(
        getCollectionMaterials?.collection_centre[0]?.details?.cover_image
      );
    }
    form.setFieldsValue({
      locationName: getCollectionMaterials?.collection_centre[0]?.name,
      mobileNumber: getCollectionMaterials?.collection_centre[0]?.phone,
      openTime: getCollectionMaterials?.collection_centre[0]?.details
        ?.open_time_tz
        ? moment(
            getCollectionMaterials?.collection_centre[0]?.details?.open_time_tz
          )
        : null,
      closeTime: getCollectionMaterials?.collection_centre[0]?.details
        ?.close_time_tz
        ? moment(
            getCollectionMaterials?.collection_centre[0]?.details?.close_time_tz
          )
        : null,
    });
    setFileList(fileListArray);
    setLoading(false);
  };

  const getResponseUrl = (file) => {
    return new Promise((resolve) => {
      getSignedUrl({
        variables: {
          fileName: file,
        },
      }).then((res) => {
        resolve(res?.data?.get_signed_url?.url);
      });
    });
  };
  const onFinish = async (values) => {
    let fileArray = [];
    fileList.map(async (item, index) => {
      fileArray.push({
        name: item.name,
        status: item.status,
        uid: item.uid,
        url: fileURL[index],
      });
    });

    let payload = {
      name: values.locationName,
      phone: values.mobileNumber,
      status: getCollectionMaterials?.collection_centre[0]?.status
        ? getCollectionMaterials?.collection_centre[0]?.status
        : "disabled",
      created_by: getCollectionMaterials?.collection_centre[0]?.created_by
        ? getCollectionMaterials?.collection_centre[0]?.created_by
        : parseInt(
            jwtDecode(localStorage.getItem("token"))[
              "https://hasura.io/jwt/claims"
            ]["x-hasura-user-id"]
          ),

      details: {
        ...getCollectionMaterials?.collection_centre[0]?.details,
        ...kcImage,
        open_time: values.openTime
          ? moment(values.openTime).format("HH:MM:SS") === "Invalid date"
            ? null
            : moment(values.openTime).format("HH:MM:SS")
          : null,
        close_time: values.closeTime
          ? moment(values.closeTime).format("HH:MM:SS") === "Invalid date"
            ? null
            : moment(values.closeTime).format("HH:MM:SS")
          : null,
        open_time_tz: values.openTime,
        close_time_tz: values.closeTime,
        cover_image: fileName,
        // response_list: fileArray,
      },
    };

    if (!location.pathname.split("/")[4]) {
      insertCollectionCentre({
        variables: {
          object: payload,
        },
      }).then((res) => {
        message.open({
          type: "success",
          content: "About details updated successfully",
        });
        navigate(
          `/locations/addLocations/address/${res?.data?.insert_collection_centre?.returning[0]?.id}`,
          { replace: true }
        );
      });
    } else {
      updateCollectionCentre({
        variables: {
          id: parseInt(location.pathname.split("/")[4]),
          object: payload,
        },
      }).then((res) => {
        if (location.pathname.split("/")[2] === "addLocations") {
          navigate(
            `/locations/addLocations/address/${parseInt(
              location.pathname.split("/")[4]
            )}`,
            { replace: true }
          );
        } else {
          message.open({
            type: "success",
            content: "About details updated successfully",
          });
        }
      });
    }
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const uploadFile = async (data) => {
    setLoading(true);
    if (fileList.length < 5) {
      let signedURL = await getS3UploadUrl(
        data.file.name,
        data.file.type,
        "cover_image"
      );
      if (signedURL[1].statuscode === "Success") {
        let URLs = await uploadFiles([signedURL[0]], [data.file], signedURL[2]);

        if (URLs) {
          setLoading(false);
        }
        let fileArray = [...fileURL];
        let fileNameArray = [...fileName];
        fileNameArray.push(signedURL[2]);
        fileArray.push({ [data.file.uid]: URLs[0] });
        setFileName(fileNameArray);
        setFileURL(fileArray);
      } else {
        notification.error({
          message: "An error occurred",
        });
      }
    } else {
      message.error(
        "You can upload only 5 images. To continue uploading, please remove any image and try again."
      );
    }
  };

  const handleChange = useCallback((info) => {
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    if (info.file.status === "done") {
      let newFileList = [...info.fileList];
      setFileList(newFileList);
    }
  }, []);

  const handleRemove = (file) => {
    let oldArray = [...fileList];
    let oldFileArray = [...fileURL];
    let filenameArray = [...fileName];
    let newFileArray = [];
    let newFilename = [];
    let newArray = oldArray.filter((item) => {
      return item.uid !== file.uid;
    });
    if (newArray.length === 0) {
      newFileArray = [];
    } else {
      newFileArray = oldFileArray.filter((item) => {
        return Object.keys(item)[0] !== file.uid;
      });
      newFilename = filenameArray.filter((ele) => {
        return ele !== file.name;
      });
    }
    setFileName(newFilename);
    setFileURL(newFileArray);
    setFileList(newArray);
  };

  return (
    <Spin spinning={getCollectionLoading}>
      <div className="location bg-bgGray min-h-screen w-full flex justify-center items-center py-4">
        <div className="w-5/12 p-4 bg-white rounded-md shadow-3xl">
          <p className="text-lg mb-0">About</p>
          <p className="text-sm text-gray500 font-normal">
            This information will be displayed publicly so please review
            carefully before you save
          </p>
          <Form
            name="about"
            layout="vertical"
            onFinish={onFinish}
            form={form}
            requiredMark={false}
            // onValuesChange={() => setFormChange(true)}
          >
            <Form.Item
              label={<span className="text-gray700">Location name</span>}
              name="locationName"
              rules={[
                {
                  required: true,
                  message: "Please enter a location name",
                },
                () => ({
                  validator(_, value) {
                    // let alphanumeric = /^([ A-Za-z0-9]){1,}$/;
                    if (value && value.match(/^ *$/) !== null) {
                      return Promise.reject("Please enter a location name");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Mobile Number</span>}
              name="mobileNumber"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value !== "" && !mobile.test(value)) {
                      return Promise.reject(
                        "Please enter a valid phone number"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Location Cover Photo</span>}
              name="coverphoto"
            >
              <>
                <Upload
                  name="coverPhoto"
                  listType="picture-card"
                  fileList={fileList}
                  method="PUT"
                  accept={".png,.jpg,.jpeg"}
                  onPreview={handlePreview}
                  customRequest={uploadFile}
                  onChange={(info) => {
                    handleChange(info);
                  }}
                  onRemove={handleRemove}
                  multiple
                  maxCount={5}
                  beforeUpload={async (file) => {
                    const isLt2M = file.size / 1024 / 1024 < 10;
                    if (!isLt2M) {
                      message.error("Image must smaller than 10MB!");
                      return Upload.LIST_IGNORE;
                    }

                    return isLt2M;
                  }}
                >
                  <div>
                    <p className="mb-1">
                      <img src={AddImage} alt="addimage" />
                    </p>
                    <p className="mb-1">
                      <span className="text-green">Upload a file</span> or drag
                      and drop
                    </p>
                    <p className="text-gray500">PNG,JPG/JPEG up to 10MB</p>
                  </div>
                </Upload>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{
                      width: "100%",
                    }}
                    src={previewImage}
                  />
                </Modal>
              </>
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Kabadiwalla photo</span>}
            >
              <Form.Item style={{ width: "16%" }} className="float-left">
                {/* <Avatar
                  className="bg-indigo float-left mr-5"
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  src={avatar}
                >
                  <UserOutlined />
                </Avatar> */}
                {avatar ? (
                  <Image
                    className="bg-indigo float-left mr-10"
                    // shape="square"
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "50%",
                    }}
                    src={avatar}
                  ></Image>
                ) : (
                  <Avatar
                    className="bg-indigo float-left mr-5"
                    style={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <UserOutlined />
                  </Avatar>
                )}
              </Form.Item>
              <Form.Item name="kabadiwallaPhoto">
                <Upload
                  name="kcImage"
                  multiple={false}
                  method="PUT"
                  accept={".png,.jpg,.jpeg"}
                  className="ml-6"
                  beforeUpload={async (file) => {
                    let signedURL = await getS3UploadUrl(
                      file.name,
                      file.type,
                      "kabadiwala_image"
                    );
                    if (signedURL[1].statuscode === "Success") {
                      // eslint-disable-next-line
                      let URLs = await uploadFiles([signedURL[0]], [file]);
                      getSignedUrl({
                        variables: {
                          fileName: signedURL[2],
                        },
                      }).then((res) => {
                        let payload = {
                          image: signedURL[2],
                          avatar: res?.data?.get_signed_url?.url,
                        };
                        setKcImage(payload);
                        // updateCollectionCentre({
                        //   variables: {
                        //     id: parseInt( location.pathname.split("/")[4]),
                        //     object: payload,
                        //   },
                        // });
                        setAvatar(res?.data?.get_signed_url?.url);
                      });
                    } else {
                      notification.error({
                        message: "An error occurred",
                      });
                    }
                    return true;
                  }}
                  showUploadList={false}
                >
                  <Button type="primary" ghost size="medium" className="mt-2">
                    Change
                  </Button>
                </Upload>
                <span
                  className="ml-4 cursor-pointer"
                  onClick={() => {
                    let payload = {
                      image: "",
                      avatar: null,
                    };
                    setKcImage(payload);
                    setAvatar(null);
                  }}
                >
                  <img src={deleteIcon} alt="deleteIcon" />
                </span>
              </Form.Item>
            </Form.Item>
            <Form.Item className="mb-0">
              <Form.Item
                label={<span className="text-gray700">Open Time</span>}
                name="openTime"
                style={{ width: "49%" }}
                className="float-left"
                dependencies={["closeTime"]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const beginningTime = moment(value, "h:mm a");
                      const endTime = moment(
                        getFieldValue("closeTime"),
                        "h:mm a"
                      );
                      if (
                        !value ||
                        !getFieldValue("closeTime") ||
                        beginningTime.isBefore(endTime) ||
                        beginningTime === endTime
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Close time should be greater than open time")
                      );
                    },
                  }),
                ]}
              >
                <TimePicker
                  use12Hours
                  className="w-full"
                  format="h:mm A"
                  placeholder="Open Time"
                  showNow={false}
                  // onChange={(time, timeString) => {
                  //   setOpenTime(timeString);
                  // }}
                />
              </Form.Item>
              <Form.Item
                label={<span className="text-gray700">Close Time</span>}
                name="closeTime"
                style={{ width: "49%" }}
                className="float-right"
                dependencies={["openTime"]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const beginningTime = moment(
                        getFieldValue("openTime"),
                        "h:mm a"
                      );
                      const endTime = moment(value, "h:mm a");
                      if (
                        !value ||
                        !getFieldValue("openTime") ||
                        beginningTime.isBefore(endTime) ||
                        beginningTime === endTime
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Close time should be greater than open time")
                      );
                    },
                  }),
                ]}
              >
                <TimePicker
                  use12Hours
                  className="w-full"
                  format="h:mm A"
                  placeholder="Close Time"
                  showNow={false}
                  // onChange={(time, timeString) => {
                  //   setCloseTime(timeString);
                  // }}
                />
              </Form.Item>
            </Form.Item>

            <Form.Item>
              {loading && (
                <span className="text-red ml-44">
                  Uploading image(s), please wait.
                </span>
              )}
              <Button
                type="primary"
                htmlType="submit"
                className="float-right"
                disabled={loading}
              >
                {location.pathname.split("/")[2] === "addLocations"
                  ? "Next"
                  : "Save"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
}

export default AddLocation;
