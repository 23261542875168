import {
  Spin,
  Input,
  Form,
  Select,
  Radio,
  Button,
  Avatar,
  Upload,
  notification,
  message,
  Modal,
  Image,
} from "antd";
import { useEffect, useState, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { getS3UploadUrl, uploadFiles, capitalizeFirst } from "../../../utils";
import TitleHeadingContext from "../../../context/TitleHeadingContext";
import deleteIcon from "../../../assets/deleteIcon.svg";
import {
  GET_SIGNED_URL,
  INSERT_VENDOR,
  GET_VENDOR_DETAILS,
  UPDATE_VENDOR,
  GET_PHONE_EMAIL,
  INSERT_USERS,
  UPDATE_USER,
  ADD_VENDOR_IN_FIREBASE,
  UPDATE_REWARDS_STATUS,
  DELETE_IN_FIREBASE,
} from "./graphql";
import moment from "moment";

const { Option } = Select;

function VendorDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [avatar, setAvatar] = useState(null);
  const [phone, setPhone] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [fileName, setfileName] = useState();
  const [loading, setLoading] = useState(false);
  const [oldNumber, setOldnumber] = useState();
  let mobile = /^[0-9]{10}$/;
  const [logoError, setLogoError] = useState(false);
  const { TextArea } = Input;
  const [getSignedUrl] = useMutation(GET_SIGNED_URL);
  const [insertVendor] = useMutation(INSERT_VENDOR);
  const [updateVendor] = useMutation(UPDATE_VENDOR);
  const [insertUser] = useMutation(INSERT_USERS);
  const [updateUser] = useMutation(UPDATE_USER);
  const [updateRewardStatus] = useMutation(UPDATE_REWARDS_STATUS);
  const [addInFirebase] = useMutation(ADD_VENDOR_IN_FIREBASE);
  const [deleteInFirebase] = useMutation(DELETE_IN_FIREBASE);
  const titleHeading = useContext(TitleHeadingContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: vendorDetails,
    loading: vendorLoading,
    refetch,
  } = useQuery(GET_VENDOR_DETAILS, {
    variables: {
      id: location.pathname.split("/")[4],
    },
  });

  const placeholderText =
    "Tap 'Copy bank related labels' to copy the labels \nLegel Entity or name: \nBank: \nAccount no: \nIFSC Code: \nBranch: \nEmail:  ";

  const {
    data: emailPhoneDetails,
    loading: emailPhoneLoading,
    refetch: emailPhone,
  } = useQuery(GET_PHONE_EMAIL);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form.setFieldsValue({
      bankDetails:
        "Legel Entity or name: \nBank: \nAccount no: \nIFSC Code: \nBranch: \nEmail:  ",
    });
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    refetch();
    emailPhone();
  }, []);

  useEffect(() => {
    if (emailPhoneDetails) {
      let newArray = [];
      let emailListArray = [];
      emailPhoneDetails?.users?.map((item) => {
        newArray.push(item?.phone?.replace("+91", ""));
        emailListArray.push(item.email);
      });
      setPhone(newArray);
      setEmailList(emailListArray);
    }
  }, [emailPhoneDetails]);

  useEffect(() => {
    if (vendorDetails) {
      getSignedUrl({
        variables: {
          fileName: vendorDetails.vendors[0].details.logo,
        },
      }).then((res) => {
        setAvatar(res?.data?.get_signed_url?.url);
      });
      titleHeading.setTitle(vendorDetails.vendors[0].name);
      form.setFieldsValue({
        vendorName: vendorDetails.vendors[0].name,
        vendorLocality: vendorDetails.vendors[0].locality,
        email: vendorDetails.vendors[0].email,
        mobileNumber: vendorDetails.vendors[0].phone,
        vendotStatus: vendorDetails.vendors[0].status,
        paymentCycle: vendorDetails.vendors[0].details.paymentCycle,
        bankDetails: vendorDetails.vendors[0].details.bankDetails,
        address: vendorDetails.vendors[0].details.address,
      });
      setfileName(vendorDetails.vendors[0].details.logo);
      setOldnumber(vendorDetails.vendors[0].phone);
    }
  }, [vendorDetails]);

  const onFinish = (values) => {
    if (!avatar) {
      setLogoError(true);
    } else {
      setLogoError(false);

      if (!location.pathname.split("/")[4]) {
        let userobj = {
          name: values.vendorName,
          email: values.email,
          phone: values.mobileNumber,
          role_id: 3,
        };
        insertUser({
          variables: {
            object: userobj,
          },
        }).then((res) => {
          let user_id = res?.data?.insert_users?.returning[0]?.id;
          let payload = {
            name: values.vendorName,
            locality: values.vendorLocality,
            email: values.email,
            phone: values.mobileNumber,
            status: values.vendotStatus ? values.vendotStatus : "active",
            user_id: user_id,
            details: {
              logo: fileName,
              paymentCycle: values.paymentCycle,
              bankDetails: values.bankDetails,
              address: values.address,
              disabledTimeStamp:
                values.vendotStatus === "disabled" ? moment() : "",
            },
          };
          insertVendor({
            variables: {
              object: payload,
            },
          }).then((res) => {
            let vendorId = res?.data?.insert_vendors?.returning[0]?.id;
            addInFirebase({
              variables: {
                hasura_id: user_id.toString(),
                phone: `+91${values.mobileNumber}`,
              },
            }).then(() => {
              message.open({
                type: "success",
                content: "Vendor created successfully",
              });
              navigate(`/rewards/addRewards/vendorDetails/${vendorId}`, {
                replace: true,
              });
            });
          });
        });
      } else {
        let userobj = {
          name: values.vendorName,
          email: values.email,
          phone: values.mobileNumber,
          role_id: 3,
          status: values.vendotStatus
            ? capitalizeFirst(values.vendotStatus)
            : "Active",
        };
        let user_id = vendorDetails.vendors[0].user_id;
        updateUser({
          variables: {
            user_id: user_id,
            object: userobj,
          },
        }).then(() => {
          let payload = {
            name: values.vendorName,
            locality: values.vendorLocality,
            email: values.email,
            phone: values.mobileNumber,
            status: values.vendotStatus ? values.vendotStatus : "active",
            // user_id: res?.id,
            details: {
              logo: fileName,
              paymentCycle: values.paymentCycle,
              bankDetails: values.bankDetails,
              address: values.address,
              disabledTimeStamp:
                values.vendotStatus === "disabled" ? moment() : "",
            },
          };
          updateVendor({
            variables: {
              id: parseInt(location.pathname.split("/")[4]),
              object: payload,
            },
          }).then((res) => {
            if (values.vendotStatus === "disabled") {
              res?.data?.update_vendors?.returning[0]?.rewards.map((item) => {
                updateRewardStatus({
                  variables: {
                    id: item.id,
                    status:
                      values.vendotStatus === "disabled"
                        ? "disabled"
                        : item.status,
                  },
                });
              });
            }
            if (oldNumber !== values.mobileNumber) {
              addInFirebase({
                variables: {
                  hasura_id: user_id.toString(),
                  phone: `+91${values.mobileNumber}`,
                },
              }).then(() => {
                deleteInFirebase({
                  variables: {
                    phone: `+91${oldNumber}`,
                  },
                }).then(() => {
                  message.open({
                    type: "success",
                    content: "Vendor details updated successfully",
                  });
                });
              });
            } else {
              message.open({
                type: "success",
                content: "Vendor details updated successfully",
              });
            }
          });
        });
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({ vendotStatus: "active" });
  }, []);

  const uploadFile = async (data) => {
    setLoading(true);
    let signedURL = await getS3UploadUrl(
      data.file.name,
      data.file.type,
      "vendor_logo"
    );
    if (signedURL[1].statuscode === "Success") {
      // eslint-disable-next-line
      let URLs = await uploadFiles([signedURL[0]], [data.file]);
      if (URLs) {
        setLoading(false);
      }
      getSignedUrl({
        variables: {
          fileName: signedURL[2],
        },
      }).then((res) => {
        setfileName(signedURL[2]);
        setAvatar(res?.data?.get_signed_url?.url);
      });
    } else {
      notification.error({
        message: "An error occurred",
      });
    }
  };

  return (
    <Spin spinning={vendorLoading || emailPhoneLoading}>
      <div className="rewards bg-bgGray min-h-screen w-full flex justify-center items-center py-4">
        <div className="w-5/12 p-4 bg-white rounded-md shadow-3xl">
          <p className="text-lg mb-0">Vendor details</p>
          <p className="text-sm text-gray500 font-normal">
            This information is for internal use only so please review carefully
            before you save
          </p>
          <Form
            name="about"
            layout="vertical"
            onFinish={onFinish}
            form={form}
            requiredMark={false}
          >
            <Form.Item
              label={<span className="text-gray700">Vendor Logo</span>}
              className="mb-0"
            >
              <Form.Item
                className="float-left"
                style={{
                  width: "120px",
                }}
              >
                {avatar ? (
                  <Image
                    className="bg-indigo float-left mr-10"
                    // shape="square"
                    style={{
                      width: "120px",
                      height: "56px",
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                      borderRadius: "5px",
                    }}
                    src={avatar}
                  ></Image>
                ) : (
                  <Avatar
                    className="bg-indigo float-left mr-10"
                    shape="square"
                    style={{
                      width: "120px",
                      height: "56px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <UserOutlined />
                  </Avatar>
                )}
              </Form.Item>
              <Form.Item name="vendorLogo">
                <Upload
                  name="vendorLogo"
                  multiple={false}
                  method="PUT"
                  accept={".png,.jpg,.jpeg"}
                  beforeUpload={async (file) => {
                    const isLt2M = file.size / 1024 / 1024 < 10;
                    if (!isLt2M) {
                      message.error("Image must smaller than 10MB!");
                      return Upload.LIST_IGNORE;
                    }

                    return isLt2M;
                  }}
                  customRequest={uploadFile}
                  showUploadList={false}
                  className="ml-6"
                >
                  <Button type="primary" ghost size="medium" className="mt-2">
                    Change
                  </Button>
                </Upload>
                <span
                  className="ml-4 cursor-pointer"
                  onClick={() => {
                    setAvatar(null);
                  }}
                >
                  <img src={deleteIcon} alt="deleteIcon" />
                </span>
              </Form.Item>
            </Form.Item>
            {logoError && (
              <Form.Item className="mb-0">
                <p className="text-red mb-0">Upload vendor logo</p>
              </Form.Item>
            )}
            <Form.Item
              label={<span className="text-gray700">Vendor Name</span>}
              name="vendorName"
              rules={[
                {
                  required: true,
                  message: "Please enter a vendor name",
                },
                () => ({
                  validator(_, value) {
                    //   let alphanumeric = /^([ A-Za-z0-9]){1,}$/;
                    if (value && value.match(/^ *$/) !== null) {
                      return Promise.reject("Please enter a vendor name");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Vendor Locality</span>}
              name="vendorLocality"
              rules={[
                {
                  required: true,
                  message: "Please enter a vendor locality",
                },
                () => ({
                  validator(_, value) {
                    //   let alphanumeric = /^([ A-Za-z0-9]){1,}$/;
                    if (value && value.match(/^ *$/) !== null) {
                      return Promise.reject("Please enter a vendor locality");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<span className="textsm">Vendor Email</span>}
              name="email"
              rules={[
                { message: "Enter the email", required: true },
                { type: "email", message: "Enter a valid email!" },
                () => ({
                  validator(_, value) {
                    if (
                      location.pathname.split("/")[4]
                        ? vendorDetails &&
                          value !== vendorDetails.vendors[0].email &&
                          emailList.includes(value)
                        : emailList.includes(value)
                    ) {
                      return Promise.reject("Email already exists");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input size="large" type="text" style={{ borderRadius: "6px" }} />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Vendor Mobile</span>}
              name="mobileNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter a phone number",
                },
                () => ({
                  validator(_, value) {
                    console.log(phone);
                    if (value !== "" && !mobile.test(value)) {
                      return Promise.reject(
                        "Please enter a valid phone number"
                      );
                    } else if (
                      location.pathname.split("/")[4]
                        ? vendorDetails &&
                          value !== vendorDetails.vendors[0].phone &&
                          phone.includes(value)
                        : phone.includes(value)
                      // (phone.includes(value) &&
                      // s   value !== vendorDetails.vendors[0].phone)
                    ) {
                      return Promise.reject("Phone number already exists");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Vendor Address</span>}
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please enter a vendor address",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              label={
                <>
                  <span className="text-gray700">Bank Transfer Details</span>
                  <Button
                    type="link"
                    className="float-right text-green pr-0"
                    onClick={() => {
                      if (form.getFieldValue("bankDetails")) {
                        showModal();
                      } else {
                        form.setFieldsValue({
                          bankDetails:
                            "Legal Entity or name: \nBank: \nAccount no: \nIFSC Code: \nBranch: \nEmail:  ",
                        });
                      }
                    }}
                  >
                    Copy bank related labels
                  </Button>
                </>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter a bank details",
                },
              ]}
              name="bankDetails"
            >
              <TextArea
                style={{
                  height: 170,
                }}
                placeholder={placeholderText}
              />
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Payment Cycle</span>}
              name="paymentCycle"
              rules={[
                {
                  required: true,
                  message: "Please select a payment cycle",
                },
              ]}
            >
              <Select>
                <Option key="Daily" value="Daily">
                  Daily
                </Option>
                <Option key="Weekly" value="Weekly">
                  Weekly
                </Option>
                <Option key="Biweekly" value="Biweekly">
                  Biweekly
                </Option>
                <Option key="Monthly" value="Monthly">
                  Monthly
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={<span className="text-gray700">Vendor Status</span>}
              style={{ height: 0 }}
            ></Form.Item>
            <Form.Item
              label={
                <span className="text-gray500">
                  Disabling removes vendor and all active campaigns from app
                </span>
              }
              name="vendotStatus"
            >
              <Radio.Group defaultValue="active">
                <Radio value="active">Active</Radio>
                <Radio value="disabled">Disabled</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              {loading && (
                <span className="text-red ml-44">
                  Uploading image(s), please wait.
                </span>
              )}
              <Button
                type="primary"
                htmlType="submit"
                className="float-right"
                disabled={loading}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
          <Modal
            title="Confirmation"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>Entered details will not be recovered</p>
          </Modal>
        </div>
      </div>
    </Spin>
  );
}

export default VendorDetails;
