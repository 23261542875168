import { gql } from "@apollo/client";

export const GET_USERS_LOCALITY_LIST = gql`
  query GET_USERS_LOCALITY_LIST {
    users {
      locality
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query GET_USER_DETAILS(
    $where: users_bool_exp
    $startTime: timestamptz
    $endTime: timestamptz
  ) {
    users(where: $where, order_by: { id: desc }) {
      name
      locality
      status
      id
      details
      email
      created_at
      updated_at
      phone
      apartment {
        name
      }
      sales_aggregate(where: { status: { _eq: "Approved" } }) {
        aggregate {
          count
        }
      }
      sales(
        order_by: { created_at: desc }
        where: { status: { _eq: "Approved" } }
      ) {
        points
        total_price
        id
        created_at
        status
        sales_materials_aggregate {
          aggregate {
            sum {
              actual_quantity
            }
          }
        }
      }
      transactionsCount: sales_aggregate(
        where: {
          created_at: { _gte: $startTime, _lte: $endTime }
          status: { _eq: "Approved" }
        }
      ) {
        aggregate {
          count
        }
      }
      user_rewards_aggregate(
        where: { status: { _in: ["Paid", "Redeemed", "Approved"] } }
      ) {
        aggregate {
          count
        }
      }
      user_rewards {
        details
        redeemed_points
        reward {
          id
          total_used_number
          details
        }
      }
    }
  }
`;

export const GET_APARTMENTS = gql`
  query GET_APARTMENTS($where: apartments_bool_exp) {
    apartments(where: $where) {
      name
      locality
    }
  }
`;

export const GET_SIGNED_URL = gql`
  mutation GET_SIGNED_URL($fileName: String) {
    get_signed_url(file_name: $fileName) {
      message
      url
    }
  }
`;

export const UPDATE_USER_STATUS = gql`
  mutation UPDATE_USER_STATUS($id: bigint, $object: users_set_input) {
    update_users(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
    }
  }
`;

export const GET_NOTIFICATION_MESSAGES = gql`
  query GET_NOTIFICATION_MESSAGES($id: Int) {
    notification_user(
      where: { user_id: { _eq: $id } }
      order_by: { id: desc }
    ) {
      notification {
        text
        created_at
      }
    }
  }
`;

export const GET_USER_STATUS = gql`
  query GET_USER_STATUS(
    $id: bigint
    $startTime: timestamptz
    $endTime: timestamptz
  ) {
    users(where: { id: { _eq: $id } }) {
      name
      locality
      status
      id
      details
      email
      created_at
      phone
      apartment {
        name
      }
      sales_aggregate(where: { status: { _eq: "Approved" } }) {
        aggregate {
          count
        }
      }
      sales(
        order_by: { created_at: desc }
        where: { status: { _eq: "Approved" } }
      ) {
        points
        total_price
        id
        created_at
        status
        sales_materials_aggregate {
          aggregate {
            sum {
              actual_quantity
            }
          }
        }
      }
      transactionsCount: sales_aggregate(
        where: {
          created_at: { _gte: $startTime, _lte: $endTime }
          status: { _eq: "Approved" }
        }
      ) {
        aggregate {
          count
        }
      }
      user_rewards_aggregate {
        aggregate {
          count
        }
      }
      user_rewards {
        details
        redeemed_points
        reward {
          id
          total_used_number
          details
        }
      }
    }
  }
`;

export const GET_SALES_DETAILS = gql`
  query GET_SALES_DETAILS($where: sales_bool_exp, $id: bigint) {
    sales(where: $where, order_by: { id: desc }) {
      id
      total_price
      receipt
      collection_centre {
        name
        locality
      }
      status
      created_at
      sales_materials(order_by: { material: { name: asc } }) {
        material {
          name
        }
      }
      sales_materials_aggregate {
        aggregate {
          sum {
            actual_quantity
          }
        }
      }
    }
    users(where: { id: { _eq: $id } }) {
      name
      details
      locality
    }
  }
`;

export const GET_USER_REWARD = gql`
  query GET_USER_REWARD($where: user_reward_bool_exp, $id: bigint) {
    user_reward(where: $where, order_by: { id: desc }) {
      id
      reward_id
      code
      is_redeemed
      payment_at
      razorpay_transaction_id
      redeemed_at
      reward {
        name
        location
        details
        vendor {
          name
          locality
          details
          status
          created_at
        }
      }
      details
      status
      transaction_id
      transaction {
        created_at
        payment_details
      }
      user {
        name
      }
    }
    users(where: { id: { _eq: $id } }) {
      name
    }
  }
`;

export const INSERT_NOTIFICATION = gql`
  mutation INSERT_NOTIFICATION($object: [notifications_insert_input!]!) {
    insert_notifications(objects: $object) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const INSERT_NOTIFICATION_USER = gql`
  mutation INSERT_NOTIFICATION_USER(
    $object: [notification_user_insert_input!]!
  ) {
    insert_notification_user(objects: $object) {
      affected_rows
    }
  }
`;

export const SEND_PUSH_NOTIFICATIONS = gql`
  mutation SEND_PUSH_NOTIFICATIONS(
    $message: jsonb
    $title: String
    $user_ids: [Int]!
  ) {
    send_notifications(message: $message, title: $title, user_ids: $user_ids) {
      message
    }
  }
`;

export const DELETE_IN_FIREBASE = gql`
  mutation DELETE_IN_FIREBASE($phone: String!) {
    delete_vendor(phone: $phone) {
      message
    }
  }
`;
