/* eslint-disable array-callback-return */
import Select from "../../components/Select";
import { Button, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import CountCard from "../../components/CountCard";
import Table from "../../components/Table";
import Tag from "../../components/Tag";
import { numFormatter } from "../../utils";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useState, useMemo, useEffect } from "react";
import { GET_CAMPAIGN_DETAILS } from "./graphql";
import CampaignDrawer from "./campaignDrawer";
import { getColumnSearchProps } from "../../utils";
import moment from "moment";

function Rewards() {
  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const [tableData, setTableData] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [localityList, setLocalityList] = useState([]);
  const [onboardVendor, setOnnboardVendor] = useState(0);
  const [activeVendor, setActiveVendor] = useState(0);
  const [activeCampaign, setActiveCampaign] = useState(0);
  const [couponsSold, setCouponsSold] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [searchLocName, setSearchLocName] = useState();
  const [category, setCategory] = useState();
  const [kcprofit, setKcprofit] = useState(0);
  const [searchTerm, setSearchTerm] = useState({});
  const [selectLocation, setSelectLocation] = useState();
  const [selectVendor, setSelectVendor] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const [currentItem, setCurrentItem] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [rewardsTerm, setRewardsTerm] = useState({});

  const [
    getCampaignDetails,
    { data: campaignDetails, loading: campaignLoading },
  ] = useLazyQuery(GET_CAMPAIGN_DETAILS, { fetchPolicy: "no-cache" });

  const {
    data: filterDetails,
    loading: filterDetailsLoading,
    refetch,
  } = useQuery(GET_CAMPAIGN_DETAILS, {
    variables: {
      where: {
        _and: { name: {} },
      },
    },
  });

  useEffect(() => {
    refetch();
    getCampaignDetails({
      variables: {
        where: {
          _and: { name: {} },
        },
      },
    });
  }, []);

  useEffect(() => {
    if (Object.keys(searchTerm).length > 0) {
      getCampaignDetails({
        variables: { where: searchTerm, rewardsWhere: rewardsTerm },
      });
    }
  }, [searchTerm, rewardsTerm]);

  useEffect(() => {
    setSearchLocName(new URLSearchParams(search).get("searchText"));
    setCategory(new URLSearchParams(search).get("category"));
    if (location.pathname.split("/")[2] === "drawer") setOpenDrawer(true);
  }, [location]);

  useEffect(() => {
    if (
      new URLSearchParams(search).get("searchText") &&
      new URLSearchParams(search).get("category") === "Vendor name"
    ) {
      setSearchTerm({
        _and: {
          name: selectVendor
            ? { _in: selectVendor.length > 0 ? selectVendor : vendorList }
            : {},
          _or: [{ name: { _ilike: `%${searchLocName}%` } }],
        },
      });

      setRewardsTerm({});
    } else if (
      new URLSearchParams(search).get("searchText") &&
      new URLSearchParams(search).get("category") === "Campaign name"
    ) {
      setSearchTerm({
        _and: {
          name: selectVendor
            ? { _in: selectVendor.length > 0 ? selectVendor : vendorList }
            : {},
          _or: [{ rewards: { name: { _ilike: `%${searchLocName}%` } } }],
        },
      });
      setRewardsTerm({ name: { _ilike: `%${searchLocName}%` } });
    } else if (
      new URLSearchParams(search).get("searchText") &&
      new URLSearchParams(search).get("category") === "null"
    ) {
      setSearchTerm({
        _and: {
          name: selectVendor
            ? { _in: selectVendor.length > 0 ? selectVendor : vendorList }
            : {},
          _or: [
            { rewards: { name: { _ilike: `%${searchLocName}%` } } },
            { name: { _ilike: `%${searchLocName}%` } },
          ],
        },
      });
      setRewardsTerm({
        _or: [
          { vendor: { name: { _ilike: `%${searchLocName}%` } } },
          { name: { _ilike: `%${searchLocName}%` } },
        ],
      });
    } else {
      setSearchTerm({
        _and: {
          name: selectVendor
            ? { _in: selectVendor.length > 0 ? selectVendor : vendorList }
            : {},
        },
      });
      setRewardsTerm({});
    }
  }, [selectVendor, searchLocName, category]);

  useEffect(() => {
    if (filterDetails) {
      let localityListArray = [];
      let vendorArray = [];
      filterDetails?.vendors?.map((item) => {
        item.rewards.map((reward) => {
          localityListArray.push(reward.location);
        });
      });

      filterDetails?.vendors?.map((user) => {
        vendorArray.push(user.name);
      });
      let list = localityListArray.filter(
        (item, index) => localityListArray.indexOf(item) === index
      );
      list.sort();
      let vendorList = vendorArray.filter(
        (item, index) => vendorArray.indexOf(item) === index
      );
      vendorList.sort();
      setLocalityList(list);
      setVendorList(vendorList);
    }
  }, [filterDetails]);

  useEffect(() => {
    if (campaignDetails) {
      let newList = [];
      let vendorArray = [];
      let activeVendorArr = [];
      let campaignArr = [];
      let couponsSoldArr = 0;
      let revenue = 0;
      let profitsum = 0;
      campaignDetails?.vendors.map((vendor) => {
        vendorArray.push(vendor.name);
        if (vendor.status === "active") activeVendorArr.push(vendor.name);

        if (vendor.rewards.length > 0) {
          vendor.rewards.map((item) => {
            let denominator = item.details.couponsCost * item.total_used_number;
            var start = moment();
            var end = moment(item.details.expiryDate);

            newList.push({
              id: vendor.id,
              camid: item.id,
              // campaignID: item.id,
              campaignName: item.name,
              vendorName: vendor.name,
              email: vendor.email,
              locality: item.location,
              phone: vendor.phone,
              // status: item.status,
              status:
                end.diff(start, "days") <= 0
                  ? "expired"
                  : item.total_used_number >= parseInt(item.details.totalCount)
                  ? "disabled"
                  : item.status,
              vendorStatus: vendor.status,
              created_at:
                vendor.status === "disabled"
                  ? vendor.details.disabledTimeStamp
                  : vendor.created_at,
              couponCost: item.details.couponsCost,
              couponAvailability: item.details.totalCount,
              couponUsed: item.total_used_number,

              profit:
                item.total_used_number === 0
                  ? 0
                  : (item.details.couponsCost - item.details.vendorCost) *
                    item.total_used_number,
              kcMargin:
                item.total_used_number === 0
                  ? 0
                  : ((item.details.couponsCost - item.details.vendorCost) *
                      item.total_used_number *
                      100) /
                    denominator,
              image: vendor.details.logo,
            });
          });
        } else {
          newList.push({
            id: vendor.id,
            vendorName: vendor.name,
            email: vendor.email,
            phone: vendor.phone,
            created_at:
              vendor.status === "disabled"
                ? vendor.details.disabledTimeStamp
                : vendor.created_at,
            camid: "-",
            // campaignID: "-",
            campaignName: "-",
            status: vendor.status,
            vendorStatus: vendor.status,
            locality: "-",
            couponUsed: 0,
            couponCost: 0,
            kcMargin: 0,
            couponAvailability: "-",
            image: vendor.details.logo,
          });
        }
      });
      newList.sort((a, b) => {
        return b.camid - a.camid;
      });

      newList.map((ele) => {
        if (ele.status === "active") campaignArr.push(ele.camid);
      });
      newList.map((ele) => {
        if (ele.camid !== "-") {
          couponsSoldArr += parseInt(ele.couponUsed);
          if (ele.couponUsed !== 0)
            revenue += parseInt(ele.couponCost * ele.couponUsed);
          profitsum += parseInt(ele.profit);
        }
      });

      if (selectLocation && selectLocation.length > 0) {
        let DisplayData = newList?.filter((ele) => {
          return selectLocation.includes(ele.locality);
        });
        setTableData(DisplayData);
      } else if (selectStatus) {
        let DisplayData = newList?.filter((ele) => {
          return ele.status === selectStatus.toLowerCase();
        });
        setTableData(DisplayData);
      } else {
        setTableData(newList);
      }

      setOnnboardVendor(vendorArray.length);
      setActiveVendor(activeVendorArr.length);
      setActiveCampaign(campaignArr.length);
      setCouponsSold(couponsSoldArr);
      setTotalRevenue(revenue);
      setKcprofit(profitsum);
    }
  }, [campaignDetails, selectLocation, selectStatus]);

  const handleselectedValues = (value, type) => {
    if (type === "offerStatus" && value !== "All") {
      setSelectStatus(value);
    } else if (type === "offerStatus") {
      setSelectStatus();
    }
    if (type === "vendors") setSelectVendor(value);
    if (type === "locality") setSelectLocation(value);
  };

  const columns = useMemo(
    () => [
      {
        title: "CAMPAIGN ID",
        dataIndex: "camid",
        key: "camid",
        className: "w-40",
        ...getColumnSearchProps("camid"),
        render: (text) => <>{`CAM${`${text}`.padStart(6, "0")}`}</>,
      },
      {
        title: "CAMPAIGN NAME",
        dataIndex: "campaignName",
        key: "campaignName",
        className: "w-40",
      },
      {
        title: "VENDOR NAME",
        dataIndex: "vendorName",
        key: "vendorName",
        className: "w-40",
      },
      {
        title: "LOCALITY",
        dataIndex: "locality",
        key: "locality",
        className: "w-40",
      },
      {
        title: "STATUS",
        dataIndex: "status",
        key: "status",
        className: "w-40",
        render: (text) => (
          <>
            {text === "expired" && (
              <Tag color="#FEE2E2" textColor="#991B1B">
                Expired
              </Tag>
            )}
            {text === "active" && (
              <Tag color="#D1FAE5" textColor="#065F46">
                Active
              </Tag>
            )}
            {text === "inactive" && (
              <Tag color="#F3F4F6" textColor="#1F2937">
                Inactive
              </Tag>
            )}
            {text === "disabled" && (
              <Tag color="#F3F4F6" textColor="#1F2937">
                Disabled
              </Tag>
            )}
            {text === "-" && <span>-</span>}
          </>
        ),
      },
      {
        title: "COUPON COST",
        dataIndex: "couponCost",
        key: "couponCost",
        className: "w-40",
        sorter: (a, b) => a.couponCost - b.couponCost,
        render: (text) =>
          text ? (
            <>
              <span className="float-right">Rs. {numFormatter(text)} </span>
            </>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "COUPON AVAILABILITY",
        dataIndex: "couponUsed",
        key: "couponUsed",
        className: "w-52",
        sorter: (a, b) => a.couponUsed - b.couponUsed,
        render: (text, record) => (
          <>
            {text !== "-" && record.couponAvailability !== "-" ? (
              <span className="float-right">
                {`${text ? numFormatter(text) : text} / ${
                  record.couponAvailability
                }`}
              </span>
            ) : (
              <span className="float-right">-</span>
            )}
          </>
        ),
      },
      {
        title: "KC MARGIN (%)",
        dataIndex: "kcMargin",
        key: "kcMargin",
        className: "w-40",
        sorter: (a, b) => a.kcMargin - b.kcMargin,
        render: (text) =>
          text ? (
            <span className="float-right">{text.toFixed(2)} %</span>
          ) : (
            <span className="float-right">-</span>
          ),
      },
      {
        title: "MANAGE",
        dataIndex: "",
        key: "",
        fixed: "right",
        className: "w-32",
        render: (text, record) => (
          <Button
            type="link"
            className="text-green hover:text-green"
            onClick={() => {
              setOpenDrawer(true);
              setCurrentItem(record);
            }}
            id="details"
          >
            Details
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <Spin spinning={campaignLoading || filterDetailsLoading}>
      <div className="flex flex-col gap-5 p-8 w-full justify-between ">
        <div className="flex flex-row gap-5  w-full ">
          <div style={{ width: "13%" }}>
            <Select
              placeholder="City"
              type="City"
              data={["Chennai"]}
              onChange={handleselectedValues}
              value="Chennai"
              allowClear={true}
            />
          </div>

          <div style={{ width: "20%" }}>
            <Select
              placeholder="Locality"
              type="locality"
              mode="multiple"
              data={localityList || []}
              onChange={handleselectedValues}
              allowClear={true}
            />
          </div>
          <div style={{ width: "16%" }}>
            <Select
              placeholder="All vendors"
              type="vendors"
              data={vendorList || []}
              mode="multiple"
              onChange={handleselectedValues}
              allowClear={true}
            />
          </div>
          <div style={{ width: "18%" }}>
            <Select
              placeholder="Offer Status"
              type="offerStatus"
              data={["All", "Active", "Disabled", "Expired"]}
              onChange={handleselectedValues}
              value=""
              allowClear={true}
            />
          </div>
          <div style={{ width: "13%" }}></div>
          <div style={{ width: "13%" }}>
            <Button
              type="primary"
              id="addlocations"
              size="large"
              className="float-right"
              onClick={() => navigate("/rewards/addRewards/vendorDetails")}
            >
              Add Vendor
            </Button>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-6 gap-5">
            <CountCard
              count={onboardVendor}
              title="Total vendors onboarded"
              className="CountCard"
            />
            <CountCard
              count={activeVendor}
              title="Total active vendors"
              className="CountCard"
            />
            <CountCard
              count={activeCampaign}
              title="Total active campaigns"
              className="CountCard"
            />
            <CountCard
              count={couponsSold}
              title="Total coupons sold"
              tab="rewards"
              className="CountCard"
            />
            <CountCard
              count={totalRevenue}
              title="Total revenue from coupon sales"
              className="CountCard"
              symbol="Rs. "
            />
            <CountCard
              count={kcprofit}
              title="Total profits for KC"
              tab="rewards"
              className="CountCard"
              symbol="Rs. "
            />
          </div>
        </div>
        <div className="w-full">
          <Table
            dataSource={!!tableData.length ? tableData : []}
            columns={columns}
            key="usersListing"
            pagination={true}
            scroll={{
              x: 1300,
            }}
          />
        </div>
        {openDrawer && (
          <CampaignDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
          />
        )}
      </div>
    </Spin>
  );
}

export default Rewards;
