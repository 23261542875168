import { Button, Input, Space } from "antd";
import _ from "lodash";
import { SearchOutlined } from "@ant-design/icons";

export const getToken = () => {
  const user = localStorage.getItem("token");
  if (user) {
    return true;
  } else {
    return false;
  }
};

export const avatarMap = (name, maxInitials) => {
  return name
    .split(/\s/)
    .map(function (part) {
      return part.substring(0, 1).toUpperCase();
    })
    .filter(function (v) {
      return !!v;
    })
    .slice(0, maxInitials)
    .join("");
};

export async function getS3UploadUrl(fileName, contentType, fileType) {
  const query = {
    query: `mutation GET_UPLOAD_URL($contentType: String!, $fileName: String!,$fileType:String!) {
      get_upload_url(content_type: $contentType, file_name: $fileName, file_type: $fileType) {
        url
        message
        data
        file_name
        error
      }
    }
    `,
    variables: {
      fileName: fileName,
      contentType: contentType,
      fileType: fileType,
    },
  };
  let postData = await fetch(`${process.env.REACT_APP_API}`, {
    method: "POST",
    body: JSON.stringify(query),
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
      // "x-hasura-admin-secret": "Remember001",
    },
  });
  const { data: uploadLink } = await postData.json();
  return [
    uploadLink.get_upload_url.url,
    { statuscode: uploadLink.get_upload_url.message },
    uploadLink.get_upload_url.file_name,
  ];
}

export function uploadFiles(urlList, fileList) {
  return new Promise((resolve, reject) => {
    const formUploadPromiseArray = fileList.map(
      (file, index) =>
        new Promise((resolveUpload, rejectUpload) => {
          const xhr = new XMLHttpRequest();
          xhr.file = file;
          xhr.onreadystatechange = function (e) {
            if (this.readyState === 4 && this.status === 200) {
              resolveUpload(urlList[index]);
            } else if (this.readyState === 4 && this.status !== 200) {
              rejectUpload(e);
            }
          };
          xhr.open("put", urlList[index], true);
          if (file.type === "image/png" || file.type === "image/jpeg") {
            xhr.setRequestHeader("Content-Type", file.type);
          }
          xhr.send(file);
        })
    );

    Promise.all(formUploadPromiseArray)
      .then((urls) => {
        resolve(urls.map((url) => url.split("?")[0]));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const dupInArray = (array) => {
  const duplicates = array.filter(
    (item, index) => array.indexOf(item) !== index
  );

  return duplicates;
};

export const removeDuplicates = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

export const capitalizeFirst = (str) => {
  return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numFormatter(number) {
  if (number > 1000000000) {
    return Math.ceil(number / 1000000000) + "B";
  } else if (number > 1000000) {
    return Math.ceil(number / 1000000) + "M";
  } else if (number > 10000) {
    return Math.ceil(number / 10000) + "K";
  } else {
    return number;
  }
}

export const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {}); // empty object is the initial value for result object
};

export const arrayEquals = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

function isBool(x) {
  return typeof x === "boolean";
}

function getKeys(obj, prefix = "") {
  if (typeof obj === "undefined" || obj === null) return [];
  return [
    ...Object.keys(obj).map((key) => `${prefix}${key}`),
    ...Object.entries(obj).reduce((acc, [key, value]) => {
      if (typeof value === "object")
        return [...acc, ...getKeys(value, `${prefix}${key}.`)];
      return acc;
    }, []),
  ];
}
function flatObject(obj, prefix = "") {
  if (typeof obj === "undefined" || obj === null) return {};
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === "object")
      return { ...acc, ...flatObject(value, `${prefix}${key}.`) };
    return { ...acc, [`${prefix}${key}`]: value };
  }, {});
}

function escapeCsvValue(cell) {
  if (cell.replace(/ /g, "").match(/[\s,"]/)) {
    return '"' + cell.replace(/"/g, '""') + '"';
  }
  return cell;
}

const getRenderData = (data) => {
  let str = "";
  if (typeof data !== "string")
    if (Array.isArray(data.props?.children)) {
      data.props?.children.map((child) => {
        if (child) {
          if (typeof child === "object") {
            str += getRenderData(child);
          } else {
            str += child;
          }
        }
      });
      // str += data.props?.children.join("");
    } else if (typeof data.props?.children === "object") {
      str += getRenderData(data.props?.children);
    } else if (typeof data.props?.children === "boolean") {
      str = "";
    } else {
      str +=
        data.props?.children !== 0
          ? data.props?.children
          : data.props?.children === 0
          ? 0
          : "";
    }
  else {
    str += data;
  }
  return str;
};

function objectsToCsv(arrayOfObjects, colKeys) {
  // collect all available keys
  const keys = new Set(
    arrayOfObjects.reduce((acc, item) => [...acc, ...getKeys(item)], [])
  );
  const objectKeys = new Set();
  for (let key of keys.keys()) {
    objectKeys.add(colKeys[key]);
  }
  // for each object create all keys
  const values = arrayOfObjects.map((item) => {
    const fo = flatObject(item);
    const val = Array.from(keys).map((key) =>
      key in fo ? escapeCsvValue(`${fo[key]}`) : ""
    );
    return val.join(",");
  });
  return `${Array.from(objectKeys).join(",")}\n${values.join("\n")}`;
}

export function downloadCSVFile(records, columns, fileName) {
  let finalRecord = (records || []).map((record, idx) => {
    const finalRecord = {};

    for (const col of columns) {
      const propName = col.dataIndex || col.key;
      if (propName && isBool(col.isExportable) ? col.isExportable : true) {
        const text = record[propName];
        finalRecord[propName] = text;
        //
        if (col.render) {
          finalRecord[propName] = getRenderData(col.render(text, record, idx)); //col.render(text, record, idx);
        } else {
          finalRecord[propName] = text !== 0 ? text : text === 0 ? 0 : "";
        }
      }
    }
    return finalRecord;
  });
  let colKeys = {};
  columns.map((column) => {
    const propName = column.dataIndex || column.key;
    if (propName) {
      colKeys[propName] = column.title;
    }
  });
  let csv = objectsToCsv(finalRecord, colKeys);
  const blob = new Blob([csv]);
  const a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = `${fileName || "tableData"}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters();
};

let searchInput;

export const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => <SearchOutlined />,
  // onFilter: (value, record) =>
  //   record[dataIndex]
  //     ? record[dataIndex]
  //         .toString()
  //         .toLowerCase()
  //         .includes(value.toLowerCase())
  //     : "",
  onFilter: (value, record) => {
    let getRecord = _.get(record, dataIndex);
    return getRecord
      ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
      : "";
  },
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
});
