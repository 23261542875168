import { gql } from "@apollo/client";

export const GET_SALES_LISTING = gql`
  query GET_SALES_LISTING(
    $where: sales_bool_exp
    $salesWhere: sales_materials_bool_exp
  ) {
    sales(where: $where, order_by: { id: desc }) {
      id
      total_price
      receipt
      collection_centre {
        name
        locality
      }
      status
      created_at
      sales_materials(
        order_by: { material: { name: asc } }
        where: $salesWhere
      ) {
        material {
          name
        }
      }
      sales_materials_aggregate {
        aggregate {
          sum {
            actual_quantity
          }
        }
      }
      user {
        id
        details
        name
        apartment {
          name
        }
        locality
      }
    }
    users(where: { role_id: { _eq: 1 } }) {
      name
    }
  }
`;

export const GET_APARTMENTS = gql`
  query GET_APARTMENTS($where: apartments_bool_exp) {
    apartments(where: $where) {
      name
      locality
    }
  }
`;

export const GET_USERS_LOCALITY_LIST = gql`
  query GET_USERS_LOCALITY_LIST {
    users {
      locality
      sales {
        id
      }
      apartment {
        locality
      }
    }
    collection_centre {
      locality
      status
      name
      sales {
        id
      }
    }
  }
`;

export const GET_SALES_MATERIAL = gql`
  query GET_SALES_MATERIAL {
    sales_materials(distinct_on: material_id) {
      material {
        id
        name
      }
    }
  }
`;

export const GET_TOP_MATERIALS = gql`
  query GET_TOP_MATERIALS($startDate: timestamptz, $endDate: timestamptz) {
    sales_materials(
      where: {
        updated_at: { _gte: $startDate, _lte: $endDate }
        status: { _eq: "Approved" }
      }
    ) {
      price
      actual_quantity
      material_id
      material {
        name
      }
    }
  }
`;

export const GET_STATS = gql`
  query GET_STATS($start: timestamptz, $end: timestamptz) {
    sales(
      where: {
        status: { _eq: "Approved" }
        updated_at: { _gte: $start, _lte: $end }
      }
    ) {
      updated_at
      total_price
      sales_materials_aggregate {
        aggregate {
          sum {
            actual_quantity
          }
        }
      }
    }
  }
`;

export const GET_SIGNED_URL = gql`
  mutation GET_SIGNED_URL($fileName: String) {
    get_signed_url(file_name: $fileName) {
      message
      url
    }
  }
`;

export const GET_USER_SALE_DETAIL = gql`
  query GET_USER_SALE_DETAIL($id: bigint) {
    sales(where: { id: { _eq: $id } }) {
      total_price
      points
      receipt
      comment
      sales_logs
      sales_materials(order_by: { id: asc }) {
        id
        material {
          name
        }
        price
        actual_quantity
      }
      user_id
    }
  }
`;

export const UPDATE_USER_SALE_STATUS = gql`
  mutation UPDATE_USER_SALE_STATUS($id: bigint, $object: sales_set_input) {
    update_sales(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
      returning {
        sales_logs
        id
        sales_materials {
          id
        }
      }
    }
  }
`;

export const UPDATE_SALE_MATERIALS_STATUS = gql`
  mutation UPDATE_SALE_MATERIALS_STATUS($id: bigint, $status: String) {
    update_sales_materials(
      where: { id: { _eq: $id } }
      _set: { status: $status }
    ) {
      affected_rows
      returning {
        sales_id
        sale {
          sales_logs
        }
      }
    }
  }
`;

export const UPDATE_SALE_MATERIAL_VALUE = gql`
  mutation UPDATE_SALE_MATERIAL_VALUE(
    $id: bigint
    $object: sales_materials_set_input
  ) {
    update_sales_materials(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
      returning {
        sales_id
        sale {
          sales_logs
        }
      }
    }
  }
`;

export const UPDATE_SALE_LOGS = gql`
  mutation UPDATE_SALE_LOGS($id: bigint, $object: sales_set_input) {
    update_sales(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
      returning {
        sales_materials {
          id
        }
      }
    }
  }
`;

export const SEND_PUSH_NOTIFICATIONS = gql`
  mutation SEND_PUSH_NOTIFICATIONS(
    $message: jsonb
    $title: String
    $user_ids: [Int]!
  ) {
    send_notifications(message: $message, title: $title, user_ids: $user_ids) {
      message
    }
  }
`;
