import React, { useState } from "react";
import { Input } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "../assets/searchCloseIcon.svg";

export default function Search({
  searchVisible,
  setSearchVisible,
  setSelectedCategory,
  selectedCategory,
  placeholder,
  tag1,
  tag2,
  tag3,
  tag4,
  role,
  ...rest
}) {
  const navigate = useNavigate();
  const search = useLocation().search;
  const location = useLocation();
  const [searchText, setSearchText] = useState(null);
  const recentList = localStorage.getItem(`recentSearches_${role}`)
    ? JSON.parse(localStorage.getItem(`recentSearches_${role}`)).reverse()
    : [];

  React.useEffect(() => {
    setSearchText(
      new URLSearchParams(search).get("searchText") !== null
        ? new URLSearchParams(search).get("searchText")
        : null
    );
  }, [search]);

  const handlesearch = (value) => {
    var names =
      JSON.parse(localStorage.getItem(`recentSearches_${role}`)) !== null
        ? JSON.parse(localStorage.getItem(`recentSearches_${role}`))
        : [];
    if (names.length < 3) {
      names.push(value);
    } else {
      names.push(value);
      names.shift();
    }
    let newList = names.filter((item, index) => names.indexOf(item) === index);
    localStorage.setItem(`recentSearches_${role}`, JSON.stringify(newList));

    value === ""
      ? navigate(`${location.pathname}`, {
          replace: true,
        })
      : navigate(
          `${location.pathname}?searchText=${value}&category=${
            selectedCategory || null
          }`
        );

    setSelectedCategory("");
    setSearchVisible(false);
  };

  return (
    <>
      <div className="w-full mx-auto mt-2 relative h-10 md:w-96">
        <div
          className={`absolute left-0 transition-all duration-500 right-0  rounded-lg ${
            searchVisible && selectedCategory
              ? " z-50 md:w-[450px] p-5 bg-white"
              : ""
          } `}
          style={{ border: "1px solid #27AE60" }}
        >
          <Input
            key="pageSearch"
            placeholder={placeholder}
            type="search"
            className={`border-none  top-0 left-0 transition-all duration-500 rounded-lg ${
              searchVisible ? "shadow-md" : ""
            } ${
              selectedCategory && searchVisible ? "border-none shadow-sm" : ""
            }`}
            suffix={
              <span
                className="cursor-pointer"
                onClick={() => {
                  if (searchText) {
                    setSearchVisible(false);
                    setSelectedCategory("");
                    setSearchText("");
                    navigate(`${location.pathname}`, {
                      replace: true,
                    });
                    // navigate("/locations", { replace: true });
                  }
                }}
              >
                <img
                  src={SearchIcon}
                  alt="close icon"
                  style={{ width: "12px", height: "12px" }}
                />
              </span>
            }
            prefix={
              selectedCategory ? (
                <div
                  className=" border border-green rounded-md text-xs font-semibold text-green px-3 py-2 cursor-pointer flex justify-center items-center mr-5 "
                  onClick={() => {
                    setSelectedCategory("");
                  }}
                  style={{ border: "1px solid #27AE60" }}
                >
                  {selectedCategory}
                  <CloseOutlined style={{ color: "#000" }} className="ml-8" />
                </div>
              ) : (
                <SearchOutlined className=" mr-4" />
              )
            }
            size="large"
            // onFocus={() => {
            //   setSearchVisible(true);
            // }}
            onClick={() => {
              console.log("here");
              setSearchVisible(true);
            }}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onPressEnter={(e) => {
              e.preventDefault();
              handlesearch(e.target.value);
            }}
          />
          {selectedCategory && recentList && recentList.length !== 0 && (
            <div
              className={` rounded-lg  left-0 right-0 top-full bg-white pt-5`}
            >
              <div className=" text-sm font-normal text-black mb-2">
                Recent searches
              </div>
              {recentList.map((item) => {
                return (
                  <div
                    className=" text-sm font-semibold text-black mb-2 cursor-pointer"
                    onClick={() => {
                      setSearchText(item);
                      handlesearch(item);
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          )}
          {location.pathname.split("/")[3] !== "pastPayments" && (
            <div
              className={` absolute left-0 right-0 bg-white top-full mt-2 py-2 px-3 border-green border shadow-md rounded-br-lg rounded-bl-lg transition duration-500 ${
                !searchVisible || selectedCategory
                  ? " opacity-0 pointer-events-none"
                  : " z-50 md:opacity-100 "
              } `}
            >
              {recentList && recentList.length !== 0 && (
                <div
                  className={` rounded-lg  left-0 right-0 top-full bg-white pt-5`}
                >
                  <div className=" text-xs font-normal text-black mb-2">
                    Recent searches
                  </div>
                  {recentList.map((item) => {
                    return (
                      <div
                        className=" text-sm font-semibold text-black mb-2 cursor-pointer hover:text-green"
                        onClick={() => {
                          setSearchText(item);
                          handlesearch(item);
                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
              )}
              <div className=" text-xs font-normal text-black mb-2">
                Search in
              </div>

              <div className="flex items-center flex-wrap gap-4">
                <div
                  className=" border border-green rounded-md text-xs font-semibold text-green px-3 py-2 cursor-pointer "
                  onClick={() => {
                    setSelectedCategory(tag1);
                  }}
                  style={{ border: "1px solid #27AE60" }}
                >
                  {tag1}
                </div>
                {tag2 && (
                  <div
                    className=" border border-green rounded-md text-xs font-semibold text-green px-3 py-2 cursor-pointer "
                    onClick={() => {
                      setSelectedCategory(tag2);
                    }}
                    style={{ border: "1px solid #27AE60" }}
                  >
                    {tag2}
                  </div>
                )}
                {tag3 && (
                  <div
                    className=" border border-green rounded-md text-xs font-semibold text-green px-3 py-2 cursor-pointer capitalize"
                    onClick={() => {
                      setSelectedCategory(tag3);
                    }}
                    style={{ border: "1px solid #27AE60" }}
                  >
                    {tag3}
                  </div>
                )}
                {tag4 && (
                  <div
                    className=" border border-green rounded-md text-xs font-semibold text-green px-3 py-2 cursor-pointer capitalize"
                    onClick={() => {
                      setSelectedCategory(tag4);
                    }}
                    style={{ border: "1px solid #27AE60" }}
                  >
                    {tag4}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
