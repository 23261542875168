import { Tag as AntTag } from "antd";
import React from "react";
import styled from "styled-components";

function Tag({ children, color, textColor }) {
  const TagStyle = styled.div`
    .ant-tag {
      padding: 6px 12px;
      background-color: ${color};
      opacity: 0.8;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: ${textColor};
      border-radius: 15px;
    }
  `;
  return (
    <TagStyle>
      <AntTag color={` ${color ? color : ""}`}>{children}</AntTag>
    </TagStyle>
  );
}

export default Tag;
