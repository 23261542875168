import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./App.css";
import client from "./client/ApolloClient";
import { ConfigProvider } from "antd";
import reportWebVitals from "./reportWebVitals";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            hashed: false,
            token: {
              colorPrimary: "#27AE60",
            },
          }}
        >
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
  // ,
  // document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
